import * as React from 'react';
import { useMemo } from 'react';

import type { Editor } from 'slate';

import { CodeBlockContext } from 'bundles/cml/editor/context/codeBlockContext';
import type { CodeBlockOptions } from 'bundles/cml/legacy/types/Content';
import { getCodeBlockIndices } from 'bundles/cml/shared/components/code/utils';

export type Props = {
  editor: Editor;
  codeBlockOptions?: CodeBlockOptions;
};

const CodeBlockContextProvider: React.LegacyFunctionComponentWithChildren<Props> = ({
  codeBlockOptions,
  editor,
  children,
}) => {
  const context = useMemo(
    () => ({ codeBlockOptions, codeBlockIndices: getCodeBlockIndices(editor.children) }),
    [codeBlockOptions, editor]
  );

  return <CodeBlockContext.Provider value={context}>{children}</CodeBlockContext.Provider>;
};

export default CodeBlockContextProvider;
