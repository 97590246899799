import * as React from 'react';
import { createContext, useContext, useMemo } from 'react';

type ProviderProps = {
  isReadOnly: boolean;
};

export type ContextProps = {
  isReadOnly: boolean;
};

export const ReadOnlyContext = createContext<ContextProps>({ isReadOnly: false });

export const useReadOnlyContext = () => useContext(ReadOnlyContext);

export const ReadOnlyContextProvider: React.LegacyFunctionComponentWithChildren<ProviderProps> = ({
  isReadOnly,
  children,
}) => {
  const value = useMemo(() => ({ isReadOnly }), [isReadOnly]);
  return <ReadOnlyContext.Provider value={value}>{children}</ReadOnlyContext.Provider>;
};
