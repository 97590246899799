import { useQuery } from '@apollo/client';
import type { OwnableProduct_ProductType } from '__generated__/graphql-types';

import GetOwnableProductsByLegacyProductIds from 'bundles/payments-common/api/GetOwnableProductsByLegacyProductIdsQuery.graphql';
import type {
  GetOwnableProductsByLegacyProductIdsQuery,
  GetOwnableProductsByLegacyProductIdsQueryVariables,
  OwnableProductPriceFragment,
} from 'bundles/payments-common/api/__generated__/GetOwnableProductsByLegacyProductIdsQuery';
import ProductType from 'bundles/payments/common/ProductType';
import type { ProductType as ProductTypeType } from 'bundles/payments/common/ProductType';

export type ProductPrices = {
  amount: number;
  countryIsoCode: string;
  currencyCode: string;
  finalAmount: number;
  formattedFinalAmount: {
    amount: string;
    currencyCode: string;
  };
  productItemId: string;
  productType: string;
  promotionInfo?:
    | {
        promotionId: number;
        promotionName: string;
        discountPercent?: number | null;
        discountAmount: number;
      }
    | {}
    | null;
};

export type PropsFromGetProductPrices = {
  productPrices?: ProductPrices[];
  loading: boolean;
};

type ProductInput = {
  productType: ProductTypeType;
  productItemId: string;
};

// Maps the `productType` enum values used in Naptime to the ones used in GraphQL Gateway
const LEGACY_PRODUCT_TYPE_MAP = {
  [ProductType.CATALOG_SUBSCRIPTION]: 'PRODUCT_TYPE_INVALID',
  [ProductType.COURSERA_PLUS]: 'PRODUCT_TYPE_COURSERA_PLUS',
  [ProductType.COURSERA_PLUS_SUBSCRIPTION]: 'PRODUCT_TYPE_COURSERA_PLUS_SUBSCRIPTION',
  [ProductType.COURSERA_TIER_LITE]: 'PRODUCT_TYPE_COURSERA_TIER_LITE',
  [ProductType.CREDENTIAL_TRACK]: 'PRODUCT_TYPE_INVALID',
  [ProductType.CREDENTIAL_TRACK_SUBSCRIPTION]: 'PRODUCT_TYPE_CREDENTIAL_TRACK_SUBSCRIPTION_V2',
  [ProductType.CREDENTIAL_TRACK_SUBSCRIPTION_V2]: 'PRODUCT_TYPE_CREDENTIAL_TRACK_SUBSCRIPTION_V2',
  [ProductType.ENTERPRISE_CONTRACT]: 'PRODUCT_TYPE_ENTERPRISE_CONTRACT',
  [ProductType.INTEREST_DEPOSIT]: 'PRODUCT_TYPE_INVALID',
  [ProductType.SPECIALIZATION]: 'PRODUCT_TYPE_SPECIALIZATION',
  [ProductType.SPECIALIZATION_PREPAID]: 'PRODUCT_TYPE_SPECIALIZATION_PREPAID',
  [ProductType.SPECIALIZATION_SUBSCRIPTION]: 'PRODUCT_TYPE_SPECIALIZATION_SUBSCRIPTION',
  [ProductType.VERIFIED_CERTIFICATE]: 'PRODUCT_TYPE_VERIFIED_CERTIFICATE',
  [ProductType.SPARK_COURSE_SHELL]: 'PRODUCT_TYPE_INVALID',
  [ProductType.SPARK_SPECIALIZATION]: 'PRODUCT_TYPE_INVALID',
  [ProductType.SPARK_VERIFIED_CERTIFICATE]: 'PRODUCT_TYPE_INVALID',
} as const;

// Maps the `productType` enum values used in GraphQL Gateway to the ones used in Naptime
const PRODUCT_TYPE_MAP = Object.keys(LEGACY_PRODUCT_TYPE_MAP).reduce((map: Record<string, string>, key: string) => {
  const value = LEGACY_PRODUCT_TYPE_MAP[key as keyof typeof LEGACY_PRODUCT_TYPE_MAP];

  if (value !== 'PRODUCT_TYPE_INVALID') {
    map[value] = key;
  }

  return map;
}, {});

export const mapToLegacyProductId = (product: ProductInput) => {
  const productType = LEGACY_PRODUCT_TYPE_MAP[product.productType];

  if (!productType) {
    throw new Error(
      `Cannot map productType ${product.productType} and productItemId ${product.productItemId} to legacyProductId`
    );
  }

  return {
    productType: productType as OwnableProduct_ProductType,
    productItemId: product.productItemId,
  };
};

export const mapToProductPricesOutput = (ownableProduct: OwnableProductPriceFragment | null): ProductPrices | null => {
  if (!ownableProduct) {
    return null;
  }

  const { priceCountryIsoCode, amount } = ownableProduct.price;
  const legacyProductId = ownableProduct.fulfillmentConfiguration?.legacyProductId;
  const productItemId = legacyProductId?.productItemId ?? '';
  const productType = legacyProductId?.productType;

  return {
    amount: Number(amount.value),
    countryIsoCode: priceCountryIsoCode,
    currencyCode: String(amount.currencyCode),
    finalAmount: Number(amount.value),
    formattedFinalAmount: {
      amount: amount.value,
      currencyCode: String(amount.currencyCode),
    },
    productItemId,
    productType: productType ? PRODUCT_TYPE_MAP[productType] : '',
    promotionInfo: {},
  };
};

const useGetOwnableProductPrices = ({ products = [], skip }: { products: ProductInput[]; skip?: boolean }) => {
  const { data, loading } = useQuery<
    GetOwnableProductsByLegacyProductIdsQuery,
    GetOwnableProductsByLegacyProductIdsQueryVariables
  >(GetOwnableProductsByLegacyProductIds, {
    variables: { legacyProductIds: products.map(mapToLegacyProductId) },
    context: { clientName: 'gatewayGql' },
    skip,
  });

  const ownableProducts = data?.OwnableProductQueries?.findLatestByLegacyProductIds;

  return {
    productPrices: ownableProducts?.map(mapToProductPricesOutput),
    loading,
  };
};

export const useGetProductPrices = ({ products = [], skip }: { products: ProductInput[]; skip?: boolean }) => {
  const { productPrices, loading } = useGetOwnableProductPrices({ products, skip });

  return {
    productPrices: productPrices?.filter(Boolean),
    loading: Boolean(loading),
  };
};
