import * as React from 'react';
import { useCallback, useState } from 'react';

import { useSlate } from 'slate-react';

import { UnlinkIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import UnlinkDialog from 'bundles/cml/editor/components/elements/link/UnlinkDialog';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const UnlinkButton: React.FC = () => {
  const editor = useSlate();
  const disabled = !hasAncestorOfType(editor, BLOCK_TYPES.LINK);
  const [unlinkDialog, setUnlinkDialog] = useState(false);

  const handleOpenDialog = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => setUnlinkDialog(true), []);
  const handleCloseDialog = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => setUnlinkDialog(false), []);

  return (
    <Button
      onClick={handleOpenDialog}
      disabled={disabled}
      className="rc-UnlinkButton"
      title={_t('Remove link')}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <UnlinkIcon size="small" />
      {unlinkDialog && <UnlinkDialog onClose={handleCloseDialog} />}
    </Button>
  );
};

export default UnlinkButton;
