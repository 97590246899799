import * as React from 'react';
import { useCallback } from 'react';

import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import WidgetIcon from 'bundles/cml/editor/components/buttons/widget/WidgetIcon';
import { useWidgetContext } from 'bundles/cml/editor/context/widgetContext';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { hasAncestorOfType, insertBlockNodes } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import { useCourseContext } from 'bundles/cml/shared/hooks/context';
import type { WidgetElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const WidgetButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const disabled = isTypeDisabled(editor, BLOCK_TYPES.WIDGET);
  const active = hasAncestorOfType(editor, BLOCK_TYPES.WIDGET);
  const { courseContext } = useCourseContext();
  const { setWidgetModalOptions, widgetMenuOptions } = useWidgetContext();
  const handleWidgetModalSelect = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    async (id: string) => {
      setWidgetModalOptions();
      // BE will never return back the default configs and instead return the first available published config.
      const result = await widgetMenuOptions?.publishWidgetSession({
        id,
        courseId: courseContext?.courseId ?? '',
      });
      const widget: WidgetElement = {
        type: BLOCK_TYPES.WIDGET,
        isVoid: true,
        id: result?.publishedId ?? id,
        children: [{ text: '' }],
      };

      ReactEditor.focus(staticEditor);
      insertBlockNodes(staticEditor, widget);
    },
    [staticEditor, setWidgetModalOptions, widgetMenuOptions, courseContext?.courseId]
  );

  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    setWidgetModalOptions({
      handleSelect: handleWidgetModalSelect,
    });
  }, [handleWidgetModalSelect, setWidgetModalOptions]);

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      active={active}
      className="rc-WidgetButton"
      title={_t('Insert plugin')}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <WidgetIcon />
    </Button>
  );
};

export default WidgetButton;
