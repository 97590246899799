/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import PropTypes from 'prop-types';
import _ from 'underscore';

import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';
import user from 'js/lib/user';
import { MED_DATETIME_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Button, ButtonGroup, Grid } from '@coursera/cds-core';
import { ReplyIcon } from '@coursera/cds-icons';
import { Checkbox } from '@coursera/coursera-ui';

import CMLEditor from 'bundles/authoring/content-authoring/components/CMLEditor';
import { Tools } from 'bundles/cml';
import type { CmlContent } from 'bundles/cml';
import { EntryTypes, States } from 'bundles/reply-message/constants/ReplyMessageConstants';
import ReplyMessageAPIUtils from 'bundles/reply-message/utils/ReplyMessageAPIUtils';

import _t from 'i18n!nls/reply-message';

import 'css!./__styles__/ReplyMessage';

const defaultCML: CmlContent = {
  typeName: 'cml',
  definition: {
    dtdId: 'email/1',
    value: '<co-content></co-content>',
  },
};

const styles = {
  footer: css`
    margin-top: var(--cds-spacing-200);
  `,
};

class ReplyMessage extends React.Component {
  static propTypes = {
    entryId: PropTypes.string.isRequired,
    entryType: PropTypes.oneOf(_(EntryTypes).toArray()).isRequired,
    placeholder: PropTypes.string,
    buttonText: PropTypes.string,
    onMessageBoxToggle: PropTypes.func,
  };

  static defaultProps = {
    get placeholder() {
      return _t('Write a message');
    },
    get buttonText() {
      return _t('Reply');
    },
  };

  state = {
    isMessageBoxOpen: false,
    replyState: States.Idle,
    allowReply: true,
    sentTime: null,
    message: defaultCML,
  };

  handleClose = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onMessageBoxToggle' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { onMessageBoxToggle } = this.props;
    onMessageBoxToggle?.(false);
    this.setState({ isMessageBoxOpen: false });
  };

  handleSend = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'entryType' does not exist on type 'Reado... Remove this comment to see the full error message
    const { entryType, entryId } = this.props;
    const { allowReply, message } = this.state;

    this.setState({ replyState: States.InProgress });

    ReplyMessageAPIUtils.sendMessage(entryType, entryId, !allowReply, message).then(() => {
      this.setState({
        replyState: States.Success,
        message: defaultCML,
        sentTime: Date.now(),
      });

      this.handleClose();
    });
  };

  handleButtonClick = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'entryId' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { entryId, onMessageBoxToggle } = this.props;
    onMessageBoxToggle?.(true);
    this.setState({ isMessageBoxOpen: true });

    ReplyMessageAPIUtils.getSentMessage(entryId).then((response) => {
      if (!_(response.elements).isEmpty()) {
        const { metadata } = response.elements[0];

        this.setState({
          replyState: States.Success,
          sentTime: metadata.updatedAt,
        });
      }
    });
  };

  handleMessageChange = (value: $TSFixMe) => {
    this.setState({ message: value });
  };

  handleAllowReply = ({ target: { checked } }: $TSFixMe) => {
    this.setState({ allowReply: checked });
  };

  customTools = [
    Tools.HEADING_1,
    Tools.HEADING_2,
    Tools.HEADING_3,
    Tools.HEADING_4,
    Tools.BODY1,
    Tools.BODY2,
    Tools.BOLD,
    Tools.ITALIC,
    Tools.UNDERLINE,
    Tools.LINK,
    Tools.UNLINK,
    Tools.SUBSCRIPT,
    Tools.SUPERSCRIPT,
  ];

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'placeholder' does not exist on type 'Rea... Remove this comment to see the full error message
    const { placeholder, buttonText } = this.props;
    const { isMessageBoxOpen, allowReply, replyState, sentTime, message } = this.state;
    const inProgress = replyState === States.InProgress;
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    const displaySentTime = formatDateTimeDisplay(sentTime, MED_DATETIME_DISPLAY);

    return (
      <div className="rc-ReplyMessage">
        {isMessageBoxOpen && (
          <div>
            {replyState === States.Success && (
              <div className="sent-reply-timestamp">
                <FormattedMessage message={_t('Reply sent {time}')} time={displaySentTime} />
              </div>
            )}

            <CMLEditor
              initialCML={message}
              placeholder={placeholder}
              onContentChange={this.handleMessageChange}
              focusOnLoad={true}
              customTools={this.customTools}
            />

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              component="footer"
              spacing={16}
              css={styles.footer}
            >
              <Grid item>
                <Checkbox onChange={this.handleAllowReply} checked={allowReply}>
                  <FormattedHTMLMessage
                    message={_t('Allow learner to reply to you at <strong>{email}</strong>')}
                    email={user.get().email_address}
                  />
                </Checkbox>
              </Grid>
              <Grid item>
                <ButtonGroup
                  size="small"
                  primaryButtonProps={{
                    children: inProgress ? _t('Sending...') : _t('Send'),
                    onClick: this.handleSend,
                    disabled: inProgress,
                  }}
                  alternateButtonProps={{
                    children: _t('Cancel'),
                    variant: 'ghost',
                    onClick: this.handleClose,
                  }}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {!isMessageBoxOpen && (
          <Button
            iconPosition="before"
            icon={<ReplyIcon size="small" />}
            variant="ghost"
            size="small"
            edgeAlign="start"
            onClick={this.handleButtonClick}
          >
            {replyState === States.Success && (
              <FormattedMessage message={_t('Reply sent {time}')} time={displaySentTime} />
            )}
            {replyState !== States.Success && buttonText}
          </Button>
        )}
      </div>
    );
  }
}

export default ReplyMessage;
