/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import config from 'js/app/config';
import { useRetracked } from 'js/lib/retracked';
import { useLocation } from 'js/lib/useRouter';
import { get } from 'js/lib/user';

import { Grid, PageGridContainer, PageNotification } from '@coursera/cds-core';

import useTimestampDataFactory from 'bundles/common/hooks/useTimestampDataFactory';

import _t from 'i18n!nls/logged-in-home';

const styles = {
  text: css`
    & p {
      font-size: 18px;
      line-height: 24px;
    }

    & h2 {
      font-size: 20px;
      line-height: 24px;
    }
  `,
  privacyBanner: css`
    margin-top: 25px;
  `,
};

type PropsFromCaller = {
  includeGrid?: boolean;
};

// We make this ID unique (and somewhat random) so that we show the banner again
const VISIT_EVENT_ID = 'dashboardEventKey~LAST_PRIVACY_BANNER_VIEW_2025';

const MaybeGridWrapper = ({ children, includeGrid }: { children: React.ReactNode; includeGrid: boolean }) =>
  includeGrid ? (
    <PageGridContainer css={styles.privacyBanner}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </PageGridContainer>
  ) : (
    <Fragment>{children}</Fragment>
  );

const PrivacyWarningBanner = ({ includeGrid }: PropsFromCaller) => {
  const { getTimestamp, setTimestamp } = useTimestampDataFactory(VISIT_EVENT_ID);
  const [shouldShowPrivacyBanner, setShouldShowPrivacyBanner] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  const retrackedTrack = useRetracked();
  const location = useLocation();

  const overridePrivacyTimestamp = !!location.query.overridePrivacyTimestamp;

  useEffect(() => {
    if (overridePrivacyTimestamp) {
      setShouldShowPrivacyBanner(true);
    } else {
      getTimestamp()
        .then((timestamp) => {
          if (dismissed) {
            setShouldShowPrivacyBanner(false);
          } else {
            setShouldShowPrivacyBanner(!timestamp);
          }
        })
        .catch(() => {
          setShouldShowPrivacyBanner(true);
        });
    }
  }, [overridePrivacyTimestamp, getTimestamp, setTimestamp, dismissed]);

  useEffect(() => {
    if (shouldShowPrivacyBanner) {
      retrackedTrack({
        trackingData: { userId: get()?.id },
        trackingName: 'privacy_warning_banner',
        action: 'view',
      });
    }
  }, [shouldShowPrivacyBanner, retrackedTrack]);

  return shouldShowPrivacyBanner ? (
    <MaybeGridWrapper includeGrid={!!includeGrid}>
      <PageNotification
        css={styles.text}
        severity="information"
        onDismiss={() => {
          setDismissed(true);
          setTimestamp();
          retrackedTrack({
            trackingData: { userId: get()?.id },
            trackingName: 'privacy_warning_banner',
            action: 'click',
          });
        }}
        title={_t('We have updated our Terms of Use')}
      >
        <p>
          <FormattedMessage
            css={styles.text}
            message={_t(
              'Starting January 1, 2025, our updated {TermsOfUseLink} and {PrivacyNoticeLink} are in effect. Please review each one in full.'
            )}
            TermsOfUseLink={
              <a href={`${config.url.base}about/terms`} target="_blank" className="link" rel="noreferrer">
                {_t('Terms of Use')}
              </a>
            }
            PrivacyNoticeLink={
              <a href={`${config.url.base}about/privacy`} target="_blank" className="link" rel="noreferrer">
                {_t('Privacy Notice')}
              </a>
            }
          />
        </p>
      </PageNotification>
    </MaybeGridWrapper>
  ) : null;
};

export default PrivacyWarningBanner;
