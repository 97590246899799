import { useCallback, useEffect, useMemo, useState } from 'react';
import type React from 'react';

import type { ScrollPageProps } from 'bundles/product-card/components/types/ProductCardV2';

const CARD_VISIBILITY_THRESHOLD = 0.5;

function useInfiniteScrollPageObserver(
  cardRef: React.MutableRefObject<HTMLDivElement | null>,
  pageNumber: number | undefined,
  setScrollPage: ((scrollPageProps: ScrollPageProps) => void) | undefined
) {
  const [windowIsSafe, setWindowIsSafe] = useState(false);

  const handleVisibilityOnInfiniteScroll = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    ([trigger]) => {
      const shouldCallback = trigger?.isIntersecting && pageNumber;
      if (shouldCallback) {
        setScrollPage?.({ pageNumber });
      }
    },
    [setScrollPage, pageNumber]
  );

  const cardPositionOnInfiniteScrollObserver = useMemo(() => {
    return windowIsSafe
      ? new IntersectionObserver(handleVisibilityOnInfiniteScroll, {
          threshold: CARD_VISIBILITY_THRESHOLD,
        })
      : false;
  }, [handleVisibilityOnInfiniteScroll, windowIsSafe]);

  useEffect(() => {
    if (pageNumber && setScrollPage && cardPositionOnInfiniteScrollObserver && cardRef.current) {
      cardPositionOnInfiniteScrollObserver.observe(cardRef.current);
    }
    return () => {
      if (cardPositionOnInfiniteScrollObserver) cardPositionOnInfiniteScrollObserver.disconnect();
    };
  }, [cardRef, cardPositionOnInfiniteScrollObserver, pageNumber, setScrollPage]);

  useEffect(() => {
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      setWindowIsSafe(true);
    }
  }, []);
}

export default useInfiniteScrollPageObserver;
