/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import user from 'js/lib/user';

import PrivacyWarningBanner from 'bundles/common/components/PrivacyWarningBanner';
import GoogleBanner from 'bundles/enterprise-admin-grow-with-google/components/learner/GoogleBanner';
import GwGHelpBanner from 'bundles/enterprise-admin-grow-with-google/components/learner/GwGHelpBanner';
import LearnerProgramHomeBanner from 'bundles/enterprise-admin-grow-with-google/components/learner/LearnerProgramHomeBanner';
import LearnerProgramHomeBottom from 'bundles/enterprise-admin-grow-with-google/components/learner/LearnerProgramHomeBottom';
import AllBrowseCollections from 'bundles/enterprise-legacy-learner-home/components/single-program/AllBrowseCollections';
import AllCuratedCollections from 'bundles/enterprise-legacy-learner-home/components/single-program/AllCuratedCollections';
import AssignedCourses from 'bundles/enterprise-legacy-learner-home/components/single-program/AssignedCourses';
import BrowseCollections from 'bundles/enterprise-legacy-learner-home/components/single-program/BrowseCollections';
import CatalogSection from 'bundles/enterprise-legacy-learner-home/components/single-program/CatalogSection';
import CuratedCollections from 'bundles/enterprise-legacy-learner-home/components/single-program/CuratedCollections';
import EnterpriseBadgeCollections from 'bundles/enterprise-legacy-learner-home/components/single-program/EnterpriseBadgeCollections';
import ForCreditCollection from 'bundles/enterprise-legacy-learner-home/components/single-program/ForCreditCollection';
import InitialActivitySection from 'bundles/enterprise-legacy-learner-home/components/single-program/InitialActivitySection';
// eslint-disable-next-line import/no-cycle
import { TabName } from 'bundles/enterprise-legacy-learner-home/components/single-program/SingleProgramTabs';
import SkillsClipsCollection from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillsClipsCollection';
import type { PropsFromWithCatalogCollectionProps } from 'bundles/enterprise-legacy-learner-home/components/single-program/withCatalogCollectionProps';
import { toProgramHomeTab } from 'bundles/enterprise-legacy-learner-home/links';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import SkillsFocus from 'bundles/program-home/components/enterprise-home/SkillsFocus';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import SkillSetCards from 'bundles/program-home/components/multiprogram/SkillSetCards';
import { MAX_ONBOARDING_SKILLS_LENGTH } from 'bundles/program-home/constants/skillCollectionsConstants';
import type { OnProductCardClick } from 'bundles/program-home/types/Products';
import { joinWithAnd } from 'bundles/program-home/utils/skillsClipsUtils';
import { OnboardingResultsHeader } from 'bundles/program-personalized-tab/components/OnboardingResultsHeader';
import { PromoBanner } from 'bundles/program-personalized-tab/components/PromoBanner';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import { shouldShowToUBanner } from 'bundles/user-consent/utils/shouldShowBannerToU';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/BrowseCatalogTabContent';

type PropsFromCaller = {
  programId: string;
  programSlug: string;
  onProductCardClick: OnProductCardClick;
  isAuthenticatedUser: boolean;
  showSkillSets: () => void;
  catalogHelpIsExpanded: boolean;
  isProgramMember: boolean;
  isProgramAdmin: boolean;
  infoCard?: React.ReactNode;
  isAllowlistedC4er: boolean;
  programCollectionSectionDescription: boolean;
  programRecommendationsAvailableCoursesCopy: boolean;
  enableCurriculumBuilder?: boolean;
  enableAcademicDisciplines?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
  thirdPartyOrganization: ThirdPartyOrganizationsV1;
  isGwGProgram?: boolean;
  skillSetLength?: number;
  academicDisciplineName?: string;
  enableSkillsDashboard: boolean;
  enableEnterpriseCustomBadges: boolean;
  isMyLearningTabEnabled: boolean;
  showSkillSetRoleFilter: boolean;
  isCareerAcademyFreemium?: boolean;
  hasCompletedOnboarding: boolean;
  onboardingDataLoading?: boolean;
  onboardingSkillNames?: string[];
  showPersonalizedRecommendations: boolean;
  onTabChange: (_: unknown, selectedIndex: number) => void;
  tabNames: TabName[];
  shouldHideCourseraRecommendations: boolean;
  shouldShowShortFormContent?: boolean;
} & PropsFromWithCatalogCollectionProps;

export type Props = PropsFromCaller;

const styles = {
  progamNameContainer: css`
    margin-top: -25px;
    margin-bottom: -25px;
  `,
};

export default function BrowseCatalogTabContent({
  programId,
  programSlug,
  onProductCardClick,
  hasTSPs,
  hasCuratedCollections,
  hasBrowseCollections,
  catalogCollectionLength,
  catalogCollectionShouldLoadMore,
  isAuthenticatedUser,
  showSkillSets,
  infoCard,
  thirdPartyOrganization,
  isAllowlistedC4er,
  programRecommendationsAvailableCoursesCopy,
  programCollectionSectionDescription,
  enableCurriculumBuilder,
  enableAcademicDisciplines,
  allowOrgForSpecializationConfiguration,
  isGwGProgram,
  skillSetLength,
  academicDisciplineName,
  enableEnterpriseCustomBadges,
  enableSkillsDashboard,
  showSkillSetRoleFilter,
  isProgramMember,
  isMyLearningTabEnabled,
  isCareerAcademyFreemium,
  hasCompletedOnboarding,
  onboardingDataLoading,
  onboardingSkillNames,
  showPersonalizedRecommendations,
  onTabChange,
  tabNames,
  shouldHideCourseraRecommendations,
  isInDeepBrowseMode,
  shouldShowShortFormContent,
}: Props): JSX.Element {
  const onboardingData = useOnboardingData();

  const shouldShowSkillBasedClipsCollection =
    !shouldHideCourseraRecommendations &&
    isInDeepBrowseMode &&
    isAuthenticatedUser &&
    hasCompletedOnboarding &&
    shouldShowShortFormContent &&
    (onboardingSkillNames ?? []).length > 0;

  const enableSocialShare = isAllowlistedC4er;
  const enableC4CAcademicDisciplines = enableAcademicDisciplines && academicDisciplineName;
  const viewMyCoursesUrl = isMyLearningTabEnabled
    ? toProgramHomeTab(programSlug, TabName.MyLearning)
    : toProgramHomeTab(programSlug, TabName.MyCourses);
  const isGWGOnlyProgram = isGwGProgram && !isCareerAcademyFreemium;
  const shouldShowPrivacyWarningBanner = shouldShowToUBanner();

  return (
    <React.Fragment>
      {shouldShowPrivacyWarningBanner && <PrivacyWarningBanner includeGrid />}
      <div className="rc-BrowseCatalogTabContent">
        {showPersonalizedRecommendations && (
          <CatalogSection>
            {onboardingData && (
              <OnboardingResultsHeader
                selectedGoals={onboardingData?.selectedGoals ?? []}
                selectedRoleId={onboardingData?.selectedRoleId ?? ''}
                hasCompletedOnboarding={Boolean(onboardingData?.hasCompletedOnboarding)}
                isUnsureOfRole={Boolean(onboardingData?.isUnsureOfRole)}
                programSlug={programSlug}
                isBrowseCatalogTab
              />
            )}
            {showPersonalizedRecommendations && (
              <PromoBanner
                hasCompletedOnboarding={hasCompletedOnboarding}
                programSlug={programSlug}
                onTabChange={onTabChange}
                tabNames={tabNames}
              />
            )}
          </CatalogSection>
        )}
        {isGWGOnlyProgram && (
          <React.Fragment>
            <GwGHelpBanner isAuthenticatedUser={isAuthenticatedUser} />
            <LearnerProgramHomeBanner />
            <GoogleBanner />
          </React.Fragment>
        )}
        <InitialActivitySection
          programId={programId}
          programSlug={programSlug}
          userId={user.get().id}
          thirdPartyOrganization={thirdPartyOrganization}
          infoCard={infoCard}
          enableSocialShare={enableSocialShare}
          isAllowlistedC4er={isAllowlistedC4er}
          isCareerAcademyFreemium={isCareerAcademyFreemium}
          isAuthenticatedUser={isAuthenticatedUser}
          viewMyCoursesUrl={viewMyCoursesUrl}
        />
        <AssignedCourses
          headingLevel={2}
          programId={programId}
          onProductCardClick={onProductCardClick}
          isSingleProgram
        />
        {shouldShowSkillBasedClipsCollection && (
          <CatalogSection>
            <SkillsClipsCollection
              programId={programId}
              onProductCardClick={onProductCardClick}
              isAuthenticatedUser={isAuthenticatedUser}
              skillNames={onboardingSkillNames}
              translatedTitleText={_t('Videos recommended for you')}
              translatedDescriptionText={_t('Videos about #{skillsText} recommended for you', {
                skillsText: joinWithAnd((onboardingSkillNames ?? []).slice(0, MAX_ONBOARDING_SKILLS_LENGTH)),
              })}
              collectionId="onboarding-clips-collection-program-home"
              onboardingDataLoading={onboardingDataLoading}
            />
          </CatalogSection>
        )}
        {isAuthenticatedUser && enableEnterpriseCustomBadges && (
          <EnterpriseBadgeCollections
            userId={user.get().id}
            programId={programId}
            thirdPartyOrganizationName={thirdPartyOrganization.name}
            programSlug={programSlug}
            onProductCardClick={onProductCardClick}
            isProgramMember={isProgramMember}
          />
        )}
        {(enableC4CAcademicDisciplines || enableCurriculumBuilder) && (
          <ForCreditCollection
            headingLevel={2}
            programId={programId}
            onProductCardClick={onProductCardClick}
            isSingleProgram
            allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
          />
        )}
        {enableSkillsDashboard && (
          <CatalogSection>
            <SkillsFocus skillLimit={6} programSlug={programSlug} programId={programId} />
          </CatalogSection>
        )}

        {hasTSPs && (
          <CatalogSection>
            <div className="BrowseCatalogTabContent-SkillSetsCards">
              <SkillSetCards
                showSkillSetRoleFilter={showSkillSetRoleFilter}
                programId={programId}
                programSlug={programSlug}
                showSkillSets={showSkillSets}
                title={
                  enableC4CAcademicDisciplines && academicDisciplineName
                    ? _t('SkillSets for the most popular roles in #{academicDisciplineName}', {
                        academicDisciplineName,
                      })
                    : _t('SkillSets recommended by your organization')
                }
                skillSetLength={skillSetLength}
                showCarousel
                isAnAcademicDisciplineProgram={Boolean(enableC4CAcademicDisciplines)}
              />
            </div>
          </CatalogSection>
        )}
        {hasCuratedCollections &&
          (catalogCollectionShouldLoadMore ? (
            <CatalogSection>
              <AllCuratedCollections
                programId={programId}
                programSlug={programSlug}
                onProductCardClick={onProductCardClick}
                limit={catalogCollectionLength}
                title={
                  programRecommendationsAvailableCoursesCopy
                    ? _t('Topics to explore')
                    : _t('Recommended by your organization')
                }
                description={
                  programCollectionSectionDescription && !enableC4CAcademicDisciplines
                    ? _t(
                        'Discover the featured courses below or search the Coursera site for more learning opportunities.'
                      )
                    : undefined
                }
                thirdPartyOrganizationId={thirdPartyOrganization.id}
                enableCurriculumBuilder={enableCurriculumBuilder}
                allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
                isGwGProgram={isGwGProgram}
                academicDisciplineName={academicDisciplineName}
                isAllCollectionsPage={false}
              />
            </CatalogSection>
          ) : (
            <CuratedCollections
              programId={programId}
              onProductCardClick={onProductCardClick}
              length={catalogCollectionLength}
              programRecommendationsAvailableCoursesCopy={programRecommendationsAvailableCoursesCopy}
              programCollectionSectionDescription={programCollectionSectionDescription}
              enableCurriculumBuilder={enableCurriculumBuilder}
              allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
              academicDisciplineName={academicDisciplineName}
              isAuthenticatedUser={isAuthenticatedUser}
            />
          ))}
        {hasBrowseCollections &&
          (catalogCollectionShouldLoadMore ? (
            <CatalogSection>
              <AllBrowseCollections
                programId={programId}
                programSlug={programSlug}
                isAuthenticatedUser={isAuthenticatedUser}
                onProductCardClick={onProductCardClick}
                limit={catalogCollectionLength}
                title={_t('Recommendations by Coursera')}
                description={_t(
                  'Enroll in popular courses based on enrollment data and ratings by learners from a variety of industries across Coursera.'
                )}
                allowOrgForSpecializationConfiguration={allowOrgForSpecializationConfiguration}
              />
            </CatalogSection>
          ) : (
            <BrowseCollections
              programId={programId}
              onProductCardClick={onProductCardClick}
              isAuthenticatedUser={isAuthenticatedUser}
              length={catalogCollectionLength}
            />
          ))}
        {isGWGOnlyProgram && <LearnerProgramHomeBottom />}
      </div>
    </React.Fragment>
  );
}
