import { useCallback } from 'react';

import type { Descendant, Editor } from 'slate';

import { isValidOperation } from 'bundles/cml/editor/utils/operationUtils';

export const useSlateToCML = (editor: Editor, onChange: (value: Descendant[]) => void) => {
  return useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (value: Descendant[]) => {
      const isValueChangeEvent = editor.operations.some(isValidOperation);
      if (isValueChangeEvent) {
        onChange(value);
      }
    },
    [editor, onChange]
  );
};
