/**
 * Private module reserved for @coursera/cds-core package.
 */

import { createLargeSvgIcon } from '@core/utils';

export default createLargeSvgIcon('CheckboxUncheckedIcon', {
  large: (
    <rect
      fill="inherit"
      height="18.333326"
      rx="0.833333"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="1.666"
      width="18.333326"
      x="0.833333"
      y="0.833333"
    />
  ),
});
