import * as React from 'react';

type Props = {
  style?: React.CSSProperties;
};

const AuthorEvaluatorSectionHeader = (props: React.PropsWithChildren<Props>) => {
  const { style, children } = props;

  return (
    <h3 className="rc-AuthorEvaluatorSectionHeader" style={style}>
      {children}
    </h3>
  );
};

export default AuthorEvaluatorSectionHeader;
