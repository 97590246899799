import * as React from 'react';
import { useState } from 'react';

import Retracked from 'js/app/retracked';
import helpcenter from 'js/lib/courseraHelpcenter';
import isMobileApp from 'js/lib/isMobileApp';
import user from 'js/lib/user';

import {
  getEnableAdminChat,
  getEnableEducatorChat,
  previewUserAdminEducator,
} from 'bundles/common/utils/globalHelpButtonUtils';
import Icon from 'bundles/iconfont/Icon';
import TrackedButton from 'bundles/page/components/TrackedButton';
import { TrackedA } from 'bundles/page/components/TrackedLink2';
import useIsMobile from 'bundles/unified-common/hooks/useIsMobile';

/* eslint-enable import/extensions */
import _t from 'i18n!nls/alice';

import 'css!./__styles__/Help';

import HelpBox from './help/HelpBox';

type Props = {
  style?: { [styleAttr: string]: string | number };
  isEnterprise?: boolean;
  /* This component used to only use the trackingName 'icon'. Under the Access to Support data science experiments we now have this help icon shown at the bottom right of almost every page. To measure effectiveness of the more global help icon we need a separate trackingName: 'global_icon' */
  trackingName?: 'icon' | 'global_icon';
  isAdminPage?: boolean;
  isEducatorPage?: boolean;
};

const getHelpCenterText = () => {
  return _t('Help Center');
};

const Help = ({ style, isEnterprise, trackingName = 'icon', isAdminPage = false, isEducatorPage = false }: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const tooltip = getHelpCenterText();

  const isMobile = useIsMobile();

  if (isMobileApp.get() || isMobile) {
    return null;
  }
  const isAuthenticatedUser = user.isAuthenticatedUser();
  const link = isEnterprise
    ? helpcenter.getEnterpriseHelpLink(isAuthenticatedUser)
    : helpcenter.getNewHelpCenterHome(isAuthenticatedUser);

  let helpButtonLink = helpcenter.getNewHelpCenterHome(isAuthenticatedUser);
  if (isAdminPage) {
    helpButtonLink = 'https://business.coursera.help';
  } else if (isEducatorPage) {
    helpButtonLink = 'https://partner.coursera.help';
  } else if (isEnterprise) {
    helpButtonLink = helpcenter.getEnterpriseHelpLink(isAuthenticatedUser);
  }

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  let helpButtonTrackingName = 'global_help_icon';
  if (isAdminPage) {
    helpButtonTrackingName = 'global_help_icon_admin';
  } else if (isEducatorPage) {
    helpButtonTrackingName = 'global_help_icon_educator';
  }

  // Regular link to help center that is not part of Access to Support experiments
  const HelpLink = (
    <TrackedA
      className="rc-Help link-button nostyle mui-fixed"
      trackingName={trackingName}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      aria-label={tooltip}
      title={tooltip}
      style={style || { minWidth: 0, bottom: 20, right: 20 }}
    >
      <div className="help-widget horizontal-box align-items-absolute-center">
        <Icon name="question-circle-o" />
      </div>
    </TrackedA>
  );

  /* There are two versions of the HelpButton:
  1. The user is in the treatment group of the Access to Support Experiment #5 and on a valid admin or educator page- onClick invokes admin/educator SF chat 
  2. The user is on a valid learner, admin, or educator page where we show global help button - onClick links to their respective Help Center */
  const HelpButton = (
    <>
      <TrackedButton
        trackingName={helpButtonTrackingName}
        title={tooltip}
        aria-label={tooltip}
        data={{ isAdminPage, isEducatorPage }}
        onClick={(e) => {
          e.stopPropagation();
          if (isAdminPage) {
            const enableAdminChat = getEnableAdminChat(); // Access to Support Experiment #5, admin chat impression point
            if (enableAdminChat) {
              togglePopover();
            } else {
              window.open(helpButtonLink);
            }
          } else if (isEducatorPage) {
            const enableEducatorChat = getEnableEducatorChat(); // Access to Support Experiment #5, educator chat impression point
            if (enableEducatorChat) {
              togglePopover();
            } else {
              window.open(helpButtonLink);
            }
          } else {
            window.open(helpButtonLink);
          }
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === 'Escape') {
            setIsPopoverOpen(false);
          }
        }}
        className="rc-Help mui-fixed"
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          padding: 0,
          minWidth: 0,
          bottom: 20,
          right: 20,
          position: 'fixed',
        }}
      >
        <div className="help-widget horizontal-box align-items-absolute-center">
          <Icon name="question-circle-o" />
        </div>
      </TrackedButton>
      <HelpBox
        isOpen={isPopoverOpen}
        setIsOpen={setIsPopoverOpen}
        isAdminChat={isAdminPage}
        isEducatorChat={isEducatorPage}
      />
    </>
  );

  const isAccessToSupport = trackingName === 'global_icon';
  return isAccessToSupport ? HelpButton : HelpLink;
};

export default Retracked.createTrackedContainer<Props>(() => {
  const { isAdmin, isEducator } = previewUserAdminEducator();
  return {
    namespace: { page: 'help' },
    isAdmin,
    isEducator,
    currentPath: typeof window !== 'undefined' ? window.location.pathname : undefined,
  };
})(Help);
