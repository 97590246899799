import * as React from 'react';
import { useCallback, useState } from 'react';

import { ReactEditor, useSlateStatic } from 'slate-react';

import DeleteWidgetButton from 'bundles/cml/editor/components/elements/asset/buttons/DeleteAssetButton';
import EditConfigButton from 'bundles/cml/editor/components/elements/widget/buttons/EditConfigButton';
import OpenInLibraryButton from 'bundles/cml/editor/components/elements/widget/buttons/OpenInLibraryButton';
import SwapWidgetButton from 'bundles/cml/editor/components/elements/widget/buttons/SwapWidgetButton';
import { getWidgetTypeId } from 'bundles/cml/editor/components/elements/widget/widgetUtils';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { useWidgetContext } from 'bundles/cml/editor/context/widgetContext';
import RestoreFocus from 'bundles/cml/editor/utils/RestoreFocus';
import type { WidgetElement } from 'bundles/cml/shared/types/elementTypes';
import type {
  WidgetSession,
  WidgetSessionDraftConfig,
  WidgetSessionPreview,
} from 'bundles/cml/shared/types/widgetManager';

import _t from 'i18n!nls/cml';

type Props = {
  anchorEl: HTMLElement | null;
  widget: WidgetElement;
  widgetSession: WidgetSession;
  widgetPreview: WidgetSessionPreview;
  onClose: () => void;
  onUpdate: (widgetSession: WidgetSession, iframeTitle: string, id: string, config: WidgetSessionDraftConfig) => void;
};

const WidgetMenu: React.FC<Props> = ({ anchorEl, widget, widgetSession, widgetPreview, onClose, onUpdate }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const staticEditor = useSlateStatic();
  const { widgetMenuOptions } = useWidgetContext();
  const { WidgetConfigDialog } = widgetMenuOptions || {};
  const hasConfig = widgetSession.draft?.configuration && Object.keys(widgetSession.draft?.configuration).length > 0;

  const handleCloseDialog = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    setDialogOpen(false);
    ReactEditor.focus(staticEditor);
  }, [staticEditor]);

  const handleDone = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    handleCloseDialog();
    onClose();
  }, [handleCloseDialog, onClose]);

  if (dialogOpen && widgetSession && widgetPreview && WidgetConfigDialog) {
    return (
      <WidgetConfigDialog
        onClose={handleCloseDialog}
        widgetSession={widgetSession}
        widgetPreview={widgetPreview}
        widgetElement={widget}
        onUpdate={onUpdate}
        onDone={handleDone}
      />
    );
  }

  const widgetTypeId = getWidgetTypeId(widgetSession.draft.widgetVersionId);

  return (
    <RestoreFocus anchorEl={anchorEl} onClose={onClose}>
      <Toolbar role="menubar" data-pendo="cml-widget-menu" autoFocus={true}>
        <OpenInLibraryButton widgetTypeId={widgetTypeId} />
        <DeleteWidgetButton title={_t('Delete plugin')} />
        <SwapWidgetButton widget={widget} widgetTypeId={widgetTypeId} onClick={onClose} />
        {hasConfig ? <EditConfigButton onClick={() => setDialogOpen(true)} /> : <></>}
      </Toolbar>
    </RestoreFocus>
  );
};

export default WidgetMenu;
