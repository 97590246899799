import { type Editor, Element, type Node, type NodeEntry, Transforms } from 'slate';

import normalizerLogger from 'bundles/cml/editor/normalize/normalizerLogger';
import type { Options } from 'bundles/cml/editor/normalize/types';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';
import { Tools } from 'bundles/cml/shared/utils/customTools';

const normalizeCodeBlockTool = (editor: Editor, { tools }: Options, [node, path]: NodeEntry<CodeElement>) => {
  if (tools.has(Tools.CODE)) {
    return false;
  }

  const text = node.codeText;
  Transforms.delete(editor, { at: path });
  Transforms.insertNodes(editor, { type: BLOCK_TYPES.TEXT, children: [{ text }] }, { at: path });
  return true;
};

const normalizeInteractiveCodeBlocks = (
  editor: Editor,
  { codeBlockOptions }: Options,
  [node, path]: NodeEntry<CodeElement>
) => {
  if (!node.evaluatorId || codeBlockOptions?.ecbEnabled) {
    return false;
  }

  Transforms.unsetNodes(editor, 'evaluatorId', { at: path });
  return true;
};

const NORMALIZERS = [normalizerLogger(normalizeCodeBlockTool), normalizerLogger(normalizeInteractiveCodeBlocks)];

export const normalizeCodeBlocks = (editor: Editor, options: Options, nodeEntry: NodeEntry<Node>) => {
  const [node] = nodeEntry;
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.CODE) {
    return false;
  }

  const codeNodeEntry = nodeEntry as NodeEntry<CodeElement>;
  return NORMALIZERS.some((normalizer) => normalizer(editor, options, codeNodeEntry));
};
