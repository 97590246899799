import clsx from 'clsx';

import getContextualHelpIconCss, { classes } from './getContextualHelpIconCss';

export type Props = {
  expanded: boolean;
  id: string;
  /**
   * Invert the color scheme. Use when displaying over dark backgrounds
   * @default false
   */
  invert?: boolean;
};

/**
 * Custom implementation of animated icons that transition between the "i" and "x",
 * when the help state is toggled.
 * Adapted from https://codepen.io/BearClawz/pen/mdNPprL
 */
const ContextualHelpIcon = ({ expanded, id, invert = false }: Props) => {
  return (
    <svg
      aria-hidden={true}
      className={clsx(classes.customHelpIcon, {
        [classes.expanded]: expanded,
        [classes.inverted]: invert,
      })}
      css={getContextualHelpIconCss}
      height="18"
      id={id}
      viewBox="0 0 24 24"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={classes.i}>
        <circle className={classes.background} cx="12" cy="12" r="9.6" />
        <rect
          className={classes.neck}
          height="13.5"
          rx="2"
          ry="2"
          width="2"
          x="10.85"
          y="10.15"
        />
        <circle className={classes.dot} cx="12" cy="7.5" r="1" />
        <rect
          className={classes.crosspath}
          height="2"
          rx="2"
          ry="2"
          width="13"
          x="10.75"
          y="10.9"
        />
      </g>
    </svg>
  );
};

export default ContextualHelpIcon;
