import * as React from 'react';
import { useEffect, useState } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import keysToConstants from 'js/lib/keysToConstants';

import EnrollErrorModal from 'bundles/enroll/components/common/EnrollErrorModal';
import PromotionApplicableCheckoutMessage from 'bundles/enroll/components/common/PromotionApplicableCheckoutMessage';
import SubscriptionEnrollButton from 'bundles/enroll/components/subscriptions/SubscriptionEnrollButton';
import SubscriptionFooter from 'bundles/enroll/components/subscriptions/SubscriptionFooter';
import SubscriptionPriceHeader from 'bundles/enroll/components/subscriptions/SubscriptionPriceHeader';
import SubscriptionVProp from 'bundles/enroll/components/subscriptions/SubscriptionVProp';
import SubscriptionVPropV2 from 'bundles/enroll/components/subscriptions/SubscriptionVPropV2';
import SubscriptionVPropFreeTrial from 'bundles/enroll/components/subscriptions/free-trialV2/SubscriptionVPropFreeTrial';
import usePageData from 'bundles/enroll/data/usePageData';
import type { ApiError } from 'bundles/enroll/utils/errorUtils';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';
import {
  generateImpressionForShowNewXdpValueProps,
  isShowNewXdpValuePropsEnabled,
} from 'bundles/enroll/utils/experimentUtils';
import { useEnrollInS12nSubscription } from 'bundles/enroll/utils/useEnrollInS12nSubscription';
import Modal from 'bundles/phoenix/components/Modal';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/SubscriptionEnrollModal';

const MODAL_TYPES = keysToConstants(['ENROLL', 'ERROR']);

type PropsFromCaller = {
  onClose: () => void;
  onSdp: boolean;
  isFromS12nSelection?: boolean;
};

const SubscriptionEnrollModal: React.FC<PropsFromCaller> = ({ onClose, onSdp, isFromS12nSelection = false }) => {
  const [activeModal, setActiveModal] = useState<keyof typeof MODAL_TYPES | null>(MODAL_TYPES.ENROLL);
  const [error, setError] = useState<ApiError | undefined>();
  const { getEnrollModalTrackingRef, trackEnrollModalClose } = useEnrollModalEventing();
  const visibilityTrackingRef = getEnrollModalTrackingRef();
  const showNewValueProps = isShowNewXdpValuePropsEnabled();
  const { s12n, course } = usePageData();
  const { s12nMonthlySkuId, canEnrollThroughS12nSubscription, canEnrollThroughS12nSubscriptionFreeTrial } =
    useEnrollInS12nSubscription(isFromS12nSelection);

  useEffect(() => {
    if (canEnrollThroughS12nSubscription) {
      generateImpressionForShowNewXdpValueProps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!s12n || !s12nMonthlySkuId) {
    return null;
  }

  const handleClose = () => {
    trackEnrollModalClose();
    onClose();
  };

  const handleError = (apiError: ApiError) => {
    setActiveModal(MODAL_TYPES.ERROR);
    setError(apiError);
  };

  const renderHeader = (): React.ReactNode => {
    if (canEnrollThroughS12nSubscription) {
      return <SubscriptionPriceHeader />;
    } else {
      return null;
    }
  };

  const renderBody = (): React.ReactNode => {
    if (canEnrollThroughS12nSubscriptionFreeTrial) {
      return <SubscriptionVPropFreeTrial onSdp={onSdp} />;
    } else if (canEnrollThroughS12nSubscription) {
      if (showNewValueProps) {
        return <SubscriptionVPropV2 onSdp={onSdp} />;
      } else {
        return <SubscriptionVProp onSdp={onSdp} />;
      }
    } else {
      return null;
    }
  };

  switch (activeModal) {
    case MODAL_TYPES.ENROLL:
      return (
        <div className="rc-SubscriptionEnrollModal">
          <CSSTransitionGroup transitionName="fade" transitionEnter={false} transitionLeaveTimeout={300}>
            <Modal
              trackingName="subscription_enroll_modal"
              key="SubscriptionEnrollModal"
              modalName={_t('Join this Specialization')}
              handleClose={handleClose}
              className="subscription-enroll-modal"
            >
              <div className="enroll-modal-container" ref={visibilityTrackingRef}>
                {isFromS12nSelection && (
                  <div className="m-t-1s">
                    <strong data-unit="step2">{_t('Step 2 of 2')}</strong>
                  </div>
                )}
                {renderHeader()}
                <PromotionApplicableCheckoutMessage
                  course={course}
                  s12n={s12n}
                  isFromS12nSelection={isFromS12nSelection}
                />
                {renderBody()}
                <SubscriptionEnrollButton isFromS12nSelection={isFromS12nSelection} onError={handleError} />
                {!onSdp && <SubscriptionFooter />}
              </div>
            </Modal>
          </CSSTransitionGroup>
        </div>
      );
    case MODAL_TYPES.ERROR:
      return <EnrollErrorModal error={error} onClose={handleClose} isFinancialAid={false} isFreeEnroll={false} />;
    default:
      return null;
  }
};

export default SubscriptionEnrollModal;
