import { useCallback, useMemo } from 'react';

import type { BaseRange, NodeEntry } from 'slate';
import { Text } from 'slate';

type Range = BaseRange & { variableSubstitution: boolean };

export const useVariableSubstitutions = (macros?: string[]) => {
  const substitutionsRegex = useMemo(() => {
    if (!macros) {
      return null;
    }

    const variables = macros.map((macro) => `(%${macro}%)`).join('|');
    return new RegExp(variables, 'g');
  }, [macros]);

  return useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    ([node, path]: NodeEntry): Range[] => {
      if (!substitutionsRegex || !Text.isText(node)) {
        return [];
      }

      const { text } = node;
      const matches = Array.from(text.matchAll(substitutionsRegex));

      return matches.map((match: RegExpMatchArray) => {
        const index = match.index ?? 0;
        return {
          anchor: { path, offset: index },
          focus: { path, offset: index + match[0].length },
          variableSubstitution: true,
        };
      });
    },
    [substitutionsRegex]
  );
};
