/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { type ButtonProps, Popover, useLocale } from '@coursera/cds-core';
import { ChevronDownIcon } from '@coursera/cds-icons';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import SelectDropdown from 'bundles/ai-coach-platform/components/patterns/select/SelectDropdown';
import Selection from 'bundles/ai-coach-platform/components/patterns/select/Selection';
import type { Option, OptionGroup } from 'bundles/ai-coach-platform/components/patterns/select/types';
import { getSelection } from 'bundles/ai-coach-platform/components/patterns/select/utils';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  rotate: css`
    transform: rotate(-180deg);
  `,
  dropdown: css`
    .cds-MenuList-groupList {
      display: flex;
      flex-direction: column;
      row-gap: var(--cds-spacing-50);
    }

    .cds-popoverDropdown-paper {
      border-radius: var(--cds-border-radius-100);
      border-color: var(--cds-color-blue-600);
    }
  `,
};

type Props = {
  /**
   * Currently selected value (use undefined if none)
   */
  value?: string;

  /**
   * Label
   */
  label: string;

  /**
   * Menu options
   */
  options: (Option | OptionGroup)[];

  /**
   * Callback fired when value changes
   */
  onChange: (value: string) => void;

  /**
   * Optional placeholder text
   */
  placeholder?: string;

  /**
   * Disbled state
   */
  disabled?: boolean;

  /**
   * CSS style overrides
   */
  className?: string;

  /**
   * Button size (default is 'medium')
   */
  size?: ButtonProps['size'];
};

/**
 * Coach-themed select with search capabilities. Temporarily added until CDS SilentSelectField adds search functionality.
 */
const CoachSingleSelect = ({
  value,
  label,
  options,
  disabled,
  placeholder = _t('Option...'),
  onChange,
  className,
  size,
}: Props) => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = useState(false);

  const toggleMenu = useCallback<() => void>(() => {
    setOpen((state) => !state);
  }, []);

  const handleChange = useCallback<(newValue: string) => void>(
    (newValue: string) => {
      setOpen(false);
      onChange(newValue);
    },
    [onChange]
  );

  const selection = useMemo(() => getSelection(value, options), [value, options]);
  const anchorPoint = useLocale().direction === 'ltr' ? 'left' : 'right';

  return (
    <React.Fragment>
      <CoachButton
        ref={anchorRef}
        variant="ghost"
        size={size}
        disabled={disabled}
        icon={<ChevronDownIcon size={size} css={open && styles.rotate} />}
        aria-haspopup="menu"
        onClick={toggleMenu}
        selected={open}
        className={className}
      >
        <Selection label={label} selection={selection} placeholder={placeholder} disabled={disabled} size={size} />
      </CoachButton>
      <Popover
        open={open}
        anchorElement={anchorRef.current}
        onClose={toggleMenu}
        dropdownProps={{
          maxHeight: '324px',
          autoFocus: true,
          transformOrigin: { vertical: -4, horizontal: anchorPoint },
          css: styles.dropdown,
        }}
      >
        <Popover.Body>
          <SelectDropdown value={value} options={options} onChange={handleChange} />
        </Popover.Body>
      </Popover>
    </React.Fragment>
  );
};

export default CoachSingleSelect;
