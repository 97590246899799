/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useRef, useState } from 'react';

import { isHotkey } from 'is-hotkey';

import { TextField } from '@coursera/cds-core';

import { AI_TOOL, type AI_TOOLS } from 'bundles/cml/shared/constants';
import { useTracker } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/cml';

const isReturnHotKey = isHotkey('return');

const styles = {
  textField: css`
    .cds-text-field-root {
      margin-top: 0;
    }
  `,
};

type Props = {
  className?: string;
  onChange: (change: { aiTool: AI_TOOLS; prompt?: string }) => void;
};

const CustomPromptMenuItem: React.FC<Props> = ({ className, onChange }) => {
  const [userPrompt, setUserPrompt] = useState('');
  const ref = useRef<HTMLInputElement | null>(null);
  const track = useTracker();

  const handleChange = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setUserPrompt(e.target.value);
    },
    []
  );

  const handleKeyDown = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: React.KeyboardEvent) => {
      if (!isReturnHotKey(event.nativeEvent)) {
        return;
      }

      event.preventDefault();

      onChange({ aiTool: AI_TOOL.PROMPT, prompt: userPrompt });
      track('create_writing_assistant_prompt', { customPrompt: userPrompt });
    },
    [userPrompt, onChange, track]
  );

  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    ref.current?.focus();
    return true;
  }, []);

  return (
    <TextField
      inputRef={ref}
      aria-label={_t('customize prompt')}
      placeholder={_t('Tell us to...')}
      onChange={handleChange}
      inputProps={{ onClick: handleClick, onKeyDown: handleKeyDown }}
      fullWidth
      css={styles.textField}
      className={className}
    />
  );
};

export default CustomPromptMenuItem;
