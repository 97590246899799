import * as React from 'react';

import CodeEvaluatorOutput from 'bundles/code-evaluator/components/CodeEvaluatorOutput';
import type Evaluation from 'bundles/code-evaluator/models/Evaluation';

import _t from 'i18n!nls/author-code-evaluator';

type Props = {
  evaluatorDraftId: string;
  evaluation: Evaluation;
  onCancel: () => void;
};

const CodeOutput = (props: Props) => {
  const { evaluation, evaluatorDraftId, onCancel } = props;
  const { active, response } = evaluation;

  return (
    <div className="rc-CodeOutput">
      {!active && !response && (
        <div className="caption-text color-secondary-text" style={{ margin: 16 }}>
          {_t('Run the code on the left to see the output that learners will see when running your code.')}
        </div>
      )}

      <CodeEvaluatorOutput onCancel={onCancel} evaluation={evaluation} evaluatorId={evaluatorDraftId} />
    </div>
  );
};

export default CodeOutput;
