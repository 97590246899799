import * as React from 'react';
import { useCallback } from 'react';

import { useSlateStatic } from 'slate-react';

import { randomUUID } from 'js/lib/uuid';

import { Menu, MenuItem } from 'bundles/authoring/common/components/Menu';
import { getMenuDirectionProps } from 'bundles/cml/editor/utils/menuUtils';
import { insertBlockNodes } from 'bundles/cml/editor/utils/slateUtils';
import type { LanguageType } from 'bundles/cml/legacy/constants/codeLanguages';
import codeLanguages from 'bundles/cml/legacy/constants/codeLanguages';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { CodeElement } from 'bundles/cml/shared/types/elementTypes';

type Props = {
  anchorEl?: HTMLElement;
  open: boolean;
  handleClose: React.ReactEventHandler<Element>;
  menuDirection?: 'top' | 'bottom';
};

const CodeMenu: React.FC<Props> = (props: Props) => {
  const { anchorEl, open, handleClose, menuDirection } = props;
  const staticEditor = useSlateStatic();

  const handleLanguageSelect = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (language: LanguageType) => {
      const code: CodeElement = {
        type: BLOCK_TYPES.CODE,
        isVoid: true,
        language,
        codeText: '',
        id: randomUUID(),
        children: [{ text: '' }],
      };

      insertBlockNodes(staticEditor, code);
    },
    [staticEditor]
  );

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      {...getMenuDirectionProps(menuDirection)}
      disableScrollLock={true}
      maxHeight="50%"
      className="rc-CMLEditorMenu"
      autoFocus
    >
      {codeLanguages.map((language: { name: string; value: string }, index: number) => (
        <MenuItem
          key={`code-${language.value}`}
          label={language.name}
          onClick={() => handleLanguageSelect(language.value as LanguageType)}
          autoFocus={index === 0}
        />
      ))}
    </Menu>
  );
};

export default CodeMenu;
