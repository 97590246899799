import * as React from 'react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import PreambleEditor from 'bundles/author-code-evaluator/components/PreambleEditor';
import ReplHarness from 'bundles/author-code-evaluator/models/ReplHarness';
import type { LanguageType } from 'bundles/cml';

type Props = {
  harness: ReplHarness;
  language: LanguageType;
  onChange: (arg: $TSFixMe) => void;
};

const ReplHarnessEditor = (props: Props) => {
  const { harness, language, onChange } = props;

  const handleChange = (preamble: string) => {
    const newHarness = new ReplHarness({ preamble });
    onChange(newHarness.toJSON());
  };

  return (
    <div className="rc-ReplHarnessEditor">
      <PreambleEditor preamble={harness.preamble} language={language} onChange={handleChange} />
    </div>
  );
};

export default ReplHarnessEditor;
