import * as React from 'react';

import { CardSection } from '@coursera/coursera-ui';

type InformationCardFooterProps = {
  htmlAttributes?: {
    className?: string;
  };
};

const InformationCardFooter: React.LegacyFunctionComponentWithChildren<InformationCardFooterProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <CardSection type="footer" {...rest}>
      {children}
    </CardSection>
  );
};

export default InformationCardFooter;
