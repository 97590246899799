import * as React from 'react';
import { useCallback } from 'react';

import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';

import { AddColumnIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { createTableCell, focusTableCell, getTableData } from 'bundles/cml/editor/components/elements/table/tableUtils';
import type { TableElement, TableRowElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  table: TableElement;
};

const AddTableColumnButton: React.FC<Props> = ({ table }) => {
  const staticEditor = useSlateStatic();

  const handleAddColumn = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    const { path, columnIndex, rowIndex } = getTableData(staticEditor, table);
    if (!path || columnIndex < 0 || rowIndex < 0) {
      return;
    }

    const nextColumnIndex = columnIndex + 1;

    table.children.forEach((row: TableRowElement, index: number) => {
      const tableCell = createTableCell();
      Transforms.insertNodes(staticEditor, tableCell, { at: [...path, index, nextColumnIndex] });
    });

    focusTableCell(staticEditor, path, rowIndex, nextColumnIndex);
  }, [staticEditor, table]);

  return (
    <Button role="menuitem" onClick={handleAddColumn} title={_t('Insert column')} type={TOOLBAR_BUTTON_TYPES.insertion}>
      <AddColumnIcon size="small" />
    </Button>
  );
};

export default AddTableColumnButton;
