import { css } from '@emotion/react';

import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('ContextualHelpIcon', [
  'customHelpIcon',
  'expanded',
  'i',
  'inverted',
  'background',
  'neck',
  'dot',
  'crosspath',
]);

const getContextualHelpIconCss = css`
  transition: 0.3s ease-in-out;
  transform: rotate(0deg);
  transition-property: transform, fill, stroke;

  .${classes.i} {
    fill: currentColor;
  }

  /* Background Transition */
  .${classes.background} {
    transition: 0.3s ease-in-out;
    transform-box: fill-box;
    transform-origin: center;
    transition-property: transform, fill, stroke;
    fill: #0056d200;
    stroke: currentColor;
    stroke-width: 2;
  }

  .${classes.neck} {
    fill: currentColor;
    transition: 0.3s ease-in-out;
    transform-box: fill-box;
    transform-origin: bottom;
    transition-property: transform, fill;
    transform: scale(1, 0.5) translate(0, -92%);
  }

  .${classes.dot} {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }

  .${classes.crosspath} {
    transition: 0.3s ease-in-out;
    transform-box: fill-box;
    transform-origin: center;
    transition-property: transform, fill;

    /*! @noflip */
    transform: translate(-39%, 0) scale(0, 1);
  }

  &.${classes.expanded} {
    transform: rotate(45deg);

    .${classes.background} {
      fill: currentColor;
    }

    .${classes.neck} {
      /*! @noflip */
      transform: translate(0%, -39%) scale(1, 0.96);
      fill: var(--cds-color-neutral-primary-invert) !important;
    }

    .${classes.dot} {
      transform: scale(0, 0);
    }

    .${classes.crosspath} {
      /*! @noflip */
      transform: translate(-39%, 0) scale(1, 0.96);
      fill: var(--cds-color-neutral-primary-invert);
    }
  }

  &.${classes.inverted} {
    .${classes.i}, .${classes.neck}, .${classes.crosspath} {
      fill: currentColor !important;
    }

    .${classes.background} {
      stroke: var(--cds-color-neutral-primary-invert);
      fill: transparent;
    }

    &.${classes.expanded} {
      .${classes.background} {
        fill: var(--cds-color-neutral-primary-invert);
      }
    }
  }
`;

export default getContextualHelpIconCss;
