/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';

import type { FormattedMessage } from 'js/lib/coursera.react-intl';

import type { StyleDeclarationValue } from '@coursera/aphrodite';
import { Typography, breakpoints } from '@coursera/cds-core';

const styles = {
  root: () => css`
    padding: var(--cds-spacing-800) var(--cds-spacing-150);
    ${breakpoints.down('md')} {
      padding: var(--cds-spacing-400) var(--cds-spacing-50);
    }

    &.altBackgroundEnabled {
      background-color: var(--cds-color-grey-25);
    }
  `,

  content: css`
    max-width: 1152px;
    margin: 0 auto;
  `,
  header: () => css`
    margin-left: var(--cds-spacing-150);
    margin-right: var(--cds-spacing-150);
  `,
};

type Props = {
  title?: string | React.ReactElement<typeof FormattedMessage>;
  altBackground?: boolean;
  className?: string;
  styles?: {
    root?: StyleDeclarationValue;
    content?: StyleDeclarationValue;
    header?: StyleDeclarationValue;
  };
};

/**
 * This component encapsulates all the shared section formatting on the XPDP page, namely:
 *  - standard header, with responsive font sizing
 *  - standard padding, with optional background
 * Note that the content inside this component should generally be wrapped inside a CUI Container component, or some
 * other component that will provide an additional 12px padding left/right.
 * Props are:
 *  - title (string, optional) - the title that's placed in the H2Bold component
 *  - altBackground (boolean, optional) - true if this section should have a light gray background instead of white
 *  - className -
 *    -The component that's overriding PdpSection simply uses the css prop on it as though it were a standard HTML node.
 *      See: https://coursera.atlassian.net/wiki/spaces/EN/pages/1481310277/Styling+components+with+Emotion#Overriding-styles-in-Emotion-components
 *        ex.
 *
 * <PdpSection {...props} css={css`margin-left: ${theme.spacing(12)};`}/>
 *
 *    -Class name selectors can be passed in the css attribute to target pdp-section-content or pdp-section-header
 */
const PdpSection: React.LegacyFunctionComponentWithChildren<Props> = ({
  title,
  altBackground = false,
  children,
  className,
}) => {
  return (
    <div css={styles.root} className={classNames(className, altBackground && 'altBackgroundEnabled')}>
      <div css={styles.content} className="pdp-section-content">
        {title && (
          <Typography className="pdp-section-header" variant="h2semibold">
            {title}
          </Typography>
        )}
        {children}
      </div>
    </div>
  );
};

export default PdpSection;
