/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { throttle } from 'lodash';
import { Element, Transforms } from 'slate';
import type { RenderElementProps } from 'slate-react';
import { ReactEditor, useReadOnly, useSelected, useSlateStatic } from 'slate-react';

import MathEditor from 'bundles/cml/editor/components/elements/math/MathEditor';
import MathPlaceholder from 'bundles/cml/editor/components/elements/math/MathPlaceholder';
import { parseFormula } from 'bundles/cml/editor/components/elements/math/utils';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { useFocusTrap } from 'bundles/cml/editor/utils/dialogUtils';
import MathRenderer from 'bundles/cml/shared/components/math/Math';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES } from 'bundles/cml/shared/constants';
import type { MathElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  button: css`
    display: inline-block;
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    text-align: unset;
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    outline: none;
    user-select: none;

    &:not(:disabled) {
      &:hover,
      &:focus {
        outline: 3px solid var(--cds-color-interactive-primary);
      }

      &[aria-pressed='true'],
      &:active {
        outline: 3px solid var(--cds-color-callouts-tertiary);
      }
    }
  `,
  selected: css`
    outline: 3px solid var(--cds-color-callouts-tertiary);
  `,
  fullWidth: css`
    width: 100%;
  `,
};

const MATH_TYPES: BLOCK_VALUES[] = [BLOCK_TYPES.MATH_BLOCK, BLOCK_TYPES.MATH_INLINE];

const Math: React.FC<RenderElementProps> = ({ element, attributes, children }) => {
  const math = element as MathElement;
  const isBlock = !math.isInline;
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const openRef = useRef(false);
  const [error, setError] = useState<string | undefined>();

  const ref = useRef<HTMLDivElement>(null);

  const isSelected = useSelected();
  const isReadOnly = useReadOnly();
  const selected = isSelected && !isReadOnly;

  const { setFocused } = useFocusedContext();
  const staticEditor = useSlateStatic();

  const formula = parseFormula(math.formula, isBlock);

  const openEditor = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    setFocused(true);
    Transforms.setNodes(staticEditor, { editMathDialog: true }, { at: ReactEditor.findPath(staticEditor, math) });
    openRef.current = true;
  }, [setFocused, staticEditor, math]);

  const closeEditor = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    Transforms.unsetNodes(staticEditor, 'editMathDialog', { at: ReactEditor.findPath(staticEditor, math) });
    openRef.current = false;

    ReactEditor.focus(staticEditor);
    setFocused(false);
  }, [setFocused, staticEditor, math]);

  const handleDone = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    closeEditor();
    ReactEditor.focus(staticEditor);
    Transforms.select(staticEditor, ReactEditor.findPath(staticEditor, math));
    Transforms.move(staticEditor, { distance: 1, unit: 'character' });
  }, [staticEditor, closeEditor, math]);

  const handleBlur = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    closeEditor();
  }, [closeEditor]);

  const handleDelete = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    closeEditor();
    ReactEditor.focus(staticEditor);
    Transforms.removeNodes(staticEditor, {
      at: ReactEditor.findPath(staticEditor, math),
      match: (node) => Element.isElement(node) && MATH_TYPES.includes(node.type),
    });
  }, [staticEditor, closeEditor, math]);

  const handleChange = useMemo(
    () =>
      throttle((value: string) => {
        const textFormula = value ? `${formula.delims[0]}${value}${formula.delims[1]}` : '';
        Transforms.setNodes<MathElement>(
          staticEditor,
          { ...math, formula: textFormula },
          {
            at: ReactEditor.findPath(staticEditor, math),
            match: (node) => Element.isElement(node) && MATH_TYPES.includes(node.type),
          }
        );
      }, 100),
    [staticEditor] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const showEditor = math.editMathDialog;
  useEffect(() => {
    if (showEditor && !openRef.current) {
      openEditor();
    }
  }, [showEditor, openEditor]);
  useFocusTrap(ref);

  const isPlaceholder = !formula.value.trim();

  return (
    <MathRenderer element={element} attributes={attributes} onError={setError}>
      {(mathFormula: React.ReactNode) => (
        <React.Fragment>
          <button
            tabIndex={-1}
            ref={setButtonRef}
            type="button"
            aria-pressed={showEditor ? 'true' : 'false'}
            disabled={isReadOnly}
            css={[styles.button, isBlock && styles.fullWidth, (selected || showEditor) && styles.selected]}
            onClick={openEditor}
            contentEditable={false}
          >
            {isPlaceholder ? !isReadOnly && <MathPlaceholder isBlock={isBlock} /> : mathFormula}
          </button>
          {showEditor && buttonRef && (
            <MathEditor
              ref={ref}
              anchorEl={buttonRef}
              value={formula.value}
              error={error}
              isBlock={isBlock}
              onDone={handleDone}
              onChange={handleChange}
              onDelete={handleDelete}
              onBlur={handleBlur}
            />
          )}
          {children}
        </React.Fragment>
      )}
    </MathRenderer>
  );
};

export default Math;
