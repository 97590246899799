import * as React from 'react';
import { useCallback } from 'react';

import { Transforms } from 'slate';
import { useSlate, useSlateStatic } from 'slate-react';

import { AddIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { createFillableBlank } from 'bundles/cml/editor/components/buttons/fillable-blank/fillableBlankUtils';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const FillableBlankButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const disabled = isTypeDisabled(editor, BLOCK_TYPES.FILLABLE_BLANK);

  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    const fillableBlank = createFillableBlank();
    Transforms.insertNodes(staticEditor, fillableBlank);
  }, [staticEditor]);

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      title={_t('Insert Response')}
      buttonProps={{
        icon: <AddIcon color="interactive" size="small" />,
        iconPosition: 'before',
        variant: 'secondary',
      }}
      useDefaultCdsColor={true}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      {_t('Response')}
    </Button>
  );
};

export default FillableBlankButton;
