/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { isEqual } from 'lodash';
import { Transforms } from 'slate';
import { ReactEditor, useReadOnly, useSelected, useSlateStatic } from 'slate-react';
import type { RenderElementProps } from 'slate-react';

import WidgetMenu from 'bundles/cml/editor/components/elements/widget/WidgetMenu';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { useWidgetContext } from 'bundles/cml/editor/context/widgetContext';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import WidgetRenderer from 'bundles/cml/shared/components/widget/Widget';
import { useCourseContext } from 'bundles/cml/shared/hooks/context';
import { useWidget } from 'bundles/cml/shared/hooks/useWidget';
import type { WidgetElement } from 'bundles/cml/shared/types/elementTypes';

const styles = {
  widget: css`
    position: relative;
    margin-bottom: 20px;

    &:hover {
      outline: 2px solid var(--cds-color-interactive-primary);
    }
  `,
  selected: css`
    /* Override above style when selected and prevent lighter color. */
    outline: 2px solid var(--cds-color-callouts-tertiary) !important;
  `,
};

const Widget: React.FC<RenderElementProps> = (props) => {
  const { element, children } = props;
  const { focused, setFocused } = useFocusedContext();
  const { widgetManager, widgetMenuOptions } = useWidgetContext();
  const { courseContext } = useCourseContext();
  const courseId = useMemo(() => courseContext?.courseId ?? '', [courseContext?.courseId]);
  const staticEditor = useSlateStatic();
  const selected = useSelected();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [buttonMenuRef, setButtonMenuRef] = useState<HTMLButtonElement | null>(null);
  const readonly = useReadOnly();
  const widgetElement = element as WidgetElement;
  const { updateWidgetSession, publishWidgetSession } = widgetMenuOptions || {};
  const { widgetPreview, widgetSession, handleUpdateWidgetSession, lastPublishedConfig } = useWidget(
    widgetElement.id,
    widgetManager,
    { updateWidgetSession }
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const path = ReactEditor.findPath(staticEditor, element);

  const handlePublish = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(async () => {
    if (!isEqual(lastPublishedConfig, widgetPreview?.configuration)) {
      const result = await publishWidgetSession?.({ id: widgetSession?.id || '', courseId });
      Transforms.setNodes(staticEditor, { id: result?.publishedId || '' }, { at: path });
    }
  }, [staticEditor, lastPublishedConfig, widgetSession, widgetPreview, path, publishWidgetSession, courseId]);

  const handleCloseMenu = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(async () => {
    handlePublish();
    setMenuOpen(false);
    ReactEditor.focus(staticEditor);
    setFocused(false);
  }, [handlePublish, setMenuOpen, setFocused, staticEditor]);

  const handleOpenMenu = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    if (!readonly) {
      setFocused(true);
      setMenuOpen(true);
    }
  }, [setFocused, readonly]);

  return (
    <WidgetRenderer
      ref={setRef}
      buttonMenuRef={setButtonMenuRef}
      css={[styles.widget, (menuOpen || (selected && focused)) && !readonly && styles.selected]}
      widget={widgetPreview}
      onClick={handleOpenMenu}
      {...props}
    >
      {children}
      {menuOpen && widgetSession && widgetPreview && (
        <FloatingMenu anchorEl={ref} onClose={handleCloseMenu} pageless>
          <WidgetMenu
            anchorEl={buttonMenuRef}
            widget={widgetElement}
            widgetSession={widgetSession}
            widgetPreview={widgetPreview}
            onClose={handleCloseMenu}
            onUpdate={handleUpdateWidgetSession}
          />
        </FloatingMenu>
      )}
    </WidgetRenderer>
  );
};

export default Widget;
