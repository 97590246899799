import * as React from 'react';
import { useCallback } from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { keyBy } from 'lodash';

import { SKILL_LIMIT } from 'bundles/program-personalized-tab/constants';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import ScoredSkillRecsContext from 'bundles/program-personalized-tab/contexts/ScoredSkillRecsContext';
import AllSkillsProgressForRecsTab from 'bundles/program-personalized-tab/queries/AllSkillsProgressForRecsTab.graphql';
import EnterpriseProductRecsForSkills from 'bundles/program-personalized-tab/queries/EnterpriseProductRecsForSkills.graphql';
import type {
  AllSkillsProgressForRecsTabQuery,
  AllSkillsProgressForRecsTabQueryVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/AllSkillsProgressForRecsTab';
import type {
  EnterpriseProductRecsForSkillsQuery,
  EnterpriseProductRecsForSkillsQueryVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseProductRecsForSkills';
import type {
  DiscoveryCollectionsProductTypename,
  MixedCollectionsBySkill,
  ProductEntry,
  Skill,
  SkillData,
} from 'bundles/program-personalized-tab/types/sharedTypes';
import { getRequestSkillsParams } from 'bundles/program-personalized-tab/utils/skillRecsUtils';
import {
  useEnterpriseProductFeatures,
  useEnterpriseProductFeaturesLoading,
} from 'bundles/unified-common/contexts/EnterpriseProductFeaturesContext';

type Props = {
  programId: string;
  children: React.ReactNode;
};

const useRecsForSkillsQuery = (skills: Skill[] | undefined, programId: string) => {
  const { error: allSkillsProgressError, data: allSkillsProgressData } = useQuery<
    AllSkillsProgressForRecsTabQuery,
    AllSkillsProgressForRecsTabQueryVariables
  >(AllSkillsProgressForRecsTab, {
    variables: { first: SKILL_LIMIT, programId },
    skip: !skills || skills.length === 0,
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'gatewayGql' },
  });

  const skillsProgressById = keyBy(allSkillsProgressData?.SkillsProgress?.queryAllSkillProgress.edges, (o) => {
    const fullId = o.node.skill.id;
    return fullId.slice(fullId.indexOf('~') + 1);
  });
  const scorableSkills: Skill[] = [];
  const skillData: SkillData = {};
  skills?.forEach((skill) => {
    if (skillsProgressById[skill.id]?.node.skill.isEligibleForScoring) {
      scorableSkills.push(skill);
      skillData[skill.id] = {
        name: skillsProgressById[skill.id].node.skill.skillName,
        score: skillsProgressById[skill.id].node.skillScore?.score,
      };
    }
  });

  const skillParams = getRequestSkillsParams(scorableSkills);
  const productFeatures = useEnterpriseProductFeatures();
  const areProductFeaturesLoading = useEnterpriseProductFeaturesLoading();

  const { loading, error, data } = useQuery<
    EnterpriseProductRecsForSkillsQuery,
    EnterpriseProductRecsForSkillsQueryVariables
  >(EnterpriseProductRecsForSkills, {
    variables: {
      programId,
      skillParams,
      contextId: productFeatures?.enableV2SkillsTaxonomy
        ? 'personalized-tab-skill-based-recs-t2'
        : 'personalized-tab-skill-based-recs-t1',
    },
    skip: areProductFeaturesLoading || skillParams.length === 0,
    notifyOnNetworkStatusChange: true,
    context: { clientName: 'gatewayGql' },
  });

  const getCollectionsBySkill = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    const collectionsBySkill: MixedCollectionsBySkill = {};
    const skillByName = keyBy(skills, 'name');

    for (const collection of data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.productCollections ??
      []) {
      if (collection?.label && skillByName[collection.label]) {
        const skillId = skillByName[collection.label].id;

        const productEntryEntities: ProductEntry[] = [];
        collection.entities?.forEach((entity) => {
          if (entity && entity.__typename && entity.__typename !== 'DiscoveryCollections_Clip') {
            productEntryEntities.push({
              type: 'productCard',
              ...entity,
              __typename: entity.__typename as DiscoveryCollectionsProductTypename,
            });
          }
        });

        collectionsBySkill[skillId] = { id: collection.id, label: collection.label, entities: productEntryEntities };
      }
    }

    return collectionsBySkill;
  }, [data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.productCollections, skills]);

  return {
    loading,
    error: { allSkillsProgressError, productRecsForSkillsError: error },
    getRecs: getCollectionsBySkill,
    skillData,
  };
};

const ScoredSkillRecsDataProvider = ({ programId, children }: Props) => {
  const onboardingData = useOnboardingData();
  const { loading, error, getRecs, skillData } = useRecsForSkillsQuery(onboardingData?.skills, programId);

  if (error) {
    Sentry.captureException(error);
  }

  return (
    <ScoredSkillRecsContext.Provider value={{ loading, error, getRecs, skillData }}>
      {children}
    </ScoredSkillRecsContext.Provider>
  );
};

export default ScoredSkillRecsDataProvider;
