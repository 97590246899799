/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useState } from 'react';

import { isEmpty } from 'lodash';

import { useRetracked } from 'js/lib/retracked';
import useRouter from 'js/lib/useRouter';

import { Button, CardMetadata, Link, ProductCard, Typography, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';

import { FilterableShowMoreGridSection, FilterableShowMoreGridSectionPlaceholder } from 'bundles/cds-labs/components';
import type { ExpandButtonProps } from 'bundles/cds-labs/components';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import { filterUndefined } from 'bundles/common/utils/commonUtils';
import { DESCRIPTION_PAGE_PATHS, launchMiniModal } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { SELECTED_SKILL_RECS_SOURCE_QUERY_VALUE } from 'bundles/program-personalized-tab/constants';
import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import { useSelectedSkillRecsData } from 'bundles/program-personalized-tab/contexts/SelectedSkillRecsContext';
import type {
  MixedCollectionsBySkill,
  Partner,
  ProductEntry,
  Skill,
} from 'bundles/program-personalized-tab/types/sharedTypes';
import { getProductRecsMetadata } from 'bundles/program-personalized-tab/utils/displayUtils';

import _t from 'i18n!nls/program-personalized-tab';

type Props = {
  programSlug: string;
  skills: Skill[];
  recs: MixedCollectionsBySkill;
};

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const styles = {
  cardWrapper: css`
    height: 100%;
    padding: var(--cds-spacing-150);
    cursor: pointer;
    ${breakpoints.down('xs')} {
      padding: var(--cds-spacing-100) 0;
    }
  `,
};

const getDescriptionPagePath = (productType: string) => {
  switch (productType) {
    case 'DiscoveryCollections_course':
      return DESCRIPTION_PAGE_PATHS.course;
    case 'DiscoveryCollections_specialization':
      return DESCRIPTION_PAGE_PATHS.specialization;
    case 'DiscoveryCollections_professionalCertificate':
      return DESCRIPTION_PAGE_PATHS.professionalCertificate;
    case 'DiscoveryCollections_guidedProject':
      return DESCRIPTION_PAGE_PATHS.project;
    default:
      return undefined;
  }
};

const SelectedSkillRecsCollectionRenderer = ({ programSlug, skills, recs }: Props) => {
  const track = useRetracked();
  const router = useRouter();
  const filters = skills.filter(({ id }) => !isEmpty(recs[id])).map(({ id, name }) => ({ id, label: name }));
  const [currentFilterId, setCurrentFilterId] = useState(filters[0].id);
  const title = _t('Skills you are interested in');
  const skillUrl = `/programs/${programSlug}/skills/${currentFilterId}?source=${SELECTED_SKILL_RECS_SOURCE_QUERY_VALUE}`;
  const items: ProductEntry[] = recs[currentFilterId]?.entities ?? [];

  const handleFilterSelection = (filterId?: string) => {
    if (filterId) {
      const filterIndex = filters.findIndex((f) => f.id === filterId);
      track({
        trackingData: { filterId, filterIndex, filterLabel: filters[filterIndex].label },
        trackingName: 'selected_skill_recs_filter_change',
        action: 'click',
      });
      setCurrentFilterId(filterId);
    }
  };

  const handleProductCardClick = (
    productId: string,
    productName: string,
    productType: string,
    productSlug: string,
    itemIndex: number,
    collectionTrackingId: string
  ) => {
    track({
      trackingData: { productId, productName, productType, itemIndex },
      trackingName: 'selected_skill_rec_product',
      action: 'click',
    });
    launchMiniModal(
      router,
      productId,
      productType === 'DiscoveryCollections_course' || productType === 'DiscoveryCollections_guidedProject',
      collectionTrackingId,
      undefined,
      { source: SELECTED_SKILL_RECS_SOURCE_QUERY_VALUE },
      {
        slug: productSlug,
        productPagePath: getDescriptionPagePath(productType),
      }
    );
  };

  const renderExpandButton = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    ({ onClick, defaultLabel, isExpanded, showMoreCount, ...rest }: ExpandButtonProps) => {
      return (
        <TrackedButton
          trackingName="show_more_selected_skill_recs"
          trackingData={{ title, isExpanded, showMoreCount }}
          size="small"
          variant="secondary"
          onClick={onClick}
          {...rest}
        >
          {defaultLabel}
        </TrackedButton>
      );
    },
    [title]
  );

  const renderSecondaryCta = () => {
    return (
      <Link
        component={TrackedLink2}
        trackingName="view_all_selected_skill_recs"
        icon={<ArrowNextIcon size="small" />}
        typographyVariant="bodyPrimary"
        href={skillUrl}
        target="_blank"
      >
        {_t('View all')}
      </Link>
    );
  };

  return (
    <TrackedDiv trackingName="selected_skill_recs" withVisibilityTracking>
      <FilterableShowMoreGridSection<ProductEntry>
        filters={filters}
        selectedFilterId={currentFilterId}
        onFilterSelect={handleFilterSelection}
        title={title}
        renderTitle={() => (
          <Typography component="h2" variant="h2semibold">
            {title}
          </Typography>
        )}
        items={items}
        renderItem={({ item, itemIndex, itemRef }) => {
          const { id, name } = item;

          const partnersCleaned = item.partners
            ?.filter(filterUndefined)
            .map(({ name: partnerName, logo }: Partner) => ({ name: partnerName, logoUrl: logo ?? undefined }));
          const collectionTrackingId = `skill~${currentFilterId}`;

          return (
            <div css={styles.cardWrapper} key={`${title}-${currentFilterId}-${id}`}>
              <ProductCard
                variant="grid"
                productType={item.__typename ?? ''}
                title={{
                  name,
                  customLinkProps: { refalt: itemRef },
                }}
                previewImageSrc={item.imageUrl ?? undefined}
                partners={partnersCleaned}
                footer={
                  <CardMetadata
                    metadata={getProductRecsMetadata({
                      level: item.difficultyLevel ?? undefined,
                      productType: item.__typename,
                    })}
                  />
                }
                onClick={() =>
                  handleProductCardClick(id, name, item.__typename, item.slug, itemIndex, collectionTrackingId)
                }
              />
            </div>
          );
        }}
        renderExpandButton={renderExpandButton}
        renderSecondaryCta={renderSecondaryCta}
      />
    </TrackedDiv>
  );
};

const SelectedSkillRecsCollection = ({ programSlug }: { programSlug: string }) => {
  const track = useRetracked();
  const onboardingData = useOnboardingData();
  const selectedSkillRecsData = useSelectedSkillRecsData();

  if (!onboardingData.loading && !selectedSkillRecsData.loading && isEmpty(selectedSkillRecsData.recs)) {
    track({
      trackingName: 'no_selected_skill_recs',
      action: 'hide',
      trackingData: { selectedSkills: onboardingData.skillsLearnerInterestedIn },
    });
    return null;
  }

  if (onboardingData.loading || selectedSkillRecsData.loading || !onboardingData.skillsLearnerInterestedIn) {
    return (
      <FilterableShowMoreGridSectionPlaceholder
        renderPlaceholderItem={() => (
          <div css={styles.cardWrapper} data-testid="placeholder-card-wrapper">
            <ProductCard variant="grid" productType="course" loading />
          </div>
        )}
      />
    );
  }

  return (
    <SelectedSkillRecsCollectionRenderer
      programSlug={programSlug}
      skills={onboardingData.skillsLearnerInterestedIn}
      recs={selectedSkillRecsData.recs}
    />
  );
};

export default SelectedSkillRecsCollection;
