import { Element, Transforms } from 'slate';
import type { Editor, Node, NodeEntry } from 'slate';

import { HEADINGS, HEADING_TO_LEVELS } from 'bundles/cml/editor/normalize/constants';
import normalizerLogger from 'bundles/cml/editor/normalize/normalizerLogger';
import type { Options } from 'bundles/cml/editor/normalize/types';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { HeadingLevel } from 'bundles/cml/shared/types/coreTypes';
import type { HeadingElement } from 'bundles/cml/shared/types/elementTypes';
import type { HeadingToolsKeys } from 'bundles/cml/shared/utils/customTools';

const normalizeHeadingTool = (editor: Editor, { tools }: Options, [node, path]: NodeEntry<HeadingElement>) => {
  const headingLevels = HEADINGS.reduce((result: Set<HeadingLevel>, headingTool: HeadingToolsKeys) => {
    if (tools.has(headingTool)) {
      const level = HEADING_TO_LEVELS[headingTool];
      result.add(level);
    }
    return result;
  }, new Set());

  if (headingLevels.has(node.level)) {
    return false;
  }

  Transforms.setNodes(editor, { type: BLOCK_TYPES.TEXT }, { at: path });
  Transforms.unsetNodes(editor, 'level', { at: path });
  return true;
};

const NORMALIZERS = [normalizerLogger(normalizeHeadingTool)];

export const normalizeHeadings = (editor: Editor, options: Options, nodeEntry: NodeEntry<Node>) => {
  if (!Element.isElement(nodeEntry[0])) {
    return false;
  }

  const headingNodeEntry = nodeEntry as NodeEntry<HeadingElement>;
  const [node] = headingNodeEntry;
  if (node.type !== BLOCK_TYPES.HEADING) {
    return false;
  }

  return NORMALIZERS.some((normalize) => normalize(editor, options, headingNodeEntry));
};
