import { BLOCK_TYPES, MARKS } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES, MARK_VALUES } from 'bundles/cml/shared/constants';
import type { HeadingLevel } from 'bundles/cml/shared/types/coreTypes';
import type { HeadingToolsKeys, ToolsKeys } from 'bundles/cml/shared/utils/customTools';
import { Tools } from 'bundles/cml/shared/utils/customTools';

export const MARKS_TO_TOOLS: Partial<Record<MARK_VALUES, ToolsKeys>> = {
  [MARKS.BOLD]: Tools.BOLD,
  [MARKS.ITALIC]: Tools.ITALIC,
  [MARKS.UNDERLINE]: Tools.UNDERLINE,
  [MARKS.MONOSPACE]: Tools.MONOSPACE,
  [MARKS.SUPERSCRIPT]: Tools.SUPERSCRIPT,
  [MARKS.SUBSCRIPT]: Tools.SUBSCRIPT,
};

export const TABLE_TYPES_TO_TOOLS: Partial<Record<BLOCK_VALUES, ToolsKeys>> = {
  [BLOCK_TYPES.TABLE]: Tools.TABLE,
  [BLOCK_TYPES.TABLE_ROW]: Tools.TABLE,
  [BLOCK_TYPES.TABLE_CELL]: Tools.TABLE,
};

export const VOID_TYPES_TO_TOOLS: Partial<Record<BLOCK_VALUES, ToolsKeys>> = {
  [BLOCK_TYPES.IMAGE]: Tools.IMAGE,
  [BLOCK_TYPES.IMAGE_UPLOADER]: Tools.IMAGE,
  [BLOCK_TYPES.ASSET]: Tools.ASSET,
  [BLOCK_TYPES.PERSONALIZATION_TAG]: Tools.PERSONALIZATION_TAG,
  [BLOCK_TYPES.FILLABLE_BLANK]: Tools.FILLABLE_BLANK,
  [BLOCK_TYPES.WIDGET]: Tools.WIDGET,
};

export const LIST_TYPES_TO_TOOLS: Partial<Record<BLOCK_VALUES, ToolsKeys>> = {
  [BLOCK_TYPES.BULLET_LIST]: Tools.UNORDERED_LIST,
  [BLOCK_TYPES.NUMBER_LIST]: Tools.ORDERED_LIST,
};

export const ALL_MARKS = [
  MARKS.BOLD,
  MARKS.ITALIC,
  MARKS.MONOSPACE,
  MARKS.UNDERLINE,
  MARKS.SUPERSCRIPT,
  MARKS.SUBSCRIPT,
];

export const HEADING_TO_LEVELS: Record<HeadingToolsKeys, HeadingLevel> = {
  [Tools.HEADING_1]: '1',
  [Tools.HEADING_2]: '2',
  [Tools.HEADING_3]: '3',
  [Tools.HEADING_4]: '4',
};

export const HEADINGS: HeadingToolsKeys[] = [Tools.HEADING_1, Tools.HEADING_2, Tools.HEADING_3, Tools.HEADING_4];
