/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import { typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import _t from 'i18n!nls/widget-admin';

const styles = {
  form: (theme: Theme) =>
    css({
      display: 'inline-block',
      marginTop: 'var(--cds-spacing-150)',
      width: '100%',
    }),
  input: (theme: Theme) =>
    css({
      ...typography2.bodyPrimary,
      borderRadius: 'var(--cds-spacing-50)',
      border: `1px solid var(--cds-color-grey-600)`,
      height: 48,
      marginBottom: 6,
      padding: 'var(--cds-spacing-200) var(--cds-spacing-100)',
      width: '100%',
    }),
};

type Props = {
  handleSearch: (x0: string) => void;
};

const WidgetSelectionSearch: React.FC<Props> = ({ handleSearch }) => {
  const onSearch = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const keyword = (event.target as HTMLInputElement).value;

      handleSearch(keyword.toLowerCase());
    },
    [handleSearch]
  );

  return (
    <div>
      <form css={styles.form}>
        <input type="text" placeholder={_t('Search available plugins')} onChange={onSearch} css={styles.input} />
      </form>
    </div>
  );
};

export default WidgetSelectionSearch;
