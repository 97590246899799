import type { Node, NodeEntry } from 'slate';
import { Editor, Element, Transforms } from 'slate';

import normalizerLogger from 'bundles/cml/editor/normalize/normalizerLogger';
import type { Options } from 'bundles/cml/editor/normalize/types';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { MathElement } from 'bundles/cml/shared/types/elementTypes';
import { Tools } from 'bundles/cml/shared/utils/customTools';

export const normalizeMath = normalizerLogger((editor: Editor, { tools }: Options, nodeEntry: NodeEntry<Node>) => {
  if (!Element.isElement(nodeEntry[0])) {
    return false;
  }

  const [node, path] = nodeEntry as NodeEntry<MathElement>;
  if (node.type !== BLOCK_TYPES.MATH_BLOCK && node.type !== BLOCK_TYPES.MATH_INLINE) {
    return false;
  }

  if (tools.has(Tools.MATH)) {
    return false;
  }

  if (node.type === BLOCK_TYPES.MATH_BLOCK) {
    Editor.withoutNormalizing(editor, () => {
      Transforms.setNodes(editor, { type: BLOCK_TYPES.TEXT }, { at: path });
      Transforms.unsetNodes(editor, ['formula', 'isVoid', 'isInline'], { at: path });
      Transforms.insertText(editor, node.formula, { at: path });
    });
  } else if (node.type === BLOCK_TYPES.MATH_INLINE) {
    Editor.withoutNormalizing(editor, () => {
      Transforms.unwrapNodes(editor, {
        at: path,
        voids: true,
        mode: 'lowest',
      });
      Transforms.insertText(editor, node.formula, { at: path });
    });
  }

  return true;
}, 'normalizeMath');
