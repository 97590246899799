import { ContextGroup } from 'bundles/ai-coach-studio/types';
import type { ContextFetcher } from 'bundles/ai-coach-studio/types';

// Eventually this list could be fetched from the BE in a later version of Coach Studio so we don't need to keep updating the FE when new context fetchers are created
export const AVAILABLE_CONTEXT_FETCHERS: ContextFetcher[] = [
  { contextFetcherKey: 'course_metadata', contextGroup: ContextGroup.COURSE },
  {
    contextFetcherKey: 'course_difficulty',
    contextGroup: ContextGroup.COURSE,
  },
  { contextFetcherKey: 'course_name', contextGroup: ContextGroup.COURSE },
  {
    contextFetcherKey: 'course_faq_embedding',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'course_item_content',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'course_item_relevant_embeddings',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'course_item_previous_items_names',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'course_instructor_names',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'course_partner_names',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'reading_and_lecture_section_embedding',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'course_module_names',
    contextGroup: ContextGroup.COURSE,
  },
  {
    contextFetcherKey: 'course_item_feedback',
    contextGroup: ContextGroup.COURSE,
  },
  { contextFetcherKey: 's12n_metadata', contextGroup: ContextGroup.S12N },
  {
    contextFetcherKey: 's12n_faq_embedding',
    contextGroup: ContextGroup.S12N,
  },
  {
    contextFetcherKey: 'additional_products_metadata',
    contextGroup: ContextGroup.ADDITIONAL_PRODUCTS,
  },
  { contextFetcherKey: 'user_profile', contextGroup: ContextGroup.USER },
  {
    contextFetcherKey: 'user_enrolled_products',
    contextGroup: ContextGroup.USER,
  },
  { contextFetcherKey: 'user_locale', contextGroup: ContextGroup.USER },
  { contextFetcherKey: 'user_message', contextGroup: ContextGroup.USER },
  { contextFetcherKey: 'user_message_relevant_embeddings', contextGroup: ContextGroup.USER },
  {
    contextFetcherKey: 'previous_response',
    contextGroup: ContextGroup.PREVIOUS_RESPONSE,
  },
];

export const COACH_STUDIO_MODELS = [
  'gpt-4o',
  'gpt-4o-mini',
  'gpt-4',
  'gpt-35-turbo-16k',
  'gemini-1.5-pro-001',
  'gemini-1.5-flash-002',
];

export const COACH_STUDIO_SESSION_STORAGE_KEY = 'COACH_STUDIO_SETTINGS';

export const COACH_STUDIO_DEFAULT_PROMPT =
  "You are a Coursera Learning Coach who is friendly, encouraging, and informative. You answer in a way that should make learners feel encouraged and empowered. Your goal is to help users be successful learners on Coursera. Answer a learner's question to the best of your ability.";
