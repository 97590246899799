/** @jsx jsx */
import { jsx } from '@emotion/react';

import type { FC } from 'react';
import { useEffect, useRef } from 'react';

import type { Props } from 'bundles/asset-admin/components/DashboardUploader';
import DashboardUploader from 'bundles/asset-admin/components/cds/DashboardUploader';
import { applyRemoveButtonA11yPatch } from 'bundles/asset-admin/utils/UppyUtils';

const DashboardUploaderWithA11yPatch: FC<Props> = (props) => {
  const { uppyRef } = props;
  const containerRef = useRef(null);

  useEffect(() => {
    const uppy = uppyRef?.current;

    const handleFileAdded = () => {
      // Wait for the next browser paint cycle to ensure DOM is updated
      requestAnimationFrame(() => {
        if (containerRef.current) {
          applyRemoveButtonA11yPatch(containerRef.current);
        }
      });
    };

    if (uppy) {
      uppy.on('file-added', handleFileAdded);
    }

    return () => {
      if (uppy) {
        uppy.off('file-added', handleFileAdded);
      }
    };
  }, [uppyRef]);

  return (
    <div ref={containerRef}>
      <DashboardUploader {...props} />
    </div>
  );
};

export default DashboardUploaderWithA11yPatch;
