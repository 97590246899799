import * as React from 'react';

import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { useParams } from 'js/lib/useRouter';

import type { AdminGlobalPartnerFragment } from '../__queries__/__generated__/adminGlobalPartnerFragment';
import type { GetPartnerByIdQuery, GetPartnerByIdQueryVariables } from '../__queries__/__generated__/getPartnerById';
import type {
  GetPartnerBySlugQuery,
  GetPartnerBySlugQueryVariables,
} from '../__queries__/__generated__/getPartnerBySlug';
import getPartnerById from '../__queries__/getPartnerById.graphql';
import getPartnerBySlug from '../__queries__/getPartnerBySlug.graphql';
import { ROUTE_ARGUMENTS } from '../analytics/constants';

export interface PartnerContextType {
  partner: AdminGlobalPartnerFragment | undefined | null;
  loading: boolean;
  error?: ApolloError | { message: string };
}

const PartnerContext = React.createContext<PartnerContextType>({
  partner: undefined,
  loading: false,
});

/**
 * Access the current partner for the page context
 */
export const usePartner = () => React.useContext(PartnerContext);

/**
 * Provides access for Admin V2 to the current partner via usePartner
 */
export const AdminV2PartnerProvider: React.LegacyFunctionComponentWithChildren = ({ children }) => {
  const params = useParams();
  const slug = params[ROUTE_ARGUMENTS.INSTITUTION_SLUG];

  const { loading, error, ...result } = useQuery<GetPartnerBySlugQuery, GetPartnerBySlugQueryVariables>(
    getPartnerBySlug,
    {
      variables: {
        slug,
      },
      context: { clientName: 'gatewayGql' },
      skip: !slug,
    }
  );

  const context: PartnerContextType = {
    loading,
    partner: result.data?.Partner?.queryBySlug,
    error,
  };

  return <PartnerContext.Provider value={context}>{children}</PartnerContext.Provider>;
};

/**
 * Provides access for Admin V1 (deprecated) to the current partner via usePartner
 *
 * @deprecated
 */
export const AdminV1PartnerProvider: React.LegacyFunctionComponentWithChildren<{ selectedPartnerId?: number }> = ({
  selectedPartnerId,
  children,
}) => {
  const { loading, ...result } = useQuery<GetPartnerByIdQuery, GetPartnerByIdQueryVariables>(getPartnerById, {
    variables: {
      id: `${selectedPartnerId}`,
    },
    context: { clientName: 'gatewayGql' },
    skip: !selectedPartnerId,
  });

  const errorFindingPartner = !selectedPartnerId;
  const errorMessage = errorFindingPartner ? { message: 'Error fetching preferred partner' } : undefined;
  const error = result.error || errorMessage;

  const context: PartnerContextType = {
    loading,
    partner: result.data?.Partner?.queryById,
    error,
  };

  return <PartnerContext.Provider value={context}>{children}</PartnerContext.Provider>;
};
