import React from 'react';

import clsx from 'clsx';

import { useLocalizedStringFormatter } from '@coursera/cds-common';
import type { OverrideProps, OverridableComponent } from '@coursera/cds-common';
import {
  ChevronNextIcon,
  ChevronPreviousIcon,
  MoreActionsIcon,
} from '@coursera/cds-icons';

import Button from '@core/Button';
import IconButtonBase from '@core/IconButton/IconButtonBase';

import i18nMessages from './i18n';
import getPaginationItemCss, { classes } from './styles/paginationItemCss';
import type { UsePaginationItem } from './usePagination';

export type BaseProps = {
  /**
   * Sets the variant of the component
   */
  variant: UsePaginationItem['type'];

  /**
   * If set to `true` selected styles will be applied
   */
  selected?: boolean;

  /**
   * If set to `true` disabled styles will be applied
   */
  disabled?: boolean;

  /**
   * Page number. Can be `undefined` for all variants except `page`
   */
  page?: number;

  /**
   * Callback fired when the component is clicked
   */
  onClick?: React.ReactEventHandler;
};

export interface PaginationItemTypeMap<D extends React.ElementType = 'button'> {
  props: BaseProps;
  defaultComponent: D;
}

export type PaginationItemProps<
  D extends React.ElementType = PaginationItemTypeMap['defaultComponent']
> = OverrideProps<PaginationItemTypeMap<D>, D> & {
  component?: React.ElementType;
};

const PaginationItem: OverridableComponent<PaginationItemTypeMap> = React.forwardRef(
  function PaginationItem(
    props: PaginationItemProps,
    ref: React.Ref<HTMLButtonElement>
  ) {
    const { variant, selected, page, component, ...otherProps } = props;

    const stringFormatter = useLocalizedStringFormatter(i18nMessages);

    const commonProps = {
      ...otherProps,
      component,
      ref,
    };

    if (variant === 'page') {
      return (
        <Button
          className={clsx(classes.default, { [classes.selected]: selected })}
          css={getPaginationItemCss}
          size="small"
          variant={selected ? 'primary' : 'ghost'}
          {...commonProps}
          aria-current={selected ? 'page' : undefined}
        >
          {page}
        </Button>
      );
    } else if (variant === 'first' || variant === 'last') {
      const labelKey = variant === 'first' ? 'firstPage' : 'lastPage';

      return (
        <Button size="small" variant="ghost" {...commonProps}>
          {stringFormatter.format(labelKey)}
        </Button>
      );
    } else if (variant === 'start-ellipsis' || variant === 'end-ellipsis') {
      return (
        <div className={clsx(classes.default)} css={getPaginationItemCss}>
          <MoreActionsIcon
            size="small"
            title={stringFormatter.format(variant)}
          />
        </div>
      );
    } else {
      return (
        <IconButtonBase
          aria-label={commonProps['aria-label'] || variant}
          disabled={commonProps.disabled}
          size="small"
          variant="secondary"
          onClick={commonProps.onClick}
        >
          {variant === 'next' ? <ChevronNextIcon /> : <ChevronPreviousIcon />}
        </IconButtonBase>
      );
    }
  }
);

export default PaginationItem;
