/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import config from 'js/app/config';

import { Link, Typography2 } from '@coursera/cds-core';

import type { CoachPersona } from 'bundles/ai-coach-platform/components/patterns/chat/types';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  children?: React.ReactNode | React.ReactNode[];
  coachPersona?: CoachPersona;
};

const ChatFooter = ({ children, coachPersona }: Props) => {
  return (
    <Typography2
      component="p"
      variant="bodySecondary"
      color="supportText"
      css={css`
        padding: var(--cds-spacing-200);
      `}
    >
      {children ?? (
        <FormattedMessage
          message={_t(
            `#{coachLabel} is powered by AI, so check for mistakes and don't share sensitive info. Your data will be used in accordance with {privacyNotice}.`,
            { coachLabel: coachPersona?.name || 'Coach' }
          )}
          privacyNotice={
            <Link
              href={`${config.url.base}about/privacy?trk_ref=coach`}
              target="_blank"
              rel="noreferrer"
              css={css`
                color: inherit !important;
              `}
            >
              {_t(`Coursera's Privacy Notice`)}
            </Link>
          }
        />
      )}
    </Typography2>
  );
};

export default ChatFooter;
