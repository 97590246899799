import * as React from 'react';

import classNames from 'classnames';

import 'css!bundles/author-code-evaluator/components/__styles__/TestCasePill';

type Props = {
  onClick?: (index: number) => void;
  name: string;
  index: number;
  isSelected: boolean;
  isPassed: boolean;
  isFailed: boolean;
};

const TestCasePill = (props: Props) => {
  const { onClick, name, index, isSelected, isPassed, isFailed } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(index);
    }
  };

  const classes = classNames('rc-TestCasePill', 'nostyle', 'body-2-text', {
    selected: isSelected,
    passed: isPassed,
    failed: isFailed,
    default: !isPassed && !isFailed,
  });

  return (
    <button className={classes} onClick={handleClick} disabled={!onClick}>
      {name || <span>&nbsp;</span>}
    </button>
  );
};

export default TestCasePill;
