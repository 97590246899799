/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useState } from 'react';

import { FullscreenDialog, Grid } from '@coursera/cds-core';

import type { PAGE_RENDER_OPTIONS_TYPE } from 'bundles/cml/editor/components/elements/asset/PdfPageSelectModalForm';
import {
  PAGE_RENDER_OPTIONS,
  PdfPageSelectModalForm,
} from 'bundles/cml/editor/components/elements/asset/PdfPageSelectModalForm';
import PdfViewer from 'bundles/cml/shared/components/asset/pdfViewer/PdfViewer';
import type { PdfDocument } from 'bundles/cml/shared/components/asset/pdfViewer/types';

const { Header, Content } = FullscreenDialog;

const styles = {
  contentContainer: css`
    padding: 0;
  `,
  viewerContainer: css`
    padding: var(--cds-spacing-800);
  `,
};

type Props = {
  embedStartPage?: number;
  embedEndPage?: number;
  onClose: () => void;
  onPageSelect: (pageStart?: number, pageEnd?: number) => void;
  url: string;
  assetId: string;
};

export const PdfPageSelectModal: React.FC<Props> = ({
  embedStartPage,
  embedEndPage,
  onPageSelect,
  onClose,
  url,
  assetId,
}) => {
  const [previewPageStart, setPreviewPageStart] = useState<number | undefined>(embedStartPage);
  const [previewPageEnd, setPreviewPageEnd] = useState<number | undefined>(embedEndPage);
  const [pdfDoc, setPdfDoc] = useState<PdfDocument | undefined>(undefined);

  const onPreviewPageStartChange = (pageNum?: number) => {
    setPreviewPageStart(pageNum);
  };

  const onPreviewPageEndChange = (pageNum?: number) => {
    setPreviewPageEnd(pageNum);
  };

  const onPdfLoad = useCallback<(...args: $TSFixMe[]) => $TSFixMe>((doc: PdfDocument) => {
    setPdfDoc(doc);
  }, []);

  const onSubmit = (renderType: PAGE_RENDER_OPTIONS_TYPE) => {
    if (renderType === PAGE_RENDER_OPTIONS.CUSTOM_RANGE) {
      onPageSelect(previewPageStart, previewPageEnd);
    } else {
      onPageSelect();
    }
  };
  return (
    <FullscreenDialog
      className="rc-CMLEditorMenu" // class is needed to prevent CML editor blur rules from applying
      open={true}
      onClose={onClose}
      aria-describedby="embedPdfContent"
      contentWidth="100%"
      sticky="none"
    >
      <Header />
      <Content css={styles.contentContainer} id="embedPdfContent">
        <Grid container>
          <Grid item xs={8} container>
            <Grid item xs>
              <div css={styles.viewerContainer}>
                <PdfViewer
                  assetId={assetId}
                  url={url}
                  pageStart={previewPageStart}
                  pageEnd={previewPageEnd}
                  onPdfLoad={onPdfLoad}
                />
              </div>
            </Grid>
          </Grid>
          {pdfDoc && (
            <PdfPageSelectModalForm
              embedStartPage={embedStartPage}
              embedEndPage={embedEndPage}
              totalPages={pdfDoc.numPages}
              onSubmit={onSubmit}
              onCancel={onClose}
              onPreviewPageStartChange={onPreviewPageStartChange}
              onPreviewPageEndChange={onPreviewPageEndChange}
            />
          )}
        </Grid>
      </Content>
    </FullscreenDialog>
  );
};
