import { useCallback } from 'react';
import type { MouseEvent, MutableRefObject } from 'react';
import * as React from 'react';

import type { ButtonProps } from '@coursera/cds-core';
import { Button as CDSButton } from '@coursera/cds-core';

import type { TrackingProps } from './BaseComponent';
import useTracker from './useTracker';
import useVisibilityTracker from './useVisibilityTracker';

type OwnProps = TrackingProps<'click_button' | 'view_button'>;
type Props = OwnProps & Omit<ButtonProps, keyof OwnProps> & { noViewTracking?: boolean };

/**
 * ProductCard is a wrapper around CDS ProductCard that tracks view and click events.
 */
const Button = ({ children, onClick, trackingData, trackingOptions, noViewTracking, ...props }: Props) => {
  const track = useTracker('click_button', trackingData, trackingOptions);
  const ref: MutableRefObject<HTMLButtonElement | null> = useVisibilityTracker('view_button', trackingData);

  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: MouseEvent<HTMLButtonElement>) => {
      track();
      onClick?.(event);
    },
    [onClick, track]
  );

  return (
    <CDSButton {...props} onClick={handleClick} ref={noViewTracking ? undefined : ref}>
      {children}
    </CDSButton>
  );
};

export type { Props };

export default Button;
