import type { Node, NodeEntry, PathRef } from 'slate';
import { Editor, Element, Text, Transforms } from 'slate';

import { getNumColumns } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { TABLE_TYPES_TO_TOOLS } from 'bundles/cml/editor/normalize/constants';
import normalizerLogger from 'bundles/cml/editor/normalize/normalizerLogger';
import type { Options } from 'bundles/cml/editor/normalize/types';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { TableCellElement, TableElement, TableRowElement } from 'bundles/cml/shared/types/elementTypes';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const normalizeTableCellChildren = (editor: Editor, options: Options, [node, path]: NodeEntry<Node>) => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.TABLE_CELL) {
    return false;
  }

  const cell = node as TableCellElement;
  const hasTextOrListElement = cell.children.every(
    (child) =>
      Element.isElement(child) &&
      (child.type === BLOCK_TYPES.TEXT ||
        child.type === BLOCK_TYPES.BULLET_LIST ||
        child.type === BLOCK_TYPES.NUMBER_LIST ||
        child.type === BLOCK_TYPES.MATH_BLOCK)
  );
  if (hasTextOrListElement) {
    return false;
  }

  const text = Editor.string(editor, path);

  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, {
      at: path,
      match: (el, childPath) => childPath.length === path.length + 1,
    });
    Transforms.insertNodes(editor, { type: BLOCK_TYPES.TEXT, children: [{ text }] }, { at: [...path, 0] });
  });

  return true;
};

const normalizeTableCellText = (editor: Editor, options: Options, [node, path]: NodeEntry<Node>) => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.TABLE_CELL) {
    return false;
  }

  const cell = node as TableCellElement;
  const hasText = cell.children.every((child) => Text.isText(child) || (Element.isElement(child) && child.isInline));
  if (!hasText) {
    return false;
  }

  const pathRefs: PathRef[] = [];
  for (let i = 0; i < cell.children.length; i += 1) {
    const pathRef = Editor.pathRef(editor, [...path, i]);
    pathRefs.push(pathRef);
  }

  Editor.withoutNormalizing(editor, () => {
    Transforms.insertNodes(editor, { type: BLOCK_TYPES.TEXT, children: [] }, { at: [...path, 0] });
    pathRefs.forEach((pathRef: PathRef, index) => {
      if (pathRef.current) {
        Transforms.moveNodes(editor, { at: pathRef.current, to: [...path, 0, index] });
      }
      pathRef.unref();
    });
  });

  return true;
};

const normalizeTableCells = (editor: Editor, options: Options, [node, path]: NodeEntry<Node>) => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.TABLE_CELL) {
    return false;
  }

  const cell = node as TableCellElement;
  const [tableRow, tableRowPath] = Editor.parent(editor, path) as NodeEntry<TableRowElement>;
  if (tableRow.type !== BLOCK_TYPES.TABLE_ROW) {
    Transforms.unwrapNodes(editor, { at: path, match: (el) => el === cell });
    return true;
  }

  const [table] = Editor.parent(editor, tableRowPath) as NodeEntry<TableElement>;
  const rowIndex = tableRowPath[tableRowPath.length - 1];

  if (!table.headless && rowIndex === 0 && !cell.header) {
    Transforms.setNodes(editor, { header: true }, { at: path });
    return true;
  }

  if ((table.headless || rowIndex > 0) && cell.header) {
    Transforms.unsetNodes(editor, 'header', { at: path });
    return true;
  }

  return false;
};

const normalizeTableRows = (editor: Editor, options: Options, [node, path]: NodeEntry<Node>) => {
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.TABLE_ROW) {
    return false;
  }

  const [table] = Editor.parent(editor, path) as NodeEntry<TableElement>;
  if (table.type !== BLOCK_TYPES.TABLE) {
    Transforms.unwrapNodes(editor, { at: path, match: (el) => el === node });
    return true;
  }

  const numColumns = getNumColumns(table);

  const row = node as TableRowElement;
  const rowIndex = path[path.length - 1];

  if (row.children.length < numColumns) {
    Transforms.insertNodes(
      editor,
      {
        type: BLOCK_TYPES.TABLE_CELL,
        header: (!table.headless && rowIndex === 0) || undefined,
        children: [{ type: BLOCK_TYPES.TEXT, children: [{ text: '' }] }],
      },
      { at: [...path, row.children.length] }
    );
    return true;
  }

  return false;
};

const normalizeTableTool = (editor: Editor, { tools }: Options, [node, path]: NodeEntry<Node>) => {
  if (!Element.isElement(node)) {
    return false;
  }

  const tool = TABLE_TYPES_TO_TOOLS[node.type];
  if (!tool || tools.has(tool)) {
    return false;
  }

  Transforms.unwrapNodes(editor, { at: path, match: (el) => el === node });
  return true;
};

const NORMALIZERS = [
  normalizerLogger(normalizeTableTool),
  normalizerLogger(normalizeTableRows),
  normalizerLogger(normalizeTableCells),
  normalizerLogger(normalizeTableCellText),
  normalizerLogger(normalizeTableCellChildren),
];

export const normalizeTables = (editor: Editor, options: Options, nodeEntry: NodeEntry<Node>) => {
  return NORMALIZERS.some((normalizer) => normalizer(editor, options, nodeEntry));
};
