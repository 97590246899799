/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { DataValue } from 'react-apollo';
import { graphql } from 'react-apollo';
import Waypoint from 'react-waypoint';

import type { EnterpriseSkills_Skillset as Skillset } from '__generated__/graphql-types';
import { times, unionBy } from 'lodash';
import { compose, withPropsOnChange, withState } from 'recompose';

import Retracked from 'js/lib/retracked';

import { Button, Grid, InlineNotification, breakpoints } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import SkillSetFilterResultsA11yAnnouncer from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFilterResultsA11yAnnouncer';
import type { FilterSelections } from 'bundles/enterprise-legacy-learner-home/components/single-program/SkillSetFiltersHOCDGS';
import SkillBasedRecommendationsQuery from 'bundles/enterprise-legacy-learner-home/components/single-program/queries/SkillBasedRecommendations.graphql';
import type {
  SkillBasedRecommendationsQuery as SkillBasedRecommendationsQueryData,
  SkillBasedRecommendationsQueryVariables,
} from 'bundles/enterprise-legacy-learner-home/components/single-program/queries/__generated__/SkillBasedRecommendations';
import {
  SkillSetProductCollectionCard,
  SkillSetProductCollectionCardPlaceholder,
} from 'bundles/enterprise-legacy-learner-home/components/skillSetProductCollectionCard/SkillSetProductCollectionCardDGS';
import type { CollectionListItemStruct } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import fetchMoreErrorFilter from 'bundles/enterprise-legacy-learner-home/utils/fetchMoreErrorFilter';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import type { TargetSkillProfileUserStatesQuery_TargetSkillProfileUserStatesV1Resource_byUserAndProgram_elements as UserSkillProfileStateType } from 'bundles/program-home/components/__generated__/TargetSkillProfileUserStatesQuery';
import { getFilterTrackingData } from 'bundles/program-home/utils/ProgramHomeUtils';
import mapExists from 'bundles/program-home/utils/mapExists';
import { isSavedSkillSet } from 'bundles/program-skills-objectives/components/ProgramSkillsObjectivesPage/skillObjectiveUtils';
import { castToStruct } from 'bundles/program-skills-objectives/utils';

import _t from 'i18n!nls/program-home';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<'button'>>(Button);

type PropsFromCaller = {
  programId: string;
  programSlug: string;
  userSkillProfileStates?: UserSkillProfileStateType[];
  skillsets?: Skillset[];
  skillSetLength?: number;
  displayLimit?: number;
  isFilterSelected?: boolean;
  filterSelections?: FilterSelections;
};

type PropsForGraphql = {
  start: string;
  limit: number;
  paginationError: boolean;
  setPaginationError: (val: boolean) => void;
} & PropsFromCaller;

type SkillsetWithCollectionListItem = {
  skillset: Skillset;
  collection: CollectionListItemStruct;
};

type PropsFromWithPropsOnChange = { limit: number | null };
type PropsForWithPropsOnChange = Pick<PropsFromCaller, 'isFilterSelected'>;

type PropsFromGraphql = {
  skillWithCollectionList: SkillsetWithCollectionListItem[];
  loading: boolean;
  error: boolean;
  isAllLoaded: boolean;
  totalSkillsetsLength: number;
  loadMoreItems: () => void;
  loadAllItems: () => void;
};

type PlaceholderProps = {
  skillSetLength?: number;
};

export type Props = PropsFromCaller & PropsFromGraphql;

const styles = {
  skillSetsContainer: css`
    margin-bottom: var(--cds-spacing-300);
  `,
  gridItem: css`
    margin-bottom: var(--cds-spacing-300);

    ${breakpoints.down('sm')} {
      margin-bottom: var(--cds-spacing-200);
    }
    ${breakpoints.down('xs')} {
      margin-bottom: var(--cds-spacing-400);
    }
  `,
  viewAll: css`
    display: block;
    margin: var(--cds-spacing-300) auto 0 auto;
  `,
};

export function SkillSetsContainerDGSPlaceholder({ skillSetLength }: PlaceholderProps) {
  return (
    <div css={styles.skillSetsContainer}>
      <Grid container spacing={{ md: 24, sm: 16 }}>
        {times(skillSetLength || 4, (value) => (
          <Grid key={value} css={styles.gridItem} item xs={12} sm={6}>
            <SkillSetProductCollectionCardPlaceholder />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export function SkillSetsContainerDGS({
  displayLimit,
  skillSetLength,
  programSlug,
  skillWithCollectionList,
  loading,
  error,
  isAllLoaded,
  totalSkillsetsLength,
  isFilterSelected,
  filterSelections,
  loadMoreItems,
  loadAllItems,
}: Props) {
  const [showViewAll, setShowViewAll] = React.useState(
    displayLimit != null && displayLimit < skillWithCollectionList.length
  );
  const turnOffViewAllButton = React.useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    () => setShowViewAll(false),
    [setShowViewAll]
  );
  /**
   * This means a filter has been applied and we are going to fetch all the other skillsets.
   * In this state the number of filtered selections is actually not true. As it may increase
   * once the other skillsets have loaded
   *
   * We should refactor this to make the data flow more clear: https://coursera.atlassian.net/browse/CORESKILLS-1499
   */
  const needToRetrieveAllSkillSets = isFilterSelected && !isAllLoaded;

  /**
   * Although we need to retrieve all skillsets we shouldn't do it if loading is true.
   * This indicates the load more request is already be inflight.
   */
  const readyToLoadAllSkillSets = !loading && needToRetrieveAllSkillSets;

  React.useEffect(() => {
    if (readyToLoadAllSkillSets) {
      loadAllItems();
    }
  }, [readyToLoadAllSkillSets, loadAllItems]);

  if (error) {
    return (
      <div css={styles.skillSetsContainer}>
        <InlineNotification severity="error" role="alert">
          {_t('Sorry! Something went wrong. Please refresh the page.')}
        </InlineNotification>
      </div>
    );
  }

  if (
    (loading && !skillWithCollectionList.length) ||
    (isFilterSelected && !isAllLoaded && !skillWithCollectionList.length)
  ) {
    return <SkillSetsContainerDGSPlaceholder skillSetLength={Math.min((displayLimit ?? skillSetLength) || 8, 8)} />;
  }

  if (skillWithCollectionList.length) {
    const skillsToRender = showViewAll ? skillWithCollectionList.slice(0, displayLimit) : skillWithCollectionList;
    const filterTrackingData = getFilterTrackingData(filterSelections);

    return (
      <div css={styles.skillSetsContainer}>
        <SkillSetFilterResultsA11yAnnouncer
          filteredSkillSetCount={isFilterSelected ? skillWithCollectionList.length : totalSkillsetsLength}
          filterSelections={filterSelections}
          loading={needToRetrieveAllSkillSets}
        />
        <Grid container spacing={{ md: 24, sm: 16 }} data-e2e="SkillSetsContainerDGS">
          {skillsToRender.map((skillWithCollection, idx) => (
            <Grid key={skillWithCollection.skillset.id} css={styles.gridItem} item xs={12} sm={6}>
              {idx + 1 === skillWithCollectionList.length && !readyToLoadAllSkillSets ? (
                <Waypoint onEnter={loadMoreItems}>
                  <div>
                    <SkillSetProductCollectionCard
                      skillSetOrder={idx}
                      skillSet={skillWithCollection.skillset}
                      programSlug={programSlug}
                      collectionListItemStruct={skillWithCollection.collection}
                      filterTrackingData={filterTrackingData}
                    />
                  </div>
                </Waypoint>
              ) : (
                <SkillSetProductCollectionCard
                  skillSetOrder={idx}
                  skillSet={skillWithCollection.skillset}
                  programSlug={programSlug}
                  collectionListItemStruct={skillWithCollection.collection}
                  filterTrackingData={filterTrackingData}
                />
              )}
            </Grid>
          ))}
          {!isAllLoaded &&
            !showViewAll &&
            times(Math.min(4, totalSkillsetsLength - skillWithCollectionList.length), (value) => (
              <Grid key={value} css={styles.gridItem} item xs={12} sm={6}>
                <div>
                  <SkillSetProductCollectionCardPlaceholder />
                </div>
              </Grid>
            ))}
        </Grid>
        {showViewAll && (
          <TrackedButton
            {...Button.defaultProps}
            variant="primary"
            aria-label={_t('View all SkillSets')}
            onClick={turnOffViewAllButton}
            trackingName="skill_set_container_view_all_button"
            css={styles.viewAll}
          >
            {_t('View all')}
          </TrackedButton>
        )}
      </div>
    );
  }

  return null;
}

export const getSkillIdFromCollectionTrackId = (collectionTrackingId: string) => {
  return collectionTrackingId.split('~')[1];
};

function updateCollectionQuery(
  prevResult: SkillBasedRecommendationsQueryData['NaptimeQueries'],
  newResult: SkillBasedRecommendationsQueryData['NaptimeQueries'] | undefined
) {
  if (!newResult?.ProgramCurriculumCollectionsV1Resource?.skillProfileCollectionsByProgram) {
    return prevResult;
  }
  const elements = unionBy(
    prevResult.ProgramCurriculumCollectionsV1Resource?.skillProfileCollectionsByProgram?.elements,
    newResult.ProgramCurriculumCollectionsV1Resource?.skillProfileCollectionsByProgram?.elements,
    (element) => element?.id
  );
  return {
    NaptimeQueries: {
      ProgramCurriculumCollectionsV1Resource: {
        __typename: newResult.__typename,
        skillProfileCollectionsByProgram: {
          elements,
          paging: newResult.ProgramCurriculumCollectionsV1Resource.skillProfileCollectionsByProgram.paging,
          __typename: newResult.ProgramCurriculumCollectionsV1Resource.skillProfileCollectionsByProgram.__typename,
        },
      },
    },
  };
}

const loadMoreItems =
  (
    data:
      | Pick<
          DataValue<SkillBasedRecommendationsQueryData, SkillBasedRecommendationsQueryVariables>,
          'loading' | 'fetchMore' | 'NaptimeQueries'
        >
      | undefined,
    props: Pick<PropsForGraphql, 'setPaginationError'>,
    loadAll: boolean
  ) =>
  () => {
    if (!data) {
      return;
    }

    const { loading, fetchMore } = data;
    const { setPaginationError } = props;
    if (loading) {
      return;
    }

    const start =
      data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.skillProfileCollectionsByProgram?.paging?.next;
    if (!start) {
      return;
    }

    fetchMore({
      variables: {
        start,
        limit: loadAll ? null : 8,
      },
      context: { clientName: 'gatewayGql' },
      // @ts-expect-error react-apollo@2.5.4
      updateQuery: (
        { NaptimeQueries: prevResult },
        // @ts-expect-error react-apollo@2.5.4
        { fetchMoreResult: { NaptimeQueries: newResult } }
      ) => {
        return updateCollectionQuery(prevResult, newResult);
      },
    })
      .catch(fetchMoreErrorFilter)
      .catch(() => {
        setPaginationError(true);
      });
  };

export const enhance = compose<Props, PropsFromCaller>(
  Retracked.createTrackedContainer<PropsFromCaller>(({ programId }) => ({ programId })),
  withState('paginationError', 'setPaginationError', false),
  withPropsOnChange<PropsFromWithPropsOnChange, PropsForWithPropsOnChange>(
    () => false,
    ({ isFilterSelected }) => ({ limit: isFilterSelected ? null : 8 })
  ),
  Retracked.createTrackedContainer<PropsFromCaller>(({ programId }) => ({ programId })),
  graphql<
    PropsForGraphql,
    SkillBasedRecommendationsQueryData,
    SkillBasedRecommendationsQueryVariables,
    PropsFromGraphql
  >(SkillBasedRecommendationsQuery, {
    options: ({ programId, limit }) => ({
      ssr: false,
      variables: {
        programId,
        numEntriesPerCollection: 3,
        limit,
        start: '0',
      },
      context: { clientName: 'gatewayGql' },
    }),
    props: ({ data, ownProps }): PropsFromGraphql => {
      const { skillsets, userSkillProfileStates } = ownProps;

      const skillWithCollectionList: SkillsetWithCollectionListItem[] = mapExists(
        filterExistsOrDefault(
          data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.skillProfileCollectionsByProgram?.elements
        ),
        (collection) => {
          const targetSkillset = skillsets?.find(
            (skillset) => skillset.id === getSkillIdFromCollectionTrackId(collection.trackId)
          );
          if (!targetSkillset) {
            return null;
          }
          return {
            collection: castToStruct(collection),
            skillset: targetSkillset,
          };
        }
      ).sort((skillCollection1, skillCollection2) => {
        const isSavedSkillset1 = !!isSavedSkillSet(skillCollection1.skillset.id, userSkillProfileStates);
        const isSavedSkillset2 = !!isSavedSkillSet(skillCollection2.skillset.id, userSkillProfileStates);

        return Number(isSavedSkillset2) - Number(isSavedSkillset1);
      });
      const paging =
        data?.NaptimeQueries?.ProgramCurriculumCollectionsV1Resource?.skillProfileCollectionsByProgram?.paging;
      const isAllLoaded = paging != null && paging.next == null;
      const totalSkillsetsLength = paging?.total || 0;
      return {
        skillWithCollectionList,
        loading: data?.loading ?? true,
        error: Boolean(data?.error),
        isAllLoaded,
        totalSkillsetsLength,
        loadMoreItems: loadMoreItems(data, ownProps, false),
        loadAllItems: loadMoreItems(data, ownProps, true),
      };
    },
  })
);

export default enhance(SkillSetsContainerDGS);
