import * as React from 'react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import ReplHarnessEditor from 'bundles/author-code-evaluator/components/ReplHarnessEditor';
import TestCaseHarnessEditor from 'bundles/author-code-evaluator/components/TestCaseHarnessEditor';
import type ExpressionHarness from 'bundles/author-code-evaluator/models/ExpressionHarness';
import ReplHarness from 'bundles/author-code-evaluator/models/ReplHarness';
import TestCaseHarness from 'bundles/author-code-evaluator/models/TestCaseHarness';
import type { LanguageType } from 'bundles/cml';

type Props = {
  harness: ExpressionHarness;
  language: LanguageType;
  onChange: (arg: $TSFixMe) => void;
};

const HarnessEditor = (props: Props) => {
  const { harness, language, onChange } = props;

  if (harness instanceof ReplHarness) {
    return <ReplHarnessEditor harness={harness} language={language} onChange={onChange} />;
  } else if (harness instanceof TestCaseHarness) {
    return <TestCaseHarnessEditor harness={harness} language={language} onChange={onChange} />;
  }

  return null;
};

export default HarnessEditor;
