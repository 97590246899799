/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';

import { MenuList, Search } from '@coursera/cds-core';

import type { Option, OptionGroup } from 'bundles/ai-coach-platform/components/patterns/select/types';
import { filterOptions, isOptionGroup } from 'bundles/ai-coach-platform/components/patterns/select/utils';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  search: css`
    margin-bottom: var(--cds-spacing-100);
  `,
  disabled: css`
    pointer-events: none;
    color: var(--cds-color-grey-600) !important;
  `,
  menuItem: css`
    --cds-color-interactive-primary: var(--cds-color-blue-600);
  `,
};

type Props = {
  value?: string;
  options: (Option | OptionGroup)[];
  onChange: (value: string) => void;
};

const SelectDropdown = ({ value, options, onChange }: Props) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState('');
  const filteredOptions = useMemo(() => filterOptions(filter, options), [options, filter]);

  const handleChange = useCallback<(option: Option) => () => void>(
    (option: Option) => () => {
      onChange(option.value);
    },
    [onChange]
  );

  const renderOption = useCallback<(option: Option) => React.ReactElement>(
    (option: Option) => (
      <MenuList.Item
        key={option.value}
        role="menuitemradio"
        checked={option.value === value}
        disabled={option.disabled}
        supportText={option.supportText}
        onClick={handleChange(option)}
        css={[styles.menuItem, option.disabled && styles.disabled]}
      >
        {option.label}
      </MenuList.Item>
    ),
    [value, handleChange]
  );

  return (
    <React.Fragment>
      <Search
        ref={searchRef}
        value={filter}
        onChange={setFilter}
        placeholder={_t('Filter results')}
        hideSearchButton
        variant="silent"
        fullWidth
        css={styles.search}
      />

      <MenuList disableFirstItemAutofocus>
        {filteredOptions.length === 0 && <MenuList.Item css={styles.disabled}>{_t('No results found')}</MenuList.Item>}
        {filteredOptions.map((option) => {
          if (isOptionGroup(option)) {
            return (
              <MenuList.Group key={option.label} label={option.label}>
                {option.children.map(renderOption)}
              </MenuList.Group>
            );
          }

          return renderOption(option);
        })}
      </MenuList>
    </React.Fragment>
  );
};

export default SelectDropdown;
