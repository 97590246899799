import * as React from 'react';

import { useSlate } from 'slate-react';

import { RedoIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { getToolBarShortcutKey } from 'bundles/cml/editor/utils/toolbarUtils';

import _t from 'i18n!nls/cml';

const RedoButton: React.FC = () => {
  const editor = useSlate();
  const disabled = editor.history.redos.length === 0;

  const handleRedo = () => editor.redo();
  const shortcutKey = getToolBarShortcutKey('shift+Z');
  return (
    <Button
      onClick={handleRedo}
      disabled={disabled}
      className="rc-RedoButton"
      title={_t('Redo #{shortcutKey}', { shortcutKey })}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <RedoIcon size="small" />
    </Button>
  );
};

export default RedoButton;
