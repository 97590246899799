import { Editor, Element, Text, Transforms } from 'slate';
import type { Node, NodeEntry, Path } from 'slate';

import normalizerLogger from 'bundles/cml/editor/normalize/normalizerLogger';
import type { Options } from 'bundles/cml/editor/normalize/types';
import { BLOCK_TYPES, MARKS } from 'bundles/cml/shared/constants';
import type { LinkElement } from 'bundles/cml/shared/types/elementTypes';
import { Tools } from 'bundles/cml/shared/utils/customTools';

const getLinkText = (editor: Editor, path: Path) => {
  try {
    return Editor.string(editor, path);
  } catch {
    return '';
  }
};

const normalizeLinkTool = (editor: Editor, { tools }: Options, [node, path]: NodeEntry<LinkElement>) => {
  if (tools.has(Tools.LINK)) {
    return false;
  }

  Transforms.unwrapNodes(editor, { at: path, match: (el) => Element.isElement(el) && el.type === node.type });
  return true;
};

const normalizeLinkText = (editor: Editor, options: Options, [, path]: NodeEntry<LinkElement>) => {
  const text = getLinkText(editor, path);
  if (!text) {
    Transforms.delete(editor, { at: path });
    return true;
  }

  return false;
};

const normalizeMonospace = (editor: Editor, options: Options, [node, path]: NodeEntry<LinkElement>) => {
  const children = node.children as Element[];
  let normalized = false;

  children.forEach((child, index) => {
    if (Text.isText(child) && child[MARKS.MONOSPACE]) {
      normalized = true;
      Transforms.unsetNodes(editor, MARKS.MONOSPACE, { at: [...path, index] });
    }
  });

  return normalized;
};

const NORMALIZERS = [
  normalizerLogger(normalizeLinkTool),
  normalizerLogger(normalizeLinkText),
  normalizerLogger(normalizeMonospace),
];

export const normalizeLinks = (editor: Editor, options: Options, nodeEntry: NodeEntry<Node>) => {
  const [node] = nodeEntry;
  if (!Element.isElement(node) || node.type !== BLOCK_TYPES.LINK) {
    return false;
  }

  const linkNodeEntry = nodeEntry as NodeEntry<LinkElement>;
  return NORMALIZERS.some((normalizer) => normalizer(editor, options, linkNodeEntry));
};
