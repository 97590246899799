/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

const style = css`
  @media (max-width: 575px) {
    justify-content: flex-start;
  }
`;

const CourseActionBox: React.LegacyFunctionComponentWithChildren<{}> = ({ children }) => (
  <div
    className="
      CourseActionBox col-xs-12 col-sm-4 col-md-3 horizontal-box align-items-vertical-center align-items-right p-t-1
    "
    css={style}
  >
    {children}
  </div>
);
export default CourseActionBox;
