/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { forwardRef, useRef } from 'react';
import { useSelector } from 'react-redux';

import { AiCoach_Action as AiCoachAction } from '__generated__/graphql-types';
import classNames from 'classnames';

import { TextField } from '@coursera/cds-core';
import { useTracker } from '@coursera/event-pulse/react';

import { CoachIconButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { SendIcon } from 'bundles/ai-coach-platform/components/icons/mui';
import type { SendMessageFunc } from 'bundles/ai-coach-platform/components/patterns/chat/types';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';

import _t from 'i18n!nls/ai-coach-platform';

import { clearUserInputText, setUserInputText } from './store/dispatcher';
import type { GlobalState } from './store/types';

type Props = {
  placeholder: string;
  disabledInput: boolean;
  autofocus?: boolean;
  sendMessage: SendMessageFunc;
  onTextInputChange?: (text: string) => void;
};

const MAX_INPUT_ROWS = 7;

const styles = {
  textField: (disabled: boolean) => css`
    /* CDS overrides */
    --cds-color-neutral-background-primary-weak: var(--cds-color-emphasis-neutral-background-xweak);
    --cds-color-interactive-stroke-primary-focus: var(--cds-color-emphasis-primary-background-xxstrong);
    --cds-color-interactive-stroke-primary-focus-invert: var(--cds-color-emphasis-primary-background-weak);

    > label {
      margin-bottom: 0;

      /* visually hidden but required for a11y */
      display: none;
    }

    /* CDS overrides for Coach */

    .cds-text-field-root {
      /* textField component */
      border-radius: var(--cds-spacing-100);
      background-color: var(--cds-color-neutral-background-primary-weak);

      /* remove border on the input field */

      .cds-input-notchedOutline {
        border: none;
      }

      /* text color */

      .cds-input-input {
        color: var(--cds-color-grey-700);
      }

      /* send suffix icon */

      .cds-inputAdornment-filled {
        padding: 0;
        border-radius: var(--cds-spacing-100);

        svg path {
          fill: ${disabled
            ? 'var(--cds-color-neutral-disabled-strong)'
            : 'var(--cds-color-emphasis-neutral-background-xxstrong)'};
        }
      }

      /* hover state */

      &:hover {
        .cds-input-notchedOutline {
          border: 1px solid var(--cds-color-blue-600);
        }

        .cds-input-input::placeholder {
          color: var(--cds-color-grey-600);
        }
      }

      /* focus state */

      &.cds-input-focused {
        background-color: var(--cds-color-emphasis-neutral-background-xweak) !important;

        .cds-input-notchedOutline {
          border: 0 solid transparent;
        }

        .cds-input-input::placeholder {
          color: var(--cds-color-grey-600);
        }

        /* focus ring */

        ::before {
          border-radius: var(--cds-spacing-100);
        }
      }

      /* send icon focus ring */

      .cds-buttonBase-focusVisible {
        &::after {
          top: 4px;
          left: 4px;
          right: 4px;
          bottom: 4px;
        }
      }

      /* disabled state */

      &.cds-input-readOnly {
        .cds-input-input::placeholder {
          color: var(--cds-color-neutral-disabled-strong);
        }

        svg path {
          fill: var(--cds-color-neutral-disabled-strong);
        }
      }
    }
  `,
  container: css`
    padding: 0 var(--cds-spacing-200);
  `,
};

const ChatInputField = (
  { sendMessage, placeholder, disabledInput, autofocus = true, onTextInputChange }: Props,
  ref: React.Ref<HTMLInputElement>
) => {
  const { inputText, isMessageLoading } = useSelector((state: GlobalState) => ({
    inputText: state.input.inputText,
    isMessageLoading: state.behavior.messageLoader,
  }));

  const inputRef = useRef<HTMLInputElement>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const track = useTracker();
  const trackingContext = useCoachTrackingContext()?.data;

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const textFromInputEvent = event.target.value;

    if (onTextInputChange) {
      onTextInputChange(textFromInputEvent);
    }

    // Update Redux store:
    setUserInputText(textFromInputEvent);
  };

  const handleSendMessageClick = () => {
    // ignore empty messages
    if (!inputText) {
      return;
    }

    sendMessage(inputText, AiCoachAction.TypedMessage);

    if (trackingContext) {
      // v3 tracking
      track('interact_coach', {
        coachInteractionType: 'send_message',
        coach: {
          mode: trackingContext.mode,
        },
        ...(trackingContext.courseId && {
          product: {
            type: 'course',
            id: trackingContext.courseId as string,
          },
        }),
        ...(trackingContext.itemId && {
          courseItem: {
            id: trackingContext.itemId as string,
          },
        }),
      });

      // This allows us to send the input message to amplitude as a separate event all together.
      // The main goal for now being to derive the character count of the input text as requested by product.
      track('send_coach_message', {
        coachMessageText: inputText,
        coachMessageLength: inputText.length,
        coach: {
          mode: trackingContext.mode,
        },
        ...(trackingContext.courseId && {
          product: {
            type: 'course',
            id: trackingContext.courseId as string,
          },
        }),
        ...(trackingContext.itemId && {
          courseItem: {
            id: trackingContext.itemId as string,
          },
        }),
      });
    }

    // Update Redux store, which will also erase the input field content:
    clearUserInputText();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessageClick();
    }
  };

  const inputElementProps = {
    onKeyPress: handleKeyPress,
    required: false,
  };

  return (
    <div ref={refContainer} css={styles.container}>
      <div
        className={classNames('coach-chat-input-field', {
          'coach-message-disabled': isMessageLoading || disabledInput,
        })}
      >
        <TextField
          ref={ref}
          autoFocus={disabledInput ? false : autofocus}
          fullWidth
          multiline
          minRows={1}
          maxRows={MAX_INPUT_ROWS}
          label={_t('Ask #{coachName}', { coachName: 'Coach' })}
          type="text"
          readOnly={isMessageLoading || disabledInput}
          inputRef={inputRef}
          placeholder={placeholder}
          onChange={handleTextChange}
          value={inputText}
          inputProps={inputElementProps}
          css={styles.textField(!inputText)}
          data-testid="coach-send-message"
          suffix={
            <CoachIconButton
              tooltip={_t('Send message')}
              variant="ghost"
              onClick={handleSendMessageClick}
              disabled={!inputText || isMessageLoading || disabledInput}
              aria-label={_t('Send message')}
              data-testid="coach-send-button"
              icon={<SendIcon />}
            />
          }
        />
      </div>
    </div>
  );
};

export default forwardRef(ChatInputField);
