/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';

import isHotkey from 'is-hotkey';
import { ReactEditor, useSlateStatic } from 'slate-react';

import type { ButtonProps } from '@coursera/cds-core';
import { Button as CdsButton } from '@coursera/cds-core';

import { useManagedFocus } from 'bundles/cml/editor/components/utils/managedFocusContext';
import { useFocusedContext } from 'bundles/cml/editor/context/focusContext';
import { mergeRefs } from 'bundles/cml/editor/utils/mergeRefs';
import { FloatingTooltip } from 'bundles/common/components/Tooltip';
import type { Props as TooltipProps } from 'bundles/common/components/Tooltip/FloatingTooltip';

type ButtonRole = ButtonProps['role'];

const styles = {
  button: css`
    flex: none;
    white-space: nowrap;
    color: var(--cds-color-neutral-primary);

    &:hover {
      text-decoration: none;
      color: var(--cds-color-emphasis-primary-content-default);
      background: var(--cds-color-neutral-background-primary-weak);
    }

    span:first-of-type {
      min-height: 20px;
    }
  `,
  active: css`
    color: var(--cds-color-emphasis-primary-content-default);
    background: var(--cds-color-interactive-background-primary-hover-weak);
  `,
  inactive: css`
    color: var(--cds-color-neutral-disabled-strong);

    svg {
      color: var(--cds-color-neutral-disabled-strong);
    }

    pointer-events: none;
  `,
  inactiveSecondary: css`
    background: var(--cds-color-neutral-background-primary-weak);
    box-shadow: inset 0 0 0 1px var(--cds-color-neutral-disabled-strong);
  `,
  defaultButtonStyles: css`
    &:disabled {
      svg {
        color: var(--cds-color-neutral-disabled-strong);
      }
    }
  `,
};

export const TOOLBAR_BUTTON_TYPES = { formatting: 'formatting', insertion: 'insertion', menu: 'menu' } as const;

type ToolbarButtonType = (typeof TOOLBAR_BUTTON_TYPES)[keyof typeof TOOLBAR_BUTTON_TYPES];
export type Props = React.PropsWithChildren<{
  active?: boolean;
  disabled?: boolean;
  onClick?: React.ReactEventHandler<HTMLButtonElement>;
  className?: string;
  title?: string;
  buttonProps?: Partial<ButtonProps>;
  useDefaultCdsColor?: boolean;
  role?: ButtonRole;
  tooltipProps?: Partial<TooltipProps>;
  type: ToolbarButtonType;
}>;

const isLeftKey = isHotkey('left', { byKey: true });
const isRightKey = isHotkey('right', { byKey: true });

const Button = React.forwardRef<HTMLButtonElement, Props>((props, parentRef) => {
  const { focused } = useFocusedContext();
  const { focusNext, focusPrevious, focusedButton, setFocusedButton } = useManagedFocus();
  const ref = useRef<HTMLButtonElement>(null);
  const staticEditor = useSlateStatic();

  const {
    active,
    onClick,
    children,
    useDefaultCdsColor = false,
    title,
    tooltipProps,
    buttonProps,
    role = 'button',
    disabled,
    type,
    ...attributes
  } = props;

  // The "pageless" toolbar must capture and preventDefault onmousedown so the editor doesn't
  // lose focus. While the standard toolbars must capture onclick in order to prevent global
  // mousedown event listeners (like Quiz Questions, etc...) from preempting our handler
  // (see CP-11575)
  const handleMouseDown = (event: React.MouseEvent) => event.preventDefault();
  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent) => {
      event.preventDefault();

      if (type === TOOLBAR_BUTTON_TYPES.insertion) {
        ReactEditor.focus(staticEditor);
      }
      if (onClick) {
        onClick(event as React.MouseEvent<HTMLButtonElement>);
      }
    },
    [onClick, staticEditor, type]
  );

  const handleKeyDown = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: React.KeyboardEvent) => {
      const { nativeEvent } = event;
      if (isLeftKey(nativeEvent)) {
        focusPrevious();
      } else if (isRightKey(nativeEvent)) {
        focusNext();
      }
    },
    [focusNext, focusPrevious]
  );

  const handleFocus = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    setFocusedButton(ref.current);
  }, [setFocusedButton]);

  const mergedRefs = useMemo(() => mergeRefs([parentRef, ref]), [parentRef, ref]);

  return (
    <FloatingTooltip show={!disabled && !!title} message={title} offset={3} {...tooltipProps}>
      <CdsButton
        tabIndex={focusedButton === ref.current ? 0 : -1}
        role={role}
        ref={mergedRefs}
        variant="ghost"
        size="small"
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onMouseDownCapture={handleMouseDown}
        onClickCapture={handleClick}
        aria-label={title}
        aria-pressed={role === 'button' && active}
        disabled={disabled}
        {...buttonProps}
        {...attributes}
        css={[
          useDefaultCdsColor ? styles.defaultButtonStyles : styles.button,
          active && styles.active,
          !focused && styles.inactive,
          !focused && useDefaultCdsColor && styles.inactiveSecondary,
        ]}
      >
        {children}
      </CdsButton>
    </FloatingTooltip>
  );
});

export default Button;
