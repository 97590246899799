import * as React from 'react';
import { graphql } from 'react-apollo';

import * as Sentry from '@sentry/react';
import cx from 'classnames';
import gql from 'graphql-tag';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { branch, compose, mapProps, pure, setDisplayName, withState } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import { FormattedHTMLMessage, FormattedMessage } from 'js/lib/coursera.react-intl';
import deferToClientSideRender from 'js/lib/deferToClientSideRender';
import { useLocation, useParams } from 'js/lib/useRouter';
import user from 'js/lib/user';
import waitForGraphQL from 'js/lib/waitForGraphQL';
import {
  MED_DATE_ONLY_DISPLAY,
  SHORT_MONTH_DAY_DISPLAY,
  formatDateTimeDisplay,
} from 'js/utils/DateTimeUtils/DateTimeUtils';

import { Button, SvgButton, color, css, spacing } from '@coursera/coursera-ui';
import { SvgBookmark, SvgEdit, SvgSuccess } from '@coursera/coursera-ui/svg';

import { getClipsTrackingInfo } from 'bundles/clips/utils/clipsUtils';
import { TrackedSvgButton } from 'bundles/common/components/TrackedCui';
import type { TrackingData } from 'bundles/common/components/withSingleTracked';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import {
  ContentCurationContext,
  type Props as ContentCurationContextType,
} from 'bundles/content-curation/components/ContentCurationContext';
import { withProductFeatures } from 'bundles/content-curation/features';
import MiniCDPContainer from 'bundles/coursera-ui/components/rich/MiniXDP/MiniCDPContainer';
import MiniSDPContainer from 'bundles/coursera-ui/components/rich/MiniXDP/MiniSDPContainer';
import AvailableCredit from 'bundles/enterprise-legacy-learner-home/components/AvailableCredit';
import CourseEnrollmentWarningModal from 'bundles/enterprise-legacy-learner-home/components/CourseEnrollmentWarningModal';
import EnrollmentChoiceModalForMultiprogram from 'bundles/enterprise-legacy-learner-home/components/EnrollmentChoiceModalForMultiprogram';
import IdVerificationWarningModal from 'bundles/enterprise-legacy-learner-home/components/IdVerificationWarningModal';
import RedirectOverlay from 'bundles/enterprise-legacy-learner-home/components/RedirectOverlay';
import StartDateButton from 'bundles/enterprise-legacy-learner-home/components/StartDateButton';
import type {
  SessionsV2AuthoringEligibilityCheckQuery as SessionsV2AuthoringEligibilityCheckQueryData,
  SessionsV2AuthoringEligibilityCheckQueryVariables,
} from 'bundles/enterprise-legacy-learner-home/components/__generated__/SessionsV2AuthoringEligibilityCheckQuery';
import ProgramActionButtonContainer from 'bundles/enterprise-legacy-learner-home/components/programActionButton/ProgramActionButtonContainer';
import { PRODUCT_TYPES } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import IdVerificationQuery from 'bundles/enterprise-legacy-learner-home/queries/IdVerificationQuery.graphql';
import SpecializationAndUserCredits from 'bundles/enterprise-legacy-learner-home/queries/SpecializationAndUserCreditsQuery.graphql';
import type {
  IdVerificationQuery as IdVerificationQueryType,
  IdVerificationQueryVariables,
} from 'bundles/enterprise-legacy-learner-home/queries/__generated__/IdVerificationQuery';
import type {
  SpecializationAndUserCredits_SpecializationFragment as Specialization,
  SpecializationAndUserCreditsQuery,
  SpecializationAndUserCreditsQueryVariables,
  SpecializationAndUserCredits_UserCreditsFragment as UserCredits,
} from 'bundles/enterprise-legacy-learner-home/queries/__generated__/SpecializationAndUserCreditsQuery';
import type { EnterpriseProductMetadataFlags } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import { getCourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseUtils';
import type { VideoData } from 'bundles/enterprise-legacy-xdp/components/MiniXDPVideoPlayer';
import MiniPDP from 'bundles/enterprise-legacy-xdp/components/miniPDP/MiniPDP';
import type { Props as PropsFromWithEnterpriseProductMetadata } from 'bundles/enterprise-legacy-xdp/hocs/withLocalCacheEnterpriseProductMetadata';
import withLocalCacheEnterpriseProductMetadata from 'bundles/enterprise-legacy-xdp/hocs/withLocalCacheEnterpriseProductMetadata';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import withRedirectToUnifiedExperience from 'bundles/enterprise-legacy-xdp/hocs/withRedirectToUnifiedExperience';
import { AutoEnrollBadge, CreditBadge } from 'bundles/enterprise-ui/components/Badges';
import { useCampusWordingForAdmin } from 'bundles/enterprise-ui/useCampusWordingForAdmin';
import Modal from 'bundles/phoenix/components/Modal';
import { IdTypes } from 'bundles/product-features';
import {
  CourseTypeMetadataByCourseIdQuery,
  EnterpriseProgramS12nSessionAssociationsQuery,
  EnterpriseProgramSessionAssociationsQueryV1,
} from 'bundles/program-home/utils/ProgramHomeGraphqlQueries';
import type {
  CourseTypeMetadataByCourseIdQuery_CourseTypeMetadataV1Resource_get as CourseTypeMetaData,
  CourseTypeMetadataByCourseIdQueryVariables,
  CourseTypeMetadataByCourseIdQuery as Query,
} from 'bundles/program-home/utils/__generated__/CourseTypeMetadataByCourseIdQuery';
import type {
  EnterpriseProgramS12nSessionAssociationsQuery as EnterpriseProgramS12nSessionAssociationsQueryData,
  EnterpriseProgramS12nSessionAssociationsQueryVariables,
} from 'bundles/program-home/utils/__generated__/EnterpriseProgramS12nSessionAssociationsQuery';
import type {
  EnterpriseProgramSessionAssociationsQuery,
  EnterpriseProgramSessionAssociationsQueryVariables,
  EnterpriseProgramSessionAssociationsQuery_EnterpriseProgramSessionAssociationsV1Resource_byProgramAndCourse_elements_session as OnDemandCourseSession,
} from 'bundles/program-home/utils/__generated__/EnterpriseProgramSessionAssociationsQuery';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/ProgramMiniModal';

const PRODUCT_TYPE_NAMES = Object.values(PRODUCT_TYPES).join(', ');
const ProgramActionButtonContainerCSR = deferToClientSideRender(ProgramActionButtonContainer);
const TrackedButton = withSingleTracked({ type: 'BUTTON' })(Button);

type PropsFromGraphQL = {
  courseTypeMetadata?: CourseTypeMetaData | null;
};

type PropsForIdVerifiedGraphql = Pick<PropsFromCaller, 'productId' | 'userId' | 'productType'> &
  Pick<OwnPropsFromMapProps, 's12n'>;

type PropsFromIdVerificationGraphQL = {
  productRequiresIdVerification: boolean;
  userRequiresIdVerification: boolean;
};

type PropsFromCurriculumSessionGraphQL = {
  session?: OnDemandCourseSession | null;
  refetchSessionDates?: () => void;
};

type PropsFromWithProductFeatures = {
  allowOrgForCurriculumBuilder?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
};

const SessionsV2AuthoringEligibilityCheckQuery = gql`
  query SessionsV2AuthoringEligibilityCheckQuery($courseId: String!) {
    SessionsV2AuthoringEligibilityCheckV1Resource {
      get(id: $courseId) {
        id
        eligibleForSessionsV2
      }
    }
  }
`;

type OnToggleSelectFn = (x0: { productId: string; isCourse: boolean; isSelected: boolean }) => void;

type SelectButtonProps = {
  onToggleSelect?: OnToggleSelectFn;
  isSelected: boolean;
  productId: string;
  isCourse: boolean;
  isSelectButtonDisabled?: boolean;
  variant?: 'primary' | 'secondary';
  children?: React.ReactNode;
};

export function SelectButton({
  isSelected,
  onToggleSelect,
  productId,
  isCourse,
  isSelectButtonDisabled,
  variant,
  children,
}: SelectButtonProps) {
  return (
    <SvgButton
      htmlAttributes={{ 'data-e2e': 'SelectButton' }}
      type={variant ?? (isSelected ? 'primary' : 'secondary')}
      label={children ?? (isSelected ? _t('Selected') : isSelectButtonDisabled ? _t('Included') : _t('Select'))}
      onClick={() =>
        onToggleSelect?.({
          productId,
          isCourse,
          isSelected: !isSelected,
        })
      }
      svgElement={isSelected || isSelectButtonDisabled ? <SvgSuccess color={color.white} size={16} /> : null}
      disabled={isSelectButtonDisabled}
    />
  );
}

type PropsFromState = {
  hasEnrolledInCourse: boolean;
  setHasEnrolledInCourse: (val: boolean) => void;
};

type PropsFromRouter = {
  router: InjectedRouter;
};

type PropsFromSpecializationAndUserCreditsGraphQL = {
  latestS12n?: Specialization;
  userCredits?: UserCredits;
};

type OwnPropsFromMapProps = {
  s12n?: Specialization;
  origProductId?: string;
};

type PropsFromSessionsV2Graphql = {
  hasMultipleAvailability?: boolean;
};

type PropsFromMapProps = OwnPropsFromMapProps & Pick<PropsFromSpecializationAndUserCreditsGraphQL, 'userCredits'>;

export type PropsFromCaller = {
  productId?: string;
  programId?: string;
  spookyMultiprogramFlag?: boolean;
  collectionId?: string;
  userId?: number;
  hasOldRoute?: boolean;
  isProgramMember?: boolean;
  isProgramAdmin?: boolean;
  onToggleSelect?: OnToggleSelectFn;
  showSelectButton?: boolean;
  actionElement?: JSX.Element;
  thirdPartyOrganizationName?: string;
  showDegreeBanner?: boolean;
  onCloseMiniModal?: () => void;
  renderCourseHomeLink?: boolean;
  productType?: string;
  videoData?: VideoData;
  isSelected?: boolean;
  modalName?: string;
  modalTrackingName?: string;
  modalTrackingData?: TrackingData;
  onActionSuccess?: () => void;
  onActionFail?: () => void;
  isUpswell?: boolean;
  showNoCertificateNotification?: boolean;
  isNonBlacklistedC4cv?: boolean;
  hasFreemiumTag?: boolean;
  thirdPartyOrgId?: string;
  hideAction?: boolean;
  disableSponsoredByMessage?: boolean;
  programJoinTrackingVerbiage?: boolean;
  isSelectButtonDisabled?: boolean;
  enterpriseProductConfiguration?: EnterpriseProductMetadataFlags;
  isEnterpriseAdminView?: boolean;
  hasMultipleAvailability?: boolean;
  isC4C?: boolean;
  sessionOverride?: {
    startsAt?: number;
    endsAt?: number;
    courseId?: string;
    isSelectedForCredit?: boolean;
    isAutoEnrollmentEnabled?: boolean;
  };
  onEditSession?: (productId: string, productType: string, refetchSessionDates?: () => void) => void;
  deepLinkItemId?: string;

  modalClassName?: string;
  Wrapper?: React.ElementType;
  WrapperHeader?: React.ReactElement;
  selectButtonProps?: Partial<SelectButtonProps>;
  isCampusWording?: boolean;
};

export type Props = PropsFromCaller &
  PropsFromMapProps &
  PropsFromGraphQL &
  PropsFromIdVerificationGraphQL &
  PropsFromCurriculumSessionGraphQL &
  PropsFromSessionsV2Graphql &
  PropsFromRouter &
  PropsFromState &
  PropsFromWithEnterpriseProductMetadata &
  PropsFromWithProductFeatures;

export type EnrollmentChoiceProps = Omit<
  React.ComponentProps<typeof EnrollmentChoiceModalForMultiprogram>,
  'thirdPartyOrgName' | 'userId' | 'onClose' | 'programJoinTrackingVerbiage' | 'headerTitle' | 'buttonTitle'
>;

type State = {
  spookyEnrollmentChoiceProps?: EnrollmentChoiceProps;
  autoEnrolling?: boolean;
};

const CreditBadgeWrapper: React.FC<{ className: string }> = (props) => {
  /*
  ProgramMiniModal default export is a pure component so I could not wrap it with a hook.
  */
  const isCampusWording: boolean = useCampusWordingForAdmin();
  if (isCampusWording) {
    return <CreditBadge {...props} />;
  }
  return null;
};

export class ProgramMiniModal extends React.Component<Props, State> {
  static childContextTypes = {
    isProgramHome: PropTypes.bool,
  };

  static contextType = ContentCurationContext;

  declare context: ContentCurationContextType;

  constructor(props: Props) {
    super(props);
    this.state = {
      spookyEnrollmentChoiceProps: undefined,
    };
  }

  getChildContext() {
    return {
      isProgramHome: true,
    };
  }

  onLoginBtnClick = () => {
    const { router } = this.props;

    if (!router) {
      return;
    }

    router.push({
      pathname: router.location.pathname,
      params: router.params,
      query: { ...router.location.query, authMode: 'login' },
    });
  };

  onModalClose = () => {
    const { onCloseMiniModal } = this.props;
    const { autoEnrolling } = this.state;

    // Go back to program home
    if (onCloseMiniModal && !autoEnrolling) {
      onCloseMiniModal();
    }
  };

  onOpenEnrollmentChoiceModal = (props: EnrollmentChoiceProps) => {
    this.setState({
      spookyEnrollmentChoiceProps: {
        ...props,
        onSelect: (programId: string) => {
          props.onSelect(programId);
          this.onCloseEnrollmentChoiceModal();
        },
      },
    });
  };

  onCloseEnrollmentChoiceModal = () => this.setState({ spookyEnrollmentChoiceProps: undefined });

  setAutoEnrolling = (autoEnrolling: boolean) => {
    this.setState({ autoEnrolling });
  };

  render() {
    const {
      productId,
      origProductId,
      s12n,
      productType,
      videoData,
      collectionId,
      userId,
      programId,
      spookyMultiprogramFlag,
      isProgramMember,
      actionElement,
      showDegreeBanner = false,
      renderCourseHomeLink,
      thirdPartyOrganizationName,
      session,
      thirdPartyOrgId,
      router,
      modalName = _t('ProgramMiniModal'),
      modalTrackingName = 'program_mini_modal',
      modalTrackingData = {},
      onActionSuccess,
      onActionFail,

      // Select logic
      showSelectButton,
      onToggleSelect,
      isSelected,
      courseTypeMetadata,
      isUpswell,
      showNoCertificateNotification,
      userCredits,
      hasEnrolledInCourse,
      setHasEnrolledInCourse,
      isNonBlacklistedC4cv,
      productRequiresIdVerification,
      userRequiresIdVerification,
      hasFreemiumTag,
      hideAction,
      disableSponsoredByMessage = false,
      programJoinTrackingVerbiage = true,
      isSelectButtonDisabled,
      isEnterpriseAdminView,
      enterpriseProductConfiguration,
      hasMultipleAvailability,
      isC4C,
      coursesMetadata,
      sessionOverride,
      onEditSession,
      refetchSessionDates,
      allowOrgForCurriculumBuilder,
      deepLinkItemId,
      modalClassName = '',
      Wrapper = React.Fragment,
      WrapperHeader = <React.Fragment />,
      selectButtonProps,
      isCampusWording,
    } = this.props;

    const { spookyEnrollmentChoiceProps: spookyEnrollmentChoiceModalProps, autoEnrolling } = this.state;

    const showIdVerificationModal = productRequiresIdVerification && userRequiresIdVerification && hasEnrolledInCourse;

    const hasRemainingCourses = userCredits
      ? !userCredits.isLimited || (userCredits.coursesRemaining ?? 0) > 0
      : undefined;
    const showCourseEnrollmentWarningModal =
      hasEnrolledInCourse && !hasRemainingCourses && (isUpswell || isNonBlacklistedC4cv);

    const showWrongProductType = productType !== PRODUCT_TYPES.COURSE && productType !== PRODUCT_TYPES.SPECIALIZATION;
    const isCourse = !showWrongProductType && productType === PRODUCT_TYPES.COURSE;

    let MiniPage;
    let trackingName;
    let trackingData;
    const courseType = isCourse && courseTypeMetadata && getCourseType(courseTypeMetadata);
    const isProject = courseType && ['Project', 'GuidedProject'].includes(courseType);

    if (isProject) {
      MiniPage = MiniPDP;
      trackingName = 'mini_pdp_modal';
      trackingData = { projectId: productId };
    } else if (isCourse) {
      MiniPage = MiniCDPContainer;
      trackingName = 'mini_cdp_modal';
      trackingData = { courseId: productId };
    } else {
      MiniPage = MiniSDPContainer;
      trackingName = 'mini_sdp_modal';
      trackingData = { s12nId: productId };
    }

    if (!_.isEmpty(modalTrackingData)) {
      trackingData = modalTrackingData;
    }

    const trackingDataId = isCourse ? { courseId: productId } : { s12nId: productId };
    const firstCourseId = s12n?.courses?.[0]?.id ?? '';
    const id = isCourse ? productId : firstCourseId;
    const btnLabel = <StartDateButton courseId={id} privateSessionStart={session?.startsAt} />;

    const skillSource = router.location.query?.source;

    const handleEditSession = () => {
      if (!productId) return;
      onEditSession?.(productId, isCourse ? 'course' : 's12n', () => refetchSessionDates?.());
    };

    const renderEditableSession = () => {
      let sessionLabel;
      const currentSession = sessionOverride || session;

      if (currentSession?.startsAt || currentSession?.endsAt) {
        sessionLabel = (
          <FormattedMessage
            message={_t('{startsAt}–{endsAt}')}
            startsAt={
              currentSession.startsAt ? formatDateTimeDisplay(currentSession.startsAt, SHORT_MONTH_DAY_DISPLAY) : null
            }
            endsAt={currentSession.endsAt ? formatDateTimeDisplay(currentSession.endsAt, MED_DATE_ONLY_DISPLAY) : null}
          />
        );
      } else if (hasMultipleAvailability || ((isSelectButtonDisabled || isSelected) && s12n)) {
        sessionLabel = _t('Always available');
      } else {
        sessionLabel = _t('Edit availability');
      }

      return onEditSession ? (
        <SvgButton
          type="link"
          isChildrenOnRight={true}
          onClick={handleEditSession}
          size="sm"
          label={sessionLabel}
          style={{ paddingLeft: 0 }}
          svgElement={<SvgEdit color={color.primary} size={18} />}
        />
      ) : (
        <span>{sessionLabel}</span>
      );
    };

    const renderEnterpriseAdminMetadata = () => {
      const isSelectedForCredit = sessionOverride
        ? sessionOverride.isSelectedForCredit
        : enterpriseProductConfiguration?.isSelectedForCredit;
      const isAutoenrolled = sessionOverride
        ? sessionOverride.isAutoEnrollmentEnabled
        : enterpriseProductConfiguration?.isAutoEnrollmentEnabled;
      return (
        <>
          {isSelectedForCredit && <CreditBadgeWrapper className={`${showSelectButton ? 'm-l-2' : ''} m-b-1s`} />}
          {isAutoenrolled && <AutoEnrollBadge className={`${showSelectButton ? 'm-l-2' : ''} m-b-1s`} />}
          {isSelectButtonDisabled || isSelected || !showSelectButton
            ? enterpriseProductConfiguration?.isConfigurationAllowed &&
              productId && <span className="m-l-2">{renderEditableSession()}</span>
            : hasMultipleAvailability && <span className="m-b-1s m-l-2">{_t('Multiple availability options')}</span>}
        </>
      );
    };

    const getS12nCoursesMetadata = () => {
      if (coursesMetadata) {
        return coursesMetadata;
      } else if (sessionOverride) {
        return s12n?.courses?.map((course) => ({
          courseId: course.id,
          isSelectedForCredit: sessionOverride.isSelectedForCredit,
          isAutoEnrollmentEnabled: sessionOverride.isAutoEnrollmentEnabled,
        }));
      } else if (enterpriseProductConfiguration) {
        return s12n?.courses?.map((course) => ({
          courseId: course.id,
          isSelectedForCredit: enterpriseProductConfiguration.isSelectedForCredit,
          isAutoEnrollmentEnabled: enterpriseProductConfiguration.isAutoEnrollmentEnabled,
        }));
      }

      return undefined;
    };

    const containerClassName = cx({
      course: isCourse,
      s12n: !isCourse,
      project: isProject,
    });

    let programActionButtonLabel = _t('Go to course');
    if (courseType === 'GuidedProject') {
      programActionButtonLabel = _t('Go to Guided Project');
    } else if (courseType === 'Project') {
      programActionButtonLabel = _t('Go to Project');
    }

    const modalContent = (
      <MiniPage
        id={productId || ''}
        videoData={videoData}
        ratingColor="1F1F1F"
        disableS12nUpsellLinks={true}
        s12nMiniCDPActionElementFn={({ courseId }: { courseId: string }) => (
          <span>
            {renderCourseHomeLink && (
              <ProgramActionButtonContainer
                renderNothingIfConditionNotMet={isCourse}
                onActionSuccess={onActionSuccess}
                onActionFail={onActionFail}
                renderAdminAction
                renderCourseHomeLink
                programId={programId}
                spookyMultiprogramFlag={spookyMultiprogramFlag}
                openEnrollmentChoiceModal={this.onOpenEnrollmentChoiceModal}
                thirdPartyOrganizationId={thirdPartyOrgId}
                collectionId={collectionId}
                productId={courseId}
                productType={PRODUCT_TYPES.COURSE}
                actionType="ENROLL"
                initialBtnLabel={btnLabel}
                btnAttributes={{
                  type: 'primary',
                  style: {
                    marginRight: spacing.sm,
                    outlineOffset: '8px',
                  },
                  htmlAttributes: {
                    'data-e2e': 'EnrollButton',
                  },
                }}
                trackingInfo={{
                  trackingData: {
                    actionType: 'ENROLL',
                    ...trackingDataId,
                    collectionId,
                    ...(skillSource && { source: skillSource }),
                  },
                  trackingName: 'mini_modal_action_button_enroll',
                }}
                shouldResetToDefaultStatus={true}
                apiStatusResetTime={5000}
                deepLinkItemId={deepLinkItemId}
              />
            )}
            {renderCourseHomeLink && (
              <ProgramActionButtonContainerCSR
                programId={programId}
                spookyMultiprogramFlag={spookyMultiprogramFlag}
                openEnrollmentChoiceModal={this.onOpenEnrollmentChoiceModal}
                thirdPartyOrganizationId={thirdPartyOrgId}
                productId={courseId}
                collectionId={collectionId}
                renderIfEnrolled
                productType={PRODUCT_TYPES.COURSE}
                actionType="RESUME"
                resumeBtnAttributes={{
                  label: _t('Go to Course'),
                  type: 'secondary',
                  style: { marginRight: spacing.sm },
                  htmlAttributes: { 'data-e2e': `${courseId}~courseHomeLink` },
                }}
                trackingInfo={{
                  trackingData: { actionType: 'RESUME', ...trackingDataId },
                  trackingName: 'mini_modal_action_button_course_home',
                }}
              />
            )}
            {thirdPartyOrganizationName && renderCourseHomeLink && !isUpswell && !disableSponsoredByMessage && (
              <div className="font-xs p-t-1s sponsored-by-text">
                <FormattedMessage
                  message={_t('Sponsored by {organization}')}
                  organization={thirdPartyOrganizationName}
                />
              </div>
            )}
          </span>
        )}
        showDegreeBanner={showDegreeBanner}
        actionElement={
          hideAction ? (
            <div className="horizontal-box align-items-vertical-center wrap">
              {allowOrgForCurriculumBuilder &&
                isEnterpriseAdminView &&
                enterpriseProductConfiguration?.isSelectedForCredit && <CreditBadgeWrapper className="m-r-1" />}
              {allowOrgForCurriculumBuilder &&
                isEnterpriseAdminView &&
                enterpriseProductConfiguration?.isAutoEnrollmentEnabled && <AutoEnrollBadge className="m-r-1" />}
              {allowOrgForCurriculumBuilder &&
                isEnterpriseAdminView &&
                enterpriseProductConfiguration?.isConfigurationAllowed &&
                renderEditableSession()}
            </div>
          ) : (
            <div className="horizontal-box align-items-vertical-center align-items-spacebetween wrap">
              <span className="action-element-container align-items-vertical-center m-t-1 wrap">
                {actionElement}
                {/* We need to notify with the origProductId since s12n may have an updated version that has a different id */}
                {showSelectButton && origProductId && (
                  <SelectButton
                    onToggleSelect={onToggleSelect}
                    productId={origProductId}
                    isSelectButtonDisabled={isSelectButtonDisabled}
                    isCourse={isCourse}
                    isSelected={isSelected ?? false}
                    {...selectButtonProps}
                  />
                )}
                {isEnterpriseAdminView && allowOrgForCurriculumBuilder && renderEnterpriseAdminMetadata()}
                {!showSelectButton && (
                  <span className="action-element-container align-items-top">
                    {!userId ? (
                      <span>
                        <TrackedButton
                          label={btnLabel}
                          type="primary"
                          onClick={this.onLoginBtnClick}
                          htmlAttributes={{
                            'data-e2e': 'LoginButton',
                          }}
                          trackingName="mini_modal_login_button"
                          trackingData={{ isCourse, productId, programId, collectionId, productType }}
                        />
                        <TrackedSvgButton
                          type="icon"
                          size="sm"
                          style={isProject ? { color: color.white } : { color: color.black }}
                          label={_t('Save for Later')}
                          trackingName="save_for_later_button"
                          trackingData={{ isCourse, productId, programId, collectionId, productType }}
                          onClick={this.onLoginBtnClick}
                          svgElement={<SvgBookmark color={color.primary} hoverColor={color.darkPrimary} />}
                          htmlAttributes={{
                            'data-e2e': 'BookmarkButton',
                          }}
                        />
                      </span>
                    ) : (
                      <span>
                        {isCourse && (
                          <ProgramActionButtonContainer
                            programId={programId}
                            spookyMultiprogramFlag={spookyMultiprogramFlag}
                            openEnrollmentChoiceModal={this.onOpenEnrollmentChoiceModal}
                            thirdPartyOrganizationId={thirdPartyOrgId}
                            productId={productId}
                            collectionId={collectionId}
                            renderIfEnrolled
                            productType={productType}
                            actionType="RESUME"
                            resumeBtnAttributes={{
                              label: programActionButtonLabel,
                              type: 'secondary',
                              style: { marginRight: spacing.sm },
                              htmlAttributes: { 'data-e2e': `${productId}~courseHomeLink` },
                            }}
                            trackingInfo={{
                              trackingData: { actionType: 'RESUME', ...trackingDataId },
                              trackingName: 'mini_modal_action_button_course_home',
                            }}
                          />
                        )}
                        <span>
                          <ProgramActionButtonContainer
                            renderNothingIfConditionNotMet={isCourse}
                            onActionSuccess={onActionSuccess}
                            onActionFail={onActionFail}
                            renderAdminAction
                            renderCourseHomeLink={renderCourseHomeLink}
                            programId={programId}
                            spookyMultiprogramFlag={spookyMultiprogramFlag}
                            openEnrollmentChoiceModal={this.onOpenEnrollmentChoiceModal}
                            thirdPartyOrganizationId={thirdPartyOrgId}
                            collectionId={collectionId}
                            productId={productId}
                            productType={productType}
                            actionType="ENROLL"
                            firstCourseId={firstCourseId}
                            initialBtnLabel={btnLabel}
                            btnAttributes={{
                              type: 'primary',
                              style: {
                                marginRight: spacing.sm,
                                outlineOffset: '8px',
                              },
                              htmlAttributes: {
                                'data-e2e': 'EnrollButton',
                              },
                            }}
                            trackingInfo={{
                              trackingData: {
                                actionType: 'ENROLL',
                                ...trackingDataId,
                                collectionId,
                                ...(skillSource && { source: skillSource }),
                                ...getClipsTrackingInfo(router.location.query?.fromClip),
                              },
                              trackingName: 'mini_modal_action_button_enroll',
                            }}
                            shouldResetToDefaultStatus={true}
                            apiStatusResetTime={5000}
                            setHasEnrolledInCourse={setHasEnrolledInCourse}
                            deepLinkItemId={deepLinkItemId}
                            autoEnrolling={autoEnrolling}
                            setAutoEnrolling={this.setAutoEnrolling}
                          />
                        </span>
                        <span className="p-t-1s">
                          <ProgramActionButtonContainer
                            renderNothingIfConditionNotMet
                            onActionSuccess={onActionSuccess}
                            onActionFail={onActionFail}
                            programId={programId}
                            spookyMultiprogramFlag={spookyMultiprogramFlag}
                            openEnrollmentChoiceModal={this.onOpenEnrollmentChoiceModal}
                            thirdPartyOrganizationId={thirdPartyOrgId}
                            productId={productId}
                            productType={productType}
                            collectionId={collectionId}
                            actionType="SELECT"
                            btnAttributes={{
                              type: 'icon',
                              size: 'sm',
                              htmlAttributes: {
                                'data-e2e': 'SelectButton',
                              },
                            }}
                            trackingInfo={{
                              trackingData: {
                                actionType: 'SELECT',
                                ...trackingDataId,
                              },
                              trackingName: 'mini_modal_action_button_select_save_for_later',
                            }}
                            shouldResetToDefaultStatus={true}
                            apiStatusResetTime={5000}
                          />
                          <ProgramActionButtonContainer
                            renderNothingIfConditionNotMet
                            onActionSuccess={onActionSuccess}
                            onActionFail={onActionFail}
                            programId={programId}
                            spookyMultiprogramFlag={spookyMultiprogramFlag}
                            openEnrollmentChoiceModal={this.onOpenEnrollmentChoiceModal}
                            thirdPartyOrganizationId={thirdPartyOrgId}
                            productId={productId}
                            productType={productType}
                            collectionId={collectionId}
                            actionType="UNSELECT"
                            btnAttributes={{
                              type: 'icon',
                              size: 'sm',
                              htmlAttributes: {
                                'data-e2e': 'UnSelectButton',
                              },
                            }}
                            trackingInfo={{
                              trackingData: {
                                actionType: 'UNSELECT',
                                ...trackingDataId,
                              },
                              trackingName: 'mini_modal_action_button_unselect_save_for_later',
                            }}
                            shouldResetToDefaultStatus={true}
                            apiStatusResetTime={5000}
                          />
                        </span>
                      </span>
                    )}
                  </span>
                )}
              </span>
              {isProgramMember && !showSelectButton && (courseType !== 'GuidedProject' || !hasFreemiumTag) && (
                <AvailableCredit programId={programId} userCredits={userCredits} isModalCredit />
              )}
              {thirdPartyOrganizationName && !isUpswell && !disableSponsoredByMessage && (
                <div {...css('w-100 font-xs p-t-1s sponsored-by-text')}>
                  <FormattedMessage
                    message={_t('Sponsored by {organization}')}
                    organization={thirdPartyOrganizationName}
                  />
                </div>
              )}
            </div>
          )
        }
        showNoCertificateNotification={showNoCertificateNotification}
        privateSessionDates={session ? { startsAt: session.startsAt, endsAt: session.endsAt } : undefined}
        programId={programId}
        orgId={thirdPartyOrgId}
        enterpriseProductConfiguration={enterpriseProductConfiguration}
        isEnterpriseAdminView={isEnterpriseAdminView}
        isC4C={this.context?.isC4C ?? isC4C ?? false}
        enableCurriculumBuilder={allowOrgForCurriculumBuilder}
        hasMultipleAvailabilities={hasMultipleAvailability}
        coursesMetadata={getS12nCoursesMetadata()}
      />
    );

    return (
      <div className="ProgramMiniModal">
        <Modal
          className={modalClassName}
          modalName={modalName}
          handleClose={this.onModalClose}
          trackingName={modalTrackingName || trackingName}
          data={trackingData}
          labelledById="programMiniModalName"
        >
          {showWrongProductType ? (
            <div>
              <h2>{_t('Wrong Product Type in URL ')}</h2>
              <p>
                <FormattedHTMLMessage
                  message={_t('Please provide correct product types: {types}')}
                  types={PRODUCT_TYPE_NAMES}
                />
              </p>
            </div>
          ) : (
            <div className={containerClassName} style={{ minHeight: '92vh', overflowX: 'hidden' }}>
              {/* <- using classname for modal x button styling */}
              <Wrapper>
                {WrapperHeader}
                {modalContent}
              </Wrapper>
            </div>
          )}
        </Modal>
        {showCourseEnrollmentWarningModal && <CourseEnrollmentWarningModal />}
        {showIdVerificationModal && <IdVerificationWarningModal />}
        {spookyEnrollmentChoiceModalProps && thirdPartyOrganizationName && userId && (
          <EnrollmentChoiceModalForMultiprogram
            {...spookyEnrollmentChoiceModalProps}
            onClose={this.onCloseEnrollmentChoiceModal}
            programJoinTrackingVerbiage={programJoinTrackingVerbiage}
            thirdPartyOrgName={thirdPartyOrganizationName}
            userId={userId}
            headerTitle={_t('Enrollment options')}
            buttonTitle={_t('Enroll now')}
          />
        )}
        {autoEnrolling && <RedirectOverlay />}
      </div>
    );
  }
}

export function useProgramMiniModalParams() {
  const { query } = useLocation();

  if (!query) {
    return null;
  }

  const { authMode, showMiniModal, collectionId, deepLinkItemId } = query;
  let { productId, productType } = query;

  const userId = user.get().id;
  // Only render when query contains valid productId and showMiniModal is true,
  // and when user is not seeing the login modal
  if (productId && showMiniModal && !(!userId && (authMode === 'login' || authMode === 'signup'))) {
    // Quick fix for a bug where productId may be passed twice from some of our Emails
    if (_.isArray(productId)) {
      productId = productId[0];
    }

    if (_.isArray(productType)) {
      productType = productType[0];
    }

    return {
      productId,
      productType,
      collectionId,
      userId,
      hasOldRoute: false,
      deepLinkItemId,
    };
  } else {
    return null;
  }
}

export const useRouterParams = (
  router: InjectedRouter
): {
  productId: string;
  productType: string;
  programSlug?: string;
  hasOldRoute: boolean;
  userId?: number;
  collectionId?: string;
  deepLinkItemId?: string;
} | null => {
  if (!router.location.query) {
    return null;
  }

  const { authMode, showMiniModal, collectionId, deepLinkItemId } = router.location.query;
  let { productId, productType } = router.location.query;

  const userId = user.get().id;
  // Only render when query contains valid productId and showMiniModal is true,
  // and when user is not seeing the login modal
  if (productId && showMiniModal && !(!userId && (authMode === 'login' || authMode === 'signup'))) {
    // Quick fix for a bug where productId may be passed twice from some of our Emails
    if (_.isArray(productId)) {
      productId = productId[0];
    }

    if (_.isArray(productType)) {
      productType = productType[0];
    }

    return {
      productId,
      productType,
      collectionId,
      userId,
      hasOldRoute: false,
      deepLinkItemId,
    };
  } else {
    return null;
  }
};

export const closeMiniModalWithRoute = (router: InjectedRouter) => {
  const query = _.omit(
    router.location.query,
    'showMiniModal',
    'productType',
    'productId',
    'authMode',
    'collectionId',
    'itemId',
    'deepLinkItemId',
    'fromClip'
  );
  router.push({
    pathname: router.location.pathname,
    params: router.params,
    query,
  });
};

export default compose<Props, PropsFromCaller>(
  setDisplayName('withProgramMiniModalWrapper'),
  connectToRouter((router) => ({ router })),
  withRedirectToUnifiedExperience,
  withState('hasEnrolledInCourse', 'setHasEnrolledInCourse', false),
  waitForGraphQL<PropsFromCaller, Query, CourseTypeMetadataByCourseIdQueryVariables, PropsFromGraphQL>(
    CourseTypeMetadataByCourseIdQuery,
    {
      skip: ({ productId, productType }) => !productId || productType === PRODUCT_TYPES.SPECIALIZATION,
      options: ({ productId }) => ({
        // See above skip().

        variables: { courseId: productId! },
      }),
      props: ({ data }) => {
        return {
          courseTypeMetadata: data?.CourseTypeMetadataV1Resource?.get,
        };
      },
    }
  ),
  branch<Props & PropsFromCaller>(
    ({ thirdPartyOrgId }) => {
      return !!thirdPartyOrgId;
    },
    withProductFeatures<PropsFromWithProductFeatures, Pick<PropsFromCaller, 'thirdPartyOrgId'>>(
      ({ thirdPartyOrgId }) => ({ idType: IdTypes.Organization, id: thirdPartyOrgId! }),
      ({ features }) => ({
        allowOrgForCurriculumBuilder: features.get('enterprise', 'allowOrgForCurriculumBuilder'),
        allowOrgForSpecializationConfiguration: features.get('enterprise', 'allowOrgForSpecializationConfiguration'),
      })
    )
  ),
  graphql<
    PropsFromWithProductFeatures & Pick<PropsFromCaller, 'productId' | 'productType' | 'isEnterpriseAdminView'>,
    SessionsV2AuthoringEligibilityCheckQueryData,
    SessionsV2AuthoringEligibilityCheckQueryVariables,
    PropsFromSessionsV2Graphql
  >(SessionsV2AuthoringEligibilityCheckQuery, {
    skip: ({ productType, productId, isEnterpriseAdminView }) =>
      productType === PRODUCT_TYPES.SPECIALIZATION || !productId || !isEnterpriseAdminView,
    options: ({ productId }) => ({
      variables: { courseId: productId! },
    }),
    props: ({ data }) => {
      const hasMultipleAvailability = data?.SessionsV2AuthoringEligibilityCheckV1Resource?.get?.eligibleForSessionsV2;
      return { hasMultipleAvailability };
    },
  }),
  graphql<
    Pick<PropsFromCaller, 'productId' | 'programId' | 'productType'>,
    EnterpriseProgramSessionAssociationsQuery,
    EnterpriseProgramSessionAssociationsQueryVariables,
    PropsFromCurriculumSessionGraphQL
  >(EnterpriseProgramSessionAssociationsQueryV1, {
    // @ts-expect-error react-apollo@2.2.0
    skip: ({ productType, productId, programId, sessionOverride }) =>
      !productId ||
      // TODO(ppaskaris): multi-program support for whatever this is
      !programId ||
      productType === 's12n' ||
      Boolean(sessionOverride),
    options: ({ programId, productId }) => ({
      // See above skip().

      variables: { programId: programId!, productId: productId! },
    }),
    props: ({ data }) => {
      const session = data?.EnterpriseProgramSessionAssociationsV1Resource?.byProgramAndCourse?.elements[0]?.session;

      return {
        session,
        refetchSessionDates: data?.refetch,
      };
    },
  }),
  graphql<
    Pick<PropsFromCaller, 'productId' | 'programId' | 'productType'> & PropsFromWithProductFeatures,
    EnterpriseProgramS12nSessionAssociationsQueryData,
    EnterpriseProgramS12nSessionAssociationsQueryVariables,
    PropsFromCurriculumSessionGraphQL
  >(EnterpriseProgramS12nSessionAssociationsQuery, {
    // @ts-expect-error react-apollo@2.2.0
    skip: ({ productType, productId, programId, sessionOverride, allowOrgForSpecializationConfiguration }) =>
      !productId ||
      // TODO(ppaskaris): multi-program support for whatever this is
      !programId ||
      productType !== 's12n' ||
      Boolean(sessionOverride) ||
      !allowOrgForSpecializationConfiguration,
    options: ({ programId, productId }) => ({
      // See above skip().

      variables: { programIds: [programId!], s12nIds: [productId!] },
    }),
    props: ({ data }) => {
      const session =
        data?.EnterpriseProgramSessionAssociationsV1Resource?.byProgramsAndSpecializations?.elements[0]?.session;

      return {
        session,
        refetchSessionDates: data?.refetch,
      };
    },
  }),
  waitForGraphQL<
    PropsFromCaller,
    SpecializationAndUserCreditsQuery,
    SpecializationAndUserCreditsQueryVariables,
    PropsFromSpecializationAndUserCreditsGraphQL
  >(SpecializationAndUserCredits, {
    options: ({ productType, productId, programId }) => {
      const isS12n = productType === PRODUCT_TYPES.SPECIALIZATION;
      const userId = user.get().id;
      return {
        variables: {
          programId: programId ?? '',
          s12nId: productId ?? '',
          userId,
          // TODO(ppaskaris): multi-program support for whatever this is
          skipUserCredits: !(userId && programId),
          skipSpecialization: !(isS12n && productId),
        },
        context: { clientName: 'gatewayGql' },
      };
    },
    props: ({ data }) => {
      return {
        userCredits: data?.ProgramUserCredits?.queryByProgramEnrollmentId,
        latestS12n: data?.Specialization?.queryById?.latestSpecialization,
      };
    },
  }),
  mapProps<Props, Omit<Props, keyof OwnPropsFromMapProps> & PropsFromSpecializationAndUserCreditsGraphQL>(
    ({ latestS12n, productType, productId: origProductId, ...rest }) => {
      const isS12n = productType === PRODUCT_TYPES.SPECIALIZATION;
      let productId = origProductId;
      let s12n: Specialization | undefined;
      if (isS12n) {
        if (latestS12n) {
          s12n = latestS12n;
          productId = latestS12n.id;
        } else {
          const error = new Error('Specialization.queryById.latestSpecialization did not return a result.');
          Object.assign(error, { productId });
          Sentry.captureException(error);
        }
      }
      return { ...rest, productId, origProductId, productType, s12n };
    }
  ),
  graphql<
    PropsForIdVerifiedGraphql,
    IdVerificationQueryType,
    IdVerificationQueryVariables,
    PropsFromIdVerificationGraphQL
  >(IdVerificationQuery, {
    skip: ({ productId, productType, s12n, userId }: PropsForIdVerifiedGraphql) =>
      !userId || productType === PRODUCT_TYPES.SPECIALIZATION ? !s12n?.courses?.length : !productId,
    options: ({ productId, productType, s12n, userId }) => ({
      variables: {
        ids:
          productType === PRODUCT_TYPES.SPECIALIZATION
            ? s12n?.courses.map((course) => course.id) ?? []
            : productId ?? [],
        userId: String(userId) || '',
      },
      context: { clientName: 'gatewayGql' },
      ssr: false,
      errorPolicy: 'all',
    }),
    props: ({ data }) => {
      const courses = data?.Course?.queryByIds;
      const userVerificationRequirements =
        data?.NaptimeQueries?.UserVerificationRequirementsV1Resource?.multiGet?.elements;

      return {
        productRequiresIdVerification:
          courses?.some((course) => course?.userVerification?.idVerification?.status === 'ENABLED') ?? false,
        userRequiresIdVerification:
          userVerificationRequirements?.some(
            (userVerificationRequirement) =>
              userVerificationRequirement?.userVerificationRequirementState !== 'REQUIREMENT_MET'
          ) ?? false,
      };
    },
  }),
  branch<Props & PropsFromCaller & PropsFromWithProductFeatures>(
    ({ allowOrgForCurriculumBuilder, programId, enterpriseProductConfiguration }) =>
      Boolean(allowOrgForCurriculumBuilder) && Boolean(programId) && !enterpriseProductConfiguration,
    withLocalCacheEnterpriseProductMetadata
  ),
  pure
)(ProgramMiniModal);
