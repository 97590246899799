/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';

import { isRightToLeft } from 'js/lib/language';

import { Drawer, MuiClickAwayListener, Popover, VisuallyHidden, useId } from '@coursera/cds-core';

import { COACH_LABEL, LAYOUTS } from 'bundles/ai-coach-platform/components/patterns/popover/constants';
import { useQuickAccessContext } from 'bundles/ai-coach-platform/components/patterns/popover/context/quickAccessContext';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  dropdown: css`
    position: fixed;
    border-radius: var(--cds-border-radius-200);
    border-color: var(--cds-color-grey-25);
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 9%), 1px 2px 8px 2px rgb(0 0 0 / 11%);
    width: 450px;
    background-color: var(--cds-color-grey-25);
    padding: var(--cds-spacing-200);
  `,
  inputField: css`
    /* overrides the rich text input background color when rendering within a dropdown */
    --cds-color-neutral-background-primary-weak: var(--cds-color-white-0);
  `,
};

type Props = {
  open: boolean;
  onClose: () => void;
};

/**
 * QuickAccessDropdown renders a FloatingUI Dropdown for desktop or a Drawer for mobile.
 * We cannot use CDS Popover, because the dropdown designs require the button trigger to be interactive
 * when the dropdwn is open. And the dropdown needs to intelligently 'flip' and 'shift' it's position
 * based on the screen dimensions.
 */
const QuickAccessDropdown = ({ open, onClose, children }: React.PropsWithChildren<Props>) => {
  const { context, layout, floatingProps } = useQuickAccessContext();
  const id = useId();
  const ariaLabel = _t('#{coachLabel} short-cuts', { coachLabel: COACH_LABEL });

  if (layout === LAYOUTS.DRAWER) {
    return (
      <Drawer data-testid="quick-access-drawer" open={open} onClose={onClose} aria-labelledby={id}>
        <VisuallyHidden id={id}>{ariaLabel}</VisuallyHidden>
        <Popover.Body>{children}</Popover.Body>
      </Drawer>
    );
  }

  if (!context || !open) {
    return null;
  }

  const horizontal = isRightToLeft(_t.getLocale()) ? 'right' : 'left';

  return (
    <FloatingPortal>
      <FloatingFocusManager context={context} modal={true} returnFocus={true}>
        <MuiClickAwayListener mouseEvent="onMouseDown" onClickAway={onClose}>
          <div
            data-testid="quick-access-dropdown"
            aria-label={ariaLabel}
            {...floatingProps}
            css={[styles.dropdown, styles.inputField]}
            style={{
              top: context.y,
              [horizontal]: context.x,
            }}
          >
            {children}
          </div>
        </MuiClickAwayListener>
      </FloatingFocusManager>
    </FloatingPortal>
  );
};

export default QuickAccessDropdown;
