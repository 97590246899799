/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import styled from '@emotion/styled';

import { Grid, Typography, Typography2 } from '@coursera/cds-core';

import { useThirdPartyOrganization } from 'bundles/admin-dashboard/components/ThirdPartyOrganizationProvider';
import { PageSection } from 'bundles/enterprise-ui/components/PageSection';
import ThirdPartyOrganizationLogo from 'bundles/page-header/components/ThirdPartyOrganizationLogo';

const Wrapper = styled(PageSection)({ backgroundColor: '#EAECF2', padding: '24px 0' });

type Props = {
  pageName: string;
};

export const OrgPageBanner: React.LegacyFunctionComponentWithChildren<Props> = ({ pageName, children }) => {
  const thirdPartyOrganization = useThirdPartyOrganization();
  return (
    <Wrapper>
      <Grid container css={{ minHeight: '96px' }} spacing={16} direction="row" wrap="nowrap" data-e2e="org-page-banner">
        <Grid container item alignItems="center" justifyContent="center" css={{ height: '96px', width: '96px' }}>
          <ThirdPartyOrganizationLogo
            thirdPartyOrganization={thirdPartyOrganization}
            logoHeight={80}
            logoWidth={80}
            defaultSize={80}
            preferRectangle={false}
          />
        </Grid>
        <Grid item css={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography2
            component="p"
            color="supportText"
            className="organization-name"
            css={{ textTransform: 'uppercase' }}
          >
            {thirdPartyOrganization.name}
          </Typography2>
          <Typography variant="h1semibold" className="page-name">
            {pageName}
          </Typography>
        </Grid>
        <Grid item css={{ marginLeft: 'auto' }}>
          {children}
        </Grid>
      </Grid>
    </Wrapper>
  );
};
