/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

const styles = {
  container: css(`
    display: flex;
    gap: 12px;

    @media (max-width: 575px) {
      flex-direction: column;
      margin-bottom: 12px;
    }
  `),
};

const CourseProgressBox: React.LegacyFunctionComponentWithChildren = ({ children }) => {
  return <div css={styles.container}>{children}</div>;
};

export default CourseProgressBox;
