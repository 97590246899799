/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import type { Action } from 'bundles/ai-coach-platform/components/patterns/popover/types';

import _t from 'i18n!nls/ai-coach-platform';

const styles = {
  toolbar: css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--cds-spacing-100);
    margin-bottom: var(--cds-spacing-100);
  `,
};

type ClickEvent = React.MouseEvent<HTMLButtonElement>;
type ClickEventHandler = (event: ClickEvent) => void;

type Props = {
  actions?: Action[];
  onClose: () => void;
};

/**
 * QuickAccessToolbar renders a toolbar containing quick actions
 */
const QuickAccessToolbar = ({ actions, onClose }: Props) => {
  const handleAction = useCallback<(onClick?: ClickEventHandler) => (event: ClickEvent) => void>(
    (onClick) => (event) => {
      onClick?.(event);
      onClose();
    },
    [onClose]
  );

  if (!actions || actions.length === 0) {
    return null;
  }

  return (
    <div role="toolbar" aria-label={_t('Quick actions')} css={styles.toolbar}>
      {actions.map(({ key, ...action }) => (
        <CoachButton key={key} variant="secondary" size="small" {...action} onClick={handleAction(action.onClick)}>
          {action.label}
        </CoachButton>
      ))}
    </div>
  );
};

export default QuickAccessToolbar;
