/**
 * Private module reserved for @coursera/cds-core package.
 */

import createLargeSvgIcon from '@core/utils/createLargeSvgIcon';

export default createLargeSvgIcon('CheckboxIndeterminateIcon', {
  large: (
    <>
      <rect fill="currentColor" height="20" rx="1.6666666667" width="20" />
      <path
        d="M5.58391 10.75C5.3753 10.75 5.19808 10.6785 5.05225 10.5356C4.90641 10.3927 4.8335 10.2156 4.8335 10.0044C4.8335 9.79312 4.90537 9.61458 5.04912 9.46875C5.19287 9.32292 5.371 9.25 5.5835 9.25H14.4164C14.625 9.25 14.8022 9.32146 14.9481 9.46438C15.0939 9.60729 15.1668 9.78438 15.1668 9.99563C15.1668 10.2069 15.095 10.3854 14.9512 10.5312C14.8075 10.6771 14.6293 10.75 14.4168 10.75H5.58391Z"
        fill="#fff"
      />
    </>
  ),
});
