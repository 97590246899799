import * as React from 'react';

import _t from 'i18n!nls/author-code-evaluator';

type Props = {
  onClickCreate: (evaluatorId: string) => void;
};

const AuthoringEvaluatorCreate = (props: Props) => {
  const [evaluatorId, setEvaluatorId] = React.useState('');
  const [createInProgress, setCreateInProgress] = React.useState(false);

  const handleCreate = () => {
    setCreateInProgress(true);

    props.onClickCreate(evaluatorId);
  };

  return (
    <div className="rc-AuthoringEvaluatorCreate">
      <h3 style={{ paddingTop: 20, paddingBottom: 5 }} className="headline-1-text">
        {_t('Evaluator Id')}
      </h3>

      <div className="caption-text" style={{ paddingBottom: 10 }}>
        {_t('Enter an existing evaluator id to clone')}
      </div>

      <input type="text" style={{ width: 300, padding: 8 }} onChange={(event) => setEvaluatorId(event.target.value)} />

      <div style={{ paddingTop: 40 }}>
        <button disabled={createInProgress} className="primary" onClick={handleCreate}>
          {createInProgress ? _t('Creating...') : _t('Create Evaluator')}
        </button>
      </div>
    </div>
  );
};

export default AuthoringEvaluatorCreate;
