import { useEffect } from 'react';

import type { ApolloError } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';

import type { UpdateChatHistory } from 'bundles/ai-coach-platform/components/patterns/chat/store/actions/types';
import { updateChatHistory } from 'bundles/ai-coach-platform/components/patterns/chat/store/dispatcher';
import type {
  AiCoachPlatform_ChatMessagesPaginatedResultQueryVariables as AiCoachChatMessagesPaginatedResultQueryVariables,
  AiCoachPlatform_ChatMessagesPaginatedResultQuery as ChatMessagesPaginatedResultQuery,
} from 'bundles/ai-coach-platform/queries/__generated__/aiCoachChatMessagesByChatSessionId';
import aiCoachChatMessagesByChatSessionIdQuery from 'bundles/ai-coach-platform/queries/aiCoachChatMessagesByChatSessionId.graphql';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';

import { MESSAGE_SENDER } from './constants';

type Props = {
  historyLimit: number;
  chatSessionId?: string;
};

/**
 * Parse the fetched result and update the chat history
 * @param data the data fetched from the query
 * @param error the apollo error happened during fetching
 */
const parseFetchedResult = (
  data: ChatMessagesPaginatedResultQuery | undefined,
  error: ApolloError | undefined
): void => {
  if (data?.AiCoach?.queryByChatSessionId?.elements) {
    const messages = data?.AiCoach?.queryByChatSessionId?.elements;
    // @ts-expect-error DateTime: string
    const parsedMessages: UpdateChatHistory['messages'] = messages.map((message) => {
      if (message.senderType === MESSAGE_SENDER.CLIENT) {
        return {
          id: message.id,
          sender: message.senderType,
          text: message.message,
          timestamp: message.createdAt,
        };
      } else if (message.senderType === MESSAGE_SENDER.RESPONSE) {
        return {
          id: message.id,
          sender: message.senderType,
          text: message.message,
          timestamp: message.createdAt,
          /** Feedback from the user on the AI coach response via a like/dislike */
          userFeedback: message.userFeedback,
          navigationButtons: message.navigationButtonsV2,
          recommendedActions: message.recommendedActionsV2,
        };
      }
      return {
        id: message.id,
        sender: message.senderType,
        text: message.message,
        timestamp: message.createdAt,
      };
    });

    // TODO: MAGIC: chat history is coming in reverse order, so we make it the new messages are at the bottom of the chat by reversing it here.
    updateChatHistory(parsedMessages.reverse());
  }

  if (error) {
    captureMessageInSentry({ source: 'useChatHistory', error });
  }
};

export function useChatHistory({ historyLimit, chatSessionId = '' }: Props):
  | {
      loading: boolean;
      error: ApolloError | undefined;
      fetchChatHistory: (chatSessionId: string) => void;
    }
  | undefined {
  const [fetchQuery, { loading, data, error }] = useLazyQuery<
    ChatMessagesPaginatedResultQuery,
    AiCoachChatMessagesPaginatedResultQueryVariables
  >(aiCoachChatMessagesByChatSessionIdQuery, {
    variables: {
      chatSessionId,
      limit: historyLimit,
    },
    context: { clientName: 'gatewayGql' },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    parseFetchedResult(data, error);
  }, [data, error]);

  return {
    loading,
    error,
    fetchChatHistory: (chatSessionIdToFetch) =>
      fetchQuery({ variables: { chatSessionId: chatSessionIdToFetch, limit: historyLimit } }),
  };
}
