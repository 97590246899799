import type { CareerAcademy_Variant as CareerAcademyVariant } from '__generated__/graphql-types';
import { compose, withProps } from 'recompose';

import {
  ENTERPRISE_FREEMIUM_CA_CONTENTFUL_SLUG,
  ENTERPRISE_PAGE_CONTENT_CONTENTFUL_SLUG_DEFAULT,
} from 'bundles/unified-career-academy/constants';
import { isCareerAcademyFreemiumEnabled } from 'bundles/unified-career-academy/utils/helpers';

type PropsFromWithProps = {
  contentfulSlug: string;
};

// TODO: To regionalize new locales, match country ISO code to contentful slug.
// Ensure to create new contentful item first ("Unified Career Academy - Page Content" Contentful type).
export const getEnterpriseContentfulSlug = (careerAcademyVariant: CareerAcademyVariant) => {
  const isCareerAcademyFreemium = isCareerAcademyFreemiumEnabled(careerAcademyVariant);
  if (isCareerAcademyFreemium) {
    return ENTERPRISE_FREEMIUM_CA_CONTENTFUL_SLUG;
  }
  return ENTERPRISE_PAGE_CONTENT_CONTENTFUL_SLUG_DEFAULT;
};

const withEnterpriseContentfulSlug = () =>
  compose<PropsFromWithProps, CareerAcademyVariant>(
    withProps<PropsFromWithProps, { careerAcademyVariant: CareerAcademyVariant }>(({ careerAcademyVariant }) => {
      return {
        contentfulSlug: getEnterpriseContentfulSlug(careerAcademyVariant),
      };
    })
  );

export default withEnterpriseContentfulSlug;
