/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import classNames from 'classnames';

import { typography2 } from '@coursera/cds-core';
import type { Product } from '@coursera/event-pulse-types';

import { CopySingleResponse } from 'bundles/ai-coach-platform/components/building-blocks';
import { isCoachMessage } from 'bundles/ai-coach-platform/components/patterns/chat/utils/messageUtils';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import type { EventData } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/ai-coach-platform';

import type { MessageTypes } from '../store/types';

const COACH_PRODUCT_REC_PARAM = 'coach-product-recs';

type Props = {
  message: MessageTypes;
};

const styles = {
  root: css`
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p,
    li,
    span {
      ${typography2.bodyPrimary}
      strong {
        font-size: 0.9em;
      }
    }

    p {
      /* shows single line break as new line */
      white-space: pre-wrap;
    }

    pre {
      padding: var(--cds-spacing-100);
      margin: 0 0 var(--cds-spacing-100);
      font-size: var(--cds-font-size-150);
      line-height: var(--cds-line-height-300);
      word-break: break-all;
      word-wrap: break-word;
      background-color: var(--cds-color-emphasis-quaternary-background-xweak);
      color: var(--cds-color-neutral-primary);
      border-radius: var(--cds-border-radius-50);
      overflow-x: auto;
    }

    code {
      padding: var(--cds-spacing-50);
      font-size: var(--cds-font-size-175);
      color: inherit;
      white-space: unset;
      background-color: var(--cds-color-emphasis-quaternary-background-xweak);
      border-radius: var(--cds-border-radius-50);
    }

    img {
      width: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }
  `,
  markdown: css`
    max-width: 100%;
  `,
};

const isContentRecLink = (linkHref: URL) => {
  return linkHref.searchParams.get('linkType') === COACH_PRODUCT_REC_PARAM;
};

const ParagraphRenderer = (props: React.HTMLAttributes<HTMLParagraphElement>) => <p {...props} />;

const ContentRecLinkRenderer = ({ href, children, ...rest }: React.LinkHTMLAttributes<HTMLAnchorElement>) => {
  const linkHref = new URL(href as string);

  const trackingContext = useCoachTrackingContext()?.data;
  const track = useTracker();
  const trackingData: EventData<'view_coach_product_recommendation'> = {
    recommendationType: 'chat_link',
    product: {
      id: linkHref.searchParams.get('productId') as Product['id'],
      type: linkHref.searchParams.get('productType') as Product['type'],
    },
    coach: {
      mode: trackingContext?.mode ?? 'basic',
    },
  };

  const linkVisibilityRef: React.MutableRefObject<HTMLAnchorElement | null> = useVisibilityTracker(
    'view_coach_product_recommendation',
    { ...trackingData }
  );

  const contentRecLinkProps = {
    ref: linkVisibilityRef,
    onClick: () => {
      track('click_coach_product_recommendation', {
        ...trackingData,
      });
    },
  };

  // clean up custom query params before rendering, these are only meant for FE eventing
  linkHref.searchParams.delete('linkType');
  linkHref.searchParams.delete('productType');
  linkHref.searchParams.delete('productId');

  return (
    <a href={linkHref.href} {...contentRecLinkProps} {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const LinkRenderer = ({ href, children, ...rest }: React.LinkHTMLAttributes<HTMLAnchorElement>) => {
  let linkHref: URL;

  try {
    linkHref = new URL(href ?? '');
  } catch {
    // handle any invalid links as plain-text
    return <span>{children}</span>;
  }

  if (isContentRecLink(linkHref)) {
    return (
      <ContentRecLinkRenderer {...rest} href={href}>
        {children}
      </ContentRecLinkRenderer>
    );
  }

  return (
    <a href={linkHref.href} {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

/**
 * Handles reference links as plain-text. We do not support links by reference, which are
 * indicated through square brackets e.g. "reference [1]"
 */
const LinkReferenceRenderer: React.LegacyFunctionComponentWithChildren = ({ children }) => {
  return <React.Fragment>[{children}]</React.Fragment>;
};

const CodeRenderer = ({ value, language = 'Unset' }: { value: string; language?: string }) => {
  const [showCopyButton, setShowCopyButton] = React.useState(false);

  return (
    <div
      css={css`
        position: relative;
        width: auto;

        .coach-copy-single-response {
          position: absolute;
          z-index: 1;
          top: 4px;
          right: 4px;
          width: 32px;
          height: 32px;

          button {
            width: 100%;
            height: 100%;
          }
        }

        /* visually hide copy button, but not from screen readers */
        &.hidden .coach-copy-single-response {
          clip: rect(0 0 0 0);
          clip-path: inset(50%);
          height: 1px;
          overflow: hidden;
          position: absolute;
          white-space: nowrap;
          width: 1px;
        }
      `}
      className={classNames(`language-${language}`, { hidden: !showCopyButton })}
      aria-label={_t('Code block language: #{language}', { language })}
      onMouseEnter={() => setShowCopyButton(true)}
      onMouseLeave={() => setShowCopyButton(false)}
    >
      <pre>{value}</pre>
      {/* wrap the value in the code block tag to be formatted correctly for copy-paste */}
      <CopySingleResponse message={`\`\`\`${language}\n${value}\n\`\`\``} tooltip="Copy code" skipMetadata />
    </div>
  );
};
/**
 * Markdown renderer for Coach messages
 */
const ChatMessage = ({ message }: Props) => {
  return (
    <div
      css={styles.root}
      data-testid="coach-message-markdown"
      className={classNames(
        'ai-coach-chat-message',
        isCoachMessage(message.sender) ? 'coach-response' : 'learner-response'
      )}
    >
      <ReactMarkdown
        css={styles.markdown}
        renderers={{
          link: LinkRenderer,
          linkReference: LinkReferenceRenderer,
          // prevent heading formatting, although we may not need this after Gemini 1.5 [COACH-587]
          heading: ParagraphRenderer,
          paragraph: ParagraphRenderer,
          code: CodeRenderer,
        }}
        source={message.text}
        linkTarget="_blank"
      />
    </div>
  );
};

export default ChatMessage;
