/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { type ButtonProps, Typography2 } from '@coursera/cds-core';

import {
  getLabelVariant,
  getPlaceholderVariant,
  getSelectionVariant,
} from 'bundles/ai-coach-platform/components/patterns/select/utils';

const styles = {
  label: css`
    margin-right: var(--cds-spacing-50);
  `,
  placeholder: css`
    color: var(--cds-color-neutral-primary-weak);
  `,
  disabled: css`
    pointer-events: none;
    color: var(--cds-color-grey-600) !important;
  `,
};

type Props = {
  label: string;
  selection?: string;
  placeholder: string;
  disabled?: boolean;
  size?: ButtonProps['size'];
};

const Selection = ({ label, selection, placeholder, disabled, size = 'medium' }: Props) => {
  const showPlaceholder = !selection;
  const selectionVariant = showPlaceholder ? getPlaceholderVariant(size) : getSelectionVariant(size);
  const labelVariant = getLabelVariant(size);

  return (
    <div>
      <Typography2 variant={labelVariant} component="span" css={[styles.label, disabled && styles.disabled]}>
        {label}
      </Typography2>
      <Typography2
        variant={selectionVariant}
        component="span"
        color={showPlaceholder ? 'supportText' : 'body'}
        css={disabled && styles.disabled}
      >
        {selection ?? placeholder}
      </Typography2>
    </div>
  );
};

export default Selection;
