import type { ProductCard_CanonicalType as ProductCardCanonicalType } from '__generated__/graphql-types';
import moment from 'moment';

import { getTranslatedProductName } from 'bundles/browse/utils';
import type { CardProps } from 'bundles/product-card/components/legacy/types/ProductCard';
import { getTranslatedDifficulty, getTranslatedDurations } from 'bundles/search-common/constants';
import type { LEARNING_PRODUCTS } from 'bundles/search-common/constants';

import _t from 'i18n!nls/product-card';

export const abbreviateLargeNumber = (num: number, locale?: string): string => {
  // eslint-disable-next-line new-cap, @babel/new-cap
  return Intl.NumberFormat(locale, { notation: 'compact' }).format(num);
};

export function getNumberOfReviews(numProductRatings?: number) {
  return numProductRatings && abbreviateLargeNumber(numProductRatings, _t.getLocale());
}

export const getPartnerNames = (partners: { id?: string; logo?: string; name?: string }[]): string[] | undefined => {
  return partners.map((partner) => partner.name).filter(Boolean) as string[];
};

export const getPartnerLogos = (partners: { id?: string; logo?: string; name?: string }[]): string[] | undefined => {
  return partners.map((partner) => partner.logo).filter(Boolean) as string[];
};

export const getAriaLabel = (cardProps: CardProps) => {
  if (!cardProps) return '';

  const {
    name,
    isCostFree,
    productType,
    partners,
    avgProductRating,
    skills,
    numProductRatings,
    productDifficultyLevel,
    productDuration,
    isNewContent,
  } = cardProps;

  let ariaLabel = name || '';
  if (isNewContent) {
    ariaLabel += _t(` new`);
  }

  if (isCostFree) {
    ariaLabel += _t(` free`);
  }

  if (productType) {
    ariaLabel += ` ${getTranslatedProductName(productType)}`;
  }

  const partnerNames = partners && getPartnerNames(partners);
  if (partnerNames && partnerNames.length) {
    ariaLabel += _t(` by #{commaSeparatedPartners},`, { commaSeparatedPartners: partnerNames.join(', ') });
  }

  if (avgProductRating) {
    ariaLabel += _t(` #{fixedAvgProductRating} stars,`, { fixedAvgProductRating: avgProductRating.toFixed(1) });
  }

  if (numProductRatings && numProductRatings >= 5) {
    ariaLabel += _t(` by #{numReviews} reviews,`, { numReviews: getNumberOfReviews(numProductRatings) });
  }

  if (skills) {
    ariaLabel += _t(` provide skills #{commaSeparatedSkills} etc...`, {
      commaSeparatedSkills: skills.slice(0, 3).join(', '),
    });
  }

  if (productDifficultyLevel) {
    ariaLabel += _t(` #{productDifficultyLevel} level,`, {
      productDifficultyLevel: getTranslatedDifficulty(productDifficultyLevel),
    });
  }

  if (productDuration) {
    ariaLabel += ` ${getTranslatedDurations(productDuration) ?? productDuration}`;
  }

  return ariaLabel;
};

export const formatSession = (startsAt?: number, endsAt?: number) => {
  const startDate = moment(startsAt);
  const endDate = moment(endsAt);

  // Format dates as "MMM D" (e.g. "Aug 1")
  const startFormatted = startDate.format('MMM D');
  const endFormatted = endDate.format('MMM D');

  const year = endDate.format('YYYY');

  return `${startFormatted} - ${endFormatted}, ${year}`;
};

export const mapProductToCanonicalType = (
  productType?: (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS]
): ProductCardCanonicalType => {
  switch (productType) {
    case 'COURSE':
    case 'GUIDED PROJECT':
    case 'PROJECT':
      return 'COURSE';
    case 'LESSON':
    case 'VIDEO':
      return 'CLIP';
    case 'SPECIALIZATION':
    case 'PROFESSIONAL CERTIFICATE':
      return 'SPECIALIZATION';
    case 'DEGREE':
    case 'UNIVERSITY CERTIFICATE':
    case 'MASTERTRACK':
    case 'POSTGRADUATE DIPLOMA':
    case 'GRADUATE CERTIFICATE':
      return 'DEGREE';
    default:
      return 'COURSE';
  }
};
