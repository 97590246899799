import * as React from 'react';

import AuthorEvaluatorSectionCaption from 'bundles/author-code-evaluator/components/AuthorEvaluatorSectionCaption';
import AuthorEvaluatorSectionHeader from 'bundles/author-code-evaluator/components/AuthorEvaluatorSectionHeader';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import PreambleEditor from 'bundles/author-code-evaluator/components/PreambleEditor';
import TestCaseHarness from 'bundles/author-code-evaluator/models/TestCaseHarness';
import type { LanguageType } from 'bundles/cml';
import CodeBlockV2 from 'bundles/code-evaluator/components/CodeBlockV2';

import _t from 'i18n!nls/author-code-evaluator';

type Props = {
  onChange: (rawTestCaseHarness: any) => void;
  harness: TestCaseHarness;
  language: LanguageType;
};

const TestCaseHarnessEditor = (props: Props) => {
  const {
    onChange,
    harness: { preamble, testCase },
    language,
  } = props;

  const handleChange = (newPreamble: string, newTestCase: string) => {
    const newHarness = new TestCaseHarness({ preamble: newPreamble, testCase: newTestCase });
    onChange(newHarness.toJSON());
  };

  const handlePreambleChange = (newPreamble: string) => {
    handleChange(newPreamble, testCase);
  };

  const handleTestCaseChange = (newTestCase: string) => {
    handleChange(preamble, newTestCase);
  };

  const renderCodeEditor = () => (
    <CodeBlockV2 readOnly={false} codeLanguage={language} expression={testCase} onChange={handleTestCaseChange} />
  );

  return (
    <div className="rc-TestCaseHarnessEditor">
      <PreambleEditor preamble={preamble} language={language} onChange={handlePreambleChange} />

      <AuthorEvaluatorSectionHeader style={{ paddingTop: 20, paddingBottom: 5 }}>
        {_t('Grading Feedback Code')}
      </AuthorEvaluatorSectionHeader>

      <AuthorEvaluatorSectionCaption style={{ paddingBottom: 10 }}>
        {_t('This code will be used to evaluate and provide feedback to the learner on their submission.')}
      </AuthorEvaluatorSectionCaption>

      {testCase != null && renderCodeEditor()}
    </div>
  );
};

export default TestCaseHarnessEditor;
