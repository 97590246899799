import * as React from 'react';

import Modal from 'bundles/author-common/components/Modal';

import _t from 'i18n!nls/author-code-evaluator';

import 'css!bundles/author-code-evaluator/components/__styles__/AuthorEvaluatorSaveModal';

const AuthorEvaluatorSaveModal = () => {
  return (
    <Modal allowClose={false} withTitle={true} heading={_t('Saving')} className="rc-AuthorEvaluatorSaveModal">
      <div className="horizontal-box align-items-spacearound body-2-text">{_t('Please do not refresh the page.')}</div>
    </Modal>
  );
};

export default AuthorEvaluatorSaveModal;
