import * as React from 'react';
import { useCallback } from 'react';

import { DownloadIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import type { Asset } from 'bundles/cml/shared/types/assetTypes';

import _t from 'i18n!nls/cml';

type Props = {
  asset: Asset;
  title?: string;
};

const DownloadAssetButton: React.FC<Props> = ({ asset, title = _t('Download Attachment') }) => {
  const handleDownload = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    window.open(asset.url.url);
  }, [asset]);

  return (
    <Button
      role="menuitem"
      title={title}
      onClick={handleDownload}
      className="download-menu-button"
      tooltipProps={{ placement: 'bottom' }}
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <DownloadIcon size="small" />
    </Button>
  );
};

export default DownloadAssetButton;
