/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import { Typography2 } from '@coursera/cds-core';

import { useHeaderLevel } from 'bundles/page-config-common/contexts/HeaderLevelContext';

import _t from 'i18n!nls/program-home';

const styles = {
  headingContainer: css`
    max-width: 720px;
    margin-bottom: var(--cds-spacing-300);
  `,
  header: css`
    margin-bottom: var(--cds-spacing-100);
  `,
};

const SkillSetDiscoveryHeader = () => {
  const { currentLevel } = useHeaderLevel();

  return (
    <div css={styles.headingContainer}>
      <Typography2 variant="titleSmall" component={currentLevel}>
        {_t('Get the in-demand skills you need with SkillSets')}
      </Typography2>
    </div>
  );
};

export default SkillSetDiscoveryHeader;
