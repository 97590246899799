import * as React from 'react';

import classNames from 'classnames';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { setLocation } from 'js/lib/coursera.redirect';
import { useRetracked } from 'js/lib/retracked';

import { Link } from '@coursera/cds-core';

import EnrollmentChoiceTypes from 'bundles/enroll-course/common/EnrollmentChoiceTypes';
import {
  choiceTypeToHandleSubmitPromise,
  submitEnrollmentPromise,
} from 'bundles/enroll-course/lib/enrollmentChoiceUtils';
import usePageData from 'bundles/enroll/data/usePageData';
import { useEnrollModalEventing } from 'bundles/enroll/utils/eventingUtils';

import _t from 'i18n!nls/enroll';

import 'css!./__styles__/SubscriptionFooter';

type PropsFromCaller = {
  removeStyles?: boolean;
};

const SubscriptionFooter: React.FC<PropsFromCaller> = ({ removeStyles }) => {
  const { trackEnrollModalContinue } = useEnrollModalEventing();
  const { course, enrollmentAvailableChoices } = usePageData();
  const { canAuditCourse } = enrollmentAvailableChoices;
  const track = useRetracked();

  if (!canAuditCourse) {
    return null;
  }

  const redirectToCourseHome = () => {
    const courseSlug = course?.slug;

    if (courseSlug) {
      return setLocation(`/learn/${courseSlug}/home/welcome`);
    }
    return setLocation('/');
  };

  const onClickAuditCourse = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    track({
      trackingName: 'enroll_subscribe_audit',
      action: 'click',
      trackingData: {
        courseId: course?.id,
      },
    });

    const enrollmentChoiceType = EnrollmentChoiceTypes.AUDIT_COURSE;
    trackEnrollModalContinue(enrollmentChoiceType);
    const handleSubmitPromise = choiceTypeToHandleSubmitPromise[enrollmentChoiceType];
    const options = { courseId: course.id };

    return submitEnrollmentPromise({ handleSubmitPromise, options }).then(redirectToCourseHome);
  };

  const footerClasses = classNames('rc-SubscriptionFooter', {
    subscriptionFooter: !removeStyles,
  });

  return (
    <div className={footerClasses} data-testid="subscription-footer">
      <p className="body-1-text">
        <FormattedMessage
          // eslint-disable-next-line no-restricted-syntax
          message={_t('{audit} the course')}
          audit={
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link component="button" onClick={onClickAuditCourse} data-testid="subscription-footer-audit-button">
              {_t('Audit')}
            </Link>
          }
        />
      </p>
    </div>
  );
};

export default SubscriptionFooter;
