import * as React from 'react';
import { useCallback } from 'react';

import Notification from 'bundles/cml/editor/components/notifications/Notifications';
import { NotificationContext } from 'bundles/cml/editor/context/notificationContext';
import type { NotificationMessage } from 'bundles/cml/editor/types/notification';

export type Props = {
  notification?: NotificationMessage;
  setNotification: (notification: NotificationMessage | undefined) => void;
};

const NotificationContextProvider: React.LegacyFunctionComponentWithChildren<Props> = ({
  notification,
  setNotification,
  children,
}) => {
  const handleDismissNotification = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    () => setNotification(undefined),
    [setNotification]
  );

  return (
    <NotificationContext.Provider value={{ setNotification }}>
      {children}
      <Notification notification={notification} onDismiss={handleDismissNotification} />
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
