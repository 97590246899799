import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { InsertMathIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { insertMath } from 'bundles/cml/editor/components/elements/math/utils';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const { MATH_BLOCK, MATH_INLINE } = BLOCK_TYPES;

const MathButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const disabled = isTypeDisabled(editor, MATH_BLOCK) || isTypeDisabled(editor, MATH_INLINE);
  const active = hasAncestorOfType(editor, [MATH_BLOCK, MATH_INLINE]);

  const handleChange = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => insertMath(staticEditor), [staticEditor]);

  return (
    <Button
      onClick={handleChange}
      disabled={disabled}
      active={active}
      className="rc-MathButton"
      title={_t('Math')}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <InsertMathIcon size="small" />
    </Button>
  );
};

export default MathButton;
