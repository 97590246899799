import * as React from 'react';
import { useCallback, useContext } from 'react';

import { ReactEditor, useReadOnly, useSlateStatic } from 'slate-react';

import { SwapIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { updateAsset } from 'bundles/cml/editor/components/elements/asset/assetUtils';
import { AssetContext } from 'bundles/cml/editor/context/assetContext';
import type { AssetElement, ImageElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  type?: 'asset' | 'image';
  element: AssetElement | ImageElement;
  onClick: () => void;
  title?: string;
};

const SwapAssetButton: React.FC<Props> = ({ type = 'asset', element, onClick, title = _t('Swap Attachment') }) => {
  const staticEditor = useSlateStatic();
  const { setAssetModalOptions } = useContext(AssetContext);

  const readonly = useReadOnly();

  const handleSwap = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    setAssetModalOptions({
      type,
      allowMultiple: false,
      handleSelect: (selected) => {
        ReactEditor.focus(staticEditor);
        updateAsset(staticEditor, element, selected[0]);
        setAssetModalOptions(undefined);
      },
    });

    onClick();
  }, [setAssetModalOptions, onClick, staticEditor, element, type]);

  return (
    <Button
      role="menuitem"
      title={title}
      disabled={readonly}
      onClick={handleSwap}
      className="swap-menu-button"
      tooltipProps={{ placement: 'bottom' }}
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <SwapIcon size="small" />
    </Button>
  );
};

export default SwapAssetButton;
