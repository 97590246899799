import { useCallback } from 'react';

import * as Sentry from '@sentry/react';
import URI from 'jsuri';
import Q from 'q';

import logger from 'js/app/loggerSingleton';
import user from 'js/lib/user';

import { UserPreferenceKeys } from 'bundles/account-settings/constants/UserPreferences';
import { apiClient, getUserPreference } from 'bundles/account-settings/utils/UserPreferenceUtils';
import type { GenericPreference } from 'bundles/naptimejs/resources/__generated__/UserPreferencesV1';

const useTimestampDataFactory = (key: string) => {
  const getTimestamp = useCallback<(...args: $TSFixMe[]) => Q.Promise<$TSFixMe>>(
    () =>
      getUserPreference(UserPreferenceKeys.GENERIC)
        .then((preferences: GenericPreference) => {
          const { userActivityMap } = preferences;
          const returnableTimestamp: number | undefined = userActivityMap?.[key];

          return returnableTimestamp;
        })
        .catch((e) => {
          logger.error('Error retrieving last accessed timestamp.');
          Sentry.captureException(e, {
            extra: { message: `Error retrieving timestamp for key`, key },
          });

          return undefined;
        }),
    [key]
  );

  const setTimestamp = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    const uri = new URI()
      .addQueryParam('action', 'setOrUpdateGenericActivity')
      .addQueryParam('id', user.get().id)
      .addQueryParam('activityId', key);

    return Q(apiClient.post(uri.toString())).fail(() => {
      logger.error('Could not POST user preferences for GENERIC preference key');
    });
  }, [key]);

  return { getTimestamp, setTimestamp };
};

export default useTimestampDataFactory;
