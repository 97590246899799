import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { ItalicsIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { isMarkActive, toggleMark } from 'bundles/cml/editor/utils/markUtils';
import { getToolBarShortcutKey } from 'bundles/cml/editor/utils/toolbarUtils';
import { MARKS } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const ItalicButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const disabled = isTypeDisabled(editor, MARKS.ITALIC);
  const active = isMarkActive(editor, MARKS.ITALIC);

  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    () => toggleMark(staticEditor, MARKS.ITALIC),
    [staticEditor]
  );

  const shortcutKey = getToolBarShortcutKey('I');
  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      active={active}
      className="rc-ItalicButton"
      title={_t('Italics #{shortcutKey}', { shortcutKey })}
      type={TOOLBAR_BUTTON_TYPES.formatting}
    >
      <ItalicsIcon size="small" />
    </Button>
  );
};

export default ItalicButton;
