import config from 'js/app/config';

// TODO(htran) remove after UCI APM Contentful migration is done in GNG-1259
export const UCI_APM_OBJECT_ID = 's12n~kLWnFWsyEeeVdhKUpvOPZg';
export const UCI_APM_IMAGE_URL = `${config.url.resource_assets}search/assets/uci_apm.png`;

// TODO(htran) remove after UCI APM Contentful migration is done in GNG-1259
export const getProductCardImageUrl = (
  id?: string | null,
  imageUrl?: string | null,
  partnerLogos?: string[] | null
): string => {
  if (!id && !imageUrl && !partnerLogos) {
    return '';
  } else if (id && id === UCI_APM_OBJECT_ID) {
    return UCI_APM_IMAGE_URL;
  } else {
    const productImage = imageUrl || '';

    // If image is a gif it is detrimental to performance, serve the partner logo instead.
    if (/\.gif/.test(productImage)) {
      return partnerLogos ? partnerLogos[0] : productImage;
    } else {
      return productImage;
    }
  }
};
