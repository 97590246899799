/**
 * Private module reserved for @coursera/cds-core package.
 */

import { createLargeSvgIcon } from '@core/utils';

export default createLargeSvgIcon('RadioUncheckedIcon', {
  large: (
    <circle
      cx="10"
      cy="10"
      fill="inherit"
      r="9.1666663"
      stroke="currentColor"
      strokeWidth="1.6666666667"
    />
  ),
});
