import * as React from 'react';
import { createContext, useContext } from 'react';

export type Props = {
  isC4C?: boolean;
};

export const ContentCurationContext = createContext<Props>({
  isC4C: undefined,
});

export const useContentCurationContext = () => useContext(ContentCurationContext);

export const ContentCurationContextProvider: React.LegacyFunctionComponentWithChildren<{ isC4C?: boolean }> = ({
  children,
  isC4C,
}) => {
  return <ContentCurationContext.Provider value={{ isC4C }}>{children}</ContentCurationContext.Provider>;
};
