import * as React from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Box, Button, StyleSheet, spacing } from '@coursera/coursera-ui';

import { getManageProgramLearnersLink } from 'bundles/enterprise-admin-navigation/utils/AdminNavigationUtils';

import _t from 'i18n!nls/program-home';

const styles = StyleSheet.create({
  ProgramAdminDenyModalContent: {
    minWidth: spacing.minWidth,
    maxWidth: '100%',
  },
});

type Props = {
  onClose: () => void;
  programSlug: string;

  orgName: string;
  orgSlug: string;
};

const ProgramAdminDenyModal: React.FunctionComponent<Props> = ({
  onClose,
  programSlug,

  orgName,
  orgSlug,
}) => {
  return (
    <Box rootClassName={styles.ProgramAdminDenyModalContent} data-e2e="ProgramAdminDenyModal">
      <Box flexDirection="column">
        <h3>{_t('Join the Program')}</h3>
        <FormattedHTMLMessage
          tagName="p"
          message={_t(
            `You do not have full access to <b>{orgName}</b> Learning Program because you
              have not joined it as a member. To join, you must send yourself an invitation or add
              yourself as a pending member via the Invitation Admin Tool.`
          )}
          orgName={orgName}
        />
        <Box alignItems="center" justifyContent="center" rootClassName="p-a-1">
          <Button
            type="primary"
            tag="a"
            size="md"
            label={_t('Go To Invitations')}
            style={{ marginRight: spacing.md }}
            htmlAttributes={{
              href: getManageProgramLearnersLink({
                thirdPartySlug: orgSlug,
                programSlug,
              }),
              target: '_blank',
              rel: 'noopener noreferrer',
              'data-e2e': 'go-to-invitations-button',
            }}
          />
          <Button type="secondary" size="md" label={_t('Dismiss')} onClick={onClose} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramAdminDenyModal;
