import { flip, limitShift, offset, shift } from '@floating-ui/react-dom';

export const LAYOUTS = {
  DRAWER: 'DRAWER',
  DROPDOWN: 'DROPDOWN',
} as const;

// intentionally not translated product name
export const COACH_LABEL = 'Coach';

export const MIDDLEWARE = [
  offset(4), // sets pixel offset relative to reference element
  shift({ limiter: limitShift() }), // shifts the tooltip along the main axis to keep it in view
  flip(), // changes the placement of the tooltip to the opposite side to keep it in view
];
