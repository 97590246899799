import * as React from 'react';

import classNames from 'classnames';
import { branch, compose } from 'recompose';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import { tupleToStringKey } from 'js/lib/stringKeyTuple';
import user from 'js/lib/user';
import waitForGraphQL from 'js/lib/waitForGraphQL';

import { StyleSheet, color, css } from '@coursera/coursera-ui';

import withEnrollment from 'bundles/enroll/components/xdp/withEnrollment';
import type { PropsFromWithEnrollment } from 'bundles/enroll/components/xdp/withEnrollment';
import { withPrice } from 'bundles/enroll/components/xdp/withNaptimeData';
import QueryProductOwnership from 'bundles/internal-enrollment/queries/QueryProductOwnership.graphql';
import type {
  QueryProductOwnershipQuery,
  QueryProductOwnershipQueryVariables,
} from 'bundles/internal-enrollment/queries/__generated__/QueryProductOwnership';
import { convertProductOwnershipToNaptime } from 'bundles/internal-enrollment/queries/mapProductOwnershipsGraphQLToNaptime';
import type ProductOwnershipsV1 from 'bundles/naptimejs/resources/productOwnerships.v1';
import type PromotionEligibilitiesV1 from 'bundles/naptimejs/resources/promotionEligibilities.v1';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import { SPECIALIZATION } from 'bundles/payments/common/ProductType';
import withPromotionInfo from 'bundles/promotions/components/withPromotionInfo';

import _t from 'i18n!nls/enroll';

const styles = StyleSheet.create({
  enrollmentNote: {
    color: color.secondaryText,
  },
});

type PropsFromCaller = {
  courseId: string;
  s12nId: string;
  promotionEligibilities?: PromotionEligibilitiesV1;
};

type PropsFromWithPrice = {
  multiS12nDerivatives?: Array<S12nDerivativesV1>;
};

type PropsFromWithHocs = PropsFromWithEnrollment & PropsFromWithPrice;

type PropsFromNaptime = {
  productOwnerships?: ProductOwnershipsV1;
};

type PropsToComponent = PropsFromCaller & PropsFromWithHocs & PropsFromNaptime;

class CourseSpecializationEnrollmentNote extends React.Component<PropsToComponent> {
  getEnrollmentNote(): React.ReactNode {
    const { s12nId, enrollmentAvailableChoices, multiS12nDerivatives, productOwnerships, promotionEligibilities } =
      this.props;

    // Don't show if no EAC (user is logged out)
    if (!enrollmentAvailableChoices) {
      return null;
    }

    const {
      canEnrollThroughS12nSubscription,
      canEnrollThroughS12nSubscriptionFreeTrial,
      getCanEnrollThroughS12nSubscriptionFreeTrial,
      isEnrolled,
    } = enrollmentAvailableChoices;

    // Order matters - priority: enrolled > promotion available > free trial available
    // If user is enrolled to the course, indicate if the user is also enrolled to the current s12n
    // Else if user is eligible for a promo, indicate if the current s12n is part of that promo
    // Else if user has a mix of FT and paid enrollment options, indicate if the current s12n has FT
    // Else if user only has paid enrollment options, indicate the price of the current s12n
    if (isEnrolled && productOwnerships && productOwnerships.owns) {
      return _t('Enrolled');
    } else if (promotionEligibilities && promotionEligibilities.isEligible) {
      return _t('Promotion available');
    } else if (canEnrollThroughS12nSubscriptionFreeTrial && canEnrollThroughS12nSubscription) {
      const enrollmentData = enrollmentAvailableChoices.getS12nSubscriptionEnrollmentData(s12nId);
      const canEnrollWithFT = enrollmentData && getCanEnrollThroughS12nSubscriptionFreeTrial(enrollmentData);
      return canEnrollWithFT ? _t('7-day free trial available') : null;
    } else if (canEnrollThroughS12nSubscription && !canEnrollThroughS12nSubscriptionFreeTrial) {
      const s12nDerivative = multiS12nDerivatives && multiS12nDerivatives.find(({ id }) => id === s12nId);
      const price = s12nDerivative && s12nDerivative.catalogPrice;
      return price ? (
        <FormattedMessage
          message={_t('{price}/mo')}
          price={<ReactPriceDisplay value={price.amount} currency={price.currencyCode} hideCurrencyCode={true} />}
        />
      ) : null;
    }

    return null;
  }

  render() {
    const enrollmentNote = this.getEnrollmentNote();

    if (!enrollmentNote) {
      return null;
    }

    const wrapperClass = classNames('rc-CourseSpecializationEnrollmentNote', css(styles.enrollmentNote).className);

    return <span className={wrapperClass}>&nbsp;({enrollmentNote})</span>;
  }
}

const EnhancedCourseSpecializationEnrollmentNote = compose<PropsToComponent, PropsFromCaller>(
  withEnrollment(),
  withPrice(),
  branch<PropsFromCaller & PropsFromWithHocs>(
    ({ enrollmentAvailableChoices }) => !!enrollmentAvailableChoices?.isEnrolled,
    waitForGraphQL<
      PropsFromCaller & PropsFromWithHocs,
      QueryProductOwnershipQuery,
      QueryProductOwnershipQueryVariables,
      PropsFromCaller & PropsFromWithHocs & PropsFromNaptime
    >(QueryProductOwnership, {
      props: ({ data, ownProps }) => {
        return { ...ownProps, productOwnerships: convertProductOwnershipToNaptime(data) };
      },
      options: ({ s12nId }) => ({
        variables: {
          productOwnershipId: tupleToStringKey([user.get().id, SPECIALIZATION, s12nId]),
        },
        context: { clientName: 'gatewayGql' },
      }),
    })
  ),
  withPromotionInfo()
)(CourseSpecializationEnrollmentNote);

const CourseSpecializationEnrollmentNoteHookContainer = (props: PropsFromCaller) => {
  if (!user.isAuthenticatedUser()) {
    return null;
  }
  return <EnhancedCourseSpecializationEnrollmentNote {...props} />;
};

export default CourseSpecializationEnrollmentNoteHookContainer;
