/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';

import type { Theme } from '@coursera/cds-core';
import { Tag, Typography2 } from '@coursera/cds-core';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import ConfigurationEditor from 'bundles/author-widget/components/ConfigurationEditor';
import type { EditorProps } from 'bundles/cml/shared/components/widget/dialog/WidgetConfigDialog';
import { useCourseContext } from 'bundles/cml/shared/hooks/context';
import type { WidgetSession, WidgetSessionDraftConfig } from 'bundles/cml/shared/types/widgetManager';

import _t from 'i18n!nls/cml';

const styles = {
  editor: css`
    padding: 0 !important;
    height: 160px;
    background-color: var(--cds-color-neutral-background-primary);

    .rc-CodeBlock {
      height: 100%;
    }
  `,
  supportText: css`
    margin-bottom: var(--cds-spacing-200);
  `,
  error: css`
    margin-bottom: var(--cds-spacing-100);
    width: 100%;
  `,
  readonly: css`
    pointer-events: none;
    background-color: var(--cds-color-neutral-background-primary-weak) !important;

    .monaco-editor-background,
    .margin {
      background-color: var(--cds-color-neutral-background-primary-weak) !important;
    }
  `,
};

type Props = EditorProps & {
  widgetSession: WidgetSession;
};

const AdvancedEditor: React.FC<Props> = ({
  widgetSession,
  onConfigChange,
  setSaveEnabled,
  config,
  hasSimpleEditorOption,
  isExternalUrl,
  readonly,
}) => {
  const [error, setError] = useState(false);
  // where courseId is grabbed from in useWidget util.
  const { courseContext } = useCourseContext();
  const courseId = useMemo(() => courseContext?.courseId ?? '', [courseContext?.courseId]);

  const handleChange = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (value: WidgetSessionDraftConfig) => {
      const newConfig = { ...value };
      // there's not enough validation on the BE to prevent these values so we remove them in case of any funny business.
      if (!hasSimpleEditorOption) {
        delete newConfig.videoId;
      }
      if (!isExternalUrl) {
        delete newConfig.url;
      }
      onConfigChange(newConfig);
      setSaveEnabled(true);
    },
    [onConfigChange, setSaveEnabled, hasSimpleEditorOption, isExternalUrl]
  );

  const catchInvalidJson = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (value: boolean) => {
      setError(value);
      setSaveEnabled(false);
    },
    [setError, setSaveEnabled]
  );

  const updatedWidgetSession = useMemo(() => {
    return {
      ...widgetSession,
      draft: {
        ...widgetSession.draft,
        configuration: config,
      },
    };
  }, [widgetSession, config]);

  return (
    <React.Fragment>
      <Typography2 component="p" variant="bodySecondary" css={styles.supportText}>
        {_t('Edit this configuration JSON to set up your plugin.')}
      </Typography2>
      {error && (
        <Tag variant="status" priority="secondary" color="error" css={styles.error}>
          {_t('Invalid JSON')}
        </Tag>
      )}
      <ConfigurationEditor
        widgetSessionProviderDraft={updatedWidgetSession}
        catchInvalidJsonError={catchInvalidJson}
        readOnly={readonly ?? false}
        hasCustomLabel={true}
        css={[styles.editor, readonly && styles.readonly]}
        onChange={handleChange}
        courseId={courseId}
      />
    </React.Fragment>
  );
};

export default AdvancedEditor;
