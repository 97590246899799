import * as React from 'react';
import { useCallback } from 'react';

import useFluxibleAction from 'js/lib/fluxibleHooks/useFluxibleAction';
import useFluxibleStore from 'js/lib/fluxibleHooks/useFluxibleStore';

import { updateAssetData } from 'bundles/asset-admin/actions/AssetAdminActions';
import AssetAdminStore from 'bundles/asset-admin/stores/AssetAdminStore';
import ImageDescriptionDialogComponent from 'bundles/cml/editor/components/elements/image/dialogs/ImageDescriptionDialog';
import { ImageContext, type Props } from 'bundles/cml/editor/context/imageContext';
import type { UpdateAssetDataPayload } from 'bundles/cml/editor/types/assetTypes';

const ImageDescriptionDialog: React.LegacyFunctionComponentWithChildren<Props> = ({ asset, onClose }) => {
  const executeAction = useFluxibleAction();
  const { updateDataFailed } = useFluxibleStore(AssetAdminStore, (store) => ({
    updateDataFailed: store.updateDataFailed,
  }));
  const updateAssetDataFn = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (payload: UpdateAssetDataPayload) => executeAction(updateAssetData, payload),
    [executeAction]
  );

  return (
    <ImageDescriptionDialogComponent
      asset={asset}
      onClose={onClose}
      updateDataFailed={updateDataFailed}
      updateAssetData={updateAssetDataFn}
    />
  );
};

const ImageContextProvider: React.LegacyFunctionComponentWithChildren = ({ children }) => {
  return <ImageContext.Provider value={{ ImageDescriptionDialog }}>{children}</ImageContext.Provider>;
};

export default ImageContextProvider;
