import { forwardRef } from 'react';

import type { BaseComponentProps } from '@coursera/cds-common';
import { useLocalizedStringFormatter } from '@coursera/cds-common';
import { MoreActionsIcon } from '@coursera/cds-icons';

import type { ButtonProps } from '@core/Button';
import Button from '@core/Button';
import { IconButton } from '@core/IconButton';

import i18nMessages from './i18n';

type Props = {
  invert?: boolean;
  expanded: boolean;
  size: ButtonProps['size'];
} & BaseComponentProps<'button'>;

const ActionOverflowMenuTrigger = forwardRef<HTMLButtonElement, Props>(
  function ActionOverflowMenuTrigger(props, ref) {
    const stringFormatter = useLocalizedStringFormatter(i18nMessages);

    const { children, invert, expanded, ...rest } = props;

    const buttonProps: Partial<Omit<ButtonProps, 'icon'>> = {
      ref,
      ['aria-expanded']: expanded,
      ['aria-haspopup']: true,
      variant: invert ? 'ghostInvert' : 'ghost',
      ...rest,
    };

    if (!children) {
      return (
        <IconButton
          aria-label={stringFormatter.format('more')}
          {...buttonProps}
          intent="more"
          size={buttonProps.size}
        />
      );
    }

    return (
      <Button {...buttonProps} icon={<MoreActionsIcon />}>
        {children}
      </Button>
    );
  }
);

export default ActionOverflowMenuTrigger;
