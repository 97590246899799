import React from 'react';

import { useRadioGroup } from '@material-ui/core/RadioGroup';

import { useFocusRing } from '@react-aria/focus';
import { usePress } from '@react-aria/interactions';
import clsx from 'clsx';

import { useId, mergeProps, IconContext } from '@coursera/cds-common';

import getFormCardCss, { classes } from '@core/forms/getFormCardCss';
import { RadioInput } from '@core/forms/RadioInput';
import type { RadioProps } from '@core/Radio';
import Typography from '@core/Typography2';

export type Props = RadioProps;

/**
 * RadioCard is a styled as a card Radio component
 *
 * See [Props](__storybookUrl__/components-inputs-radio--default#props)
 */
const RadioCard = React.forwardRef<HTMLLabelElement, Props>(function RadioCard(
  props,
  ref
) {
  const {
    id: idFromProps,
    icon,
    children,
    label,
    supportText,
    disabled,
    onChange,
    value,
    name,
    inputRef,
    inputProps,
    validationStatus,
    className,
    ...rest
  } = props;

  const radioGroup = useRadioGroup();

  const { isFocusVisible, focusProps } = useFocusRing({
    within: true,
  });
  const { isPressed, pressProps } = usePress({});

  let checked = props.checked;

  // Use props if component is not in the RadioGroupContext provided by MUI
  // @link https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Radio/Radio.js#L75
  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = radioGroup.value === props.value;
    }
  }

  const id = useId(idFromProps);
  const labelTextId = `${id}-label`;
  const supportTextId = supportText ? `${id}-support` : undefined;

  return (
    <label
      ref={ref}
      className={clsx(className, classes.label, {
        [classes.checked]: checked,
        [classes.disabled]: disabled,
        [classes.success]: validationStatus === 'success',
        [classes.error]: validationStatus === 'error',
        [classes.pressed]: isPressed,
        [classes.focusVisible]: isFocusVisible,
        [classes.hasIcon]: !!icon,
      })}
      css={getFormCardCss}
      id={id}
      {...mergeProps(focusProps, pressProps, rest)}
    >
      <RadioInput
        disableFocusVisible
        checked={checked}
        className={classes.input}
        disabled={disabled}
        inputProps={{
          'aria-labelledby': supportTextId
            ? `${labelTextId} ${supportTextId}`
            : labelTextId,
          ...inputProps,
        }}
        inputRef={inputRef}
        name={name}
        value={value}
        onChange={onChange}
      />

      {icon && (
        <div className={classes.icon}>
          <IconContext.Provider value={{ size: 'medium' }}>
            {icon}
          </IconContext.Provider>
        </div>
      )}

      <Typography
        className={classes.labelText}
        component="span"
        id={labelTextId}
        variant="bodyPrimary"
      >
        {label}
      </Typography>

      {supportText && (
        <Typography
          className={classes.supportText}
          component="div"
          id={supportTextId}
          variant="bodySecondary"
        >
          {supportText}
        </Typography>
      )}

      {children && (
        <div className={classes.nonInteractiveSpace}>{children}</div>
      )}
    </label>
  );
});

export default RadioCard;
