import * as React from 'react';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Typography2 } from '@coursera/cds-core';
import { SuccessIcon } from '@coursera/cds-icons';

import CourseActionBox from 'bundles/course-cards/components/course-card/enterprise/CourseActionBox';
import type { PropsForWithModal as PropsForCourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import { CourseAndPartnerNameWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseAndPartnerName';
import CourseHomeLink from 'bundles/course-cards/components/course-card/enterprise/CourseHomeLink';
import { CourseS12nPhotoWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseS12nPhoto';
import ViewCertificateLink from 'bundles/course-cards/components/course-card/enterprise/ViewCertificateLink';
import { DESCRIPTION_PAGE_PATHS } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';

import _t from 'i18n!nls/course-cards';

type PropsFromCaller = {
  course: CoursesV1;
  membershipId?: string;
  completedAt?: string;
};

type Props = PropsFromCaller &
  Omit<PropsForCourseAndPartnerNameWithModal, 'courseId' | 'courseName' | 'partnerIds' | 'miniModalProps'>;

const CourseCompletedCard = ({
  // Used as a discard.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  course,
  course: { id, photoUrl, slug, name, partnerIds, homeLink, isInactive },
  membershipId,
  completedAt,
  ...rest
}: Props): JSX.Element => {
  const miniModalProps = {
    isCourse: true,
    id,
    tag: undefined,
    unifiedDescriptionPageProps: { slug, productPagePath: DESCRIPTION_PAGE_PATHS.course },
  };

  return (
    <div className="rc-CourseCompletedCard">
      <div className="horizontal-box">
        <CourseS12nPhotoWithModal
          imgixClassName="course-logo"
          imageSrc={photoUrl}
          slug={slug}
          id={id}
          title={name}
          miniModalProps={miniModalProps}
        />
        <div className="rc-CourseEnrolledDefaultCard row flex-1">
          <div className="col-xs-12 col-sm-8 col-md-9">
            <CourseAndPartnerNameWithModal
              courseId={id}
              courseName={name}
              partnerIds={partnerIds}
              miniModalProps={miniModalProps}
              {...rest}
            />
            <div className="vertical-bo">
              <span className="horizontal-box align-items-vertical-center font-sm">
                <SuccessIcon color="success" size="large" className="m-r-1s" />
                {completedAt && (
                  <Typography2 component="span">
                    <FormattedMessage message={_t('Completed on {date}')} date={completedAt} />
                  </Typography2>
                )}
              </span>
              <span className="horizontal-box align-items-vertical-center wrap">
                {membershipId && <ViewCertificateLink membershipId={membershipId} />}
              </span>
            </div>
          </div>
          <CourseActionBox>
            {!isInactive && <CourseHomeLink homeLink={homeLink} label={_t('Review')} courseName={name} />}
          </CourseActionBox>
        </div>
      </div>
    </div>
  );
};

export default CourseCompletedCard;
