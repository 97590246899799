import _t from 'i18n!nls/enterprise-admin-grow-with-google';

export const getSurveyIncompleteError = () => {
  return {
    title: _t('Please fill out the survey to continue'),
    description: _t('The survey questions are required.'),
  };
};

export const getNoDomainAddedError = () => {
  return {
    title: _t('Please add a domain to continue'),
    description: _t(
      'Add domain name(s) associated to your organization such as coursera.org. Avoid adding free domain names as they may result in licenses being occupied by members outside your organization.'
    ),
  };
};

export const BaseCertificateTitles = {
  IT_AUTOMATION_WITH_PYTHON: 'IT_AUTOMATION_WITH_PYTHON',

  ADVANCED_DATA_ANALYTICS: 'ADVANCED_DATA_ANALYTICS',

  BUSINESS_INTELLIGENCE: 'BUSINESS_INTELLIGENCE',

  DATA_ANALYTICS: 'DATA_ANALYTICS',
  DATA_ANALYTICS_SPANISH: 'DATA_ANALYTICS_SPANISH',
  DATA_ANALYTICS_PORTUGESE: 'DATA_ANALYTICS_PORTUGESE',
  DATA_ANALYTICS_GERMAN: 'DATA_ANALYTICS_GERMAN',
  DATA_ANALYTICS_EU_FRENCH: 'DATA_ANALYTICS_EU_FRENCH',
  DATA_ANALYTICS_ARABIC: 'DATA_ANALYTICS_ARABIC',
  DATA_ANALYTICS_BAHASA: 'DATA_ANALYTICS_BAHASA',
  DATA_ANALYTICS_JAPANESE: 'DATA_ANALYTICS_JAPANESE',

  PROJECT_MANAGEMENT: 'PROJECT_MANAGEMENT',
  PROJECT_MANAGEMENT_SPANISH: 'PROJECT_MANAGEMENT_SPANISH',
  PROJECT_MANAGEMENT_PORTUGESE: 'PROJECT_MANAGEMENT_PORTUGESE',
  PROJECT_MANAGEMENT_GERMAN: 'PROJECT_MANAGEMENT_GERMAN',
  PROJECT_MANAGEMENT_EU_FRENCH: 'PROJECT_MANAGEMENT_EU_FRENCH',
  PROJECT_MANAGEMENT_CA_FRENCH: 'PROJECT_MANAGEMENT_CA_FRENCH',
  PROJECT_MANAGEMENT_ARABIC: 'PROJECT_MANAGEMENT_ARABIC',
  PROJECT_MANAGEMENT_TURKISH: 'PROJECT_MANAGEMENT_TURKISH',

  UX_DESIGN: 'UX_DESIGN',
  UX_DESIGN_SPANISH: 'UX_DESIGN_SPANISH',
  UX_DESIGN_PORTUGESE: 'UX_DESIGN_PORTUGESE',
  UX_DESIGN_GERMAN: 'UX_DESIGN_GERMAN',
  UX_DESIGN_EU_FRENCH: 'UX_DESIGN_EU_FRENCH',
  UX_DESIGN_ARABIC: 'UX_DESIGN_ARABIC',
  UX_DESIGN_BAHASA: 'UX_DESIGN_BAHASA',

  IT_SUPPORT: 'IT_SUPPORT',
  IT_SUPPORT_SPANISH: 'IT_SUPPORT_SPANISH',
  IT_SUPPORT_GERMAN: 'IT_SUPPORT_GERMAN',
  IT_SUPPORT_BRAZILIAN_PORTUGESE: 'IT_SUPPORT_BRAZILIAN_PORTUGESE',
  IT_SUPPORT_EUROPEAN_PORTUGESE: 'IT_SUPPORT_EUROPEAN_PORTUGESE',
  IT_SUPPORT_RUSSIAN: 'IT_SUPPORT_RUSSIAN',
  IT_SUPPORT_ARABIC: 'IT_SUPPORT_ARABIC',
  IT_SUPPORT_EU_FRENCH: 'IT_SUPPORT_EU_FRENCH',
  IT_SUPPORT_CA_FRENCH: 'IT_SUPPORT_CA_FRENCH',
  IT_SUPPORT_BAHASA: 'IT_SUPPORT_BAHASA',
  IT_SUPPORT_UKRAINIAN: 'IT_SUPPORT_UKRAINIAN',

  DIGITAL_MARKETING: 'DIGITAL_MARKETING',
  DIGITAL_MARKETING_UKRAINIAN: 'DIGITAL_MARKETING_UKRAINIAN',

  CYBERSECURITY: 'CYBERSECURITY',
};

export const getCertificateTitle = (certificateTitle: string) => {
  if (certificateTitle.toLowerCase().endsWith('spanish')) {
    return _t('Spanish');
  } else if (certificateTitle.toLowerCase().endsWith('portugese')) {
    if (certificateTitle.toLowerCase().endsWith('european_portugese')) {
      return _t('Portugese (European)');
    } else if (certificateTitle.toLowerCase().endsWith('brazilian_portugese')) {
      return _t('Portugese (Brazilian)');
    } else {
      return _t('Portugese');
    }
  } else if (certificateTitle.toLowerCase().endsWith('german')) {
    return _t('German');
  } else if (certificateTitle.toLowerCase().endsWith('eu_french')) {
    return _t('EU French');
  } else if (certificateTitle.toLowerCase().endsWith('ca_french')) {
    return _t('CA French');
  } else if (certificateTitle.toLowerCase().endsWith('arabic')) {
    return _t('Arabic');
  } else if (certificateTitle.toLowerCase().endsWith('bahasa')) {
    return _t('Bahasa');
  } else if (certificateTitle.toLowerCase().endsWith('japanese')) {
    return _t('Japanese');
  } else if (certificateTitle.toLowerCase().endsWith('ukrainian')) {
    return _t('Ukrainian');
  } else if (certificateTitle.toLowerCase().endsWith('turkish')) {
    return _t('Turkish');
  }

  switch (certificateTitle) {
    case BaseCertificateTitles.IT_SUPPORT:
      return _t('IT Support');
    case BaseCertificateTitles.IT_AUTOMATION_WITH_PYTHON:
      return _t('IT Automation with Python');
    case BaseCertificateTitles.DATA_ANALYTICS:
      return _t('Data Analytics');
    case BaseCertificateTitles.PROJECT_MANAGEMENT:
      return _t('Project Management');
    case BaseCertificateTitles.UX_DESIGN:
      return _t('UX Design');
    case BaseCertificateTitles.DIGITAL_MARKETING:
      return _t('Digital Marketing & E-commerce');
    case BaseCertificateTitles.ADVANCED_DATA_ANALYTICS:
      return _t('Advanced Data Analytics');
    case BaseCertificateTitles.BUSINESS_INTELLIGENCE:
      return _t('Business Intelligence');
    case BaseCertificateTitles.CYBERSECURITY:
      return _t('Cybersecurity');
    default:
      return _t('IT Support');
  }
};

export const googleCertificatesBase = {
  // STANDALONE
  '7_nEU3iaEeiVXgoT1iWlYg': {
    title: BaseCertificateTitles.IT_AUTOMATION_WITH_PYTHON,
  },
  'elzGL0l-EeyHXRKqb0U9Hw': {
    title: BaseCertificateTitles.BUSINESS_INTELLIGENCE,
  },
  'kx6DXkl-EeyQ6Qp2PlG9FQ': {
    title: BaseCertificateTitles.ADVANCED_DATA_ANALYTICS,
  },
  'Dy6K-2UKEe2PIRJn6nL9pQ': {
    title: BaseCertificateTitles.CYBERSECURITY,
  },

  // DATA ANALYTICS
  kr43OcbTEeqeNBKhfgCLyw: {
    title: BaseCertificateTitles.DATA_ANALYTICS,
    hasLanguage: true,
  },
  hXUHfDgkEeylXgqHwJpmyQ: {
    baseCertificateId: 'kr43OcbTEeqeNBKhfgCLyw',
    title: BaseCertificateTitles.DATA_ANALYTICS_SPANISH,
  },
  '7xSggzgjEeyqUA7_yMmHRQ': {
    baseCertificateId: 'kr43OcbTEeqeNBKhfgCLyw',
    title: BaseCertificateTitles.DATA_ANALYTICS_PORTUGESE,
  },
  '3rZfdTgiEeylXgqHwJpmyQ': {
    baseCertificateId: 'kr43OcbTEeqeNBKhfgCLyw',
    title: BaseCertificateTitles.DATA_ANALYTICS_GERMAN,
  },
  gqM_HzhIEeygcBLCZu413w: {
    baseCertificateId: 'kr43OcbTEeqeNBKhfgCLyw',
    title: BaseCertificateTitles.DATA_ANALYTICS_EU_FRENCH,
  },
  MlfP5CfxEey4dA6DVmO2QQ: {
    baseCertificateId: 'kr43OcbTEeqeNBKhfgCLyw',
    title: BaseCertificateTitles.DATA_ANALYTICS_ARABIC,
  },
  WhulIDghEeylXgqHwJpmyQ: {
    baseCertificateId: 'kr43OcbTEeqeNBKhfgCLyw',
    title: BaseCertificateTitles.DATA_ANALYTICS_BAHASA,
  },
  aJbRXK7pEey8UArux7BqFw: {
    baseCertificateId: 'kr43OcbTEeqeNBKhfgCLyw',
    title: BaseCertificateTitles.DATA_ANALYTICS_JAPANESE,
  },

  // PROJECT MANAGEMENT
  fq9UWMbTEeqpthJ2RmWGow: {
    title: BaseCertificateTitles.PROJECT_MANAGEMENT,
    hasLanguage: true,
  },
  uH4PMjgkEeyveAoAZrlzUQ: {
    baseCertificateId: 'fq9UWMbTEeqpthJ2RmWGow',
    title: BaseCertificateTitles.PROJECT_MANAGEMENT_SPANISH,
  },
  KDD9DDgkEeylXgqHwJpmyQ: {
    baseCertificateId: 'fq9UWMbTEeqpthJ2RmWGow',
    title: BaseCertificateTitles.PROJECT_MANAGEMENT_PORTUGESE,
  },
  GHJWlDgjEeygcBLCZu413w: {
    baseCertificateId: 'fq9UWMbTEeqpthJ2RmWGow',
    title: BaseCertificateTitles.PROJECT_MANAGEMENT_GERMAN,
  },
  '8nJx1DhIEeyveAoAZrlzUQ': {
    baseCertificateId: 'fq9UWMbTEeqpthJ2RmWGow',
    title: BaseCertificateTitles.PROJECT_MANAGEMENT_EU_FRENCH,
  },
  lWdUezjREeygcBLCZu413w: {
    baseCertificateId: 'fq9UWMbTEeqpthJ2RmWGow',
    title: BaseCertificateTitles.PROJECT_MANAGEMENT_CA_FRENCH,
  },
  '298DVzhEEeyqUA7_yMmHRQ': {
    baseCertificateId: 'fq9UWMbTEeqpthJ2RmWGow',
    title: BaseCertificateTitles.PROJECT_MANAGEMENT_ARABIC,
  },
  di7FZTgjEeyCmhJ_shlqow: {
    baseCertificateId: 'fq9UWMbTEeqpthJ2RmWGow',
    title: BaseCertificateTitles.PROJECT_MANAGEMENT_TURKISH,
  },

  // UX DESIGN
  'Z-5wCcbTEeqeNBKhfgCLyw': {
    title: BaseCertificateTitles.UX_DESIGN,
    hasLanguage: true,
  },
  '8ZIHaDgkEeym8A7cXo-JEw': {
    title: BaseCertificateTitles.UX_DESIGN_SPANISH,
    baseCertificateId: 'Z-5wCcbTEeqeNBKhfgCLyw',
  },
  'VMM1lDgkEeym8A7cXo-JEw': {
    title: BaseCertificateTitles.UX_DESIGN_PORTUGESE,
    baseCertificateId: 'Z-5wCcbTEeqeNBKhfgCLyw',
  },
  Q69erTgjEeygcBLCZu413w: {
    title: BaseCertificateTitles.UX_DESIGN_GERMAN,
    baseCertificateId: 'Z-5wCcbTEeqeNBKhfgCLyw',
  },
  H_ot1jhJEeyCmhJ_shlqow: {
    title: BaseCertificateTitles.UX_DESIGN_EU_FRENCH,
    baseCertificateId: 'Z-5wCcbTEeqeNBKhfgCLyw',
  },
  aotozDhGEeyqUA7_yMmHRQ: {
    title: BaseCertificateTitles.UX_DESIGN_ARABIC,
    baseCertificateId: 'Z-5wCcbTEeqeNBKhfgCLyw',
  },
  // this is not launched yet
  // etcCTmH7Ee2t5w5TgixGXw: {
  //   title: BaseCertificateTitles.UX_DESIGN_BAHASA,
  //   baseCertificateId: 'Z-5wCcbTEeqeNBKhfgCLyw',
  // },

  // IT SUPPORT
  '7lHCSlFIEeeffRIHljDI_g': {
    title: BaseCertificateTitles.IT_SUPPORT,
    hasLanguage: true,
  },
  uvWXX6NMEem8VwqbwgpIcA: {
    title: BaseCertificateTitles.IT_SUPPORT_SPANISH,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  CzXJiZvMEeuPzw6FiK7eGQ: {
    title: BaseCertificateTitles.IT_SUPPORT_GERMAN,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  QE4tKYGmEem2fgrSf7QW9A: {
    title: BaseCertificateTitles.IT_SUPPORT_BRAZILIAN_PORTUGESE,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  M8RS7JvMEeuGcAoTbllJww: {
    title: BaseCertificateTitles.IT_SUPPORT_EUROPEAN_PORTUGESE,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  u3VzKDhIEeyCmhJ_shlqow: {
    title: BaseCertificateTitles.IT_SUPPORT_EU_FRENCH,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  'XD7DhjjREeym8A7cXo-JEw': {
    title: BaseCertificateTitles.IT_SUPPORT_CA_FRENCH,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  keawlRvDEeyefgqTxeev3Q: {
    title: BaseCertificateTitles.IT_SUPPORT_BAHASA,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  '-i1_MszCEeybkQrr5PDszw': {
    title: BaseCertificateTitles.IT_SUPPORT_UKRAINIAN,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  H6Nu8pvMEeu8PQ4qCIcxVQ: {
    title: BaseCertificateTitles.IT_SUPPORT_RUSSIAN,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },
  '3_Ysi5FsEeuqgw4hkHF3Iw': {
    title: BaseCertificateTitles.IT_SUPPORT_ARABIC,
    baseCertificateId: '7lHCSlFIEeeffRIHljDI_g',
  },

  // DIGITAL MARKETING
  'aYYrIEl-EeyCjQ5Y8Mzdsw': {
    title: BaseCertificateTitles.DIGITAL_MARKETING,
    hasLanguage: true,
  },
  '2iG4GMzBEeyneQrq9vwxIw': {
    title: BaseCertificateTitles.DIGITAL_MARKETING_UKRAINIAN,
    baseCertificateId: 'aYYrIEl-EeyCjQ5Y8Mzdsw',
  },
} as Record<string, { title: string; hasLanguage?: boolean; baseCertificateId?: string }>;

// TODO: this should also be replaced by the language ids returned from withGoogleCertificates
export const getITSupportCertOtherLanguages = (): Record<string, { language: string; productId: string }> => ({
  SPANISH: {
    language: _t('Spanish'),
    productId: 'uvWXX6NMEem8VwqbwgpIcA',
  },
  GERMAN: {
    language: _t('German'),
    productId: 'CzXJiZvMEeuPzw6FiK7eGQ',
  },
  BRAZILIAN_PORTUGESE: {
    language: _t('Portugese (Brazilian)'),
    productId: 'QE4tKYGmEem2fgrSf7QW9A',
  },
  EUROPEAN_PORTUGESE: {
    language: _t('Portugese (Eurpoean)'),
    productId: 'M8RS7JvMEeuGcAoTbllJww',
  },
  RUSSIAN: {
    language: _t('Russian'),
    productId: 'H6Nu8pvMEeu8PQ4qCIcxVQ',
  },
  ARABIC: {
    language: _t('Arabic'),
    productId: '3_Ysi5FsEeuqgw4hkHF3Iw',
  },
});

export const getGwGDPAText = () => ({
  header: _t('Privacy terms and agreement for online training'),
  body: _t(
    'Welcome to your Coursera Enterprise administrator account! Please review the <a href={LINK} target="_blank"> Privacy Terms for Online Training</a> and check the box to continue.'
  ),
  checkbox: _t(
    'By checking this box I confirm that I read and agree with the <a href={LINK} target="_blank"> Coursera Privacy Terms for Online Training</a>.'
  ),
  programCheckbox: '',
  agreementTermsLink: '/business/googlecloud-terms',
});

export const gwgContractTags: Array<string> = ['GWG_CC', 'GWG_ENTERPRISE', 'GWG_SMB', 'GOOGLE_SCHOLARSHIP'];
