/**
 * Private module reserved for @coursera/cds-core package.
 */
import { createLargeSvgIcon } from '@core/utils';

export default createLargeSvgIcon('CheckboxCheckedIcon', {
  large: (
    <>
      <rect fill="currentColor" height="20" rx="1.6666666667" width="20" />
      <path
        d="M8.10417 12.3128L14.3333 6.08366C14.4849 5.93088 14.6616 5.85449 14.8635 5.85449C15.0656 5.85449 15.2431 5.93088 15.3958 6.08366C15.5486 6.23644 15.625 6.41408 15.625 6.61658C15.625 6.81908 15.5513 6.99401 15.404 7.14137L8.64583 13.917C8.49431 14.0698 8.31757 14.1462 8.11562 14.1462C7.91354 14.1462 7.73611 14.0698 7.58333 13.917L4.60417 10.9378C4.45139 10.785 4.375 10.608 4.375 10.4066C4.375 10.2052 4.45139 10.0281 4.60417 9.87533C4.75694 9.72255 4.93521 9.64616 5.13896 9.64616C5.34257 9.64616 5.51847 9.72255 5.66667 9.87533L8.10417 12.3128Z"
        fill="#fff"
      />
    </>
  ),
});
