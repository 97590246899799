/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';
import type { TrackingData } from 'js/lib/retracked';

import type { Theme } from '@coursera/cds-core';
import { VisuallyHidden } from '@coursera/cds-core';

import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { getScoreLevel } from 'bundles/skills-common/private/progress-tracker/utils/scoreLevel';

import _t from 'i18n!nls/skills-common';

const styles = {
  contentContainer: (theme: Theme) =>
    css({
      padding: 'var(--cds-spacing-150)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    }),
  score: css({
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 1,
  }),
  simpleDescription: (theme: Theme) =>
    css({
      fontSize: 14,
      marginBottom: 'var(--cds-spacing-50)',
      marginTop: 'var(--cds-spacing-50)',
      textAlign: 'start',
      fontWeight: 600,
    }),
  fullDescription: (theme: Theme) =>
    css({
      fontSize: 14,
      color: 'var(--cds-color-grey-600)',
      textAlign: 'start',
    }),
  scoringLabelContainer: (theme: Theme) =>
    css({
      backgroundColor: 'var(--cds-color-grey-50)',
      marginLeft: 'var(--cds-spacing-100)',
      borderRadius: 'var(--cds-spacing-50)',
      display: 'flex',
      padding: `0 var(--cds-spacing-50)`,
    }),
  scoringLabel: css({
    fontSize: '0.75rem',
    fontWeight: 600,
  }),
  scoreContainer: css({
    display: 'flex',
    flexDirection: 'row',
  }),
};

export type ProgressTrackerPopoverContentProps = {
  score: number | undefined;
  skillName: string | undefined;
  copy?: {
    simpleDescription: string;
    fullDescription: string;
  };
  trackingName?: string;
  trackingData?: TrackingData;
};

const ProgressTrackerPopoverContent: React.LegacyFunctionComponentWithChildren<ProgressTrackerPopoverContentProps> = (
  props
) => {
  const { children, score, copy, skillName, trackingName = 'skill_tracker_target_popover', trackingData } = props;

  const renderScoreLevel = () => {
    if (!score) {
      return '';
    }

    const { scoreLevel, minValue, maxValue } = getScoreLevel(score);
    return (
      <div css={styles.scoringLabelContainer}>
        <div css={styles.scoringLabel} data-e2e="popover-scoreLevel">
          <FormattedHTMLMessage
            message={_t(`<b>{scoreLevel}</b> ({minValue}-{maxValue})`)}
            scoreLevel={scoreLevel}
            minValue={minValue}
            maxValue={maxValue}
          />
        </div>
      </div>
    );
  };

  return (
    <TrackedDiv withVisibilityTracking trackingName={trackingName} data={trackingData} css={styles.contentContainer}>
      {children || (
        <div>
          <VisuallyHidden className="rc-A11yScreenReaderOnly">{skillName}</VisuallyHidden>
          {score !== undefined && (
            <div css={styles.scoreContainer}>
              <div data-test="score" key="score" css={styles.score}>
                {score}
              </div>
              {renderScoreLevel()}
            </div>
          )}
          <div data-test="simpleDescription" key="simpleDescription" css={styles.simpleDescription}>
            {copy?.simpleDescription}
          </div>
          {copy?.fullDescription && (
            <div data-test="fullDescription" key="fullDescription" css={styles.fullDescription}>
              <FormattedHTMLMessage message={copy.fullDescription} />
            </div>
          )}
        </div>
      )}
    </TrackedDiv>
  );
};

export default ProgressTrackerPopoverContent;
