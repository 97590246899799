import * as React from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Dialog, Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/program-home';

type Props = {
  open: boolean;
  onClose?: () => void;
};

const { HeadingGroup, Content, Actions } = Dialog;

const EnterpriseBadgeEnrollConfirmationModal: React.LegacyFunctionComponentWithChildren<Props> = ({
  open,
  onClose,
  children,
}) => {
  const content = (
    <FormattedHTMLMessage
      message={_t(
        'By enrolling you agree that your name and email address can be sent to Credly Acclaim for the sole purpose of administering and issuing this badge, the use of Credly will be subject to <a target="_blank" href="https://www.credly.com/terms">Credly’s Terms of Use</a> and <a target="_blank" href="https://www.credly.com/privacy">Privacy Notice</a>.'
      )}
    />
  );
  return (
    <Dialog
      aria-describedby="badge-enrollment-confirmation-dialog-content"
      open={open}
      width="medium"
      onClose={onClose}
    >
      <HeadingGroup>{_t('Digital badge agreement')}</HeadingGroup>
      <Content>
        <Typography2 component="p" variant="bodyPrimary">
          {content}
        </Typography2>
      </Content>
      <Actions>{children}</Actions>
    </Dialog>
  );
};

export default EnterpriseBadgeEnrollConfirmationModal;
