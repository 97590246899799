import * as React from 'react';
import { useCallback, useState } from 'react';

import { useReadOnly, useSlateStatic } from 'slate-react';

import { TrashIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import DeleteConfirmationDialog from 'bundles/cml/editor/components/dialogs/DeleteConfirmDialog';

import _t from 'i18n!nls/cml';

type Props = {
  title?: string;
  onClick?: () => void;
  onConfirm?: (confirm: boolean) => void;
};

const DeleteAssetButton: React.FC<Props> = ({ title = _t('Delete Attachment'), onClick, onConfirm }) => {
  const editor = useSlateStatic();
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const handleDelete = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    if (onConfirm) {
      onConfirm(true);
    } else {
      setDeleteConfirm(true);
    }
  }, [setDeleteConfirm, onConfirm]);

  const readonly = useReadOnly();

  return (
    <Button
      role="menuitem"
      title={title}
      onClick={onClick || handleDelete}
      className="delete-menu-button"
      tooltipProps={{ placement: 'bottom' }}
      disabled={readonly}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <TrashIcon size="small" />
      {deleteConfirm && <DeleteConfirmationDialog editor={editor} setConfirm={setDeleteConfirm} />}
    </Button>
  );
};

export default DeleteAssetButton;
