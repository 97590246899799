import * as React from 'react';

type Props = {
  style?: React.CSSProperties;
};

const AuthorEvaluatorSectionCaption = (props: React.PropsWithChildren<Props>) => {
  const { style, children } = props;

  return (
    <div className="rc-AuthorEvaluatorSectionCaption caption-text color-secondary-text" style={style}>
      {children}
    </div>
  );
};

export default AuthorEvaluatorSectionCaption;
