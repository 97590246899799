import * as React from 'react';

import user from 'js/lib/user';

import SubscriptionVPropBulletPoint from 'bundles/enroll/components/subscriptions/free-trialV2/SubscriptionVPropBulletPoint';
import { getFreeTrialBullets } from 'bundles/enroll/components/subscriptions/free-trialV2/utils/bulletData';
import usePageData from 'bundles/enroll/data/usePageData';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';

import 'css!./__styles__/SubscriptionPropBulletPoints';

const SubscriptionPropBulletPoints: React.FC<{}> = () => {
  const { s12nDerivatives, s12n } = usePageData();

  if (!(s12n && s12nDerivatives?.catalogPrice)) {
    return null;
  }

  const { catalogPrice } = s12nDerivatives;

  const monthlyPrice = <ReactPriceDisplay currency={catalogPrice.currencyCode} value={catalogPrice.amount} />;
  const { FREE_TRIAL_LOGGED_IN_BULLETS, FREE_TRIAL_LOGGED_OUT_BULLETS } = getFreeTrialBullets({
    s12nProductVariant: s12n.productVariant,
  });

  const bullets = user.isAuthenticatedUser() ? FREE_TRIAL_LOGGED_IN_BULLETS : FREE_TRIAL_LOGGED_OUT_BULLETS;

  return (
    <div className="rc-SubscriptionPropBulletPoints">
      <ul className="bullet_list nostyle">
        {bullets.map((bullet) => {
          return (
            <li key={`bullet_${bullet.header}`}>
              <SubscriptionVPropBulletPoint messageProps={{ monthlyPrice }} highlightHeader={true} {...bullet} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SubscriptionPropBulletPoints;
