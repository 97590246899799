import * as React from 'react';
import { useCallback } from 'react';

import { times } from 'lodash';
import { Editor, Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';

import { RemoveRowIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { focusTableCell, getTableData } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { removeElement } from 'bundles/cml/editor/utils/slateUtils';
import type { TableElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  table: TableElement;
};

const RemoveTableRowButton: React.FC<Props> = ({ table }) => {
  const staticEditor = useSlateStatic();

  const handleRemoveRow = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    const { path, rowIndex, columnIndex, numRows, numColumns } = getTableData(staticEditor, table);
    if (numRows === 1) {
      removeElement(staticEditor, table);
      return;
    }

    if (!path || rowIndex < 0 || columnIndex < 0) {
      return;
    }

    Transforms.removeNodes(staticEditor, { at: [...path, rowIndex] });

    if (rowIndex === 0 && !table.headless) {
      Editor.withoutNormalizing(staticEditor, () => {
        times(numColumns, (col: number) => {
          Transforms.setNodes(staticEditor, { header: true }, { at: [...path, rowIndex, col] });
        });
      });
    }

    focusTableCell(staticEditor, path, rowIndex - 1, columnIndex);
  }, [staticEditor, table]);

  return (
    <Button role="menuitem" onClick={handleRemoveRow} title={_t('Remove row')} type={TOOLBAR_BUTTON_TYPES.insertion}>
      <RemoveRowIcon size="small" />
    </Button>
  );
};

export default RemoveTableRowButton;
