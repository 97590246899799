/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useState } from 'react';

import { Button, CheckboxGroup, ContextualHelp, Typography2, typography2 } from '@coursera/cds-core';

import TrackedButton from 'bundles/page/components/TrackedButton';
import { useTracker } from 'bundles/page/lib/event-pulse/react';
import CourseraPlusSearchFilter from 'bundles/search-common/components/filters/CourseraPlusSearchFilter';
import SearchFilterItem from 'bundles/search-common/components/filters/SearchFilterItem';
import type { EventingData } from 'bundles/search-common/components/filters/SearchFilterItem';
import SearchFilterModal from 'bundles/search-common/components/filters/SearchFilterModal';
import { useSortedItems } from 'bundles/search-common/components/filters/useSortedItems';
import { IS_PART_OF_COURSERA_PLUS, NUMBER_OF_FILTER_ITEMS_TO_SHOW } from 'bundles/search-common/constants/filter';
import type { SearchFilterOption } from 'bundles/search-common/types';

import _t from 'i18n!nls/search-common';

type Props = {
  attribute: string;
  showCPlusSupportText?: boolean;
  items?: SearchFilterOption[];
  getName: () => string;
  showAllItems: boolean;
  addFilters?: (x0: string) => void;
  removeFilters?: (x0: string) => void;
  clearFilters: () => void;
  eventingData: EventingData;
  getToolTip?: () => string;
  getSupportText?: () => string;
  setFiltersByFacet?: (facetName: string, filters: string[]) => void;
};

export type ItemType = SearchFilterOption & {
  translatedLabel?: string;
  supportText?: string;
  dialogText?: string;
};

const styles = {
  searchFilter: css`
    position: relative;
    margin-bottom: 32px;

    // hide unused div in cds CheckboxGroup that should only
    // show up if group is required
    .cds-formLabel-root > div {
      display: none;
    }
  `,
  checkboxGroup: css`
    width: 100%;

    legend {
      border-bottom: none;
    }

    /* necessary to fix discrepancy between storybook and here */
    button.cds-ContextualHelp-helpToggleButton {
      bottom: -4px;
    }
  `,
  seeMoreButton: css`
    button {
      padding: 0;
      text-decoration: underline;
      color: var(--cds-color-grey-975);
      ${typography2.bodyPrimary}
      margin-top: var(--cds-spacing-100);
    }
  `,
  contextualHelp: css`
    margin-bottom: var(--cds-spacing-50);

    .cds-ContextualHelp-tooltipContainer {
      margin-bottom: var(--cds-spacing-150);
    }
  `,
};

export const SearchFilterGroup = ({
  items = [],
  getName,
  attribute,
  showAllItems,
  addFilters,
  removeFilters,
  clearFilters,
  eventingData,
  showCPlusSupportText,
  getToolTip,
  getSupportText,
  setFiltersByFacet,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const trackV3 = useTracker();

  const sortedItems = useSortedItems(items, attribute, { sortByCount: true });
  const filterName = getName();

  const handleAddFilters = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (filters: string) => {
      trackV3('apply_filter', {
        filter: [filters],
        filterSource: 'sidebar_filter',
      });
      addFilters?.(filters);
    },
    [trackV3, addFilters]
  );

  const handleRemoveFilters = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (filters: string) => {
      trackV3('apply_filter', {
        filter: [],
        filterSource: 'sidebar_filter',
      });
      removeFilters?.(filters);
    },
    [trackV3, removeFilters]
  );

  let filterGroupToolTip;
  if (getToolTip?.()) {
    filterGroupToolTip = (labelComponent: React.ReactElement) => (
      <ContextualHelp
        helpToggleProps={{
          'aria-label': 'Information about this filter group',
        }}
        label={labelComponent}
        css={styles.contextualHelp}
      >
        <Typography2 component="p">{getToolTip()}</Typography2>
      </ContextualHelp>
    );
  }

  if (attribute === IS_PART_OF_COURSERA_PLUS) {
    return (
      <CourseraPlusSearchFilter
        name={filterName}
        items={items}
        addFilters={handleAddFilters}
        removeFilters={handleRemoveFilters}
        eventingData={eventingData}
        showCPlusSupportText={showCPlusSupportText}
      />
    );
  }

  if (sortedItems.length === 0) {
    return null;
  }

  const showEntireList = showAllItems || sortedItems.length <= NUMBER_OF_FILTER_ITEMS_TO_SHOW;
  const itemsToShow = showEntireList ? sortedItems : sortedItems.slice(0, NUMBER_OF_FILTER_ITEMS_TO_SHOW);

  return (
    <div css={styles.searchFilter} data-testid={`search-filter-group-${filterName}`}>
      <CheckboxGroup
        label={filterName}
        supportText={getSupportText?.()}
        renderLabel={filterGroupToolTip}
        css={styles.checkboxGroup}
      >
        {itemsToShow.map((item) => (
          <SearchFilterItem
            key={item.label}
            {...item}
            value={`${attribute}:${item.value}`}
            filterCategoryName={filterName}
            addFilters={handleAddFilters}
            removeFilters={handleRemoveFilters}
            eventingData={eventingData}
            isCompact={true}
          />
        ))}
      </CheckboxGroup>
      {!showEntireList && (
        <div css={styles.seeMoreButton}>
          <Button
            data-test="expand-filter-items-button"
            trackingName={`expand_filter_items_button_${attribute}`}
            withVisibilityTracking={false}
            requireFullyVisible={false}
            component={TrackedButton}
            variant="ghost"
            onClick={() => setIsModalOpen(true)}
            aria-label={_t('Show more #{filterName} options', { filterName })}
          >
            {_t('Show more')}
          </Button>
        </div>
      )}
      {isModalOpen && (
        <SearchFilterModal
          isModalOpen
          attribute={attribute}
          setOpenModal={setIsModalOpen}
          name={filterName}
          items={sortedItems}
          setFiltersByFacet={setFiltersByFacet}
          clearFilters={clearFilters}
          eventingData={eventingData}
        />
      )}
    </div>
  );
};

const placeholderStyles = {
  checkboxGroupPlaceholder: css`
    margin-bottom: 32px;
  `,
  labelPlaceholder: css`
    height: 20px;
    width: 200px;
    margin: 0 0 var(--cds-spacing-50);
    background-color: var(--cds-color-grey-25);
  `,
  itemContainer: css`
    display: flex;
    flex-direction: column;
    row-gap: var(--cds-spacing-50);
    margin: var(--cds-spacing-50) 0 0;
  `,
  searchFilterItemPlaceholder: css`
    height: 26px;
    width: 260px;
    background-color: var(--cds-color-grey-25);
  `,
};

export const CheckboxGroupPlaceholder = ({ itemCount = 4 }) => {
  return (
    <div css={placeholderStyles.checkboxGroupPlaceholder} role="group">
      <div css={placeholderStyles.labelPlaceholder} />
      <div css={placeholderStyles.itemContainer}>
        {Array(itemCount)
          .fill(1)
          .map(() => (
            <div css={placeholderStyles.searchFilterItemPlaceholder} />
          ))}
      </div>
    </div>
  );
};

export default SearchFilterGroup;
