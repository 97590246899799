/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import type { Theme } from '@coursera/cds-core';

import { Menu, MenuItem } from 'bundles/authoring/common/components/Menu';
import type { MenuProps } from 'bundles/authoring/common/components/Menu/Menu';
import {
  getTextVariantsDisplayNames,
  hasTextByVariant,
} from 'bundles/cml/editor/components/buttons/typography/textUtils';
import { getAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { TextVariant } from 'bundles/cml/shared/types/coreTypes';
import { TEXT_VARIANTS } from 'bundles/cml/shared/types/coreTypes';
import type { TextElement } from 'bundles/cml/shared/types/elementTypes';
import { Tools } from 'bundles/cml/shared/utils/customTools';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

import _t from 'i18n!nls/cml';

const pendoTargets = {
  body1: 'cml-typography-body1',
  body2: 'cml-typography-body2',
};

const styles = {
  body1: (theme: Theme) => css`
    ${theme.typography.body1}
  `,
  body2: (theme: Theme) => css`
    ${theme.typography.body2}
  `,
};

type Props = {
  tools: ToolsKeys[];
  className?: string;
};

const BodyMenu: React.FC<Props> = ({ tools, className }) => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();

  const handleBody = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (variant?: TextVariant) => {
      ReactEditor.focus(staticEditor);
      Transforms.setNodes(staticEditor, { type: 'text', variant });
    },
    [staticEditor]
  );

  const bodyTools = tools.filter((tool) => tool === Tools.BODY1 || tool === Tools.BODY2);
  const hasText = !!getAncestorOfType(editor, BLOCK_TYPES.TEXT);

  if (bodyTools.length === 1) {
    return (
      <MenuItem
        key="body"
        className={`rc-BodyButton ${className}`}
        label={_t('Body')}
        selected={hasText}
        onClick={() => handleBody()}
      />
    );
  }

  return (
    <MenuItem key="body" className={`rc-BodyButton ${className}`} label={_t('Body')} selected={hasText}>
      {(textMenuProps: MenuProps) => (
        <Menu {...textMenuProps} autoFocus>
          {TEXT_VARIANTS.map((variant: TextVariant) => {
            const textMatcher = hasTextByVariant(variant);
            const variantName = getTextVariantsDisplayNames()[variant];
            const text = getAncestorOfType(editor, BLOCK_TYPES.TEXT);
            const selected = !!text && textMatcher(text[0] as TextElement);
            return (
              <MenuItem
                key={`body-${variant}`}
                label={<div css={styles[variant]}>{variantName}</div>}
                selected={selected}
                onClick={() => handleBody(variant)}
                data-pendo={pendoTargets[variant]}
                className={className}
              />
            );
          })}
        </Menu>
      )}
    </MenuItem>
  );
};

export default BodyMenu;
