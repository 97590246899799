/** @jsx jsx */
import type { Interpolation } from '@emotion/react';
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  branch,
  compose,
  getContext,
  mapProps,
  renderComponent,
  setDisplayName,
  setPropTypes,
  withProps,
} from 'recompose';

import deferToClientSideRender from 'js/lib/deferToClientSideRender';

import type { Theme } from '@coursera/cds-core';

import CardDropdownWidget from 'bundles/course-cards/components/course-card/common/CardDropdownWidget';
import CourseCompletedCard from 'bundles/course-cards/components/course-card/enterprise/CourseCompletedCard';
import CourseEnrolledCapstoneCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledCapstoneCard';
import CourseEnrolledDefaultCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledDefaultCard';
import CourseEnrolledFuturePrivateSessionCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledFuturePrivateSessionCard';
import CourseEnrolledFuturePublicSessionCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledFuturePublicSessionCard';
import CourseEnrolledPastPrivateSessionCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledPastPrivateSessionCard';
import CourseEnrolledPastPublicSessionCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledPastPublicSessionCard';
import CourseEnrolledPreEnrollCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledPreEnrollCard';
import CourseEnrolledRunningPrivateSessionCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledRunningPrivateSessionCard';
import CourseEnrolledRunningPublicSessionCard from 'bundles/course-cards/components/course-card/enterprise/CourseEnrolledRunningPublicSessionCard';
import { CourseS12nPhotoWithModal } from 'bundles/course-cards/components/course-card/enterprise/CourseS12nPhoto';
import PrivateSessionEndedCard from 'bundles/course-cards/components/course-card/enterprise/PrivateSessionEndedCard';
import SessionSwitchCard from 'bundles/course-cards/components/course-card/enterprise/SessionSwitchCard';
import SessionSwitchCardV2 from 'bundles/course-cards/components/course-card/enterprise/SessionSwitchCardV2';
import { PRODUCT_ID_INFIX } from 'bundles/enterprise-legacy-learner-home/constants/ProgramActionConstants';
import { DESCRIPTION_PAGE_PATHS } from 'bundles/enterprise-legacy-xdp/hocs/withMiniModal';
import { CreditBadge, ExclusiveBadge } from 'bundles/enterprise-ui/components/Badges';
import ProgramHomeIncidentNotification from 'bundles/integrity-portal/components/ProgramHomeIncidentNotification';
import Naptime from 'bundles/naptimejs';
import type {
  ProgramCourseMetadata,
  ProgramProductMetadataTypeCourse,
} from 'bundles/naptimejs/resources/__generated__/ProgramProductMetadataV1';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';
import LearnerCourseSchedulesV1 from 'bundles/naptimejs/resources/learnerCourseSchedules.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import OnDemandLearnerMaterialWeeksV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterialWeeks.v1';
import OnDemandLearnerMaterialsV1 from 'bundles/naptimejs/resources/onDemandLearnerMaterials.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import OnDemandLearnerSessionsV1 from 'bundles/naptimejs/resources/onDemandLearnerSessions.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import ProgramCurriculumProductsV1 from 'bundles/naptimejs/resources/programCurriculumProducts.v1';
import ProgramProductMetadataV1 from 'bundles/naptimejs/resources/programProductMetadata.v1';
import { IdTypes } from 'bundles/product-features';
import { withProductFeatures } from 'bundles/program-home/productFeatureFlags';
import { getEnrolledSession, getProgressByLearnerMaterial } from 'bundles/program-home/utils/CourseUtils';
import type { ProductMemoType } from 'bundles/program-home/utils/ProgramHomeUtils';
import { useCampusWordingForLearner } from 'bundles/unified-common/hooks/useCampusWordingForLearner';

import _t from 'i18n!nls/course-cards';

import 'css!./__styles__/CourseEnrolledCard';

type PropsFromCaller = {
  course: CoursesV1;

  userId: number;
  programId: string;
  isPartOfS12n?: boolean;
  canUnenroll?: boolean;
  thirdPartyOrganizationId?: string;
};

type PropsFromProductFeatures = {
  allowOrgForCurriculumBuilder: boolean;
};

type PropsFromWithProps = {
  courseMetadata?: ProgramCourseMetadata;
};

type PropsFromContext = {
  contextProducts: ProductMemoType;
};

type PropsFromNonCompletedCard = {
  learnerSessions?: OnDemandLearnerSessionsV1[];
  learnerMaterialsArray?: OnDemandLearnerMaterialsV1[];
  learnerMaterialWeeksArray?: OnDemandLearnerMaterialWeeksV1[];
  programProductMetadata?: ProgramProductMetadataV1 | null;
};

type PropsFromSessionsQuery = {
  membershipId: string;
  sessionsV2Enabled: boolean;
  curriculumProduct: any;
};

type PropsFromMapProps = {
  completedAt?: string;
  isCompleted?: boolean;
};

export type Props = PropsFromCaller &
  PropsFromNonCompletedCard &
  PropsFromMapProps &
  PropsFromSessionsQuery &
  PropsFromWithProps;

const getStyle: (hasMenu?: boolean) => Record<string, Interpolation<Theme>> = (hasMenu) => {
  const widgetBuffer = hasMenu ? '32px' : '0px';
  return {
    container: css`
      min-height: 104;
    `,
    badges: css`
      position: absolute;
      display: flex;
      flex-direction: row;
      top: 0.25rem;
      gap: 6px;
      align-items: flex-start;
      right: ${widgetBuffer};

      @media (max-width: 575px) {
        width: auto;
        flex-direction: column;
        top: auto;
        bottom: 0;
        left: 40%;
      }
    `,
  };
};

const CourseEnrolledCard: React.FC<Props> = ({
  course,
  course: { id, isOnDemand, isPreEnroll, photoUrl, slug, isCapstone, homeLink, name },
  learnerMaterialsArray,
  learnerMaterialWeeksArray,
  learnerSessions,
  programId,
  isPartOfS12n,
  // Used as a discard.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isCompleted,
  canUnenroll,
  userId,
  sessionsV2Enabled,
  thirdPartyOrganizationId,
  courseMetadata,
  ...rest
}) => {
  const miniModalProps = {
    isCourse: true,
    id,
    tag: undefined,
    unifiedDescriptionPageProps: { slug, productPagePath: DESCRIPTION_PAGE_PATHS.course },
  };
  const learnerMaterials = _.first(learnerMaterialsArray);
  const learnerMaterialWeeks = _.first(learnerMaterialWeeksArray);
  const isCampusWording = useCampusWordingForLearner();

  let isEnrolledInFutureSession = false;
  let isEnrolledInRunningSession = false;
  let isEnrolledInPastSession = false;
  let isPrivateSession = false;
  const isAutoenrolled = courseMetadata?.isAutoEnrollmentEnabled;
  const firstSession = learnerSessions && _.first(learnerSessions);
  const enrolledSession = getEnrolledSession(learnerSessions);

  if (enrolledSession) {
    isEnrolledInFutureSession = !enrolledSession.isRunningNow && !enrolledSession.isEnded;
    isEnrolledInRunningSession = enrolledSession.isRunningNow;
    isEnrolledInPastSession = enrolledSession.isEnded;
    isPrivateSession = enrolledSession.isPrivate;
  }
  // A user could be enrolled in past session that is not listed in learnerSession
  if (!enrolledSession && isOnDemand) {
    isEnrolledInPastSession = true;
  }

  const onClick = enrolledSession
    ? () => {
        window.open(homeLink, '_blank');
      }
    : undefined;

  // Only show session switch for public sessions and when course is not completed
  const summaryCoreProgressPercentage = learnerMaterials && getProgressByLearnerMaterial(learnerMaterials);
  const isCourseCompleted = summaryCoreProgressPercentage === 100;
  const enableSwitchSessions = isOnDemand && !isPrivateSession && !sessionsV2Enabled;
  const showSessionSwitchCard =
    !isCourseCompleted &&
    learnerSessions &&
    learnerMaterials &&
    learnerMaterials.isProgressCritical &&
    !isPrivateSession &&
    !sessionsV2Enabled;
  const style = getStyle(canUnenroll || enableSwitchSessions);
  const cardDropdownWidget = (
    <CardDropdownWidget
      trackingName="course_card_ellipses"
      programId={programId}
      itemType="COURSE"
      itemId={id}
      enableSwitchSessions={enableSwitchSessions}
      isPartOfS12n={isPartOfS12n}
      canUnenroll={canUnenroll}
      name={course.name}
      thirdPartyOrganizationId={thirdPartyOrganizationId}
      userId={userId}
    />
  );

  /**
   * Handle below courses:
   * CourseEnrolledPreEnrollCard: only show plannedCourseLaunch date and preview
   * CourseEnrolledRunningPublicSessionCard: show current week, assignment info, show session switch
   * CourseEnrolledFuturePublicSessionCard: show session start info
   * CourseEnrolledRunningPrivateSessionCard: show current week, assignment info
   * CourseEnrolledFuturePrivateSessionCard: show session start info
   * CourseEnrolledCapstoneCard: currently don't have much info, so just show resume
   * CourseEnrolledPreEnrollCard: show preview
   * CourseEnrolledDefaultCard: handle all other course types: draft, inactive, closed
   */
  const renderCourseEnrolledCard = () => {
    if (isCapstone) {
      return (
        <CourseEnrolledCapstoneCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
          cardDropdownWidget={cardDropdownWidget}
          {...rest}
        />
      );
    }

    if (isPreEnroll) {
      return (
        <CourseEnrolledPreEnrollCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
          cardDropdownWidget={cardDropdownWidget}
          {...rest}
        />
      );
    }

    if (isEnrolledInPastSession && isPrivateSession) {
      return (
        <CourseEnrolledPastPrivateSessionCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
          learnerMaterials={learnerMaterials}
          learnerSession={enrolledSession}
          onClick={onClick}
          isAutoenrolled={isAutoenrolled}
          cardDropdownWidget={cardDropdownWidget}
          {...rest}
        />
      );
    }

    if (isEnrolledInPastSession && !isPrivateSession) {
      return (
        <CourseEnrolledPastPublicSessionCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
          learnerMaterials={learnerMaterials}
          learnerSession={enrolledSession}
          onClick={onClick}
          isAutoenrolled={isAutoenrolled}
          cardDropdownWidget={cardDropdownWidget}
          {...rest}
        />
      );
    }

    if (isEnrolledInRunningSession && isPrivateSession) {
      return (
        <CourseEnrolledRunningPrivateSessionCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
          learnerMaterialWeeks={learnerMaterialWeeks}
          learnerMaterials={learnerMaterials}
          enrolledSession={enrolledSession}
          onClick={onClick}
          isAutoenrolled={isAutoenrolled}
          cardDropdownWidget={cardDropdownWidget}
          {...rest}
        />
      );
    }

    if (isEnrolledInRunningSession && !isPrivateSession) {
      return (
        <CourseEnrolledRunningPublicSessionCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
          learnerMaterialWeeks={learnerMaterialWeeks}
          learnerMaterials={learnerMaterials}
          learnerSession={enrolledSession}
          onClick={onClick}
          isAutoenrolled={isAutoenrolled}
          cardDropdownWidget={cardDropdownWidget}
          {...rest}
        />
      );
    }

    if (isEnrolledInFutureSession && isPrivateSession) {
      return (
        <CourseEnrolledFuturePrivateSessionCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
          learnerMaterials={learnerMaterials}
          learnerSession={firstSession}
          isAutoenrolled={isAutoenrolled}
          cardDropdownWidget={cardDropdownWidget}
          {...rest}
        />
      );
    }

    if (isEnrolledInFutureSession && !isPrivateSession) {
      return (
        <CourseEnrolledFuturePublicSessionCard
          course={course}
          isPartOfS12n={isPartOfS12n}
          miniModalProps={miniModalProps}
          learnerMaterials={learnerMaterials}
          learnerSession={firstSession}
          isAutoenrolled={isAutoenrolled}
          cardDropdownWidget={cardDropdownWidget}
          {...rest}
        />
      );
    }

    return (
      <CourseEnrolledDefaultCard
        course={course}
        isPartOfS12n={isPartOfS12n}
        miniModalProps={miniModalProps}
        isAutoenrolled={isAutoenrolled}
        cardDropdownWidget={cardDropdownWidget}
        {...rest}
      />
    );
  };

  return (
    <div className="rc-CourseEnrolledCard" css={style.container} data-e2e={`CourseEnrolledCard~${id}`}>
      <div className="horizontal-box pos-relative">
        <CourseS12nPhotoWithModal
          imgixClassName="course-logo"
          imageSrc={photoUrl}
          slug={slug}
          id={id}
          title={course?.name}
          miniModalProps={miniModalProps}
          onClick={onClick}
        />
        <div css={style.badges}>
          {courseMetadata?.isSelectedForCredit && isCampusWording && (
            <CreditBadge aria-label={_t('This course is being offered for credit by your organization')} />
          )}
          {courseMetadata?.isExclusive && (
            <ExclusiveBadge aria-label={_t('This course is being offered for credit by your organization')} />
          )}
        </div>
        {renderCourseEnrolledCard()}
      </div>
      {showSessionSwitchCard && <SessionSwitchCard courseId={id} courseName={name} />}
      {sessionsV2Enabled && <SessionSwitchCardV2 userId={userId} courseId={id} courseName={name} />}
      {isEnrolledInPastSession && isPrivateSession && <PrivateSessionEndedCard endsAt={enrolledSession.endsAt} />}
      <ProgramHomeIncidentNotification courseId={id} />
    </div>
  );
};

const withCourseCompletedBranch = (Component: React.ComponentClass<Props>) =>
  compose<Props, Props>(
    branch<Props>(
      (props) => Boolean(props.isCompleted),
      renderComponent(CourseCompletedCard),
      renderComponent(Component)
    ),
    setDisplayName(`withCourseCompletedBranch(${Component.displayName || Component.name})`),
    setPropTypes({
      isCompleted: PropTypes.bool,
    })
  )(Component);

// Render CourseCompletedCard if isCompleted, so we don't have to make 3 more api calls
export default compose<Props, PropsFromCaller>(
  deferToClientSideRender,
  setDisplayName('CourseEnrolledCardHOC'),
  getContext({ contextProducts: PropTypes.object }),
  Naptime.createContainer<PropsFromSessionsQuery, PropsFromCaller & PropsFromContext>(
    ({ contextProducts, course: { id: courseId }, userId, programId }) => {
      const productQueryId = `${programId}~${PRODUCT_ID_INFIX.COURSE}~${courseId}`;
      const membershipId = `${userId}~${courseId}`;
      return {
        membershipId,
        // TODO: LearnerCourseSchedulesV1.getSessionsV2Enabled has a wrong type annotation.
        // Need to fix its return type
        sessionsV2Enabled: LearnerCourseSchedulesV1.getSessionsV2Enabled(userId, courseId, false) as unknown as boolean,
        curriculumProduct:
          (contextProducts && contextProducts[courseId]) ||
          (programId && ProgramCurriculumProductsV1.get(productQueryId, {})),
      };
    }
  ),
  mapProps<PropsFromMapProps, PropsFromSessionsQuery>(({ curriculumProduct, ...rest }) => {
    return {
      completedAt: curriculumProduct && curriculumProduct.completedAt,
      isCompleted: curriculumProduct && curriculumProduct.isCompleted,
      ...rest,
    };
  }),
  withCourseCompletedBranch,
  withProductFeatures<PropsFromProductFeatures, PropsFromCaller>(
    ({ programId }) => ({
      idType: IdTypes.Program,
      id: programId,
    }),
    ({ features }) => ({
      allowOrgForCurriculumBuilder: features.get('enterprise', 'allowOrgForCurriculumBuilder'),
    })
  ),
  Naptime.createContainer<PropsFromNonCompletedCard, PropsFromCaller & PropsFromProductFeatures>(
    ({ course: { id: courseId }, userId, programId, allowOrgForCurriculumBuilder }) => ({
      programProductMetadata: allowOrgForCurriculumBuilder
        ? ProgramProductMetadataV1.get(`${programId}~VerifiedCertificate~${courseId}`)
        : null,
      learnerMaterialsArray: OnDemandLearnerMaterialsV1.finder('courses', {
        params: { courseIds: [courseId] },
        fields: [
          'courseId',
          'weekNumbers',
          'totalPassablesCount',
          'isPassedOrCompleted',
          'passedPassablesPercentage',
          'summaryCoreProgressPercentage',
          'isProgressCritical',
        ],
      }),
      learnerMaterialWeeksArray: OnDemandLearnerMaterialWeeksV1.finder('scheduleCurrent', {
        params: { courseIds: [courseId] },
        fields: ['courseId', 'weekNumber', 'totalPassablesCount', 'isPassedOrCompleted', 'passedPassablesPercentage'],
      }),
      learnerSessions:
        userId &&
        OnDemandLearnerSessionsV1.finder('activeAndUpcoming', {
          params: { learnerId: userId, courseIds: [courseId] },
          fields: [
            'courseId',
            'enrollmentStartsAt',
            'enrollmentEndsAt',
            'startsAt',
            'endsAt',
            'isRunningNow',
            'isEnrolled',
            'sessionSwitch',
            'isPrivate',
          ],
        }),
    })
  ),
  withProps<PropsFromWithProps, PropsFromNonCompletedCard>(({ programProductMetadata }) => {
    const courseMetadataType = programProductMetadata?.metadataType as
      | ProgramProductMetadataTypeCourse
      | undefined
      | null;
    return {
      courseMetadata: courseMetadataType?.definition,
    };
  })
)(CourseEnrolledCard);

export const BaseComponent = CourseEnrolledCard;
