import type { Editor, Node, NodeEntry, Text } from 'slate';
import { Text as SlateText, Transforms } from 'slate';

import { ALL_MARKS, MARKS_TO_TOOLS } from 'bundles/cml/editor/normalize/constants';
import normalizerLogger from 'bundles/cml/editor/normalize/normalizerLogger';
import type { Options } from 'bundles/cml/editor/normalize/types';
import { MARKS, type MARK_VALUES } from 'bundles/cml/shared/constants';

const UNSUPPORTED_MONOSPACE_MARKS: MARK_VALUES[] = [
  MARKS.BOLD,
  MARKS.ITALIC,
  MARKS.UNDERLINE,
  MARKS.SUPERSCRIPT,
  MARKS.SUBSCRIPT,
];

const normalizeUnsupportedMarks = (editor: Editor, [text, path]: NodeEntry<Text>, marks: MARK_VALUES[]): boolean => {
  const unsupportedMarks = marks.filter((mark) => text[mark] === true);
  if (!unsupportedMarks.length) {
    return false;
  }

  Transforms.unsetNodes(editor, unsupportedMarks, { at: path, mode: 'lowest' });
  return true;
};

const isTextNode = (nodeEntry: NodeEntry<Node>): nodeEntry is NodeEntry<Text> => {
  const [node] = nodeEntry;
  return SlateText.isText(node);
};

const normalizeMonospace = (editor: Editor, options: Options, nodeEntry: NodeEntry<Node>): boolean => {
  if (!isTextNode(nodeEntry)) {
    return false;
  }

  const [text] = nodeEntry;
  if (!text.monospace) {
    return false;
  }

  return normalizeUnsupportedMarks(editor, nodeEntry, UNSUPPORTED_MONOSPACE_MARKS);
};

const normalizeSupportedMarks = (editor: Editor, { tools }: Options, nodeEntry: NodeEntry<Node>): boolean => {
  if (!isTextNode(nodeEntry)) {
    return false;
  }

  const unsupportedMarks = ALL_MARKS.filter((mark: MARK_VALUES) => {
    const tool = MARKS_TO_TOOLS[mark];
    return !tool || !tools.has(tool);
  });

  return normalizeUnsupportedMarks(editor, nodeEntry, unsupportedMarks);
};

const NORMALIZERS = [normalizerLogger(normalizeSupportedMarks), normalizerLogger(normalizeMonospace)];

export const normalizeMarks = (editor: Editor, options: Options, nodeEntry: NodeEntry<Node>): boolean => {
  return NORMALIZERS.some((normalizer) => normalizer(editor, options, nodeEntry));
};
