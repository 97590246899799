/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router';
import { Link as ReactRouterLink } from 'react-router';

import { compose, withProps } from 'recompose';

import { Button as CdsButton, Link as CdsLink } from '@coursera/cds-core';
import type { ButtonProps, LinkProps as CdsLinkProps } from '@coursera/cds-core';

import withSingleTracked from 'bundles/common/components/withSingleTracked';

type Props = {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
  disabled?: boolean;
  /**
   * @deprecated use emotion instead
   */
  rootClassName?: string;
  /**
   * @deprecated pass children instead
   */
  label?: string;
  'data-e2e'?: string;
  children?: React.ReactNode;
  href?: string;
  target?: '_blank';
} & Omit<ButtonProps, 'children'>;

export const Button: React.FC<Props> = ({ label, children, onClick, css, rootClassName, ...props }) => {
  const [isWaitingForClickHandler, setIsWaitingForClickHandler] = React.useState(false);
  const handleClick = React.useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event?: React.MouseEvent<HTMLButtonElement>) => {
      if (!onClick) {
        return;
      }
      setIsWaitingForClickHandler(true);
      Promise.resolve(onClick(event)).then(() => setIsWaitingForClickHandler(false));
    },
    [onClick]
  );

  // TODO remove all the `.styleguide a` global styling
  const styleOverride: { style: React.CSSProperties } | undefined =
    props.variant === 'primary'
      ? {
          style: {
            ...props.style,
            color: 'white',
            textDecoration: 'none',
          },
        }
      : undefined;
  return (
    <CdsButton
      {...props}
      className={props.className ?? rootClassName}
      variant={props.variant ?? 'secondary'}
      css={css as $TSFixMe}
      onClick={handleClick}
      loading={isWaitingForClickHandler}
      {...styleOverride}
    >
      {/* // The text node is wrapped to fix Google Translate behavior */}
      {/* // https://github.com/facebook/react/issues/11538 */}
      <span>{label || children}</span>
    </CdsButton>
  );
};

Button.displayName = 'Button';

type TrackingProps = {
  trackingName: string;
  trackingData?: { [key: string]: unknown };
};

/**
 * @deprecated enterprise-ui use useTracker fron @coursera/event-pulse/react instead
 */
export const TrackedButton: React.FC<Props & TrackingProps> = compose<{}, React.PropsWithChildren<{}>>(
  withSingleTracked({ type: 'BUTTON' }),
  withProps((props: $TSFixMe) => ({
    ...CdsButton.defaultProps,
    ...props,
  }))
)(Button) as $TSFixMe;
TrackedButton.displayName = 'TrackedButton';

/**
 * @deprecated enterprise-ui use useTracker fron @coursera/event-pulse/react instead
 */
export const TrackedButtonLink: React.FC<
  Props &
    TrackingProps & {
      to: string;
    }
> = compose<{}, React.PropsWithChildren<{}>>(
  withSingleTracked({ type: 'BUTTON' }),
  withProps((props: $TSFixMe) => ({
    component: ReactRouterLink,
    ...CdsButton.defaultProps,
    ...props,
  }))
)(Button) as $TSFixMe;
TrackedButtonLink.displayName = 'TrackedButtonLink';

type TrackedLinkProps = CdsLinkProps & TrackingProps & RouterLinkProps;
/**
 * @deprecated enterprise-ui use useTracker fron @coursera/event-pulse/react instead
 */
export const TrackedLink: React.ComponentClass<TrackedLinkProps> = compose<
  TrackedLinkProps,
  React.PropsWithChildren<TrackedLinkProps>
>(
  withSingleTracked({ type: 'BUTTON' }),
  withProps((props: $TSFixMe) => ({
    component: ReactRouterLink,
    ...CdsLink.defaultProps,
    ...props,
  }))
)(CdsLink);
TrackedLink.displayName = 'TrackedLink';
