import * as React from 'react';

import { compose, setDisplayName } from 'recompose';

import { Col, Notification, Row } from '@coursera/coursera-ui';

import { withMediaMinWidthMobile } from 'bundles/browse/utils';
import type { GraphQLCML } from 'bundles/collections-tool/types/collectionTypesSU';
import CourseSpecializationList from 'bundles/enroll/components/xdp/CourseSpecializationList';
import { getS12nOrder } from 'bundles/enroll/utils/mixAndMatchUtils';
import { PRODUCTS } from 'bundles/enterprise-legacy-learner-home/constants/xdpConstants';
import type {
  Degree,
  DifficultyLevel,
  EnterpriseProductConfiguration,
  LearningOutcome,
  Ratings,
  Specialization,
} from 'bundles/enterprise-legacy-learner-home/types/xdpTypes';
import getCourseGlanceList from 'bundles/enterprise-legacy-learner-home/utils/getCourseGlanceList';
import { addColorHashIfNeeded } from 'bundles/enterprise-legacy-learner-home/utils/xdpUtils';
import CmlLearningObjectives from 'bundles/enterprise-legacy-xdp/components/CmlLearningObjectives';
import FixedSchedule from 'bundles/enterprise-legacy-xdp/components/FixedSchedule';
import ProductMetrics, { ProductMetricsRenderType } from 'bundles/enterprise-legacy-xdp/components/ProductMetrics';
import Skills from 'bundles/enterprise-legacy-xdp/components/Skills';
import {
  renderDesktopAtAGlanceItems,
  renderMobileAtAGlanceItems,
  renderToggleButtonWithoutCollapse,
} from 'bundles/enterprise-legacy-xdp/components/aboutXrenderFuncs';
import EnterprisePromoUnitSidebar from 'bundles/enterprise-legacy-xdp/components/experiments/enterprise-promo-units/EnterprisePromoUnitSidebar';
import EnterpriseGrowthExperiments from 'bundles/epic/clients/EnterpriseGrowth';
import LearnerIncentive, { shouldShowLearnerIncentive } from 'bundles/learner-incentives/components/LearnerIncentive';
import type { LearnerIncentive as LearnerIncentiveType } from 'bundles/learner-incentives/types';
import type S12nDerivativesV1 from 'bundles/naptimejs/resources/s12nDerivatives.v1';
import TogglableContent from 'bundles/page/components/TogglableContent';
import { VERIFIED_CERTIFICATE } from 'bundles/payments/common/ProductType';
import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';
import { useIsEnterpriseLearner } from 'bundles/unified-description-page-common/contexts/UserContext';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

// eslint-disable-next-line
import 'css!bundles/coursera-ui/styles/css/utilities';
import 'css!bundles/enterprise-legacy-xdp/components/__styles__/AboutX';
import 'css!bundles/global-styles/global-element-styles';

type InputProps = {
  activeCourseId: string;
  activeCourseName: string;
  activeCourseSlug: string;
  degree?: Degree;
  description?: string;
  learningOutcomes?: LearningOutcome;
  level: DifficultyLevel;
  skills?: string[];
  primaryColor?: string;
  htmlRecommendedBackground?: string;
  cmlRecommendedBackground?: GraphQLCML;
  htmlLearningObjectives?: Array<string>;
  cmlLearningObjectives?: Array<GraphQLCML>;
  primaryLanguages: Array<string>;
  subtitleLanguages: Array<string>;
  avgLearningHoursAdjusted?: number;
  activeS12n?: Specialization;
  ratings?: Ratings;
  s12ns?: Array<Specialization>;
  hasCertificate?: boolean;
  isSCDP?: boolean;
  s12nProductVariant?: S12nProductVariant;
  showProductMetrics: boolean;
  hasLearningObjectives?: boolean;
  hasSkillsGained?: boolean;
  showNoCertificateNotification?: boolean;
  isPrivate?: boolean;
  privateSessionDates?: { startsAt: number; endsAt: number };
  totalEnrollmentCount?: number | null;
  pageViewsInLastMonthCount?: number | null;
  isC4C?: boolean;
  enterpriseProductConfiguration?: EnterpriseProductConfiguration;
  isEnterpriseAdminView?: boolean;
  enableCurriculumBuilder?: boolean;
  hasMultipleAvailabilities?: boolean;
  disableS12nUpsellLinks?: boolean;
  learnerIncentives?: Array<LearnerIncentiveType>;
  showCourseraLabsGlance?: boolean;
};

type PropsFromNaptimePrice = {
  s12nDerivative?: S12nDerivativesV1;
};

export type Props = InputProps & PropsFromNaptimePrice & { matches?: boolean };

export function AboutCourse({
  activeCourseId,
  activeS12n,
  learningOutcomes,
  avgLearningHoursAdjusted,
  description,
  primaryColor,
  primaryLanguages,
  level,
  skills,
  cmlLearningObjectives = [],
  htmlRecommendedBackground,
  cmlRecommendedBackground,
  subtitleLanguages,
  s12ns = [],
  hasCertificate,
  isSCDP,
  s12nProductVariant,
  showProductMetrics,
  hasLearningObjectives,
  hasSkillsGained,
  showNoCertificateNotification,
  isPrivate,
  privateSessionDates,
  totalEnrollmentCount,
  pageViewsInLastMonthCount,
  isC4C,
  enterpriseProductConfiguration,
  isEnterpriseAdminView,
  enableCurriculumBuilder,
  hasMultipleAvailabilities,
  disableS12nUpsellLinks,
  learnerIncentives,
  s12nDerivative,
  matches,
  showCourseraLabsGlance,
}: Props) {
  const glanceList = getCourseGlanceList({
    activeCourseId,
    activeS12n,
    avgLearningHoursAdjusted,
    hasCertificate,
    isSCDP,
    s12nProductVariant,
    level,
    cmlRecommendedBackground,
    htmlRecommendedBackground,
    primaryLanguages,
    s12ns,
    subtitleLanguages,
    isPrivate,
    privateSessionDates,
    isC4C,
    enterpriseProductConfiguration,
    isEnterpriseAdminView,
    enableCurriculumBuilder,
    hasMultipleAvailabilities,
    s12nDerivative,
    showCourseraLabsGlance,
  });
  const iconColor = addColorHashIfNeeded(primaryColor);
  const mixAndMatchS12ns = s12ns && getS12nOrder(activeCourseId, s12ns);
  const { isEnterpriseLearner, loadingIsEnterpriseLearner } = useIsEnterpriseLearner();
  const getCollapsedHeight = () => {
    const defaultHeight = 100;
    const extendedHeight = 290;
    if (matches && !(hasLearningObjectives || hasSkillsGained)) return extendedHeight;
    return defaultHeight;
  };

  const enableEnterpriseXDPPromoUnits =
    EnterpriseGrowthExperiments.get('enableEnterpriseXDPPromoUnits') && !isEnterpriseAdminView;

  return (
    <div className="AboutCourse">
      <Row rootClassName="text-xs-left">
        <Col rootClassName="m-t-2" xs={12} md={enableCurriculumBuilder ? 7 : 8}>
          {privateSessionDates && !isEnterpriseAdminView && (
            <div className="m-b-3 p-t-1s">
              <FixedSchedule startsAt={privateSessionDates.startsAt} endsAt={privateSessionDates.endsAt} />
            </div>
          )}
          {shouldShowLearnerIncentive(learnerIncentives) && (
            <div style={{ marginBottom: '24px' }}>
              <LearnerIncentive learnerIncentives={learnerIncentives as Array<LearnerIncentiveType>} />
            </div>
          )}
          <h2 className="m-r-1 m-b-0 bold headline-4-text" style={{ verticalAlign: 'middle' }}>
            {_t('About this Course')}
          </h2>
          {showProductMetrics && (
            <ProductMetrics
              totalEnrollmentCount={totalEnrollmentCount}
              pageViewsInLastMonthCount={pageViewsInLastMonthCount}
              productItemId={activeCourseId}
              productType={VERIFIED_CERTIFICATE}
              productMetricsRenderType={ProductMetricsRenderType.VIEWS_WITH_TEXT_ONLY}
            />
          )}
          <div className="m-t-1 description" style={{ whiteSpace: 'pre-wrap' }}>
            <TogglableContent
              childrenWrapperTag="div"
              className="about-section"
              collapsedHeight={getCollapsedHeight()}
              disableCollapseScroll
              hideShowLessBtn
              renderToggleButtonContentFunction={renderToggleButtonWithoutCollapse(_t('COURSE OUTLINE'))}
              toggleButtonClassName="bold body-2-text"
              toggleButtonType="button"
              trackingData={{ courseId: activeCourseId }}
              trackingName="description"
              useSmartCollapse
            >
              {description}
            </TogglableContent>
          </div>
          {!hasCertificate && showNoCertificateNotification && (
            <Notification message={_t('A shareable certificate is not offered for this course.')} type="warning" />
          )}
          {mixAndMatchS12ns?.length > 1 && !disableS12nUpsellLinks && (
            <div className="m-y-2">
              <CourseSpecializationList s12ns={mixAndMatchS12ns} courseId={activeCourseId} />
            </div>
          )}
          {renderMobileAtAGlanceItems(glanceList, PRODUCTS.course, iconColor, learningOutcomes)}
          {enableEnterpriseXDPPromoUnits && !loadingIsEnterpriseLearner && !isEnterpriseLearner && (
            <EnterprisePromoUnitSidebar
              isMobile
              primaryLanguages={primaryLanguages}
              productId={activeCourseId}
              iconColor={iconColor}
            />
          )}
          {hasLearningObjectives && (
            <div className="m-y-2">
              <CmlLearningObjectives cmlLearningObjectives={cmlLearningObjectives} />
            </div>
          )}
          {hasSkillsGained && <Skills skills={skills} rootClassName="m-y-2 p-x-2 p-t-1 p-b-2" />}
        </Col>
        <Col md={enableCurriculumBuilder ? 5 : 4}>
          {renderDesktopAtAGlanceItems(glanceList, PRODUCTS.course, iconColor, learningOutcomes)}
          {enableEnterpriseXDPPromoUnits && !loadingIsEnterpriseLearner && !isEnterpriseLearner && (
            <EnterprisePromoUnitSidebar
              primaryLanguages={primaryLanguages}
              productId={activeCourseId}
              iconColor={iconColor}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default compose<Props, InputProps>(setDisplayName('AboutCourseHOC'), withMediaMinWidthMobile)(AboutCourse);
