/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Mutation } from 'react-apollo';
import { useDispatch } from 'react-redux';

import { useRetracked } from 'js/lib/retracked';

import type { CoachInteractionType } from '@coursera/event-pulse-types';
import { useTracker } from '@coursera/event-pulse/react';

import Dislike from 'bundles/ai-coach-platform/components/patterns/chat/FeedbackDislike';
import Like from 'bundles/ai-coach-platform/components/patterns/chat/FeedbackLike';
import FeedbackModal from 'bundles/ai-coach-platform/components/patterns/chat/FeedbackModal';
import { AICOACH_FEEDBACK, MESSAGE_SENDER } from 'bundles/ai-coach-platform/components/patterns/chat/constants';
import { setLike, setUnLike, setUnSet } from 'bundles/ai-coach-platform/components/patterns/chat/store/actions';
import type { MessageTypes } from 'bundles/ai-coach-platform/components/patterns/chat/store/types';
import type {
  AiCoachPlatform_UpdateWithUserFeedbackMutation as UpdateWithUserFeedbackMutation,
  AiCoachPlatform_UpdateWithUserFeedbackMutationVariables as UpdateWithUserFeedbackMutationVariables,
} from 'bundles/ai-coach-platform/queries/__generated__/aiCoachUpdateWithUserFeedbackMutation';
import AiCoachUpdateWithUserFeedbackMutation from 'bundles/ai-coach-platform/queries/aiCoachUpdateWithUserFeedbackMutation.graphql';
import { captureMessageInSentry } from 'bundles/ai-coach-platform/utils/sentry';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';

import _t from 'i18n!nls/ai-coach-platform';

type FeedBackActions = (typeof AICOACH_FEEDBACK)[keyof typeof AICOACH_FEEDBACK];

const Feedback = ({ message }: { message: MessageTypes }) => {
  const dispatch = useDispatch();
  const trackRetracked = useRetracked();
  const track = useTracker();
  const trackingContext = useCoachTrackingContext()?.data;

  const [feedbackGiven, setFeedbackGiven] = React.useState<FeedBackActions>(AICOACH_FEEDBACK.unset);
  const feedbackHasBeenGiven = feedbackGiven !== AICOACH_FEEDBACK.unset;
  const {
    customId: messageCustomId, // extracting this to reduce the number of times we have to do a lookup on the message object.
  } = message;

  // In the edge case that this component is rendered for a message not sent by the server, do not allow feedback
  if (!messageCustomId) {
    return null;
  }

  // a tracking helper to reduce code duplication
  const handleTracking = (trackingName: string, interaction?: CoachInteractionType) => {
    trackRetracked({
      trackingData: {
        message_id: messageCustomId,
        coach_response: message.text,
        timestamp: Date.now(),
      },
      trackingName,
      action: `click`,
    });

    // TODO: add deselection events to schema
    // TODO: pass `messageCustomId` once supported in schema
    if (interaction) {
      if (trackingContext) {
        // v3 tracking
        track('interact_coach', {
          coachInteractionType: interaction,
          coach: {
            mode: trackingContext.mode,
          },
          ...(trackingContext.courseId && {
            product: {
              type: 'course',
              id: trackingContext.courseId as string,
            },
          }),
          ...(trackingContext.itemId && {
            courseItem: {
              id: trackingContext.itemId as string,
            },
          }),
        });
      }
    }
  };

  let feedbackPlaceholderText = '';
  if (feedbackGiven === AICOACH_FEEDBACK.like) {
    feedbackPlaceholderText = _t('What do you like about this response?');
  } else if (feedbackGiven === AICOACH_FEEDBACK.dislike) {
    feedbackPlaceholderText = _t('How could this response be improved?');
  }

  return (
    <React.Fragment>
      {message.sender === MESSAGE_SENDER.RESPONSE && (
        <Mutation<UpdateWithUserFeedbackMutation, UpdateWithUserFeedbackMutationVariables>
          mutation={AiCoachUpdateWithUserFeedbackMutation}
          context={{ clientName: 'gatewayGql' }}
        >
          {(updateWithUserFeedbackInput) => {
            const input = { messageId: messageCustomId, userComments: undefined };
            const onLikeSelect = () => {
              handleTracking('ai_course_coach_chat_user_feedback_like_select', 'like_message');
              setFeedbackGiven(AICOACH_FEEDBACK.like);
              dispatch(setLike(messageCustomId));
              try {
                updateWithUserFeedbackInput({
                  variables: {
                    input: { ...input, userFeedback: AICOACH_FEEDBACK.like },
                  },
                });
              } catch (error) {
                captureMessageInSentry({ error });
              }
            };

            const onDislikeSelect = () => {
              handleTracking('ai_course_coach_chat_user_feedback_dislike_select', 'dislike_message');
              setFeedbackGiven(AICOACH_FEEDBACK.dislike);
              dispatch(setUnLike(messageCustomId));
              try {
                updateWithUserFeedbackInput({
                  variables: {
                    input: { ...input, userFeedback: AICOACH_FEEDBACK.dislike },
                  },
                });
              } catch (error) {
                captureMessageInSentry({ error });
              }
            };

            const onDeselect = () => {
              handleTracking('ai_course_coach_chat_user_feedback_remove_selection');
              setFeedbackGiven(AICOACH_FEEDBACK.unset);
              dispatch(setUnSet(messageCustomId));
              try {
                updateWithUserFeedbackInput({
                  variables: {
                    input: { ...input, userFeedback: AICOACH_FEEDBACK.unset },
                  },
                });
              } catch (error) {
                captureMessageInSentry({ error });
              }
            };

            const onFeedbackSubmit = ({ userComments }: { userComments: string }) => {
              try {
                updateWithUserFeedbackInput({
                  variables: {
                    input: { ...input, userFeedback: feedbackGiven, userComments },
                  },
                });
              } catch (error) {
                captureMessageInSentry({ error });
              }
            };

            const handleSubmit = ({ userComments }: { userComments: string }) => {
              if (userComments) {
                onFeedbackSubmit({ userComments });
              }
              handleTracking('ai_course_coach_chat_user_feedback_feedback_form_submit');
            };

            const handleModalClose = () => {
              handleTracking('ai_course_coach_chat_user_feedback_feedback_form_close');
            };

            const handleModalCancel = () => {
              handleTracking('ai_course_coach_chat_user_feedback_feedback_form_cancel');
            };

            return (
              <div
                className="coach-feedback-container"
                css={css`
                  display: flex;
                  margin-right: 0;
                `}
              >
                {feedbackHasBeenGiven && (
                  <FeedbackModal
                    title={feedbackPlaceholderText}
                    onSubmit={handleSubmit}
                    onClose={handleModalClose}
                    onCancel={handleModalCancel}
                  />
                )}
                <Like
                  selected={message.userFeedback === AICOACH_FEEDBACK.like}
                  onSelect={onLikeSelect}
                  onDeselect={onDeselect}
                />
                <Dislike
                  selected={message.userFeedback === AICOACH_FEEDBACK.dislike}
                  onSelect={onDislikeSelect}
                  onDeselect={onDeselect}
                />
              </div>
            );
          }}
        </Mutation>
      )}
    </React.Fragment>
  );
};

export default Feedback;
