/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { AiCoach_Action as AiCoachAction } from '__generated__/graphql-types';
import classNames from 'classnames';

import { useTracker } from '@coursera/event-pulse/react';

import RichInputField from 'bundles/ai-coach-platform/components/patterns/chat/common/RichInputField';
import type { SendMessageFunc } from 'bundles/ai-coach-platform/components/patterns/chat/types';
import { useCoachTrackingContext } from 'bundles/ai-coach-platform/utils/tracking';

import type { GlobalState } from './store/types';

type Props = {
  placeholder: string;
  disabledInput: boolean;
  sendMessage: SendMessageFunc;
};

const styles = {
  container: css`
    padding: 0 var(--cds-spacing-200);

    .coach-chat-input-field {
      padding: var(--cds-spacing-100) 0;
    }
  `,
};

/**
 * Wrapper around `RichInputField` that handles the Redux store interaction and eventing for Coach chats
 */
const ChatRichInputField = (props: Props) => {
  const { sendMessage, placeholder, disabledInput, ...rest } = props;
  const { isMessageLoading } = useSelector((state: GlobalState) => ({
    isMessageLoading: state.behavior.messageLoader,
  }));

  const refContainer = useRef<HTMLDivElement>(null);
  const track = useTracker();
  const trackingContext = useCoachTrackingContext()?.data;

  const handleSendMessageClick = (message: string) => {
    // ignore empty messages
    if (!message) {
      return;
    }

    sendMessage(message, AiCoachAction.TypedMessage);

    if (trackingContext) {
      // v3 tracking
      track('interact_coach', {
        coachInteractionType: 'send_message',
        coach: {
          mode: trackingContext.mode,
        },
        ...(trackingContext.courseId && {
          product: {
            type: 'course',
            id: trackingContext.courseId as string,
          },
        }),
        ...(trackingContext.itemId && {
          courseItem: {
            id: trackingContext.itemId as string,
          },
        }),
      });

      // This allows us to send the input message to amplitude as a separate event all together.
      // The main goal for now being to derive the character count of the input text as requested by product.
      track('send_coach_message', {
        coachMessageText: message,
        coachMessageLength: message.length,
        coach: {
          mode: trackingContext.mode,
        },
        ...(trackingContext.courseId && {
          product: {
            type: 'course',
            id: trackingContext.courseId as string,
          },
        }),
        ...(trackingContext.itemId && {
          courseItem: {
            id: trackingContext.itemId as string,
          },
        }),
      });
    }
  };

  return (
    <div ref={refContainer} css={styles.container}>
      <div
        className={classNames('coach-chat-input-field', {
          'coach-message-disabled': isMessageLoading || disabledInput,
        })}
      >
        <RichInputField
          disabledInput={isMessageLoading || disabledInput}
          placeholder={placeholder}
          data-testid="coach-chat-rich-input-field"
          onSend={handleSendMessageClick}
          {...rest}
        />
      </div>
    </div>
  );
};

export default ChatRichInputField;
