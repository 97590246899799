import * as React from 'react';

import { CardSection } from '@coursera/coursera-ui';

type InformationCardBodyProps = {
  isFullBleed?: boolean;
};

const InformationCardBody: React.LegacyFunctionComponentWithChildren<InformationCardBodyProps> = (props) => {
  const { children, ...rest } = props;

  return <CardSection {...rest}>{children}</CardSection>;
};

export default InformationCardBody;
