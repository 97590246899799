/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';
import type { Interpolation } from '@emotion/react';

import * as React from 'react';

import classNames from 'classnames';

import logger from 'js/app/loggerSingleton';
import Imgix from 'js/components/Imgix';

import { Typography, Typography2 } from '@coursera/cds-core';
import type { Theme } from '@coursera/cds-core';

import CourseraLiteEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/CourseraLiteEnrollButton';
import SubscriptionTiersCourseraPlusEnrollButton from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersCourseraPlusEnrollButton';
import type {
  CopiesType,
  Copy,
} from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollCopies';
import {
  getCopies,
  getEnrollmentModalDefaultFeatureList,
} from 'bundles/coursera-plus/components/subscriptionTiers/SubscriptionTiersEnrollCopies';
import ValueList from 'bundles/coursera-plus/components/subscriptionTiers/ValueList';
import { logo } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import {
  isLiteEnrollModalDesignTweaksEnabled,
  isLiteEnrollModalDesignTweaksVariant1,
  isLiteEnrollModalDesignTweaksVariant2,
  isLiteEnrollModalDesignTweaksVariant3,
  isLiteEnrollModalDesignTweaksVariant4,
  isLiteEnrollModalDesignTweaksVariant5,
  isSubscriptionTiersVariantA,
  isSubscriptionTiersVariantB,
} from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import usePageData from 'bundles/enroll/data/usePageData';

import cert from 'images/coursera-plus/subscriptionTiers/cert.png';
import tools from 'images/coursera-plus/subscriptionTiers/tools.png';
import trophy from 'images/coursera-plus/subscriptionTiers/trophy.png';

const styles: Record<string, Interpolation<Theme>> = {
  boxContainer: () => css`
    @media (min-width: 1024px) {
      display: grid;
      column-gap: 4%;
      grid-template-columns: 48% 48%;
    }

    .shadow {
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
      display: none;
    }

    .singleBox {
      border-style: solid;
      border-radius: 4px;
      border-width: 1px;
      margin-bottom: 48px;
    }

    .box-secondary {
      border-color: #b1b1b1;
    }

    .box-primary {
      border-color: var(--cds-color-blue-700);
      box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
      background-color: rgb(176 229 253 / 8%);
    }

    .box-primaryV2 {
      border: none;
      border-radius: 4px;
      box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
      background-color: rgb(176 229 253 / 8%);
    }

    .box-secondaryV2 {
      border: none;
      border-radius: 4px;
      box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
    }

    .box-variantA {
      background-color: var(--cds-color-blue-25) !important;
    }

    .padded {
      padding: 30px 24px 6px 24px;
    }

    .fit-height {
      height: fit-content;
    }

    .singleBox strong {
      font-family: 'Source Sans Pro', Arial, sans-serif !important;
      font-weight: 600;
    }
  `,

  recommendationsContainer: () => css`
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: grid;
    grid-template-columns: 34px auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: var(--cds-spacing-200) var(--cds-spacing-150);
  `,

  logoContainer: () => css`
    height: 30px;
    margin-bottom: var(--cds-spacing-200);
    text-align: center;
  `,

  price: css`
    text-align: center;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  `,
  cancel: () => css`
    text-align: center;
    padding-bottom: var(--cds-spacing-300);
  `,
  buttonContainer: () => css`
    padding: var(--cds-spacing-300) 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  `,
  freeTrial: () => css`
    margin-bottom: var(--cds-spacing-200);
    text-align: center;
  `,
  recommendedPill: (theme) => css`
    background: ${theme.palette.aqua[300]};
    border-radius: 50px;
    margin: var(--cds-spacing-200) 0 0 var(--cds-spacing-200);
    padding: 1px 14px;
    width: fit-content;
    display: inline-block;
  `,
};

// Lite Design Tweaks variant 1 and 2 use the same design as Subscription Tiers MVP variant B
const isSubscriptionTiersVariantBDesign = () =>
  isSubscriptionTiersVariantB() || isLiteEnrollModalDesignTweaksVariant1() || isLiteEnrollModalDesignTweaksVariant2();

type BoxProps = {
  isHighlighted?: boolean;
};

const Box: React.LegacyFunctionComponentWithChildren<BoxProps> = ({ isHighlighted, children }) => {
  const updatedBoxDesigns =
    isLiteEnrollModalDesignTweaksVariant3() ||
    isLiteEnrollModalDesignTweaksVariant4() ||
    isLiteEnrollModalDesignTweaksVariant5();

  const liteFitHeight =
    isLiteEnrollModalDesignTweaksVariant3() ||
    isLiteEnrollModalDesignTweaksVariant4() ||
    isLiteEnrollModalDesignTweaksVariant5();

  const className = classNames('singleBox', {
    'box-variantA': isSubscriptionTiersVariantA(),
    'box-primary': isHighlighted && !updatedBoxDesigns,
    'box-secondary': !isHighlighted && !updatedBoxDesigns,
    'box-primaryV2': isHighlighted && updatedBoxDesigns,
    'box-secondaryV2': !isHighlighted && updatedBoxDesigns,
    'fit-height': liteFitHeight || (!isHighlighted && isSubscriptionTiersVariantBDesign()),
  });

  return (
    <div data-testid="enroll-choice-colunn" className={className}>
      {children}
    </div>
  );
};

type RecommendationProps = {
  src: string;
};
const Recommendation: React.LegacyFunctionComponentWithChildren<RecommendationProps> = (props) => {
  const IMAGE_SIZE = 25;
  const v5 = isLiteEnrollModalDesignTweaksVariant5();
  return (
    <div
      css={styles.recommendationsContainer}
      style={{
        backgroundColor: v5 ? '#fff' : 'rgba(0, 86, 210, 0.1)',
        marginTop: v5 ? '-12px' : 'inherit',
      }}
      data-testid="recommendation"
    >
      <Imgix
        src={props.src}
        maxWidth={IMAGE_SIZE}
        maxHeight={IMAGE_SIZE}
        alt={Imgix.DECORATIVE}
        style={{ width: `${IMAGE_SIZE}px`, height: `${IMAGE_SIZE}px` }}
      />
      {props.children}
    </div>
  );
};

type LogoProps = {
  src: string;
  alt: string;
  width: number;
  height: number;
};

const Logo: React.FC<LogoProps> = (props) => {
  const { src, alt, width, height } = props;
  return (
    <div data-testid="logo" css={styles.logoContainer}>
      <Imgix
        src={src}
        alt={alt}
        maxWidth={width}
        maxHeight={height}
        style={{ width: `${width}px`, height: `${height}px` }}
      />
    </div>
  );
};

type PromiseToUserProps = {
  src: string;
};

const promiseToUserStyles: Record<string, Interpolation<Theme>> = {
  container: css`
    margin-top: 46px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 52px auto;
    align-items: center;
  `,
  text: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  imageContainer: css`
    align-self: start;
  `,
  noPromiseSpace: css`
    margin-bottom: 42px;
  `,
};

const PromiseToUser: React.LegacyFunctionComponentWithChildren<PromiseToUserProps> = (props) => {
  if (isSubscriptionTiersVariantBDesign()) {
    return <div css={promiseToUserStyles.noPromiseSpace} />;
  }
  const IMAGE_SIZE = 40;
  return (
    <div css={promiseToUserStyles.container}>
      <div css={promiseToUserStyles.imageContainer}>
        <Imgix
          src={props.src}
          maxWidth={IMAGE_SIZE}
          maxHeight={IMAGE_SIZE}
          alt={Imgix.DECORATIVE}
          style={{ width: `${IMAGE_SIZE}px`, height: `${IMAGE_SIZE}px` }}
        />
      </div>
      <div css={promiseToUserStyles.text}>{props.children}</div>
    </div>
  );
};

type PropsFromCaller = {};

const SubscriptionTiersEnrollChoices: React.FC<PropsFromCaller> = () => {
  const { enrollmentAvailableChoices, courseraLiteProductPrice, courseraPlusProductPrice, s12n } = usePageData();

  if (!courseraLiteProductPrice || !courseraPlusProductPrice) {
    logger.error('Cannot render SubscriptionTiersEnrollChoices without pricing');
    return null;
  }

  const copies: CopiesType = getCopies(courseraLiteProductPrice, courseraPlusProductPrice);
  const defaultFeatureList = getEnrollmentModalDefaultFeatureList(s12n);

  const getLiteValueProps = () => {
    const initialVariants = (
      isSubscriptionTiersVariantA() ? copies.liteValuePropsVariantA : copies.liteValuePropsVariantB
    ) as Copy[];

    if (isSubscriptionTiersVariantBDesign()) {
      return initialVariants;
    } else if (isLiteEnrollModalDesignTweaksVariant5()) {
      return [...defaultFeatureList.sharedFeatures, copies.litePriceFeature as Copy];
    } else {
      return defaultFeatureList.sharedFeatures;
    }
  };

  const getPlusValueProps = () => {
    const initialVariants = (
      isSubscriptionTiersVariantA() ? copies.plusValuePropsVariantA : copies.plusValuePropsVariantB
    ) as Copy[];

    if (isSubscriptionTiersVariantBDesign()) {
      return initialVariants;
    } else if (isLiteEnrollModalDesignTweaksVariant5()) {
      return [
        ...defaultFeatureList.sharedFeatures,
        copies.plusPriceFeature as Copy,
        ...defaultFeatureList.plusOnlyFeatures,
      ];
    } else {
      return [...defaultFeatureList.sharedFeatures, ...defaultFeatureList.plusOnlyFeatures];
    }
  };

  const hasFreeTrialForLite = enrollmentAvailableChoices.canSubscribeToCourseraLiteFreeTrial;
  const hasFreeTrialForPlus = enrollmentAvailableChoices.canSubscribeToCourseraPlusFreeTrial;

  const liteColumn = (
    <Box>
      {/* include this component to preserve top padding layout */}
      {(isLiteEnrollModalDesignTweaksVariant3() || isLiteEnrollModalDesignTweaksVariant4()) && (
        <div style={{ height: 72 }} />
      )}

      {/* include this component to preserve top padding layout */}
      {isLiteEnrollModalDesignTweaksVariant5() && <div style={{ height: 41 }} />}

      <div className="padded">
        <Logo src={logo.LITE_PILL_BLUE} alt={copies.courseraLite as string} width={70} height={30} />

        {(isLiteEnrollModalDesignTweaksVariant4() || isLiteEnrollModalDesignTweaksVariant5()) &&
          hasFreeTrialForLite && (
            <Typography2 component="p" variant="bodyPrimary" css={styles.freeTrial}>
              {copies.freeTrial}
            </Typography2>
          )}

        {/* placeholder for when free trial is available for one unit but not the other */}
        {(isLiteEnrollModalDesignTweaksVariant4() || isLiteEnrollModalDesignTweaksVariant5()) &&
          hasFreeTrialForPlus &&
          !hasFreeTrialForLite && <div style={{ height: 41 }} />}

        {!isLiteEnrollModalDesignTweaksVariant5() && (
          <React.Fragment>
            <Typography
              variant={isLiteEnrollModalDesignTweaksVariant4() ? 'h2semibold' : 'h1semibold'}
              css={styles.price}
            >
              {isLiteEnrollModalDesignTweaksVariant3() || isLiteEnrollModalDesignTweaksVariant4()
                ? copies.litePricePerMonth
                : copies.litePrice}
            </Typography>
            <Typography2 component="p" variant="bodySecondary" css={styles.cancel}>
              {copies.cancelAnyTime}
            </Typography2>
          </React.Fragment>
        )}

        {/* Don't include this before the experiment kicks off */}
        {!(
          isLiteEnrollModalDesignTweaksVariant1() ||
          isLiteEnrollModalDesignTweaksVariant2() ||
          isLiteEnrollModalDesignTweaksVariant3() ||
          isLiteEnrollModalDesignTweaksVariant4() ||
          isLiteEnrollModalDesignTweaksVariant5()
        ) && <PromiseToUser src={tools}>{copies.litePromiseText}</PromiseToUser>}

        <ValueList points={getLiteValueProps()} />

        {(isLiteEnrollModalDesignTweaksVariant3() ||
          isLiteEnrollModalDesignTweaksVariant4() ||
          isLiteEnrollModalDesignTweaksVariant5()) && (
          <ValueList points={defaultFeatureList.plusOnlyFeatures} hasFeatures={false} />
        )}

        <div css={styles.buttonContainer}>
          <CourseraLiteEnrollButton
            fullWidth={true}
            variant="secondary"
            trackingName="subscription_tiers_enroll_choices_lite_enroll_button"
          >
            {hasFreeTrialForLite ? copies.start7day : copies.subscribeNow}
          </CourseraLiteEnrollButton>
        </div>
      </div>
    </Box>
  );

  const plusColumn = (
    <Box isHighlighted>
      {/* MVP variant A and Design tweaks variant 5 don't show this at all; MVP uses one text, while Design tweaks uses another text */}
      {!(isSubscriptionTiersVariantA() || isLiteEnrollModalDesignTweaksVariant5()) && (
        <Recommendation src={trophy}>
          {isLiteEnrollModalDesignTweaksEnabled() ? copies.plusRecommendationTextNew : copies.plusRecommendationText}
        </Recommendation>
      )}

      {isLiteEnrollModalDesignTweaksVariant5() && (
        <Typography2 component="p" variant="bodyPrimary" css={styles.recommendedPill}>
          {copies.recommended}
        </Typography2>
      )}

      <div className="padded">
        <Logo src={logo.PLUS_PILL_BLUE} alt={copies.courseraPlus as string} width={83} height={30} />

        {(isLiteEnrollModalDesignTweaksVariant4() || isLiteEnrollModalDesignTweaksVariant5()) &&
          hasFreeTrialForPlus && (
            <Typography2 component="p" variant="bodyPrimary" css={styles.freeTrial}>
              {copies.freeTrial}
            </Typography2>
          )}

        {/* placeholder for when free trial is available for one unit but not the other */}
        {(isLiteEnrollModalDesignTweaksVariant4() || isLiteEnrollModalDesignTweaksVariant5()) &&
          !hasFreeTrialForPlus &&
          hasFreeTrialForLite && <div style={{ height: 40 }} />}

        {!isLiteEnrollModalDesignTweaksVariant5() && (
          <React.Fragment>
            <Typography
              variant={isLiteEnrollModalDesignTweaksVariant4() ? 'h2semibold' : 'h1semibold'}
              css={styles.price}
              component="h2"
              data-testid="plus-price"
            >
              {isLiteEnrollModalDesignTweaksVariant3() || isLiteEnrollModalDesignTweaksVariant4()
                ? copies.plusPricePerMonth
                : copies.plusPrice}
            </Typography>
            <Typography2 component="p" variant="bodySecondary" css={styles.cancel}>
              {copies.cancelAnyTime}
            </Typography2>
          </React.Fragment>
        )}

        {!(
          isLiteEnrollModalDesignTweaksVariant1() ||
          isLiteEnrollModalDesignTweaksVariant2() ||
          isLiteEnrollModalDesignTweaksVariant3() ||
          isLiteEnrollModalDesignTweaksVariant4() ||
          isLiteEnrollModalDesignTweaksVariant5()
        ) && <PromiseToUser src={cert}>{copies.plusPromiseText}</PromiseToUser>}

        <ValueList points={getPlusValueProps()} />

        <div css={styles.buttonContainer}>
          <SubscriptionTiersCourseraPlusEnrollButton
            fullWidth={true}
            variant="primary"
            trackingName="subscription_tiers_enroll_choices_plus_enroll_button"
          >
            {hasFreeTrialForPlus ? copies.start7day : copies.subscribeNow}
          </SubscriptionTiersCourseraPlusEnrollButton>
        </div>
      </div>
      {isLiteEnrollModalDesignTweaksVariant5() && (
        <Recommendation src={trophy}>
          <Typography2 component="p" variant="bodySecondary" color="supportText">
            {copies.plusRecommendationTextNew}
          </Typography2>
        </Recommendation>
      )}
    </Box>
  );

  // Subscription Tiers MVP variant A, Subscription Tiers MVP variant B, and Lite design tweaks variant 1 show Lite column first
  // Lite design tweaks variant 2, variant 3, variant 4, and variant 5 show Plus column first
  const showLiteColumnFirst =
    isSubscriptionTiersVariantA() || isSubscriptionTiersVariantB() || isLiteEnrollModalDesignTweaksVariant1();

  return (
    <div css={styles.boxContainer} data-testid="choices">
      {showLiteColumnFirst ? (
        <>
          {liteColumn}
          {plusColumn}
        </>
      ) : (
        <>
          {plusColumn}
          {liteColumn}
        </>
      )}
    </div>
  );
};

export default SubscriptionTiersEnrollChoices;
