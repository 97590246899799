import * as React from 'react';
import { useContext } from 'react';

import type { ProductFeatures } from 'bundles/unified-common/types/enterprise';

type ProductFeaturesContextType = {
  features?: ProductFeatures;
  isLoading?: boolean;
};

const EnterpriseProductFeaturesContext = React.createContext<ProductFeaturesContextType | undefined>(undefined);

export const useEnterpriseProductFeatures = () => {
  const context = useContext(EnterpriseProductFeaturesContext);

  return context?.features;
};

export const useEnterpriseProductFeaturesLoading = () => {
  const context = useContext(EnterpriseProductFeaturesContext);

  return context?.isLoading;
};

export default EnterpriseProductFeaturesContext;
