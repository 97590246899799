/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { Divider, Typography2 } from '@coursera/cds-core';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  /**
   * Visual label (required)
   */
  label: string;

  /**
   * CSS style overrides (optional)
   */
  className?: string;
};

const styles = {
  root: css`
    display: flex;
    align-items: center;
  `,
  divider: css`
    flex: 1;
    min-width: 0;
    color: var(--cds-color-grey-100);
  `,
  label: css`
    flex: initial;
    max-width: 80%;
    padding: 0 var(--cds-spacing-50);
    color: var(--cds-color-grey-700);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  `,
};

/**
 * CoachContextHeader is a header component for displaying context separators in Coach Chat
 */
const CoachContextHeader = ({ label, className }: Props) => (
  <Typography2
    variant="subtitleMedium"
    component="h3"
    aria-label={_t(`You're now in a conversation about #{label}`, { label })}
    css={styles.root}
    className={className}
  >
    <Divider data-testid="divider" orientation="horizontal" css={styles.divider} />
    <div css={styles.label}>{label}</div>
    <Divider data-testid="divider" orientation="horizontal" css={styles.divider} />
  </Typography2>
);

export default CoachContextHeader;
