import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { NumberedListIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { shouldDisableList, toggleList } from 'bundles/cml/editor/components/buttons/list/listUtils';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const { NUMBER_LIST } = BLOCK_TYPES;

const NumberedListButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const active = hasAncestorOfType(editor, NUMBER_LIST);
  const disabled = shouldDisableList(editor, NUMBER_LIST);
  const handleChange = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    () => toggleList(staticEditor, NUMBER_LIST),
    [staticEditor]
  );

  return (
    <Button
      onClick={handleChange}
      disabled={disabled}
      active={active}
      className="rc-NumberedListButton"
      title={_t('Numbered list')}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <NumberedListIcon size="small" />
    </Button>
  );
};

export default NumberedListButton;
