import * as React from 'react';
import { useCallback, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';

import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import $ from 'jquery';
import type { PlayerOptions } from 'video.js';

import { languageCodeToName } from 'js/lib/language';

import {
  CLIPPED_VIDEO_PLAYER_OPTIONS,
  clippedVideoPlayerOptionKey,
} from 'bundles/author-lecture/components/video-trimming/utils/clippedVideoPlayerUtils';
import type { Html5VideoResolutionsSources, Html5VideoTracks } from 'bundles/phoenix/components/HTML5Video';

const TRACK_WITH_SENTRY_CLASSNAME = 'author-lecture-sentry-track';

export const getPlayerOptions = (
  sources?: Html5VideoResolutionsSources,
  hideControlsBar = false,
  enableVideoTrimming = false
): PlayerOptions => {
  const playerOptions: PlayerOptions = {
    ...(hideControlsBar
      ? {}
      : {
          inactivityTimeout: 1000, // Hides after 1 sec
        }),
    // TODO: `sources` is usually defined at least with {}. Should this be `if (!isEmpty(sources))`?
    ...(sources ? { plugins: { resolutions: { sourcesByResolution: sources } } } : {}),
    [clippedVideoPlayerOptionKey]: enableVideoTrimming,
    ...(enableVideoTrimming ? CLIPPED_VIDEO_PLAYER_OPTIONS : {}),
  };

  return playerOptions;
};

const ENABLE_CAPTURE = true; // necessary for resource loading errors
const LOGGED_SRC_ENDPOINT = 'subtitleDraftProxy.v1';

// https://docs.sentry.io/platforms/javascript/troubleshooting/
export const useSetupResource404Logging = (extra: object) => {
  const captureToSentry = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: ErrorEvent) => {
      const target = event?.target as HTMLTrackElement;
      if (
        target.classList.contains(TRACK_WITH_SENTRY_CLASSNAME) &&
        target.tagName === 'TRACK' &&
        target.src.includes(LOGGED_SRC_ENDPOINT)
      ) {
        Sentry.captureException(
          new Error(`Failed to load track from subtitleDraftProxy.v1`, {
            cause: JSON.stringify({ filename: target.src, extra }),
          }),
          extra
        );
      }
    },
    [extra]
  );

  useEffect(() => {
    document?.body?.addEventListener('error', captureToSentry, ENABLE_CAPTURE);
    return () => {
      document?.body?.removeEventListener('error', captureToSentry, ENABLE_CAPTURE);
    };
  }, [captureToSentry]);
};

/*
 * In order to initiate the videojs object, which is stored in the video store, we need access to the video node
 */

type Props = React.MediaHTMLAttributes<HTMLVideoElement> & {
  width: number | string;
  height: number | string;
  sources: Html5VideoResolutionsSources;
  tracks: Html5VideoTracks;
};

const Html5Video = ({ width, height, sources, tracks, className, ...attributes }: React.PropsWithChildren<Props>) => {
  const classes = classNames(
    'video-js',
    'vjs-circle-play-centered',
    'vjs-coursera-phoenix-skin',
    'vjs-coursera-skin',
    className
  );

  const videoSources = Object.entries(sources ?? {}).flatMap(([resolution, html5Sources]) =>
    html5Sources.map((source) => (
      <source key={resolution + ':' + source.type} src={source.src ?? undefined} type={source.type} />
    ))
  );

  const videoSubtitles = Object.entries(tracks ?? {}).map(([languageCode, subtitleUrl]) => (
    <track
      kind="subtitles"
      key={languageCode}
      label={languageCodeToName(languageCode)}
      srcLang={languageCode}
      src={subtitleUrl}
      className={TRACK_WITH_SENTRY_CLASSNAME}
    />
  ));

  return (
    <video className={classes} width={width} height={height} preload="auto" {...attributes} crossOrigin="anonymous">
      {videoSources}
      {videoSubtitles}
    </video>
  );
};

export const renderHtml5Video = (props: Props) => {
  return $(ReactDOMServer.renderToStaticMarkup(<Html5Video {...props} />)).get(0);
};

export const getVideoJsNode = (sources: Html5VideoResolutionsSources, tracks: Html5VideoTracks) => {
  return renderHtml5Video({ sources, tracks, width: 780, height: 439, autoPlay: false });
};

export default { getPlayerOptions, getVideoJsNode };
