/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import { type ButtonProps } from '@coursera/cds-core';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';
import { useQuickAccessContext } from 'bundles/ai-coach-platform/components/patterns/popover/context/quickAccessContext';

const styles = {
  button: css`
    height: 36px;
    width: 36px;
    padding: 0;
  `,
  selected: css`
    background-color: var(--cds-color-interactive-background-primary-hover-weak);
  `,
};

type Props = Omit<ButtonProps, 'children'> & { selected?: boolean };

/**
 * QuickAccessButton is a wrapper component on CoachButton with some style overrides.
 * Ideally we should use CoachIconButton, but that component overrides the Sparkle icon gradient.
 */
const QuickAccessButton = ({ icon, selected, ...props }: Props) => {
  const { referenceProps } = useQuickAccessContext();

  return (
    <CoachButton
      {...props}
      {...referenceProps}
      component="button"
      variant="ghost"
      size="small"
      css={[styles.button, selected && styles.selected]}
    >
      {icon}
    </CoachButton>
  );
};

export default QuickAccessButton;
