import type { Editor, Node, NodeEntry } from 'slate';
import { Element, Transforms } from 'slate';

import normalizerLogger from 'bundles/cml/editor/normalize/normalizerLogger';
import type { Options } from 'bundles/cml/editor/normalize/types';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { BLOCK_VALUES } from 'bundles/cml/shared/constants';

export const TOP_LEVEL_ELEMENTS = new Set<BLOCK_VALUES>([
  BLOCK_TYPES.IMAGE,
  BLOCK_TYPES.IMAGE_UPLOADER,
  BLOCK_TYPES.TABLE,
  BLOCK_TYPES.WIDGET,
  BLOCK_TYPES.HEADING,
  BLOCK_TYPES.ASSET,
  BLOCK_TYPES.CODE,
]);

export const normalizeTopLevelElements = normalizerLogger(
  (editor: Editor, options: Options, [node, path]: NodeEntry<Node>): boolean => {
    if (!Element.isElement(node) || !TOP_LEVEL_ELEMENTS.has(node.type)) {
      return false;
    }

    const maxPathLength = hasAncestorOfType(editor, BLOCK_TYPES.AI_ELEMENT) ? 2 : 1;
    if (path.length <= maxPathLength) {
      return false;
    }

    Transforms.liftNodes(editor, { at: path, match: (el) => el === node });
    return true;
  },
  'normalizeTopLevelElements'
);
