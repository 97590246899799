/** @jsx jsx */
import { jsx } from '@emotion/react';

import { CertificateIcon, DegreeIcon } from '@coursera/cds-icons';

import _t from 'i18n!nls/product-card';

export const productCardDegreeOrCredit = (isDegree: boolean) => {
  return {
    icon: isDegree ? <DegreeIcon /> : <CertificateIcon />,
    label: isDegree ? _t('Earn a degree') : _t('Credit offered'),
  };
};
