/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import Enterprise from 'bundles/epic/data/defaults/Enterprise.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  fullStoryTeamsLandingEnabled: boolean;
  enableOrgForProgramAdminLeaderboard: any;
  whitelistOrganizationProgramsForSSOLogin: any;
  wesPricingInternationalization: boolean;
  wesPricingInternationalizationBySession: boolean;
  enableSkillsHistograms: boolean;
  disableNonScalableOrgActions: any;
  enableCancellationDeflection: boolean;
  enableInvoiceUpsells: boolean;
  enableWESPaymentPendoGuide: boolean;
  enableFreeTrial: boolean;
  disableSentMessagesForOrg: any;
  bypassWESFreeTrialC4TCheck: boolean;
  enableSalesforceSandbox: boolean;
  fullStoryContentCurationEnabled: boolean;
  enableDiscretionaryDiscount: boolean;
  enableGradebookAcademicIntegrity: boolean;
  enableUserDataDelayBanner: boolean;
  enableTeamsLandingContentfulIntegration: boolean;
  enterpriseSearchShadow: boolean;
  enableMicroBadgeReporting: boolean;
  enableInternalCourseraWarningBanner: any;
  enableSkillSetsByDefault: boolean;
  enableSkillSetsByDefaultDGS: boolean;
  enableClipsInCollections: boolean;
  fullscreenProductModal: boolean;
  openSearchInNewPage: boolean;
  enableCourseBuilder: boolean;
  enableLearnerPage: boolean;
  enableAiProgramBuilder: boolean;
  renameUtilizationDashboards: boolean;
  enableAutoEnrollment: 'control' | 'enableCourseHomeAutoEnroll' | 'enableFirstCourseItemAutoEnroll';
  enableRecruiterDashboardUpdates: any;
  enableRecruiterInvitationFlow: any;
  enableSeparateReports: boolean;
  enableAssignLearningPaths: boolean;
  enableLearningObjectivesGeneration: boolean;
  enableCourseProfiles: boolean;
  enableAdminSyncFlow: boolean;
  migrateEnterpriseContractProvisionActions: boolean;
  enableNewUsersPage: boolean;
};

const NAMESPACE = 'Enterprise';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([Enterprise as $TSFixMe]);

const EnterpriseEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default EnterpriseEpicClient;
