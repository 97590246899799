import type { MutableRefObject } from 'react';
import { useCallback } from 'react';
import * as React from 'react';

import { merge } from 'lodash';

import type { LinkProps } from '@coursera/cds-core';
import { Link as CDSLink } from '@coursera/cds-core';

import { isExternalLink } from 'bundles/common/utils/urlUtils';

import type { TrackingProps } from './BaseComponent';
import type { EventData } from './useTracker';
import useTracker from './useTracker';
import useVisibilityTracker from './useVisibilityTracker';

type OwnProps = TrackingProps<'click_button'>;
type Props = OwnProps & Omit<LinkProps, keyof OwnProps> & { noViewTracking?: boolean };

/**
 * Link is a wrapper around CDS Link that tracks click_button and view_button events with automatic linkType and linkURL.
 */
const Link = ({
  children,
  href,
  onAuxClick,
  onClick,
  trackingData,
  trackingOptions,
  noViewTracking,
  ...props
}: Props) => {
  const payload = merge<{}, EventData<'click_button'>, { button: Partial<EventData<'click_button'>['button']> }>(
    {},
    trackingData,
    {
      button: {
        ...(href && {
          linkType: isExternalLink(href) ? 'external' : 'internal',
          linkURL: href,
        }),
      },
    }
  );

  const track = useTracker('click_button', payload, trackingOptions);
  const ref: MutableRefObject<HTMLAnchorElement | null> = useVisibilityTracker('view_button', payload);

  const handleAuxClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      track();
      onAuxClick?.(event);
    },
    [onAuxClick, track]
  );

  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      track();
      onClick?.(event);
    },
    [onClick, track]
  );

  return (
    <CDSLink
      {...props}
      href={href}
      onAuxClick={handleAuxClick}
      onClick={handleClick}
      ref={noViewTracking ? undefined : ref}
    >
      {children}
    </CDSLink>
  );
};

export type { Props };

export default Link;
