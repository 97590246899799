import { createContext, useContext } from 'react';

import type { HeaderLevel } from 'bundles/cds-labs/components/Modules/CollectionsModule';

type HeaderLevelContextType = {
  currentLevel: HeaderLevel;
  nextLevel: HeaderLevel;
};

export const HeaderLevelContext = createContext<HeaderLevelContextType>({
  currentLevel: 'h2',
  nextLevel: 'h3',
});

export const useHeaderLevel = () => {
  return useContext(HeaderLevelContext);
};
