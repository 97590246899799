/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { EnterpriseSkills_Skillset as Skillset } from '__generated__/graphql-types';

import a11yKeyPress from 'js/lib/a11yKeyPress';
import { FormattedMessage } from 'js/lib/coursera.react-intl';
import useRouter from 'js/lib/useRouter';

import { Button, Typography, Typography2, breakpoints } from '@coursera/cds-core';
import { ArrowNextIcon } from '@coursera/cds-icons';
import { TextTruncate, css as cuicss, placeholder } from '@coursera/coursera-ui';
import type { SectionName } from '@coursera/event-pulse-types';

import { getAllProducts } from 'bundles/enterprise-collections/components/getCollectionItemList';
import {
  ProductInfoList,
  ProductInfoListPlaceholder,
} from 'bundles/enterprise-legacy-learner-home/components/skillSetProductCollectionCard/ProductInfoList';
import { SkillPillListPlaceholder } from 'bundles/enterprise-legacy-learner-home/components/skillSetProductCollectionCard/SkillPillList';
import { toProgramSkillSet } from 'bundles/enterprise-legacy-learner-home/links';
import type { CollectionListItemStruct } from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import TrackedDiv from 'bundles/page/components/TrackedDiv';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import { GoldStarSvg } from 'bundles/skills-common';

import _t from 'i18n!nls/program-home';

type SkillSetProductCollectionCardProp = {
  skillSet: Skillset;
  programSlug: string;
  collectionListItemStruct: CollectionListItemStruct;
  skillSetOrder: number;
  filterTrackingData: string[];
};

const styles = {
  container: css`
    border: 1px solid var(--cds-color-grey-50);
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgb(0 0 0 / 10%);
    border-radius: 2px;
    overflow: hidden;
    height: 100%;
  `,
  headerContainer: css`
    background-color: var(--cds-color-purple-950);
  `,
  innerContainer: css`
    padding: var(--cds-spacing-200) var(--cds-spacing-300);
    ${breakpoints.down('sm')} {
      padding: var(--cds-spacing-200);
    }
  `,
  detailsContainer: css`
    display: flex;
    flex-direction: column;
    height: calc(100% - 244px);
    ${breakpoints.down('sm')} {
      height: calc(100% - 236px);
    }
    ${breakpoints.down('xs')} {
      height: calc(100% - 228px);
    }
  `,
  headerTitle: css`
    display: flex;
    align-items: center;
  `,
  skillSetHeader: css`
    margin-left: var(--cds-spacing-100);
  `,
  skillSetName: css`
    margin: var(--cds-spacing-200) 0;
    ${breakpoints.down('sm')} {
      margin: var(--cds-spacing-150) 0;
    }
    ${breakpoints.down('xs')} {
      margin: var(--cds-spacing-100) 0;
    }

    height: 72px;
    display: flex;
    align-items: center;

    > .name-container {
      flex-grow: 1;
    }
  `,
  description: css`
    height: 72px;
  `,
  gainMessageContainer: css`
    margin: var(--cds-spacing-100) 0;
    ${breakpoints.down('sm')} {
      margin-top: 0;
    }
  `,
  linkSection: css`
    margin-top: auto;
    margin-left: calc(var(--cds-spacing-200) * -1);
    align-self: flex-start;
    ${breakpoints.down('sm')} {
      margin-left: calc(var(--cds-spacing-100) * -1);
    }
  `,
};

export function SkillSetProductCollectionCardPlaceholder() {
  return (
    <div css={styles.container} aria-hidden>
      <div css={[styles.headerContainer, styles.innerContainer]} aria-hidden>
        <div css={styles.headerTitle} aria-hidden>
          <GoldStarSvg width={31} height={36} />
          <Typography2
            display="inline"
            css={styles.skillSetHeader}
            component="div"
            variant="subtitleMedium"
            color="invertBody"
            aria-hidden
          >
            {_t('SKILLSET')}
          </Typography2>
        </div>
        <Typography
          css={styles.skillSetName}
          variant="h1semibold"
          component="div"
          {...cuicss(placeholder.styles.shimmer)}
          color="invertBody"
          aria-hidden
        >
          &nbsp;
        </Typography>
        <Typography
          {...cuicss(placeholder.styles.shimmer)}
          css={styles.description}
          component="div"
          color="invertBody"
          aria-hidden
        >
          &nbsp;
        </Typography>
      </div>
      <div css={[styles.innerContainer, styles.detailsContainer]}>
        <Typography {...cuicss(placeholder.styles.shimmer)} variant="h3semibold" component="div" aria-hidden>
          &nbsp;
        </Typography>
        <SkillPillListPlaceholder />
        <Typography2 variant="subtitleMedium" component="div" css={styles.gainMessageContainer} aria-hidden>
          {_t('Grow your skills with recommendations:')}
        </Typography2>
        <ProductInfoListPlaceholder />
        <Button
          aria-hidden
          css={styles.linkSection}
          variant="ghost"
          disabled={true}
          component={TrackedLink2}
          icon={<ArrowNextIcon size="medium" />}
          iconPosition="after"
        >
          {_t('View all recommendations')}
        </Button>
      </div>
    </div>
  );
}

export function SkillSetProductCollectionCard({
  skillSet,
  programSlug,
  collectionListItemStruct,
  skillSetOrder,
  filterTrackingData,
}: SkillSetProductCollectionCardProp) {
  const track = useTracker();

  const { id, description, skillProficiencyTargets, name, slug } = skillSet;
  const products = getAllProducts(collectionListItemStruct);
  const router = useRouter();

  const eventingV3SkillSetData = {
    skillsetCard: {
      index: skillSetOrder,
    },
    pageSection: {
      sectionName: 'cta_banner' as SectionName,
      index: 1,
    },
    filter: filterTrackingData,
    skillset: {
      id,
      name,
    },
  };

  const href = toProgramSkillSet(programSlug, slug);
  const onSkillSetContainerClick = React.useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      router.push(href);
    },
    [router, href]
  );

  const onSkillSetContainerLinkClick = React.useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      track('click_skillset_card', eventingV3SkillSetData);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const skillsetCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_skillset_card',
    eventingV3SkillSetData
  );

  return (
    <div ref={skillsetCardRef}>
      <TrackedDiv
        trackingName="skillset_container"
        data={{
          skillSetOrder,
          objectId: `skillset~${programSlug}/${skillSet.slug}`,
          skillsetSlug: skillSet.slug,
          programSlug,
          tab: 'skillset-tab',
        }}
        css={styles.container}
        role="button"
        onClick={onSkillSetContainerClick}
        onKeyPress={(event) => a11yKeyPress(event, onSkillSetContainerClick)}
        data-e2e="ObjectiveCard"
        trackClicks
        withVisibilityTracking
      >
        <div css={[styles.headerContainer, styles.innerContainer]}>
          <div css={styles.headerTitle}>
            <GoldStarSvg width={31} height={36} />
            <Typography2
              display="inline"
              css={styles.skillSetHeader}
              component="div"
              variant="subtitleMedium"
              color="invertBody"
            >
              {_t('SKILLSET')}
            </Typography2>
          </div>
          <Typography css={styles.skillSetName} variant="h1semibold" component="div" color="invertBody">
            <TextTruncate containerClassName="name-container" line={2} text={name} />
          </Typography>
          <Typography2 css={styles.description} component="div" color="invertBody">
            <TextTruncate line={3} text={description} />
          </Typography2>
        </div>
        <div css={[styles.innerContainer, styles.detailsContainer]}>
          <Typography2 variant="subtitleMedium" component="div">
            <FormattedMessage
              message={_t('Gain {numSkills, plural, =1 {# skill} other {# skills}}:')}
              numSkills={skillProficiencyTargets?.totalCount ?? 0}
            />
          </Typography2>
          {/* <SkillPillList targetSkillProficiencies={targetSkillProficiencies} /> */}
          <Typography2 variant="subtitleMedium" component="div" css={styles.gainMessageContainer}>
            {_t('Grow your skills with recommendations:')}
          </Typography2>
          <ProductInfoList
            products={products}
            programSlug={programSlug}
            skillsetSlug={skillSet.slug}
            skillSetOrder={skillSetOrder}
          />
          <Button
            css={styles.linkSection}
            aria-label={_t('View SkillSet: #{skillSetName}', { skillSetName: name })}
            variant="ghost"
            component={TrackedLink2}
            trackingName="skillset_link"
            data={{
              skillSetOrder,
              objectId: `skillset~${programSlug}/${skillSet.slug}`,
              skillsetSlug: skillSet.slug,
              programSlug,
              tab: 'skillset-tab',
            }}
            href={href}
            onClick={onSkillSetContainerLinkClick}
            icon={<ArrowNextIcon size="medium" />}
            iconPosition="after"
          >
            {_t('View all recommendations')}
          </Button>
        </div>
      </TrackedDiv>
    </div>
  );
}
