/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import type Q from 'q';

import user from 'js/lib/user';

import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';
import { ErrorIcon, SpinnerIcon, SuccessIcon } from '@coursera/cds-icons';
import type { ApiStatus } from '@coursera/coursera-ui/lib/constants/sharedConstants';
import {
  API_BEFORE_SEND,
  API_ERROR,
  API_IN_PROGRESS,
  API_SUCCESS,
} from '@coursera/coursera-ui/lib/constants/sharedConstants';

import withSingleTracked from 'bundles/common/components/withSingleTracked';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const BUTTON_ICONS = {
  API_BEFORE_SEND: undefined,
  API_IN_PROGRESS: <SpinnerIcon />,
  API_SUCCESS: <SuccessIcon color="success" />,
  API_ERROR: <ErrorIcon color="error" />,
};

export type PropsFromCaller = ButtonProps & {
  onCtaClick: () => Q.Promise<{}>;
  trackingName: string;
  actionBeforeEnroll?: () => void;
  showAuthModal?: () => void;
};

const SubscriptionTiersEnrollButton: React.FC<PropsFromCaller> = ({
  trackingName,
  children,
  actionBeforeEnroll,
  showAuthModal,
  onCtaClick,
  // TypeScript complains that refs from the parent are not the same type,
  // so destruct it here so that it doesn't get passed with {...props}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...props
}) => {
  const [apiStatus, setApiStatus] = useState<ApiStatus>(API_BEFORE_SEND);

  const handleClick = () => {
    if (typeof actionBeforeEnroll === 'function') {
      actionBeforeEnroll();
    }

    if (!user.isAuthenticatedUser() && typeof showAuthModal === 'function') {
      showAuthModal();
      return;
    }

    if (apiStatus === API_IN_PROGRESS) {
      return;
    }

    setApiStatus(API_IN_PROGRESS);

    onCtaClick()
      .then(() => {
        setApiStatus(API_SUCCESS);
      })
      .catch(() => {
        setApiStatus(API_ERROR);
      });
  };

  return (
    <TrackedButton
      trackingName={trackingName}
      onClick={handleClick}
      disabled={apiStatus !== API_BEFORE_SEND}
      icon={BUTTON_ICONS[apiStatus]}
      {...props}
    >
      {children}
    </TrackedButton>
  );
};

export default SubscriptionTiersEnrollButton;
