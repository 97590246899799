/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { Link } from 'react-router';

import { Button, Grid, PageGridContainer, Typography, breakpoints, useTheme } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import type { Domain } from 'bundles/browse/components/Domain/types/Domain';
import type { Subdomain } from 'bundles/browse/components/Domain/types/SubDomain';
import withSingleTracked from 'bundles/common/components/withSingleTracked';
import DomainBreadcrumbs from 'bundles/enterprise-collections/components/DomainBreadcrumbs';
import SearchFilters from 'bundles/enterprise-collections/components/filters/SearchFilters';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type DomainsV1 from 'bundles/naptimejs/resources/domains.v1';
// @ts-expect-error TS7016 Untyped import http://go.dkandu.me/strict-ts-migration#TS7016
import type SubdomainsV1 from 'bundles/naptimejs/resources/subdomains.v1';
import { Scroller } from 'bundles/program-home/components/multiprogram/Scroller';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps<Link>>(
  // TODO(ppaskaris): Ask how to type this.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  Button
);

type Props = {
  domain: DomainsV1;
  subdomain: SubdomainsV1;
  programSlug?: string;
  thirdPartyOrgSlug: string;
  thirdPartyOrganizationId: string;
  programName?: string;
};

const useStyles = () => {
  const theme = useTheme();
  return {
    root: css`
      background-color: var(--cds-color-blue-700);
      border-bottom: 1px solid var(--cds-color-grey-50);
      display: flex;
      flex-direction: column;

      .rc-SearchFilters {
        padding-top: var(--cds-spacing-300);
      }

      /* TODO(ppaskaris): push into component after search bundle splitting */
      .SearchFilters-container {
        background-color: initial !important;
      }

      /* TODO(ppaskaris): push into component after search bundle splitting */
      .filter-menu-and-number-of-results > :not(.rc-ToggleFilterButton) {
        display: none;
      }

      /* TODO(ppaskaris): push into component after search bundle splitting */
      .rc-DesktopSearchFilters {
        background-color: initial;
        padding: initial;
      }

      .ProgramHomeDomainSubdomainHeader-Scroller {
        margin: 32px -6px 0 -6px;

        .Scroller-ShimLeft {
          background-image: linear-gradient(to left, rgb(255 255 255 / 0%) 0, var(--cds-color-blue-700) 16px);
        }

        .Scroller-ShimRight {
          background-image: linear-gradient(to right, rgb(255 255 255 / 0%) 0, var(--cds-color-blue-700) 16px);
        }

        .Scroller-ScrollLeft,
        .Scroller-ScrollRight {
          border: none;
        }
      }
    `,
    banner: css`
      margin: 0 auto;
      padding: var(--cds-spacing-300) var(--cds-spacing-150);
      width: 100%;
    `,
    bannerPlaceholder: css`
      height: 172px;
      ${breakpoints.up('lg')} {
        height: 175px;
      }
    `,
    breadcrumbs: css`
      margin-bottom: var(--cds-spacing-300);
    `,
    filterContainer: css`
      background-color: var(--cds-color-grey-25);
      min-height: 46px;
      ${breakpoints.up('md')} {
        min-height: 97px;
      }
    `,
    subdomainList: css`
      white-space: nowrap;
      list-style: none;
      margin: 4px 0;
      padding: 0;
    `,
    subdomainListItem: css`
      display: inline-block;
      margin-left: var(--cds-spacing-300);

      ${breakpoints.down('sm')} {
        margin-left: var(--cds-spacing-150);
      }

      &:first-child {
        margin-left: var(--cds-spacing-100);
      }
    `,
  };
};

export function ProgramHomeDomainSubdomainHeaderPlaceholder() {
  const styles = useStyles();
  return (
    <div css={styles.root}>
      <div css={styles.banner}>
        <PageGridContainer>
          <Grid item xs={12}>
            <div css={styles.bannerPlaceholder} />
          </Grid>
        </PageGridContainer>
      </div>

      <PageGridContainer>
        <Grid item xs={12}>
          <div css={styles.filterContainer} />
        </Grid>
      </PageGridContainer>
    </div>
  );
}

export default function ProgramHomeDomainSubdomainHeader({
  domain,
  subdomain,
  programSlug,
  thirdPartyOrgSlug,
  thirdPartyOrganizationId,
  programName,
}: Props) {
  const styles = useStyles();
  const onGetSubdomainPageUrl = (domainObj: Domain, subdomainObj: Subdomain) =>
    programSlug
      ? `/programs/${programSlug}/${domainObj.id}/${subdomainObj.id}`
      : `/programs/all/${thirdPartyOrgSlug}/${domainObj.id}/${subdomainObj.id}`;

  const subdomains: Subdomain[] | undefined = domain?.subdomains?.elements?.filter(
    (current: Subdomain) => current.id !== domain.id && (!subdomain || current.id !== subdomain.id)
  );

  return (
    <div css={styles.root}>
      <div css={styles.banner}>
        <PageGridContainer>
          <Grid item xs={12}>
            <DomainBreadcrumbs
              domain={domain}
              subdomain={subdomain}
              programSlug={programSlug}
              thirdPartyOrgSlug={thirdPartyOrgSlug}
              thirdPartyOrganizationId={thirdPartyOrganizationId}
              programName={programName}
              css={styles.breadcrumbs}
            />
            <Typography variant="d2" color="invertBody" component="h1" data-test="heading">
              {subdomain ? subdomain.name : domain.name}
            </Typography>
            {/* TODO(ppaskaris): Make this thing do react-router redirects */}
            {/* Or better yet, stop using Growth's component and just implement it ourselves.  */}
            {subdomains && subdomains.length >= 1 && (
              <Scroller
                className="ProgramHomeDomainSubdomainHeader-Scroller"
                trackingName="program_domain_subdomain_header_list"
              >
                <ul css={styles.subdomainList}>
                  {subdomains?.map((subdomainObj) => (
                    <li css={styles.subdomainListItem} key={subdomainObj.id} data-e2e="subdomain-pill">
                      <TrackedButton
                        {...Button.defaultProps}
                        variant="secondary"
                        component={Link}
                        to={onGetSubdomainPageUrl(domain, subdomainObj)}
                        trackingName="subdomain_pill"
                        trackingData={{ subdomain: subdomainObj.id }}
                      >
                        {subdomainObj.name}
                      </TrackedButton>
                    </li>
                  ))}
                </ul>
              </Scroller>
            )}
          </Grid>
        </PageGridContainer>
      </div>
      <div css={styles.filterContainer}>
        <PageGridContainer>
          <Grid item xs={12}>
            <SearchFilters />
          </Grid>
        </PageGridContainer>
      </div>
    </div>
  );
}
