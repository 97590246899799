import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useFocusRing } from '@react-aria/focus';
import isHotkey from 'is-hotkey';
import { Range as SlateRange } from 'slate';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import { ToolButtonFactory } from 'bundles/cml/editor/components/toolbars/constants';
import type { ToolOptions } from 'bundles/cml/editor/components/toolbars/types';
import { getHoveringTools } from 'bundles/cml/editor/components/toolbars/utils';
import { FocusContext } from 'bundles/cml/editor/context/focusContext';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import FloatingMenu from 'bundles/cml/shared/components/menu/FloatingMenu';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { HoverToolsKeys, ToolsKeys } from 'bundles/cml/shared/utils/customTools';

const isEscapeKey = isHotkey('escape');

type Props = {
  customTools: ToolsKeys[];
  options: ToolOptions;
};

const HoveringToolbar = (props: Props) => {
  const { customTools, options } = props;
  const editor = useSlate();
  const enableIndent = hasAncestorOfType(editor, [BLOCK_TYPES.BULLET_LIST, BLOCK_TYPES.NUMBER_LIST]);

  const toolOptions = useMemo(
    () => ({
      ...options,
      indent: enableIndent,
    }),
    [options, enableIndent]
  );

  const hoveringTools = useMemo(() => getHoveringTools(customTools, toolOptions), [customTools, toolOptions]);

  const [rangeRef, setRangeRef] = useState<Range | null>(null);
  const [open, setOpen] = useState(false);
  const { focused } = useContext(FocusContext);

  const staticEditor = useSlateStatic();
  const selection = editor.selection;

  useEffect(() => {
    setOpen(!!selection && SlateRange.isExpanded(selection) && focused); // && !hasVoidNode(value);
  }, [selection, focused]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!open) {
        setRangeRef(null);
        return;
      }

      try {
        const domSelection = window.getSelection();
        if (domSelection) {
          setRangeRef(domSelection.getRangeAt(0));
        }
      } catch {
        // eslint-disable-next-line no-console
        console.error('[CMLEditor] Unable to get range from selection');
      }
    }, 100);

    return () => clearTimeout(timeout);
  }, [open, selection]);

  const handleKeyDown = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (e: React.KeyboardEvent) => {
      if (isEscapeKey(e.nativeEvent)) {
        e.preventDefault();
        ReactEditor.focus(staticEditor);
        setOpen(false);
      }
    },
    [staticEditor]
  );

  const { isFocusVisible } = useFocusRing();

  return (
    <FloatingMenu
      anchorEl={rangeRef}
      strategy="absolute"
      offset={8}
      enableFlip
      enableReferenceHidden={false}
      enableEscaped={false}
      pageless
    >
      <Toolbar
        contentEditable={false}
        className="rc-HoveringToolbar"
        onKeyDown={handleKeyDown}
        autoFocus={isFocusVisible}
      >
        <React.Fragment>
          {hoveringTools.map((tool: HoverToolsKeys) => {
            const toolbarButtonFactory = ToolButtonFactory[tool];
            if (!toolbarButtonFactory) {
              return null;
            }

            const ToolbarButton = toolbarButtonFactory();

            return <ToolbarButton tools={customTools} options={options} key={tool} pageless={false} />;
          })}
        </React.Fragment>
      </Toolbar>
    </FloatingMenu>
  );
};

export default HoveringToolbar;
