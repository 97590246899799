/** @jsx jsx */
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { CourseraTierSubscriptions_BillingCycle as BillingCycleType } from '__generated__/graphql-types';

import { FormattedMessage } from 'js/lib/coursera.react-intl';

import { Typography2 } from '@coursera/cds-core';

import ChangePaymentMethodButton from 'bundles/coursera-plus/components/subscriptionTiers/upgradeToPlus/buttons/ChangePaymentMethodButton';
import { numberOfProducts } from 'bundles/coursera-plus/utils/courseraPlusUtils';
import { getPriceDisplayFromAmount, getReadableDateFromTimestamp } from 'bundles/coursera-plus/utils/generalUtils';
import { redactPayPalEmail } from 'bundles/coursera-plus/utils/subscriptionTiersUtils';
import type OnDemandSpecializationsV1 from 'bundles/naptimejs/resources/onDemandSpecializations.v1';
import type PaymentWalletsV1 from 'bundles/naptimejs/resources/paymentWallets.v1';
import type SubscriptionsV1 from 'bundles/naptimejs/resources/subscriptions.v1';
import ReactPriceDisplay from 'bundles/payments-common/components/ReactPriceDisplay';
import type { ProductPrices } from 'bundles/payments-common/hooks/useGetProductPrices';
import { getRefundPolicyLink, getTermsOfUseLink } from 'bundles/payments/common/staticLinks';

import _t from 'i18n!nls/coursera-plus';

const styles = {
  byClickingThisUpgrade: css`
    display: block;
    margin-bottom: 16px;
  `,
  noWrap: css`
    white-space: nowrap;
  `,
  boldText: css`
    font-weight: bold;
  `,
};

export const getStepCopy = (step: number) => {
  return {
    step: <FormattedMessage message={_t('Step {step} of 2')} step={step} />,
    courseraPlus: _t('Coursera Plus'),
  };
};

export type StepOneIndiaTiersModalCopy = {
  stepHeader: string;
  youGet: string;
  valuePropsList: (string | jsx.JSX.Element | undefined)[];
  price: jsx.JSX.Element;
  continue: string;
  courseraPlus: string;
};

export const getCopyForStepOneIndiaTiersTest = ({
  courseraPlusProductPrice,
  hasCourseraPlusFreeTrial,
  translatedProductTypeName,
  s12n,
}: {
  courseraPlusProductPrice: ProductPrices;
  hasCourseraPlusFreeTrial: boolean;
  translatedProductTypeName?: string;
  s12n?: OnDemandSpecializationsV1;
}) => {
  let coursesInThis;
  if (s12n) {
    coursesInThis = (
      <FormattedMessage
        message={_t('Access to all {numberOfCourses} courses in this {productName}')}
        numberOfCourses={s12n.courseIds.length}
        productName={translatedProductTypeName}
      />
    );
  }
  return {
    stepHeader: _t('Upgrade to Coursera Plus for the full learning experience'),
    youGet: _t("You'll get:"),
    valuePropsList: [
      coursesInThis,
      <Typography2 component="p">
        <FormattedMessage
          message={_t('{gradedAssignments} to measure your progress')}
          gradedAssignments={<span css={styles.boldText}>{_t('Graded assignments and quizzes')}</span>}
        />
      </Typography2>,
      _t('#{numberOfProducts} additional learning programs', { numberOfProducts }),
      <Typography2 component="p">
        <FormattedMessage
          message={_t('{aCertificate} for every learning program you complete after your trial ends')}
          aCertificate={<span css={styles.boldText}>{_t('A certificate')}</span>}
        />
      </Typography2>,
      hasCourseraPlusFreeTrial ? _t('A 7-day free trial') : undefined,
    ],
    price: (
      <Typography2 component="p">
        <FormattedMessage
          message={_t('{price} monthly')}
          price={
            <ReactPriceDisplay
              value={Number(courseraPlusProductPrice.amount)}
              currency={courseraPlusProductPrice.currencyCode}
              hideCurrencyCode={true}
            />
          }
        />
      </Typography2>
    ),
    continue: _t('Continue'),
    courseraPlus: _t('Coursera Plus'),
  };
};

export type StepTwoIndiaTiersModalCopy = {
  stepHeader: string;
  disclaimer: jsx.JSX.Element;
  courseraPlus: string;
  upgradeToCourseraPlus: string;
};

export const getCopyForStepTwoModalIndiaTiersTest = (translatedProductTypeName?: string) => {
  return {
    stepHeader: _t('More about your Coursera Plus upgrade'),
    disclaimer: (
      <FormattedMessage
        message={_t(
          "You'll automatically be enrolled in this {productName}. To save your progress in other learning programs, please re-enroll in all of them after you upgrade to Coursera Plus."
        )}
        productName={translatedProductTypeName}
      />
    ),
    courseraPlus: _t('Coursera Plus logo'),
    upgradeToCourseraPlus: _t('Upgrade to Coursera Plus'),
  };
};

export const getCopyForStepThreeModalIndiaTiersTest = (courseName: string) => {
  const defaultActionPointsList = [
    _t(
      "If you're enrolled in other learning programs, please re-enroll in each one to save your progress. We'll send you an email with details."
    ),
    _t(
      "If your 7-day free trial has ended, you'll be refunded for your last Coursera Lite payment in 7-10 business days."
    ),
  ];

  let actionPointsList = defaultActionPointsList;
  if (courseName) {
    actionPointsList = [
      _t("Keep learning—you're currently enrolled in #{courseName} and have access.", { courseName }),
      ...defaultActionPointsList,
    ];
  }

  return {
    courseraPlus: _t('Coursera Plus'),
    title: (
      <FormattedMessage
        message={_t("Success! You've upgraded to {courseraPlus} 🎉")}
        courseraPlus={<span css={styles.noWrap}>{_t('Coursera Plus')}</span>}
      />
    ) as React.ReactNode & React.ReactText,
    actionPointsTitle: _t('What happens next:'),
    actionPointsList,
    primaryCta: _t('Confirm'),
  };
};

export const getCopyForNotification = (s12nName: string) => {
  return (
    <strong>
      <FormattedMessage
        message={_t(
          "Success! You've upgraded to Coursera Plus and have full access to {productName}. We've sent you a confirmation email with your updated subscription information."
        )}
        productName={s12nName}
      />
    </strong>
  );
};

export const getErrorStateModalCopy = ({ courseId, s12nId }: { courseId?: string; s12nId?: string }) => {
  return {
    title: _t("We weren't able to complete your payment due to a technical issue."),
    loadingText: _t('Fetching data...'),
    subText: (
      <FormattedMessage
        message={_t("We're taking you to our {checkoutPageButton} where you can make your payment.")}
        checkoutPageButton={
          <ChangePaymentMethodButton courseId={courseId} s12nId={s12nId} variant="ghost" size="small">
            {_t('checkout page')}
          </ChangePaymentMethodButton>
        }
      />
    ),
  };
};

type IdealUpgradeLegalCopyInput = {
  nextBillingDatePrice: JSX.Element;
  nextBillingDate: string;
  toTierBillingCycle: BillingCycleType;
  isStandaloneCourseraPlusUpgrade: boolean;
};

export const getIdealUpgradeLegalCopy = ({
  nextBillingDatePrice,
  nextBillingDate,
  isStandaloneCourseraPlusUpgrade,
  toTierBillingCycle,
}: IdealUpgradeLegalCopyInput) => {
  const isAnnualCourseraPlus = toTierBillingCycle === BillingCycleType.Annual;
  let yourSubscriptionStartsToday = <strong>{_t('Your Coursera Plus subscription starts today')}</strong>;

  // We only need to mention "this course" if the learner is upgrading
  // from course, not when they're purchasing via landing page.
  if (!isStandaloneCourseraPlusUpgrade && !isAnnualCourseraPlus) {
    yourSubscriptionStartsToday = (
      <FormattedMessage
        message={_t('{yourSubscriptionStartsToday} and includes this course')}
        yourSubscriptionStartsToday={yourSubscriptionStartsToday}
      />
    );
  }

  const youWillBeCharged = (
    <strong>
      <FormattedMessage
        message={_t(
          "You'll be charged {nextBillingDatePrice} (plus applicable taxes) on {nextBillingDate}, and {billingCycle} thereafter until you cancel."
        )}
        nextBillingDatePrice={nextBillingDatePrice}
        nextBillingDate={nextBillingDate}
        billingCycle={isAnnualCourseraPlus ? _t('yearly') : _t('monthly')}
      />
    </strong>
  );

  const byClickingThisUpgrade = (
    <span css={styles.byClickingThisUpgrade}>
      <FormattedMessage
        message={_t(
          'By clicking "Upgrade to Coursera Plus," you agree to the terms below, {termsOfUseLink}, and {refundPolicyLink}.'
        )}
        termsOfUseLink={getTermsOfUseLink()}
        refundPolicyLink={getRefundPolicyLink()}
      />
    </span>
  );

  return (
    <FormattedMessage
      message={_t(
        '{byClickingThisUpgrade} {yourSubscriptionStartsToday}. {youWillBeCharged} To avoid future charges, you can cancel any time in your account settings.'
      )}
      byClickingThisUpgrade={byClickingThisUpgrade}
      yourSubscriptionStartsToday={yourSubscriptionStartsToday}
      youWillBeCharged={youWillBeCharged}
      nextBillingDate={nextBillingDate}
    />
  );
};

type PaymentDetailsInputType = {
  lastFourCreditCardDigits?: JSX.Element;
  nextBillingDatePrice: JSX.Element;
  toTierBillingCycle?: BillingCycleType;
  priceToday: JSX.Element;
};

type PaymentDetailsCopyType = {
  title: string;
  securityText: string;
  todaysTotalLabel: string;
  todaysTotalPrice: JSX.Element;
  lastFourCreditCardDigits?: JSX.Element;
  monthlyPrice: JSX.Element;
};

export const getPaymentDetailsCopy = ({
  lastFourCreditCardDigits,
  toTierBillingCycle,
  priceToday,
  nextBillingDatePrice,
}: PaymentDetailsInputType): PaymentDetailsCopyType => {
  return {
    title: _t('Payment details'),
    securityText: _t('Your payments are processed securely'),
    todaysTotalLabel: _t("Today's total:"),
    todaysTotalPrice: priceToday,
    lastFourCreditCardDigits,
    monthlyPrice: (
      <FormattedMessage
        message={_t('then {price}/{billingCycle}')}
        price={nextBillingDatePrice}
        billingCycle={toTierBillingCycle === BillingCycleType.Annual ? _t('year') : _t('mo')}
      />
    ),
  };
};

export type StepThreeCopyType = {
  title: React.ReactNode & React.ReactText;
  courseraPlus: string;
  actionPointsTitle: string;
  actionPointsList: string[];
  primaryCta: string;
};

export const getIdealUpgradeStepThreeCopy = ({
  isStandaloneCourseraPlusUpgrade,
}: {
  isStandaloneCourseraPlusUpgrade: boolean;
}): StepThreeCopyType => {
  return {
    courseraPlus: _t('Coursera Plus'),
    title: (
      <FormattedMessage
        message={_t("Success! You've upgraded to {courseraPlus} 🎉")}
        courseraPlus={<span css={styles.noWrap}>{_t('Coursera Plus')}</span>}
      />
    ) as React.ReactNode & React.ReactText,
    actionPointsTitle: _t('What happens next:'),
    actionPointsList: [
      _t("We'll send you a confirmation email with details about your new subscription"),
      isStandaloneCourseraPlusUpgrade
        ? _t(
            'Start exploring courses while we take 10-30 minutes to prepare access to your Coursera Plus learning programs'
          )
        : _t(
            'Jump back into this quiz while we take 10-30 minutes to prepare access to the rest of your Coursera Plus learning programs'
          ),
    ],
    primaryCta: _t('Continue'),
  };
};

export type StepOneCopyType = {
  title: string;
  valuePropTitle: string;
  courseraPlus: string;
  valuePropList: Array<string | JSX.Element>;
  primaryCta: string;
};

export const getIdealUpgradeStepOneCopy = ({
  nextBillingDatePrice,
  toTierBillingCycle,
}: {
  nextBillingDatePrice: JSX.Element;
  toTierBillingCycle: BillingCycleType;
}): StepOneCopyType => {
  const gradedQuizzes = (
    <FormattedMessage
      message={_t('{gradedQuizzes} to measure your progress')}
      gradedQuizzes={<strong>{_t('Graded assignments and quizzes')}</strong>}
    />
  );
  const guidedProjects = (
    <FormattedMessage
      message={_t('{guidedProjects} to learn by doing')}
      guidedProjects={<strong>{_t('2,000+ guided projects')}</strong>}
    />
  );
  const certificate = (
    <FormattedMessage
      message={_t('{certificate} for every learning program you complete after your trial ends')}
      certificate={<strong>{_t('A certificate')}</strong>}
    />
  );
  const monthlyPrice = (
    <strong>
      <FormattedMessage
        message={_t('A {monthlyPrice} {billingCycle} subscription')}
        monthlyPrice={nextBillingDatePrice}
        billingCycle={toTierBillingCycle === BillingCycleType.Annual ? _t('yearly') : _t('monthly')}
      />
    </strong>
  );

  return {
    title: _t('Upgrade to Coursera Plus for the full learning experience'),
    primaryCta: _t('Continue'),
    courseraPlus: _t('Coursera Plus'),
    valuePropTitle: _t("You'll get: "),
    valuePropList: [gradedQuizzes, guidedProjects, certificate, monthlyPrice],
  };
};

type StepTwoCopyInputType = {
  priceToday: JSX.Element;
  nextBillingDatePrice: JSX.Element;
  readableNextBillingDate: string;
  lastFourCreditCardDigits?: JSX.Element;
  isStandaloneCourseraPlusUpgrade: boolean;
  toTierBillingCycle: BillingCycleType;
};

export type StepTwoCopyType = {
  title: string;
  stepProgressTodayPriceCopy: JSX.Element;
  stepProgressNextBillingPriceCopy: JSX.Element;
  legal: JSX.Element;
  primaryCta: string;
  secondaryCta: string;
  paymentDetails: PaymentDetailsCopyType;
};

export const getIdealUpgradeStepTwoCopy = ({
  priceToday,
  nextBillingDatePrice,
  readableNextBillingDate,
  lastFourCreditCardDigits,
  isStandaloneCourseraPlusUpgrade,
  toTierBillingCycle,
}: StepTwoCopyInputType): StepTwoCopyType => {
  const stepProgressTodayPriceCopy = (
    <Typography2 component="span">
      <FormattedMessage
        message={_t('{getInstantAccess} to graded assignments and quizzes')}
        getInstantAccess={<strong>{_t('Get instant access')}</strong>}
      />
    </Typography2>
  );

  const stepProgressNextBillingPriceCopy = (
    <Typography2 component="span">
      <FormattedMessage
        message={_t('{nextBillingDatePriceAndCycle} starting on {nextBillingDate}')}
        nextBillingDatePriceAndCycle={
          <strong>
            <FormattedMessage
              message={_t('Pay {nextBillingDatePrice} {billingCycle}')}
              nextBillingDatePrice={nextBillingDatePrice}
              billingCycle={toTierBillingCycle === BillingCycleType.Annual ? _t('yearly') : _t('monthly')}
            />
          </strong>
        }
        nextBillingDate={readableNextBillingDate}
      />
    </Typography2>
  );

  return {
    title: _t('What to expect when you upgrade:'),
    primaryCta: _t('Upgrade to Coursera Plus'),
    secondaryCta: _t('Pay with'),
    stepProgressTodayPriceCopy,
    stepProgressNextBillingPriceCopy,
    legal: getIdealUpgradeLegalCopy({
      nextBillingDatePrice,
      nextBillingDate: readableNextBillingDate,
      isStandaloneCourseraPlusUpgrade,
      toTierBillingCycle,
    }),
    paymentDetails: getPaymentDetailsCopy({
      lastFourCreditCardDigits,
      toTierBillingCycle,
      nextBillingDatePrice,
      priceToday,
    }),
  };
};

export type IdealUpgradeGetModalCopyInputType = {
  existingCourseraLiteSubscription: SubscriptionsV1;
  courseraPlusProductPrice: ProductPrices;
  braintreeWallets?: Array<PaymentWalletsV1>;
  paypalWallets?: Array<PaymentWalletsV1>;
  isStandaloneCourseraPlusUpgrade: boolean;
  toTierBillingCycle: BillingCycleType;
};

export type IdealUpgradeModalCopyType = {
  stepTwoCopy: StepTwoCopyType;
  stepOneCopy: StepOneCopyType;
  stepThreeCopy: StepThreeCopyType;
};

export const getIdealUpgradeModalsCopy = (input: IdealUpgradeGetModalCopyInputType): IdealUpgradeModalCopyType => {
  const {
    existingCourseraLiteSubscription,
    braintreeWallets,
    paypalWallets,
    courseraPlusProductPrice,
    isStandaloneCourseraPlusUpgrade,
    toTierBillingCycle,
  } = input;
  const priceToday = getPriceDisplayFromAmount(0, courseraPlusProductPrice.currencyCode);
  const nextBillingDatePrice = getPriceDisplayFromAmount(
    courseraPlusProductPrice.amount,
    courseraPlusProductPrice.currencyCode
  );

  const braintreeWallet = braintreeWallets?.[0];
  const paypalWallet = paypalWallets?.[0];

  let lastFourCreditCardDigits;
  if (braintreeWallet?.creditCardLastFourDigits) {
    lastFourCreditCardDigits = (
      <span>
        {braintreeWallet?.creditCardType}
        <span style={{ marginLeft: '12px' }}>*</span>
        {braintreeWallet?.creditCardLastFourDigits}
      </span>
    );
  } else if (paypalWallet?.email) {
    lastFourCreditCardDigits = (
      <FormattedMessage message={_t('PayPal: {paypalEmail}')} paypalEmail={redactPayPalEmail(paypalWallet?.email)} />
    );
  }

  const subscriptionNextBillingAt = existingCourseraLiteSubscription.nextBillingAt;
  if (!subscriptionNextBillingAt) {
    throw new Error('Invalid next billing date');
  }

  const readableNextBillingDate = getReadableDateFromTimestamp(subscriptionNextBillingAt);

  return {
    stepOneCopy: getIdealUpgradeStepOneCopy({ nextBillingDatePrice, toTierBillingCycle }),
    stepTwoCopy: getIdealUpgradeStepTwoCopy({
      priceToday,
      nextBillingDatePrice,
      readableNextBillingDate,
      lastFourCreditCardDigits,
      isStandaloneCourseraPlusUpgrade,
      toTierBillingCycle,
    }),
    stepThreeCopy: getIdealUpgradeStepThreeCopy({ isStandaloneCourseraPlusUpgrade }),
  };
};
