import React from 'react';

import { useFocusRing } from '@react-aria/focus';
import { usePress } from '@react-aria/interactions';
import clsx from 'clsx';

import { useId, mergeProps, IconContext } from '@coursera/cds-common';

import type { CheckboxProps } from '@core/Checkbox/Checkbox';
import { useCheckboxGroupContext } from '@core/CheckboxGroup/CheckboxGroupContext';
import type { BaseCheckboxProps } from '@core/forms';
import { CheckboxInput } from '@core/forms/CheckboxInput';
import getFormCardCss, { classes } from '@core/forms/getFormCardCss';
import Typography from '@core/Typography2';
import { useControlled } from '@core/utils';
import { ariaLabelledByForMultipleLabels } from '@core/utils/a11y';

export type Props = Omit<CheckboxProps, 'indeterminate'>;

/**
 * CheckboxCard is a styled as a card Checkbox component
 *
 * See [Props](__storybookUrl__/components-inputs-checkbox--default#props)
 */
const CheckboxCard = React.forwardRef<HTMLLabelElement, Props>(
  function CheckboxCard(props, ref) {
    const {
      id: idFromProps,
      icon,
      children,
      label,
      labelSuffix,
      supportText,
      disabled,
      onChange,
      value,
      name,
      inputRef,
      inputProps,
      validationStatus,
      className,
      checked: checkedProp,
      defaultChecked = false,
      ...rest
    } = props;

    const { isFocusVisible, focusProps } = useFocusRing({
      within: true,
    });
    const { isPressed, pressProps } = usePress({});

    const context = useCheckboxGroupContext();

    const id = useId(idFromProps);
    const labelTextId = `${id}-label-text`;
    const labelSuffixId = labelSuffix ? `${id}-label-suffix` : undefined;
    const supportTextId = supportText ? `${id}-support-text` : undefined;

    // Determine the initial checked state
    const initialChecked =
      value && context?.value ? context.value.includes(value) : checkedProp;

    const [checked, setChecked] = useControlled<boolean>({
      controlled: initialChecked,
      default: defaultChecked,
      name: 'CheckboxCard',
      state: 'checked',
    });

    const handleChange: BaseCheckboxProps['onChange'] = (event, value) => {
      setChecked(value);
      onChange?.(event, value);
    };

    return (
      <label
        ref={ref}
        className={clsx(className, classes.label, {
          [classes.checked]: checked,
          [classes.disabled]: disabled,
          [classes.success]: validationStatus === 'success',
          [classes.error]: validationStatus === 'error',
          [classes.pressed]: isPressed,
          [classes.focusVisible]: isFocusVisible,
          [classes.hasIcon]: !!icon,
        })}
        css={getFormCardCss}
        id={id}
        {...mergeProps(focusProps, pressProps, rest)}
      >
        <CheckboxInput
          disableFocusVisible
          checked={checked}
          className={classes.input}
          disabled={disabled}
          inputProps={{
            'aria-labelledby': ariaLabelledByForMultipleLabels(
              labelTextId,
              labelSuffixId,
              supportTextId
            ),
            ...inputProps,
          }}
          inputRef={inputRef}
          name={name}
          value={value}
          onChange={handleChange}
        />

        {icon && (
          <div className={classes.icon}>
            <IconContext.Provider value={{ size: 'medium' }}>
              {icon}
            </IconContext.Provider>
          </div>
        )}

        <div className={classes.labelText}>
          <Typography
            color="inherit"
            component="span"
            id={labelTextId}
            variant="bodyPrimary"
          >
            {label}
          </Typography>
          {labelSuffix && (
            <Typography
              className={classes.labelSuffix}
              color="inherit"
              component="span"
              id={labelSuffixId}
            >
              {labelSuffix}
            </Typography>
          )}
        </div>

        {supportText && (
          <Typography
            className={classes.supportText}
            component="div"
            id={supportTextId}
            variant="bodySecondary"
          >
            {supportText}
          </Typography>
        )}

        {children && (
          <div className={classes.nonInteractiveSpace}>{children}</div>
        )}
      </label>
    );
  }
);

export default CheckboxCard;
