import { type Change, diffLines } from 'diff';
import type { Editor, Node, NodeEntry } from 'slate';

import type { Options } from 'bundles/cml/editor/normalize/types';

type StandardNormalizerArgs = [editor: Editor, options: Options, nodeEntry: NodeEntry<Node>, token: unknown];

type TokenlessNormalizerArgs = [editor: Editor, options: Options, nodeEntry: NodeEntry<Node>];

export type NodeEntryNormalizerArgs = [editor: Editor, nodeEntry: NodeEntry<Node>];

type AllNormalizersArgs = StandardNormalizerArgs | TokenlessNormalizerArgs | NodeEntryNormalizerArgs;

type NormalizerFunction<TArgs extends Array<any>> = (...args: TArgs) => boolean;

const chooseCharacter = (diff: Change) => {
  if (diff.added) {
    return '+';
  } else if (diff.removed) {
    return '-';
  }

  return ' ';
};

const mapToLoggable = (prefix: string, textBlock: string) => {
  // Remove trailing newline
  const withoutTrailingNewline = textBlock.replace(/\n$/, '');
  const lines = withoutTrailingNewline.split('\n');

  return lines.map((line) => `${prefix} ${line}`);
};

/**
 * @private exported only for test
 */
export const diffAndFormat = (before: string, after: string) => {
  const diffResult = diffLines(before, after);

  const contentToLog = diffResult.reduce((linesToLog, diff) => {
    const prefix = chooseCharacter(diff);
    return [...linesToLog, ...mapToLoggable(prefix, diff.value)];
  }, new Array<string>());

  return contentToLog.join('\n');
};

/**
 * A mechanism to allow logging of the effects of normalizers when debugging within tests.
 *
 * Provide the environment variable of LOG_CML_EDITOR_NORMALIZATION to your jest process to print this information.
 */
const normalizerLogger = <TNormalizerArgs extends AllNormalizersArgs>(
  fn: NormalizerFunction<TNormalizerArgs>,
  /** Optionally pass the name of the function being tracked if the first argument is an anonymous function */
  functionName?: string
): NormalizerFunction<TNormalizerArgs> => {
  const wrappedFunction: NormalizerFunction<TNormalizerArgs> = (...args): boolean => {
    // Exit ASAP if we're not in jest
    // eslint-disable-next-line no-process-env
    if (typeof process === 'undefined' || !process.env.LOG_CML_EDITOR_NORMALIZATION) {
      return fn(...args);
    }

    const wrappedFunctionName = functionName || fn.name;
    const editor = args[0];
    const before = JSON.stringify(editor.children, null, 2);

    const result = fn(...args);

    if (result) {
      // eslint-disable-next-line no-console
      console.log(`Normalizer had effect: ${wrappedFunctionName}`);
      const after = JSON.stringify(editor.children, null, 2);
      // eslint-disable-next-line no-console
      console.log(diffAndFormat(before, after));
    }

    return result;
  };

  return wrappedFunction;
};

export default normalizerLogger;
