/**
 * Private module reserved for @coursera/cds-core package.
 */

import { createLargeSvgIcon } from '@core/utils';

import { classes } from './getRadioInputCss';

export default createLargeSvgIcon('RadioCheckedIcon', {
  large: (
    <>
      <circle
        className={classes.checkedIconBg}
        cx="10"
        cy="10"
        fill="#fff"
        r="9.1666663"
        stroke="currentColor"
        strokeWidth="1.6666666667"
      />
      <circle cx="10" cy="10" fill="currentColor" r="5.83333" />
    </>
  ),
});
