import * as React from 'react';
import { useCallback } from 'react';

import type { RenderElementProps, RenderLeafProps, RenderPlaceholderProps } from 'slate-react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import AIElement from 'bundles/cml/editor/components/elements/ai/AIElement';
import Asset from 'bundles/cml/editor/components/elements/asset/Asset';
import Code from 'bundles/cml/editor/components/elements/code/Code';
import FillableBlank from 'bundles/cml/editor/components/elements/fillableBlank/FillableBlank';
import Image from 'bundles/cml/editor/components/elements/image/Image';
import ImageUploader from 'bundles/cml/editor/components/elements/imageUploader/ImageUploader';
import Link from 'bundles/cml/editor/components/elements/link/Link';
import Math from 'bundles/cml/editor/components/elements/math/Math';
import PersonalizationTag from 'bundles/cml/editor/components/elements/personalizationTag/PersonalizationTag';
import Placeholder from 'bundles/cml/editor/components/elements/placeholder/Placeholder';
import Table from 'bundles/cml/editor/components/elements/table/Table';
import TableCell from 'bundles/cml/editor/components/elements/table/TableCell';
import Text from 'bundles/cml/editor/components/elements/text/Text';
import Widget from 'bundles/cml/editor/components/elements/widget/Widget';
import Heading from 'bundles/cml/shared/components/heading/Heading';
import List from 'bundles/cml/shared/components/list/List';
import ListItem from 'bundles/cml/shared/components/list/ListItem';
import Paragraph from 'bundles/cml/shared/components/paragraph/Paragraph';
import TableRow from 'bundles/cml/shared/components/table/TableRow';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

export const useRenderElement = () =>
  useCallback<(...args: $TSFixMe[]) => $TSFixMe>((props: RenderElementProps) => {
    switch (props.element.type) {
      case BLOCK_TYPES.HEADING:
        return <Heading {...props} />;

      case BLOCK_TYPES.LINK:
        return <Link {...props} />;

      case BLOCK_TYPES.IMAGE:
        return <Image {...props} />;

      case BLOCK_TYPES.IMAGE_UPLOADER:
        return <ImageUploader {...props} />;

      case BLOCK_TYPES.LEGACY_AUDIO:
      case BLOCK_TYPES.ASSET:
        return <Asset {...props} />;

      case BLOCK_TYPES.TABLE:
        return <Table {...props} />;

      case BLOCK_TYPES.TABLE_ROW:
        return <TableRow {...props} />;

      case BLOCK_TYPES.TABLE_CELL:
        return <TableCell {...props} />;

      case BLOCK_TYPES.MATH_BLOCK:
      case BLOCK_TYPES.MATH_INLINE:
        return <Math {...props} />;

      case BLOCK_TYPES.BULLET_LIST:
      case BLOCK_TYPES.NUMBER_LIST:
        return <List {...props} />;

      case BLOCK_TYPES.LIST_ITEM:
        return <ListItem {...props} />;

      case BLOCK_TYPES.CODE:
        return <Code {...props} />;

      case BLOCK_TYPES.FILLABLE_BLANK:
        return <FillableBlank {...props} />;

      case BLOCK_TYPES.PERSONALIZATION_TAG:
        return <PersonalizationTag {...props} />;

      case BLOCK_TYPES.WIDGET:
        return <Widget {...props} />;

      case BLOCK_TYPES.AI_ELEMENT:
        return <AIElement {...props} />;

      case BLOCK_TYPES.TEXT:
      default:
        return <Paragraph {...props} />;
    }
  }, []);

export const useRenderTextElement = () =>
  useCallback<(...args: $TSFixMe[]) => $TSFixMe>((props: RenderLeafProps) => <Text {...props} />, []);

export const useRenderPlaceholder = () =>
  useCallback<(...args: $TSFixMe[]) => $TSFixMe>((props: RenderPlaceholderProps) => <Placeholder {...props} />, []);
