import * as React from 'react';
import { useCallback } from 'react';

import { MenuList } from '@coursera/cds-core';

import { AI_TOOL, type AI_TOOLS } from 'bundles/cml/shared/constants';
import { useTracker } from 'bundles/page/lib/event-pulse/react';

import _t from 'i18n!nls/cml';

type Props = {
  onChange: (change: { aiTool: AI_TOOLS; prompt?: string }) => void;
};

const ExpandTextMenuItem: React.FC<Props> = ({ onChange }) => {
  const track = useTracker();
  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    onChange({ aiTool: AI_TOOL.CONTINUE });
    track('click_button', { button: { name: 'writing_assistant_expand_text' } });
  }, [track, onChange]);

  return <MenuList.Item onClick={handleClick}>{_t('Expand text')}</MenuList.Item>;
};

export default ExpandTextMenuItem;
