/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import type { AiCoach_Action as AiCoachAction } from '__generated__/graphql-types';
import classNames from 'classnames';

import { get as getUser } from 'js/lib/user';

import { Grid, Typography2, breakpoints } from '@coursera/cds-core';

import CoachIcon from 'bundles/ai-coach-platform/components/branding/CoachIcon';
import {
  CopySingleResponse,
  LearnerAvatar,
  ProgressiveLoader,
} from 'bundles/ai-coach-platform/components/building-blocks';
import ChatEmptyState from 'bundles/ai-coach-platform/components/patterns/chat/common/ChatEmptyState';
import ChatRecommendations from 'bundles/ai-coach-platform/components/patterns/chat/recommendations/ChatRecommendations';
import type { CoachPersona, SendMessageFunc } from 'bundles/ai-coach-platform/components/patterns/chat/types';
import { firstNameFromFullName } from 'bundles/ai-coach-platform/utils/nameParser';

import Feedback from './Feedback';
import ChatMessage from './common/ChatMessage';
import type { GlobalState, MessageTypes } from './store/types';
import {
  isClientMessage as isClientMessageUtil,
  isCoachMessage as isCoachMessageUtil,
  isErrorMessage as isErrorMessageUtil,
  isTextMessage as isTextMessageUtil,
} from './utils/messageUtils';

export type HideMessageType = ({
  message,
  index,
  messages,
}: {
  message: MessageTypes;
  index: number;
  messages: Array<MessageTypes>;
}) => boolean;

export type Props = {
  profileClientAvatar?: string;
  sendMessage: SendMessageFunc;
  emptyState?: React.ReactElement;
  courseId?: string;

  /**
   * Hides the message in the chat. This function accepts an individual message and its index
   * returns a boolean value if the message needs to be hidden based on the function's implementation
   * at the parent level.
   * Eg: Hiding the first "initiation" message and chat summary message for coach item use case.
   */
  hideMessage?: HideMessageType;
  activeChatSessionId?: string;
  coachPersona?: CoachPersona;
  forceHideMessageLoader?: boolean;
};

const styles = {
  messagesContainer: () => css`
    /* Hide scrollbar for Chrome, Safari and Opera */

    ::-webkit-scrollbar {
      display: none;
    }

    height: 100%;
    max-height: none;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ${breakpoints.down('xs')} {
      height: 100%;
      max-height: none;
    }
  `,
  message: css`
    padding: 0 var(--cds-spacing-200);
    margin: var(--cds-spacing-200) 0;
    word-wrap: break-word;

    svg {
      flex-shrink: 0;
    }

    &.coach-message-response {
      border-radius: 0;
      scroll-margin-top: var(--cds-spacing-150);
    }

    &:focus {
      outline: none;
    }
  `,
  messageContainer: () => css`
    display: flex;
    gap: var(--cds-spacing-100);
  `,
  senderName: css`
    color: var(--cds-color-grey-950);
    font-weight: 600;
  `,
};

export const MessageContainer = ({
  avatar,
  name,
  children,
}: {
  /**
   * Message sender avatar
   */
  avatar?: React.ReactNode;
  /**
   * Message sender name
   */
  name: string;
  /**
   * Message content
   */
  children: React.ReactNode;
}) => {
  return (
    <div className="coach-message-container" css={styles.messageContainer}>
      {avatar}
      <Grid
        container
        css={css`
          overflow: auto;
          display: flex;
          flex-direction: column;
          gap: none;
        `}
      >
        <Grid item>
          <Typography2 component="h4" variant="subtitleMedium" css={styles.senderName}>
            {name}
          </Typography2>
        </Grid>

        <Grid
          item
          css={css`
            max-width: 100%;
          `}
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

const CoachLoader = ({
  action,
  coachAvatar,
  coachName,
}: {
  action?: AiCoachAction;
  coachAvatar: React.ReactNode;
  coachName: string;
}): React.ReactElement => {
  return (
    <div className="coach-message" css={styles.message} role="status" aria-live="polite">
      <MessageContainer avatar={coachAvatar} name={coachName}>
        <ProgressiveLoader action={action} />
      </MessageContainer>
    </div>
  );
};

const ChatMessages = ({
  profileClientAvatar,
  emptyState,
  hideMessage,
  sendMessage,
  activeChatSessionId: chatSessionId,
  coachPersona,
  forceHideMessageLoader,
}: Props) => {
  const { messages, showMessageLoader, activeChatSessionId } = useSelector((state: GlobalState) => ({
    messages: (chatSessionId && state.messages.messagesBySessionId[chatSessionId]) || state.messages.messages,
    showMessageLoader: state.behavior.messageLoader,
    activeChatSessionId: state.sessionState.chatSessionId,
  }));

  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  const messageCount = messages.length;

  // scroll latest message into view every time `messageCount` updates i.e. new message is added
  useEffect(() => {
    if (lastMessageRef.current) {
      // First need to focus before scrolling into view otherwise the latest message will not always scroll to the top if it's a long message
      lastMessageRef.current.focus();
      lastMessageRef.current.scrollIntoView?.({ behavior: 'smooth' });
    }
  }, [messageCount]);

  const { fullName: userName, id: userId } = getUser();
  const userFirstName = firstNameFromFullName(userName);

  const learnerAvatarToRender = <LearnerAvatar imageUrl={profileClientAvatar} name={userFirstName} />;
  const { name: coachName, avatar: coachAvatar } = coachPersona || {};
  const coachAvatarToRender = coachAvatar || <CoachIcon size="medium" />;
  const coachNameToRender = coachName || 'Coach'; // 'Coach' is intentionally not translated since it is a product name

  const shouldShowMessageLoader =
    !forceHideMessageLoader && showMessageLoader && (activeChatSessionId === chatSessionId || !chatSessionId);

  const emptyStateRenderer = () => {
    return emptyState ?? <ChatEmptyState />;
  };

  return (
    <div
      data-testid="ai-coach-chat-messages"
      ref={messageContainerRef}
      css={[
        styles.messagesContainer,
        !messages?.length &&
          css`
            height: 100%;
          `,
      ]}
    >
      {!messages?.length && emptyStateRenderer()}
      {messages?.map((message, index) => {
        const { timestamp, sender } = message;

        const isLastMessage = index === messages.length - 1;

        const isCoachMessage = isCoachMessageUtil(sender);
        const isClientMessage = isClientMessageUtil(sender);

        // only allow Coach text messages to be copied
        const showCopyMessage = isCoachMessage && isTextMessageUtil(message as MessageTypes);
        const isErrorMessage = isCoachMessage && isErrorMessageUtil(message as MessageTypes);

        if (hideMessage && hideMessage({ message, index, messages })) {
          return null;
        }

        return (
          <div
            className={classNames('coach-message', {
              'coach-message-client': isClientMessage,
              'coach-message-response': isCoachMessage,
            })}
            key={isCoachMessage ? message.customId : `${userId}-${timestamp.valueOf()}`}
            css={styles.message}
            ref={isLastMessage ? lastMessageRef : null}
            // we only want the latest message to be called out, not the entire chat history,
            // see https://coursera.atlassian.net/browse/COACH-130
            aria-live={isLastMessage ? 'polite' : 'off'}
            tabIndex={isLastMessage ? -1 : undefined}
          >
            <MessageContainer
              avatar={isClientMessage ? learnerAvatarToRender : coachAvatarToRender}
              name={isClientMessage ? userFirstName : coachNameToRender}
            >
              <ChatMessage message={message as MessageTypes} />
              {isCoachMessage && (
                <ChatRecommendations
                  message={message}
                  sendMessage={sendMessage}
                  showRecommendedActions={isLastMessage}
                />
              )}
              {/* hide message actions for Coach error messages */}
              {!isErrorMessage && (
                <div
                  className="coach-message-actions"
                  css={css`
                    display: flex;
                    justify-content: end;
                    align-items: center;
                  `}
                >
                  {showCopyMessage && <CopySingleResponse message={message.text} />}
                  <Feedback message={message as MessageTypes} />
                </div>
              )}
            </MessageContainer>
          </div>
        );
      })}

      {shouldShowMessageLoader && (
        <CoachLoader
          action={messages[messages.length - 1]?.action}
          coachAvatar={coachAvatarToRender}
          coachName={coachNameToRender}
        />
      )}
    </div>
  );
};

export default ChatMessages;
