import { useCallback, useEffect, useRef, useState } from 'react';

type FocusCallback = () => void;

const OVERRIDE_FOCUS_MS = 0;
const OVERRIDE_BLUR_MS = 250;

export const useFocusHandler = (
  element: HTMLElement | null,
  onFocus: FocusCallback | undefined,
  onBlur: FocusCallback | undefined
) => {
  const [focusedOverride, setFocusedOverride] = useState(false);
  const [focusedElement, setFocusedElement] = useState<boolean>();
  const [mergedFocus, setMergedFocus] = useState(false);
  const focusedElementRef = useRef(focusedElement);
  const focusedOverrideRef = useRef(focusedOverride);
  const pendingFocusChange = useRef<number>();

  const handleFocus = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    if (!focusedElementRef.current) {
      setFocusedElement(true);
      focusedElementRef.current = true;
    }
  }, []);

  const handleBlur = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (event: FocusEvent) => {
      if (!focusedElementRef.current) {
        return;
      }

      const target = event?.relatedTarget || document.activeElement;
      const isFocused = element?.contains(target as Node) === true;
      if (isFocused) {
        return;
      }

      setFocusedElement(false);
      focusedElementRef.current = false;
    },
    [element]
  );

  const handleFocusOverride = useCallback<(...args: $TSFixMe[]) => $TSFixMe>((value: boolean) => {
    if (pendingFocusChange.current) {
      if (value) {
        window.clearTimeout(pendingFocusChange.current);
      } else {
        return;
      }
    }

    if (value === true) {
      focusedOverrideRef.current = true;
      pendingFocusChange.current = window.setTimeout(() => {
        setFocusedOverride(true);
        focusedOverrideRef.current = true;
        pendingFocusChange.current = undefined;
      }, OVERRIDE_FOCUS_MS);
      return;
    }

    focusedOverrideRef.current = false;
    pendingFocusChange.current = window.setTimeout(() => {
      setFocusedOverride(false);
      focusedOverrideRef.current = false;
      pendingFocusChange.current = undefined;
    }, OVERRIDE_BLUR_MS);
  }, []);

  useEffect(() => {
    element?.addEventListener('focusin', handleFocus);
    element?.addEventListener('focusout', handleBlur);

    return () => {
      element?.removeEventListener('focusin', handleFocus);
      element?.removeEventListener('focusout', handleBlur);
    };
  }, [element, handleFocus, handleBlur]);

  const focused = focusedElement || focusedOverride || false;

  useEffect(() => {
    const value = focusedElementRef.current || focusedOverrideRef.current || false;
    if (focusedElementRef.current == null || mergedFocus === value) {
      return;
    }

    if (value) {
      onFocus?.();
    } else {
      onBlur?.();
    }

    setMergedFocus(focused);
  }, [focused, mergedFocus, onFocus, onBlur]);

  return {
    focused: mergedFocus,
    setFocusedOverride: handleFocusOverride,
    setFocused: handleFocus,
  };
};
