import { simplifyQueryValue } from 'js/lib/queryUtils';
import requestCountry from 'js/lib/requestCountry';
import { useLocation } from 'js/lib/useRouter';

import type CartsV2 from 'bundles/naptimejs/resources/carts.v2';
import usePageData from 'bundles/payments/data/usePageData';

// These partner IDs are for Standford,Princeton and Yale
const FINAID_CEILING_EXCLUDED_PARTNERS = [1, 4, 109];

// FinAid Ceiling countries with 75% discount
const FINAID_CEILING_75_PERCENT_DISCOUNT_REGIONS = [
  'AD',
  'AU',
  'AX',
  'BE',
  'CA',
  'CH',
  'DE',
  'DK',
  'ES',
  'FI',
  'FO',
  'FR',
  'GB',
  'GG',
  'GI',
  'GL',
  'GU',
  'HK',
  'IE',
  'IM',
  'IS',
  'IT',
  'JE',
  'JP',
  'KR',
  'LI',
  'LU',
  'MC',
  'MO',
  'NL',
  'NO',
  'NZ',
  'PT',
  'SE',
  'SG',
  'SJ',
  'SM',
  'TW',
  'US',
  'VA',
  'RO',
  'LT',
  'XK',
  'AL',
  'MT',
  'HU',
  'GR',
  'MK',
  'SK',
  'RS',
  'ME',
  'PL',
  'EE',
  'HR',
  'TR',
  'BG',
  'LV',
  'BA',
  'SI',
  'CZ',
  'BH',
  'KW',
  'SA',
  'CY',
  'IL',
  'AE',
  'OM',
  'QA',
  'KZ',
  'TM',
  'UZ',
  'BY',
  'MD',
  'AM',
  'KG',
  'GE',
  'AZ',
  'TJ',
];

// Exclude from FinAid Ceiling
const FINAID_CEILING_EXCLUDED_REGIONS = ['RU', 'CN', 'SD', 'SY', 'KP', 'IR', 'CU', 'UA'];

export const getIsPartnerExcludedFromFinAidCeilingEpic = (partnerIds?: Array<string>) => {
  const isPartnerExcludedFromFinAidCeilingTest = partnerIds
    ?.map((partnerId) => Number(partnerId))
    ?.some((partnerId) => FINAID_CEILING_EXCLUDED_PARTNERS.includes(partnerId));

  return Boolean(isPartnerExcludedFromFinAidCeilingTest);
};

export const getFinaidCeilingDiscountPercentage = (partnerIds?: Array<string>) => {
  if (
    getIsPartnerExcludedFromFinAidCeilingEpic(partnerIds) ||
    FINAID_CEILING_EXCLUDED_REGIONS.includes(requestCountry.get())
  ) {
    return 100;
  } else if (FINAID_CEILING_75_PERCENT_DISCOUNT_REGIONS.includes(requestCountry.get())) {
    return 75;
  }
  return 90;
};

export const getCeilingId = (partnerIds?: Array<string>) => {
  const discountPercent = getFinaidCeilingDiscountPercentage(partnerIds);
  if (discountPercent === 100) {
    return undefined;
  } else if (discountPercent === 75) {
    return 1075;
  }
  return 1090;
};

export const isFinaidCeilingEnabled = (partnerIds?: Array<string>) =>
  getFinaidCeilingDiscountPercentage(partnerIds) !== 100;

export const isFinancialAidCeilingVariantCart = (cart: CartsV2, isFinancialAid?: string): boolean =>
  Boolean(isFinancialAid === 'true' && cart.hasDiscount && cart.isVC);

export const useGetIsFinancialAidCeilingVariantCart = () => {
  const { cart } = usePageData();
  const location = useLocation();
  const isFinancialAid = location?.query?.isFinancialAid ?? '';

  return isFinancialAidCeilingVariantCart(cart, simplifyQueryValue(isFinancialAid));
};
