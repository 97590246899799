/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import _ from 'lodash';

import { FormattedMessage } from 'js/lib/coursera.react-intl';
import imgix from 'js/lib/imgix';
import { formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import { Button } from '@coursera/cds-core';
import { color, font, gradient } from '@coursera/coursera-ui';
import { SvgCircleWarning, SvgEdit } from '@coursera/coursera-ui/svg';
import type { CarouselType } from '@coursera/event-pulse-types';

import { PRODUCT_TYPE_NAMES } from 'bundles/browse/constants';
import { getProductCardDisplayProps } from 'bundles/browse/utils';
import { fade } from 'bundles/coursera-ui/utils/colorUtils';
import type {
  ProductCardSize,
  ProductCardType,
} from 'bundles/enterprise-legacy-learner-home/components/ProductCardBase';
import ProductCardBase from 'bundles/enterprise-legacy-learner-home/components/ProductCardBase';
import type {
  EnterpriseProductMetadataFlags,
  PartnerList,
  Product,
} from 'bundles/enterprise-legacy-learner-home/types/programCommon';
import { getCourseType, isProjectOrSelfPacedProject } from 'bundles/enterprise-legacy-learner-home/utils/courseUtils';
import { CreditBadge, ExclusiveBadge, RecommendedForCreditBadge } from 'bundles/enterprise-ui/components/Badges';
import { useTracker, useVisibilityTracker } from 'bundles/page/lib/event-pulse/react';
import { getS12nVariantLabel } from 'bundles/s12n-common/lib/s12nProductVariantUtils';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

export { ProductCardPlaceholder } from '@coursera/coursera-ui/lib/components/coursera/ProductCard';

export const PRODUCT_TYPE: {
  [key: string]: ProductCardType;
} = {
  COURSE: 'course',
  DEGREE: 'degree',
  S12N: 's12n',
  CLIP: 'clip',
};

const CONFIG = {
  maxImageWidth: 600,
  maxImageHeight: 160,
  placeholderImageHeight: 144,
  fade: {
    overlay: 0.65,
    message: 0.85,
    icon: 0.5,
  },
  zIndex: {
    banner: 1,
    overlay: 2,
  },
};

const styles = {
  recommendedBanner: css({
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    color: color.white,
    fontSize: font.sm,
    textTransform: 'uppercase',
    background: `linear-gradient(${gradient.primary.deg}deg, ${gradient.primary.start}, ${gradient.primary.end})`,
    zIndex: CONFIG.zIndex.banner,
  }),
  unavailableOverlay: css({
    position: 'absolute',
    height: '100%',
    backgroundColor: fade(color.white, CONFIG.fade.overlay),
    cursor: 'default',
    zIndex: CONFIG.zIndex.overlay,
  }),
  unavailableMessage: css({
    height: CONFIG.placeholderImageHeight,
    backgroundColor: fade(color.border, CONFIG.fade.message),
    textAlign: 'center',
    color: color.white,
  }),
  sessionInfo: css`
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    margin-top: 12px;
    margin-left: 1.5em;
    padding: 0 !important;
    position: relative;
    z-index: 1;

    &:hover {
      background-color: transparent !important;
    }
  `,
  badges: css`
    position: relative;
    z-index: 1;
    padding: 0 1.5rem;
  `,
};

type CourseSession = {
  courseId?: string;
  startsAt?: number;
  endsAt?: number;
};

export type Props = {
  size?: ProductCardSize;
  product: Product;
  productType: ProductCardType;
  isRecommended?: boolean;
  isUnavailable?: boolean;
  productStateElement?: React.ReactNode;
  htmlAttributes: { [styleAttr: string]: string | number | React.RefObject<HTMLElement> | undefined };
  showLogo?: boolean;
  showGradient?: boolean;
  // TODO(ppaskaris): replace this with an object call signature since we have ~5 different signatures (EMPLOYER-10751)
  onClick?: (
    product: Product,
    productType: ProductCardType,
    enterpriseProductMetadata?: EnterpriseProductMetadataFlags
  ) => void;
  startsAt?: number;
  endsAt?: number;
  onEditSession?: (productId: string, productType?: string) => void;
  isInContentCuration?: boolean;
  enterpriseProductMetadata?: EnterpriseProductMetadataFlags;
  hasUpcomingSession?: boolean;
  isIncluded?: boolean;
  metadataOverride?: {
    selectedSession: CourseSession;
    isSelectedForCredit?: boolean;
    isAutoEnrollmentEnabled?: boolean;
  };
  prioritizeImageLoad?: boolean;
  showClipStyleIfClip?: boolean;
  eventingV3Data: {
    productCard: {
      index: number;
    };
    carousel?: {
      id: string;
      name: string;
      type: CarouselType;
      model?: string;
      section?: number;
    };
    product: {
      id: string;
      name: string;
      type: ProductCardType;
    };
  };
};

function getPartnerNames(partners: PartnerList | null | undefined): string {
  if (!partners) {
    return '';
  }

  const names = _.map(
    _.filter(_.compact(partners.elements), (partner) => {
      return !!partner?.name;
    }),
    'name'
  );

  return names.join(', ');
}

function getAdminSessionInfo(
  isPublicSession: boolean,
  startsAt?: number,
  endsAt?: number,
  hasUpcomingSession?: boolean,
  isIncluded?: boolean
) {
  if (startsAt || endsAt) {
    return (
      <FormattedMessage
        message={_t('{startsAt} - {endsAt}')}
        startsAt={startsAt ? formatDateTimeDisplay(startsAt, 'MMM D') : null}
        endsAt={endsAt ? formatDateTimeDisplay(endsAt, 'MMM D, YYYY') : null}
      />
    );
  } else if ((isIncluded && hasUpcomingSession) || isPublicSession) {
    return _t('Always available');
  } else {
    return '';
  }
}

function getSessionInfo(isPublicSession: boolean, startsAt?: number, endsAt?: number, hasMultipleOfferings?: boolean) {
  if (startsAt || endsAt) {
    return (
      <FormattedMessage
        message={_t('{startsAt} - {endsAt}')}
        startsAt={startsAt ? formatDateTimeDisplay(startsAt, 'MMM D') : null}
        endsAt={endsAt ? formatDateTimeDisplay(endsAt, 'MMM D, YYYY') : null}
      />
    );
  } else if (isPublicSession) {
    return _t('Open enrollment');
  } else if (hasMultipleOfferings) {
    return _t('Several enrollment options');
  } else {
    return '';
  }
}

export const ProductCard: React.LegacyFunctionComponentWithChildren<Props> = ({
  children,
  size,
  product,
  productType,
  isRecommended,
  isUnavailable,
  productStateElement,
  htmlAttributes,
  showLogo = true,
  showGradient,
  onClick,
  startsAt,
  endsAt,
  onEditSession,
  enterpriseProductMetadata,
  isInContentCuration,
  hasUpcomingSession,
  isIncluded,
  metadataOverride,
  prioritizeImageLoad,
  showClipStyleIfClip,
  eventingV3Data,
}) => {
  const track = useTracker();
  const { name, courseIds, partners, promoPhoto, courseTypeMetadata } = product;
  const courseType = product?.courseTypeMetadata && getCourseType(product.courseTypeMetadata);
  const isS12n = productType === PRODUCT_TYPE.S12N && !!courseIds;
  const s12nType = isS12n && getS12nVariantLabel(product.productVariant);
  const { gradient: gradientProp, label } = getProductCardDisplayProps(product, isS12n);
  const firstPartner = partners?.elements[0];
  const logo = (firstPartner || {}).squareLogo || (firstPartner || {}).logo || (firstPartner || {}).classLogo;
  const logoSrc = showLogo && logo ? imgix.processImage(logo, { width: 72, height: 72 }) : undefined;
  const shouldShowNewBadge = isProjectOrSelfPacedProject(courseTypeMetadata);
  const isSelectedForCredit = metadataOverride
    ? metadataOverride.isSelectedForCredit
    : enterpriseProductMetadata?.isSelectedForCredit;

  const handleClick = () => {
    track('click_product_card', eventingV3Data);

    onClick?.(product, productType, enterpriseProductMetadata);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Spacebar' || e.key === ' ') {
      handleClick();
      e.preventDefault();
    }
  };

  const handleEditSession = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onEditSession) {
      onEditSession(product.id, isS12n ? PRODUCT_TYPE_NAMES.SPECIALIZATION : PRODUCT_TYPE_NAMES.COURSE);
      e.stopPropagation();
    }
  };

  const sessionInfo = isInContentCuration
    ? getAdminSessionInfo(Boolean(onEditSession), startsAt, endsAt, hasUpcomingSession || isS12n, isIncluded)
    : getSessionInfo(Boolean(onEditSession), startsAt, endsAt, product.hasMultipleOfferings);

  const productCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    eventingV3Data
  );

  return (
    <div
      ref={productCardRef}
      className="ProductCard pos-relative"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      data-product-type={courseType || s12nType}
      {...htmlAttributes}
    >
      {isRecommended && (
        <div css={styles.recommendedBanner} className="p-a-1s">
          {_t('your recommendation')}
        </div>
      )}
      {isUnavailable && (
        <div css={styles.unavailableOverlay}>
          <div className="p-y-2 p-x-2" css={styles.unavailableMessage}>
            <SvgCircleWarning color={fade(color.disabledThemeDark, CONFIG.fade.icon)} suppressTitle />
            <p>{_t('Sorry, there are no more enrollments left.')}</p>
          </div>
        </div>
      )}
      <ProductCardBase
        size={size}
        isInteractive
        imageSrc={promoPhoto ? imgix.processImage(promoPhoto) : ''}
        title={name}
        subtitle={getPartnerNames(partners)}
        gradient={gradientProp}
        label={label}
        actionsElement={productStateElement}
        type={productType}
        logoSrc={logoSrc}
        showGradient={showGradient}
        shouldShowNewBadge={shouldShowNewBadge}
        isInContentCuration={isInContentCuration}
        prioritizeImageLoad={prioritizeImageLoad}
        showClipStyleIfClip={showClipStyleIfClip}
        htmlAttributes={{ ref: productCardRef }}
      >
        <div css={styles.badges}>
          {enterpriseProductMetadata?.isExclusive && (
            <ExclusiveBadge className="m-r-1s" data-e2e="private-course-label" />
          )}
          {isSelectedForCredit && <CreditBadge className="m-r-1s" />}
          {enterpriseProductMetadata?.isRecommendedForCredit && !isSelectedForCredit && <RecommendedForCreditBadge />}
        </div>
        {onEditSession && enterpriseProductMetadata?.isConfigurationAllowed ? (
          <Button
            variant={isS12n ? 'ghostInvert' : 'ghost'}
            onClick={handleEditSession}
            css={styles.sessionInfo}
            aria-label={_t('Edit session for #{productName}', { productName: product.name })}
            data-e2e="edit-session"
            icon={
              <SvgEdit
                size={18}
                color={isS12n ? color.white : color.primary}
                suppressTitle
                style={{ marginLeft: '6px' }}
              />
            }
          >
            {sessionInfo}
          </Button>
        ) : (
          <div css={styles.sessionInfo} className="p-x-2">
            {sessionInfo}
          </div>
        )}
        {children}
      </ProductCardBase>
    </div>
  );
};

export default ProductCard;
