import * as React from 'react';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import path from 'js/lib/path';
import user from 'js/lib/user';

import { isProjectLandingEnabled, isWeekLandingEnabled } from 'bundles/enroll-course/utils/featureGates';
import CourseEnrolledQuery from 'bundles/enroll-course/utils/queries/CourseEnrolledQuery.graphql';
import type {
  CourseEnrolledQueryQuery as CourseEnrolledQueryQueryData,
  CourseEnrolledQueryQueryVariables,
} from 'bundles/enroll-course/utils/queries/__generated__/CourseEnrolledQuery';
import { useCourseEnrollModalData } from 'bundles/enroll/data/usePageData';
import type {
  GuidedCourseWeekCardsV1MultiGetQuery,
  GuidedCourseWeekCardsV1MultiGetQueryVariables,
} from 'bundles/naptimejs/resources/__generated__/GuidedCourseWeekCardsV1';

import _t from 'i18n!nls/enroll-course';

import 'css!./__styles__/CourseEnrollmentConfirmation';

type PropsFromCaller = {
  children?: React.ReactChildren;
  courseId: string;
};

const CourseEnrollmentConfirmation: React.LegacyFunctionComponentWithChildren<PropsFromCaller> = ({
  courseId,
  children,
}) => {
  const { courseTypeMetadataWithVersion } = useCourseEnrollModalData();

  const { data: courseEnrolledData } = useQuery<CourseEnrolledQueryQueryData, CourseEnrolledQueryQueryVariables>(
    CourseEnrolledQuery,
    {
      variables: {
        id: courseId,
      },
      context: {
        clientName: 'gatewayGql',
      },
    }
  );

  const course = courseEnrolledData?.Course?.queryById;

  const { data: courseWeekCardsData } = useQuery<
    GuidedCourseWeekCardsV1MultiGetQuery,
    GuidedCourseWeekCardsV1MultiGetQueryVariables
  >(
    gql`
      query courseWeekCards($ids: String!) {
        GuidedCourseWeekCardsV1 @naptime {
          multiGet(ids: $ids) {
            elements {
              id
              weeks
            }
          }
        }
      }
    `,
    {
      variables: {
        ids: `${user.get().id}~${course?.slug}`,
      },
      errorPolicy: 'all',
      skip: !course?.slug,
    }
  );

  const courseWeekCards = courseWeekCardsData?.GuidedCourseWeekCardsV1?.multiGet?.elements?.[0];

  if (!course) {
    return null;
  }

  const projectItem = courseWeekCards?.weeks?.[0]?.modules?.[0]?.items?.find(
    ({ contentSummary: { typeName } }) => typeName === 'ungradedLti'
  );

  const isPreEnroll =
    // @ts-expect-error DateTime: string
    course.launchedAt >= Date.now() &&
    // @ts-expect-error DateTime: string
    course.preEnrollmentEnabledAt < Date.now() &&
    !course.isRestrictedMembership &&
    course.isReal;

  const link = path.join('/', 'learn', course.slug);
  const homeLink = path.join(link, 'home', 'welcome');

  let cta = isPreEnroll ? link : homeLink;

  if (isWeekLandingEnabled(courseId)) {
    cta = !isPreEnroll ? path.join(link, 'home', 'week', '1') : path.join(link, 'home');
  }

  if (isProjectLandingEnabled(courseId) && projectItem?.resourcePath) {
    cta = projectItem.resourcePath;
  }

  const getWelcomeMessage = () => {
    if (isPreEnroll) {
      return _t('Welcome to the course. The course will start #{plannedDate}.', {
        plannedDate: course.plannedLaunchDate,
      });
    }

    const courseLabel = courseTypeMetadataWithVersion?.courseLabel;
    const isCourse = courseTypeMetadataWithVersion?.isCourse;

    if (courseLabel && !isCourse) {
      return _t('Welcome to the #{courseType}. You can now access the project materials.', { courseType: courseLabel });
    }

    return _t('Welcome to the course. You can now access the course materials.');
  };

  return (
    <div className="rc-CourseEnrollmentConfirmation">
      {children || false}

      <div className="headline-1-text align-horizontal-center welcome-message">
        {getWelcomeMessage()}
        <br />
      </div>

      <br />

      <div className="align-horizontal-center">
        <a href={cta} className="styleguide nostyle start-learning-button-link">
          {/* eslint-disable react/button-has-type */}
          <button
            className="primary align-horizontal-center cozy"
            data-testid="course-enrollment-confirmation-start-learning-button"
          >
            {isPreEnroll ? _t('Continue') : _t('Start Learning')}
          </button>
          {/* eslint-enable react/button-has-type */}
        </a>
      </div>
    </div>
  );
};

export default CourseEnrollmentConfirmation;
