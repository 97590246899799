import type { S12nProductVariant } from 'bundles/s12n-common/constants/s12nProductVariants';
import { ProfessionalCertificateS12n } from 'bundles/s12n-common/constants/s12nProductVariants';

import _t from 'i18n!nls/enroll';

const getUnlimitedBullet = ({ s12nProductVariant }: { s12nProductVariant?: S12nProductVariant }) => {
  const UNLIMITED_BULLET_HEADER_CERTIFICATE = _t('Unlimited access to all courses in the Certificate');
  const UNLIMITED_BULLET_HEADER_S12N = _t('Unlimited access to all courses in the Specialization');
  return {
    header:
      s12nProductVariant === ProfessionalCertificateS12n
        ? UNLIMITED_BULLET_HEADER_CERTIFICATE
        : UNLIMITED_BULLET_HEADER_S12N,
    subheader: _t('Watch lectures, try assignments, participate in discussion forums, and more.'),
  };
};

export const getFreeTrialBullets = ({ s12nProductVariant }: { s12nProductVariant?: S12nProductVariant }) => {
  const UNLIMITED_BULLET = getUnlimitedBullet({ s12nProductVariant });

  const CANCEL_ANYTIME_BULLET = {
    header: _t('Cancel anytime.'),
    subheader: _t("No penalties - simply cancel before the trial ends if it's not right for you."),
  };

  const MONTHLY_PRICE_BULLET = {
    header: _t('{monthlyPrice} per month to continue learning after your trial ends'),
    subheader: _t('Go as fast as you can - the faster you go, the more you save.'),
  };

  const CERTIFICATE_BULLET = {
    header: _t('Certificate when you complete after your trial ends'),
    subheader: _t('Share on your resume, LinkedIn, and CV.'),
  };

  const FREE_TRIAL_LOGGED_IN_BULLETS = [
    UNLIMITED_BULLET,
    CANCEL_ANYTIME_BULLET,
    MONTHLY_PRICE_BULLET,
    CERTIFICATE_BULLET,
  ];

  const FREE_TRIAL_LOGGED_OUT_BULLETS = [UNLIMITED_BULLET, CANCEL_ANYTIME_BULLET, CERTIFICATE_BULLET];

  return {
    FREE_TRIAL_LOGGED_IN_BULLETS,
    FREE_TRIAL_LOGGED_OUT_BULLETS,
  };
};

export const getPaidSubscriptionBullets = () => [
  _t('Practice material, graded assignments, discussion forums and more'),
  _t('Certificates to share on your resume, Linkedin, or CV'),
  _t('24/7 customer support'),
  _t('Easy cancellation'),
];

export const getPaidSubscriptionBulletsV2 = ({ s12nProductVariant }: { s12nProductVariant?: S12nProductVariant }) => {
  const UNLIMITED_BULLET = getUnlimitedBullet({ s12nProductVariant });

  const CANCEL_ANYTIME_BULLET = {
    header: _t('Cancel anytime.'),
    subheader: _t("No penalties - simply cancel if it's not right for you."),
  };

  const CERTIFICATE_BULLET = {
    header: _t('Certificate when you complete.'),
    subheader: _t('Share on your resume, LinkedIn, and CV.'),
  };

  return [UNLIMITED_BULLET, CANCEL_ANYTIME_BULLET, CERTIFICATE_BULLET];
};
