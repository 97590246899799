/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Dialog, Grid, TextField, Typography2, VisuallyHidden } from '@coursera/cds-core';

import { CoachButton } from 'bundles/ai-coach-platform/components/building-blocks';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  title: string;
  placeholder?: string;
  onSubmit({ userComments }: { userComments: string }): void;
  onClose(e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>): void;
  onCancel(e: React.MouseEvent<Element, MouseEvent>): void;
};

const FeedbackModal = ({ title, placeholder, onSubmit, onClose, onCancel, ...rest }: Props) => {
  const { Content, Actions } = Dialog;
  const [show, setShow] = React.useState(true);
  const textFieldRef = React.useRef<HTMLInputElement>(null);
  const [feedbackText, setFeedbackText] = React.useState('');

  const handleOnClose = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    setShow(false);
    onClose(e);
  };

  const handleOnSubmit = () => {
    setShow(false);
    onSubmit({ userComments: feedbackText });
  };

  const handleOnCancel = (e: React.MouseEvent<Element, MouseEvent>) => {
    setShow(false);
    onCancel(e);
  };

  return (
    <Dialog aria-labelledby="dialog-header" open={show} sticky="actions" onClose={handleOnClose} {...rest}>
      <VisuallyHidden id="dialog-header">{_t('Response feedback')}</VisuallyHidden>
      <Content id="dialog-content">
        <Grid container>
          <Grid item sm={12} xs={12}>
            <TextField
              maxLength={255}
              onChange={(e) => setFeedbackText(e.target.value)}
              placeholder={placeholder}
              inputRef={textFieldRef}
              multiline
              fullWidth
              label={title}
              renderLabel={() => (
                <Typography2 variant="titleMedium" component="label">
                  {title}
                </Typography2>
              )}
            />
          </Grid>
        </Grid>
      </Content>

      <Actions
        css={css`
          display: flex;
          gap: var(--cds-spacing-200);
        `}
      >
        <CoachButton size="medium" variant="primary" onClick={handleOnSubmit} aria-disabled={!feedbackText}>
          {_t('Submit')}
        </CoachButton>

        <CoachButton size="medium" variant="secondary" onClick={handleOnCancel}>
          {_t('Cancel')}
        </CoachButton>
      </Actions>
    </Dialog>
  );
};

export default FeedbackModal;
