import * as React from 'react';
import { useCallback, useContext } from 'react';

import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';

import { AttachmentIcon } from '@coursera/cds-icons';

import type { Asset } from 'bundles/asset-admin/types/assets';
import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { assetToElement } from 'bundles/cml/editor/components/buttons/asset/assetUtils';
import { AssetContext } from 'bundles/cml/editor/context/assetContext';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { hasAncestorOfType, insertBlockNodes } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

const AssetButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const disabled =
    isTypeDisabled(editor, BLOCK_TYPES.ASSET) ||
    isTypeDisabled(editor, BLOCK_TYPES.IMAGE) ||
    isTypeDisabled(editor, BLOCK_TYPES.LEGACY_AUDIO);
  const active = hasAncestorOfType(editor, [BLOCK_TYPES.ASSET, BLOCK_TYPES.IMAGE, BLOCK_TYPES.LEGACY_AUDIO]);

  const { setAssetModalOptions } = useContext(AssetContext);
  const handleAssetModalSelect = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (selectedAssets: Array<Asset>) => {
      setAssetModalOptions();
      ReactEditor.focus(staticEditor);
      if (selectedAssets.length) {
        const assets = selectedAssets.map(assetToElement);
        insertBlockNodes(staticEditor, assets);
      }
    },
    [staticEditor, setAssetModalOptions]
  );

  const handleClick = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    setAssetModalOptions({
      type: 'asset',
      allowMultiple: true,
      handleSelect: handleAssetModalSelect,
    });
  }, [setAssetModalOptions, handleAssetModalSelect]);

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      active={active}
      className="rc-AssetButton"
      title={_t('Insert asset')}
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <AttachmentIcon size="small" />
    </Button>
  );
};

export default AssetButton;
