/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { CardCreditType, CardRatings, Grid, Typography2 } from '@coursera/cds-core';
import { CertificateIcon, LockIcon } from '@coursera/cds-icons';

import { getUpsellCopy } from 'bundles/stackability-common';

import _t from 'i18n!nls/product-card';

import { productCardDegreeOrCredit } from './ProductCardDegreeOrCredit';

const Styles = {
  iconContainer: css`
    margin-left: 8px;
  `,
};

export const EnterpriseCreditEligibleBadge = () => {
  return (
    <Grid container>
      <Grid item>
        <CertificateIcon size="medium" color="support" />
      </Grid>
      <Grid item css={Styles.iconContainer}>
        <Typography2 component="p" variant="bodySecondary" color="supportText">
          {_t('Credit Eligible')}
        </Typography2>
      </Grid>
    </Grid>
  );
};

export const EnterpriseExclusiveBadge = () => {
  return (
    <Grid container>
      <Grid item>
        <LockIcon size="medium" color="support" />
      </Grid>
      <Grid item css={Styles.iconContainer}>
        <Typography2 component="p" variant="bodySecondary" color="supportText">
          {_t('Exclusive')}
        </Typography2>
      </Grid>
    </Grid>
  );
};

export const PathwayContentBadge = () => (
  <CardCreditType
    creditType={{
      icon: <CertificateIcon />,
      label: getUpsellCopy().homepagesNavXdp.buildTowardADegree,
    }}
  />
);

export const SearchResultsBadges = ({
  shouldShowThePathwayBadge,
  ratingElement,
  shouldShowCreditBadge,
  isDegree,
}: {
  shouldShowThePathwayBadge?: boolean;
  ratingElement?: JSX.Element | boolean;
  shouldShowCreditBadge?: boolean;
  isDegree?: boolean;
}) => {
  if (shouldShowThePathwayBadge) {
    return (
      <>
        <PathwayContentBadge />
        <CardRatings>{ratingElement}</CardRatings>
      </>
    );
  } else if (shouldShowCreditBadge) {
    return <CardCreditType creditType={productCardDegreeOrCredit(isDegree ?? false)} />;
  } else {
    return <CardRatings>{ratingElement}</CardRatings>;
  }
};
