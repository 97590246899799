import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';

import AssetAdminModalV2 from 'bundles/asset-admin/components/AssetAdminModalV2';
import { DEFAULT_PLUGIN_SOURCES } from 'bundles/asset-admin/constants/AssetUpload';
import type { TransloaditFile } from 'bundles/authoring/common/types/Upload';
import type { AssetModalOptions, AssetOptionsContext, AssetUploadOptions } from 'bundles/cml/editor/types/assetTypes';
import { isScriptInjectableImageType } from 'bundles/cml/editor/utils/imageUtils';

import _t from 'i18n!nls/cml';

export type Props = {
  assetOptions?: AssetModalOptions;
  uploadOptions?: AssetUploadOptions;
  onClose: () => void;
  onFocused: (focused: boolean) => void;
  isLearnerUpload?: boolean;
};

const AssetModal: React.FC<Props> = ({ uploadOptions, isLearnerUpload = false, assetOptions, onClose, onFocused }) => {
  const showDialog = !!assetOptions && !!uploadOptions;

  useEffect(() => {
    if (showDialog) {
      onFocused(true);
      return () => onFocused(false);
    }

    return () => undefined;
  }, [showDialog, onFocused]);

  const handleFileAdded = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (file: TransloaditFile) => {
      if (isLearnerUpload) {
        return !isScriptInjectableImageType(file);
      }

      return true;
    },
    [isLearnerUpload]
  );

  if (!showDialog) {
    return null;
  }

  // use learner-specific asset creation endpoints when passed in
  const url = isLearnerUpload
    ? uploadOptions.asset?.assetCreationUrl || uploadOptions.image?.assetCreationUrl
    : undefined;

  const title = assetOptions.type === 'image' ? _t('Embed Images') : _t('Attach Assets');
  const assetType = assetOptions.type === 'image' ? 'image' : undefined;

  // hide "Learn More" for learners until we have a learner-specific article
  const helpLinkId = isLearnerUpload ? undefined : '360039540792';
  const description = isLearnerUpload
    ? _t(
        'You can add {fileTypes, plural, =0 {an image} other {images}} to your content by importing {fileTypes, plural, =0 {a file} other {one or more files}} here.'
      )
    : undefined;

  return createPortal(
    <AssetAdminModalV2
      assetContext={uploadOptions.asset?.context as AssetOptionsContext}
      assetCreationUrl={url}
      title={title}
      onConfirmHandler={assetOptions.handleSelect}
      onCancel={onClose}
      assetType={assetType}
      allowMultiple={assetOptions.allowMultiple}
      helperLinkId={helpLinkId}
      hideAssetLibraryLink={isLearnerUpload}
      pluginSources={isLearnerUpload ? null : DEFAULT_PLUGIN_SOURCES}
      hideExtraFeatures={isLearnerUpload}
      description={description}
      onFileAdded={handleFileAdded}
    />,
    document.body
  );
};

export default AssetModal;
