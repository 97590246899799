import * as React from 'react';

import type { ToolOptions } from 'bundles/cml/editor/components/toolbars/types';
import { ToolsContext } from 'bundles/cml/editor/context/toolsContext';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

export type Props = {
  tools: ToolsKeys[];
  options: ToolOptions;
};

const ToolsContextProvider: React.LegacyFunctionComponentWithChildren<Props> = ({ tools, options, children }) => {
  return <ToolsContext.Provider value={{ tools, options }}>{children}</ToolsContext.Provider>;
};

export default ToolsContextProvider;
