import * as React from 'react';

import classNames from 'classnames';

import { Button } from '@coursera/cds-core';

import CodeOutput from 'bundles/author-code-evaluator/components/CodeOutput';
import type EvaluatorTestCaseRunner from 'bundles/author-code-evaluator/models/EvaluatorTestCaseRunner';
import type { LanguageType } from 'bundles/cml';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import CodeBlockV2 from 'bundles/code-evaluator/components/CodeBlockV2';
import CodeEvaluatorOutput from 'bundles/code-evaluator/components/CodeEvaluatorOutput';
import Evaluation from 'bundles/code-evaluator/models/Evaluation';

import _t from 'i18n!nls/author-code-evaluator';

import 'css!bundles/author-code-evaluator/components/__styles__/TestCaseEditor';

type Props = {
  testCaseRunner: EvaluatorTestCaseRunner;
  isTestCaseAdded: boolean;
  selectedTestCaseIndex: number | undefined | null;
  language: LanguageType;
  onChange: (testCaseRunner: EvaluatorTestCaseRunner) => void;
  onCancel: () => void;
  handleRun: (expression: string) => void;
  evaluatorDraftId: string;
  titleRow: JSX.Element | undefined | null;
  onOverwriteOutput: () => void;
  runInProgress: boolean | undefined | null;
};

const TestCaseEditor = (props: Props) => {
  const {
    testCaseRunner,
    isTestCaseAdded,
    selectedTestCaseIndex,
    language,
    onChange,
    onCancel,
    evaluatorDraftId,
    titleRow,
    onOverwriteOutput,
    runInProgress,
  } = props;

  const handleRun = () => {
    props.handleRun(testCaseRunner.testCase.expression);
  };

  const onChangeExpression = (expression: string) => {
    const copy = testCaseRunner.copy();
    copy.testCase.expression = expression;

    onChange(copy);
  };

  const testCaseOutput = testCaseRunner.response;
  const { expression } = testCaseRunner.testCase;
  const testCaseRunDisabled = expression === '';

  const expectedResponse = testCaseRunner.testCase.result && testCaseRunner.testCase.result.toJSON();
  const expectedOutput = new Evaluation({
    active: false,
    fail: false,
    response: expectedResponse,
  });

  const actualResponse = testCaseOutput && testCaseOutput.toJSON();
  const actualOutput = new Evaluation({
    active: false,
    fail: false,
    response: actualResponse,
  });

  let actualResponseErrorUrl: string | null = null;
  if (actualResponse != null && !actualResponse.hasSuccessResult && actualResponse.logUrls != null) {
    actualResponseErrorUrl = actualResponse.logUrls.stderrUrl;
  }

  return (
    <div className="rc-TestCaseEditor">
      {/* top row */}
      <div className="row">
        <div className="column">{titleRow}</div>
      </div>

      {/* input title */}
      <div className="row column">
        <div className="horizontal-box align-items-spacebetween flex-1" style={{ padding: '8px 12px' }}>
          {_t('Input')}
          <Button onClick={handleRun} disabled={testCaseRunDisabled} className="caption-text" variant="ghost">
            {_t('Run')}
          </Button>
        </div>
      </div>

      {/* input row */}
      <div className="row">
        <div className="column">
          <div className="flex-1 align-self-start">
            <CodeBlockV2
              key={selectedTestCaseIndex || undefined}
              readOnly={false}
              codeLanguage={language}
              expression={expression}
              onChange={onChangeExpression}
            />
            {testCaseRunDisabled && (
              <p className="caption-text color-secondary-text" style={{ paddingLeft: 16 }}>
                {_t('Add some code to run this test case.')}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* middle row */}
      <div className="row">
        {isTestCaseAdded && (
          <div className="column">
            <div className="horizontal-box align-items-spacebetween flex-1" style={{ padding: '8px 12px' }}>
              {_t('Output')}
            </div>
            <Button
              disabled={!testCaseRunner.isFailed}
              onClick={onOverwriteOutput}
              style={{ marginRight: 12 }}
              variant="ghost"
            >
              {_t('Overwrite')}
            </Button>
          </div>
        )}

        <div
          className={classNames('column', 'horizontal-box', 'align-items-spacebetween', {
            'flex-2': !isTestCaseAdded,
          })}
        >
          <div className="flex-1" style={{ padding: '8px 12px' }}>
            {_t('Expected Output')}
          </div>
        </div>
      </div>

      {/* bottom row */}
      <div className="row">
        <div
          className={classNames('column', 'vertical-box', {
            'flex-2': !isTestCaseAdded,
          })}
        >
          <CodeOutput
            evaluatorDraftId={evaluatorDraftId}
            evaluation={actualOutput}
            onCancel={onCancel}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            runInProgress={runInProgress}
          />
          {actualResponse != null && (
            <div className="caption-text color-secondary-text" style={{ padding: '8px 12px' }}>
              {_t('Evaluation ID:')} &nbsp; {actualResponse.id}
              {actualResponseErrorUrl && (
                <div>
                  <a href={actualResponseErrorUrl}>{_t('stderr logs')}</a>
                </div>
              )}
            </div>
          )}
        </div>
        {isTestCaseAdded && (
          <div className="column vertical-box">
            <CodeEvaluatorOutput
              evaluatorId={evaluatorDraftId}
              evaluation={expectedOutput}
              // active evaluations require an onCancel, but this evaluation will never be active.
              onCancel={() => {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TestCaseEditor;
