import { graphql } from 'react-apollo';

import { useQuery } from '@apollo/client';
import {
  OwnableProduct_BillingCycle as BillingCycle,
  OwnableProduct_ProductType as ProductType,
} from '__generated__/graphql-types';

import { tupleToStringKey } from 'js/lib/stringKeyTuple';

import paymentExperiments from 'bundles/epic/clients/payments';
import FindOwnableProductsByLegacyProductId from 'bundles/payments-common/api/FindOwnableProductsByLegacyProductIdQuery.graphql';
import FindOwnableProductsByUnderlyingProductItemId from 'bundles/payments-common/api/FindOwnableProductsByUnderlyingProductItemIdQuery.graphql';
import type {
  FindOwnableProductsByLegacyProductIdQuery,
  FindOwnableProductsByLegacyProductIdQueryVariables,
  InstallmentPaymentSchemeFragment,
  OwnableProductByLegacyProductIdFragment,
} from 'bundles/payments-common/api/__generated__/FindOwnableProductsByLegacyProductIdQuery';
import type {
  FindOwnableProductsByUnderlyingProductItemIdQuery,
  FindOwnableProductsByUnderlyingProductItemIdQueryVariables,
  OwnableProductByUnderlyingProductItemIdFragment,
  OwnableProductSubscriptionPaymentSchemeFragment,
  ProductTypeFragment,
} from 'bundles/payments-common/api/__generated__/FindOwnableProductsByUnderlyingProductItemIdQuery';
import ProductSkusProductType from 'bundles/payments/common/ProductType';
import { subscriptionBillingType } from 'bundles/payments/common/constants';

// Map of ProductSkusV1 productType enum values to their OwnableProduct productType equivalent
export const OWNABLE_PRODUCT_PRODUCT_TYPE_MAP = {
  [ProductSkusProductType.CATALOG_SUBSCRIPTION]: ProductType.ProductTypeInvalid,
  [ProductSkusProductType.SPARK_COURSE_SHELL]: ProductType.ProductTypeInvalid,
  [ProductSkusProductType.SPARK_SPECIALIZATION]: ProductType.ProductTypeInvalid,
  [ProductSkusProductType.SPARK_VERIFIED_CERTIFICATE]: ProductType.ProductTypeInvalid,
  [ProductSkusProductType.SPECIALIZATION]: ProductType.ProductTypeSpecialization,
  [ProductSkusProductType.SPECIALIZATION_PREPAID]: ProductType.ProductTypeSpecializationPrepaid,
  [ProductSkusProductType.SPECIALIZATION_SUBSCRIPTION]: ProductType.ProductTypeSpecializationSubscription,
  [ProductSkusProductType.VERIFIED_CERTIFICATE]: ProductType.ProductTypeVerifiedCertificate,
  [ProductSkusProductType.ENTERPRISE_CONTRACT]: ProductType.ProductTypeEnterpriseContract,
  [ProductSkusProductType.INTEREST_DEPOSIT]: ProductType.ProductTypeInvalid,
  [ProductSkusProductType.CREDENTIAL_TRACK_SUBSCRIPTION]: ProductType.ProductTypeCredentialTrackSubscription,
  [ProductSkusProductType.CREDENTIAL_TRACK_SUBSCRIPTION_V2]: ProductType.ProductTypeCredentialTrackSubscriptionV2,
  [ProductSkusProductType.CREDENTIAL_TRACK]: ProductType.ProductTypeCredentialTrack,
  [ProductSkusProductType.COURSERA_PLUS_SUBSCRIPTION]: ProductType.ProductTypeCourseraPlusSubscription,
  [ProductSkusProductType.COURSERA_PLUS]: ProductType.ProductTypeCourseraPlus,
  [ProductSkusProductType.COURSERA_TIER_LITE]: ProductType.ProductTypeCourseraTierLite,
} as const;

// Map of OwnableProduct productType enum values to their ProductSkusV1 productType equivalent
const PRODUCT_SKUS_PRODUCT_TYPE_MAP = Object.keys(OWNABLE_PRODUCT_PRODUCT_TYPE_MAP).reduce(
  (map: Record<string, string>, key: string) => {
    const value = OWNABLE_PRODUCT_PRODUCT_TYPE_MAP[key as keyof typeof OWNABLE_PRODUCT_PRODUCT_TYPE_MAP];

    if (value !== ProductType.ProductTypeInvalid) {
      map[value] = key;
    }

    return map;
  },
  {}
);

// Map of OwnableProduct billingCycle enum values to their ProductSkusV1 billingCycle equivalent
const PRODUCT_SKUS_BILLING_CYCLE_MAP = {
  [BillingCycle.BillingCycleInvalid]: '',
  [BillingCycle.BillingCycleMonthly]: subscriptionBillingType.MONTHLY,
  [BillingCycle.BillingCycleBiannual]: subscriptionBillingType.BIANNUAL,
  [BillingCycle.BillingCycleAnnual]: subscriptionBillingType.ANNUAL,
};

export type OwnableProductByUnderlyingProductItemId = {
  isSubscription: boolean;
  numberOfInstallments?: number;
  productItemId?: string;
  productType?: string;
  subscriptionType?: string;
};

export const isOwnableProductsEnabled = () => paymentExperiments.get('productSkusToOwnableProductsEnabled');

const formatOwnableProductByUnderlyingProductItemId = (
  ownableProduct: OwnableProductByUnderlyingProductItemIdFragment
): OwnableProductByUnderlyingProductItemId => {
  const { legacyProductId, paymentScheme } = ownableProduct.fulfillmentConfiguration;
  const billingCycle = ((paymentScheme as OwnableProductSubscriptionPaymentSchemeFragment) ?? {}).billingCycle;
  const numberOfInstallments = ((paymentScheme as InstallmentPaymentSchemeFragment) ?? {})?.numberOfInstallments;

  return {
    isSubscription: billingCycle
      ? [BillingCycle.BillingCycleAnnual, BillingCycle.BillingCycleMonthly].includes(billingCycle)
      : false,
    numberOfInstallments,
    productItemId: legacyProductId?.productItemId,
    productType: legacyProductId?.productType ? PRODUCT_SKUS_PRODUCT_TYPE_MAP[legacyProductId?.productType] : '',
    subscriptionType: PRODUCT_SKUS_BILLING_CYCLE_MAP[billingCycle],
  };
};

type PropsForFindByUnderlyingProductItemId = {
  s12nId?: string;
  skip?: boolean;
};

type PropsFromFindByUnderlyingProductItemId = {
  ownableProducts: OwnableProductByUnderlyingProductItemId[];
};

export const useFindOwnableProductsByUnderlyingProductItemId = ({
  s12nId,
  skip,
}: PropsForFindByUnderlyingProductItemId) => {
  const { data, loading, error } = useQuery<
    FindOwnableProductsByUnderlyingProductItemIdQuery,
    FindOwnableProductsByUnderlyingProductItemIdQueryVariables
  >(FindOwnableProductsByUnderlyingProductItemId, {
    variables: { underlyingProductItemId: s12nId! },
    context: { clientName: 'gatewayGql' },
    skip: skip || !s12nId || !isOwnableProductsEnabled(),
  });

  const ownableProductsRaw = data?.OwnableProductQueries?.findByOwnableProductItemId?.filter(Boolean) ?? [];

  return {
    ownableProducts: ownableProductsRaw.map(formatOwnableProductByUnderlyingProductItemId),
    loading,
    error,
  };
};

export const withFindOwnableProductsByUnderlyingProductItemId = graphql<
  PropsForFindByUnderlyingProductItemId,
  FindOwnableProductsByUnderlyingProductItemIdQuery,
  FindOwnableProductsByUnderlyingProductItemIdQueryVariables,
  PropsFromFindByUnderlyingProductItemId
>(FindOwnableProductsByUnderlyingProductItemId, {
  options: ({ s12nId, skip }) => ({
    variables: { underlyingProductItemId: s12nId! },
    context: { clientName: 'gatewayGql' },
    skip: skip || !s12nId || !isOwnableProductsEnabled(),
  }),
  props: ({ data, ownProps }) => {
    const ownableProductsRaw = data?.OwnableProductQueries?.findByOwnableProductItemId?.filter(Boolean) ?? [];

    return {
      ...ownProps,
      ownableProducts: ownableProductsRaw.map(formatOwnableProductByUnderlyingProductItemId),
    };
  },
});

export type OwnableProductByLegacyProductId = {
  underlyingProductItemId?: string;
  underlyingProductId?: string;
  numberOfInstallments?: number;
  ownershipDays?: number;
};

type PropsForFindByLegacyProductId = {
  productItemId?: string;
  skip?: boolean;
} & Pick<ProductTypeFragment, 'productType'>;

type PropsFromFindByLegacyProductId = {
  ownableProduct: OwnableProductByLegacyProductId;
};

const formatOwnableProductByLegacyProductId = (
  ownableProduct?: OwnableProductByLegacyProductIdFragment
): OwnableProductByLegacyProductId => {
  if (!ownableProduct) {
    return {};
  }

  let ownershipDays;
  const underlyingProductItemId = ownableProduct.ownableProduct?.productItemId;
  const underlyingProductType = ownableProduct.ownableProduct?.productType
    ? PRODUCT_SKUS_PRODUCT_TYPE_MAP[ownableProduct.ownableProduct.productType]
    : '';
  const underlyingProductId = tupleToStringKey([underlyingProductType, underlyingProductItemId]);
  const numberOfInstallments = (
    ownableProduct.fulfillmentConfiguration?.paymentScheme as InstallmentPaymentSchemeFragment
  )?.numberOfInstallments;
  if (ownableProduct?.fulfillmentConfiguration?.paymentScheme.__typename === 'OwnableProduct_OneTimePaymentScheme') {
    ownershipDays = ownableProduct.fulfillmentConfiguration.paymentScheme.ownershipDays;
  }

  return {
    underlyingProductItemId,
    underlyingProductId,
    numberOfInstallments,
    ...(ownershipDays ? { ownershipDays } : {}),
  };
};

export const useFindOwnableProductByLegacyProductId = ({
  productItemId,
  productType,
  skip,
}: PropsForFindByLegacyProductId) => {
  const { data, loading, error } = useQuery<
    FindOwnableProductsByLegacyProductIdQuery,
    FindOwnableProductsByLegacyProductIdQueryVariables
  >(FindOwnableProductsByLegacyProductId, {
    variables: { legacyProductId: { productType: productType!, productItemId: productItemId! } },
    context: { clientName: 'gatewayGql' },
    skip: skip || !productItemId || !productType || !isOwnableProductsEnabled(),
  });

  return {
    ownableProduct: formatOwnableProductByLegacyProductId(
      data?.OwnableProductQueries?.findLatestByLegacyProductId ?? undefined
    ),
    loading,
    error,
  };
};

export const withFindOwnableProductsByLegacyProductId = graphql<
  PropsForFindByLegacyProductId,
  FindOwnableProductsByLegacyProductIdQuery,
  FindOwnableProductsByLegacyProductIdQueryVariables,
  PropsFromFindByLegacyProductId
>(FindOwnableProductsByLegacyProductId, {
  options: ({ productItemId, productType, skip }) => ({
    variables: { legacyProductId: { productType: productType!, productItemId: productItemId! } },
    context: { clientName: 'gatewayGql' },
    skip: skip || !productItemId || !productType || !isOwnableProductsEnabled(),
  }),
  props: ({ data, ownProps }) => {
    return {
      ...ownProps,
      ownableProduct: formatOwnableProductByLegacyProductId(
        data?.OwnableProductQueries?.findLatestByLegacyProductId ?? undefined
      ),
    };
  },
});
