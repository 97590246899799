import * as React from 'react';
import { useCallback, useState } from 'react';

import CopyLinkButton from 'bundles/cml/editor/components/elements/link/CopyLinkButton';
import EditLinkButton from 'bundles/cml/editor/components/elements/link/EditLinkButton';
import OpenLinkButton from 'bundles/cml/editor/components/elements/link/OpenLinkButton';
import RemoveLinkButton from 'bundles/cml/editor/components/elements/link/RemoveLinkButton';
import UnlinkDialog from 'bundles/cml/editor/components/elements/link/UnlinkDialog';
import Toolbar from 'bundles/cml/editor/components/toolbars/Toolbar';
import RestoreFocus from 'bundles/cml/editor/utils/RestoreFocus';
import type { LinkElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

export type Props = {
  anchorEl: HTMLElement | null;
  link: LinkElement;
  onEditLink: () => void;
  onClose: () => void;
};

const LinkToolbar: React.FC<Props> = ({ anchorEl, link, onEditLink, onClose }) => {
  const [showUnlinkDialog, setShowUnlinkDialog] = useState(false);

  const handleUnlinkDialogOpen = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => setShowUnlinkDialog(true), []);
  const handleUnlinkDialogClose = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => setShowUnlinkDialog(false), []);

  if (showUnlinkDialog) {
    return <UnlinkDialog onClose={handleUnlinkDialogClose} />;
  }

  return (
    <RestoreFocus anchorEl={anchorEl} onClose={onClose}>
      <Toolbar label={_t('Link Options')} role="menubar">
        <OpenLinkButton link={link} />
        <CopyLinkButton link={link} />
        <EditLinkButton onEditLink={onEditLink} />
        <RemoveLinkButton onUnlink={handleUnlinkDialogOpen} />
      </Toolbar>
    </RestoreFocus>
  );
};

export default LinkToolbar;
