import * as React from 'react';
import { useCallback } from 'react';

import type { Node } from 'slate';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';

import { RemoveColumnIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { focusTableCell, getTableData } from 'bundles/cml/editor/components/elements/table/tableUtils';
import { removeElement } from 'bundles/cml/editor/utils/slateUtils';
import type { TableElement, TableRowElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  table: TableElement;
};

const RemoveTableColumnButton: React.FC<Props> = ({ table }) => {
  const staticEditor = useSlateStatic();

  const handleRemoveColumn = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    const { path, rowIndex, columnIndex, numColumns } = getTableData(staticEditor, table);
    if (numColumns === 1) {
      removeElement(staticEditor, table);
      return;
    }

    if (!path || rowIndex < 0 || columnIndex < 0) {
      return;
    }

    const cells = table.children.reduce((result: Set<Node>, row: TableRowElement) => {
      result.add(row.children[columnIndex] as Node);
      return result;
    }, new Set());

    Transforms.removeNodes(staticEditor, { at: path, match: (node) => cells.has(node) });
    focusTableCell(staticEditor, path, rowIndex, columnIndex - 1);
  }, [staticEditor, table]);

  return (
    <Button
      role="menuitem"
      onClick={handleRemoveColumn}
      title={_t('Remove column')}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      <RemoveColumnIcon size="small" />
    </Button>
  );
};

export default RemoveTableColumnButton;
