// To understand difficulties with debounce in React hooks, see https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
// This solution is derived from this: https://stackoverflow.com/a/62017005/16366156
import { useCallback, useEffect, useRef } from 'react';

import type { Cancelable } from 'lodash';
import { debounce } from 'lodash';

import useIsMounted from 'bundles/common/hooks/useIsMounted';

type DebounceOptions = {
  leading?: boolean;
  trailing?: boolean;
};

function useDebounce(callback: () => void, delay?: number, options?: DebounceOptions) {
  const inputsRef = useRef({ callback, delay });
  const isMounted = useIsMounted();

  useEffect(() => {
    inputsRef.current = { callback, delay };
  });

  const debouncer = useRef(
    debounce(
      () => {
        // Don't execute callback if
        // (1) component has been unmounted
        // (2) delay has changed
        if (inputsRef.current.delay === delay && isMounted()) {
          inputsRef.current.callback();
        }
      },
      delay,
      options
    )
  ).current;

  return useCallback<(() => void) & Cancelable>(debouncer, [delay, isMounted, options, debouncer]);
}

export default useDebounce;
