/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { breakpoints } from '@coursera/cds-core';

import { usePageContext } from 'bundles/page-config-common/contexts/PageContext';

type PropsForHomeSection = {
  children: React.ReactNode;
  spacing?: number;
  className?: string;
  dataTestId?: string;
};

const styles = {
  separator: css`
    &::before,
    &::after {
      content: '';
      display: table;
    }
  `,
  marginEnterprise: (spacing: number) => css`
    margin: ${spacing + 30}px auto;
    padding: 30px 0;

    ${breakpoints.down('md')} {
      margin: ${spacing}px auto;
    }
  `,
  marginConsumer: (spacing: number) => css`
    margin: ${spacing + 30}px auto;

    ${breakpoints.down('md')} {
      margin: ${spacing + 15}px auto;
    }
  `,
};

const HomeSection = ({ children, spacing, className, dataTestId }: PropsForHomeSection): JSX.Element | null => {
  const pageContext = usePageContext();

  if (pageContext?.isConsumer) {
    return (
      <div className={className} css={styles.separator} data-testid={dataTestId}>
        <div css={styles.marginConsumer(spacing || 0)} data-testid="margin-div" className="HomeSection">
          {children}
        </div>
      </div>
    );
  } else {
    return (
      <div className={className} css={styles.separator} data-testid={dataTestId}>
        <div css={styles.marginEnterprise(spacing || 0)} data-testid="margin-div" className="HomeSection">
          {children}
        </div>
      </div>
    );
  }
};

export default HomeSection;
