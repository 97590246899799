/* eslint-disable @typescript-eslint/no-explicit-any, camelcase */

/**
 * This file is autogenerated, please don't edit it manually.
 * Use this command to regenerate it if you modified Epic experiments:
 * yarn epic:types
 */

/**
 * Epic experiments namespaces with their possible keys.
 * Autogenerated from static/bundles/epic/data/defaults/
 */
import client from 'bundles/epic/client';
import paymentsbackend from 'bundles/epic/data/defaults/payments-backend.json';
import type { Tags } from 'bundles/epic/lib/EpicTypes';

type Namespace = {
  sandboxEnabled: boolean;
  mockProcessorEnabled: boolean;
  paymentSchemeAvailable: 'SUBSCRIPTION' | 'PREPAID' | 'PREPAID_AND_SUBSCRIPTION';
  useNewCurrency: any;
  enableOnetimeMTC: boolean;
  enableCourseraPlusUpsellUpgrade: boolean;
  courseraPlusPrepaidEnabled: boolean;
  vatTaxableRegions: any;
  '2024q1-charm-pricing-us': '2024q1-charm-pricing-us-control' | '2024q1-charm-pricing-us-variant-99c';
  stripeSubscriptionPaymentMethodConfig:
    | {
        stripeBvConsumer: 'pmc_1PC76EAEXNY2zYJrBbgPAPqB';
        stripeBvConsumerSandbox: 'pmc_1OyfCuAEXNY2zYJrm0Lbt9Fv';
        stripeConsumerSandbox: 'pmc_1PC3SwBEfO1jc2fnBE9wxPjn';
        stripeConsumer: 'pmc_1PC3kMBEfO1jc2fnYx9gjOvo';
      }
    | {
        stripeBvConsumer: 'pmc_1PqdkXAEXNY2zYJrt09VelKd';
        stripeBvConsumerSandbox: 'pmc_1P9sPiAEXNY2zYJrmGljfhMd';
        stripeConsumerSandbox: 'pmc_1P98RMBEfO1jc2fn4VF6Bg4t';
        stripeConsumer: 'pmc_1PqdhUBEfO1jc2fn2Y31iEKB';
      };
};

const NAMESPACE = 'payments-backend';

// Adding safe optional chaining to avoid massive changes in unit tests.
client.addDefaults?.([paymentsbackend as $TSFixMe]);

const paymentsbackendEpicClient = {
  get<K extends keyof Namespace>(key: K, tags?: Tags): Namespace[K] {
    return client.get(NAMESPACE, key, tags);
  },

  preview<K extends keyof Namespace>(key: K, tags?: Tags, defaults?: Namespace): Namespace[K] {
    return client.preview(NAMESPACE, key, tags);
  },
};

export default paymentsbackendEpicClient;
