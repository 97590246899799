/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import { useSlate, useSlateStatic } from 'slate-react';

import { MonospaceIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { isMarkActive, toggleMark } from 'bundles/cml/editor/utils/markUtils';
import { getToolBarShortcutKey } from 'bundles/cml/editor/utils/toolbarUtils';
import { MARKS } from 'bundles/cml/shared/constants';

import _t from 'i18n!nls/cml';

// temporarily overrides disabled color due to
// https://coursera.slack.com/archives/CPJET5H4L/p1690316059442879?thread_ts=1689172688.819759&cid=CPJET5H4L
const styles = {
  button: css`
    svg {
      path {
        fill: currentcolor;
      }
    }
  `,
};
const MonospaceButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();
  const monospaceDisabled = isTypeDisabled(editor, MARKS.MONOSPACE);

  const handleMonospace = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    toggleMark(staticEditor, MARKS.MONOSPACE);
  }, [staticEditor]);

  const active = isMarkActive(editor, MARKS.MONOSPACE);
  const shortcutKey = getToolBarShortcutKey('+shift+M');

  return (
    <Button
      className="rc-MonospaceButton"
      title={_t('Monospace #{shortcutKey}', { shortcutKey })}
      active={active}
      disabled={monospaceDisabled}
      onClick={handleMonospace}
      type={TOOLBAR_BUTTON_TYPES.formatting}
      css={styles.button}
    >
      <MonospaceIcon size="small" />
    </Button>
  );
};

export default MonospaceButton;
