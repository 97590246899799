/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import PropTypes from 'prop-types';

import type { Theme } from '@coursera/cds-core';

const styles = {
  Toaster: css({
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: '60px',
    zIndex: 10001, // over the CDS Dialog
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  Toast: (theme: Theme) =>
    css({
      maxWidth: '382px',
      width: '100%',
      borderRadius: '3px',
      backgroundColor: 'var(--cds-color-grey-975)',
      boxShadow: `0 2px 10px 0 var(--cds-color-grey-975)`,
      padding: `var(--cds-spacing-150) var(--cds-spacing-200)`,
      marginTop: 'var(--cds-spacing-200)',
      color: 'var(--cds-color-white-0)',
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
      display: 'flex',
    }),
};

type Toast = {
  id: number;
  message: string;
};

type State = {
  toasts: Toast[];
};

/**
 * @deprecated use CDS InlineNotification instead
 */
export class Toaster extends React.LegacyComponentWithChildren<{}, State> {
  state: State = {
    toasts: [],
  };

  static childContextTypes = { addToast: PropTypes.func };

  getChildContext() {
    return {
      addToast: (message: string) => {
        const id = Math.random();
        this.setState((state) => ({
          ...state,
          toasts: [...state.toasts, { message, id }],
        }));

        setTimeout(() => {
          this.setState((state) => ({
            ...state,
            toasts: state.toasts.filter((toast) => toast.id !== id),
          }));
        }, 6000);
      },
    };
  }

  render() {
    const { children } = this.props;
    const { toasts } = this.state;
    return (
      <div>
        {children}
        <div css={styles.Toaster}>
          {toasts.map((toast) => {
            return (
              <div key={toast.id} css={styles.Toast}>
                {toast.message}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
