import type { ReactElement } from 'react';
import * as React from 'react';

import classNames from 'classnames';
import gql from 'graphql-tag';
import moment from 'moment';
import { compose } from 'recompose';

import type { InjectedRouter } from 'js/lib/connectToRouter';
import connectToRouter from 'js/lib/connectToRouter';
import { FormattedMessage } from 'js/lib/coursera.react-intl';
import waitForGraphQL from 'js/lib/waitForGraphQL';
import { LONG_DATE_ONLY_DISPLAY, SHORT_MONTH_DAY_DISPLAY, formatDateTimeDisplay } from 'js/utils/DateTimeUtils';

import withSessionsV2EnrollmentEnabled from 'bundles/course-sessions/utils/withSessionsV2EnrollmentEnabled';
import CourseStatus from 'bundles/enroll-course/components/user-interest/CourseStatus';
import type {
  CoursesV1GetQuery,
  CoursesV1GetQueryVariables,
} from 'bundles/naptimejs/resources/__generated__/CoursesV1';
import type CoursesV1 from 'bundles/naptimejs/resources/courses.v1';

import _t from 'i18n!nls/phoenix-cdp';

type InputProps = {
  courseId: string;
  className?: string;
  customStyle?: React.CSSProperties;
};

type Props = InputProps & {
  course: CoursesV1;
  sessionsV2EnrollmentEnabled: boolean;
  router?: InjectedRouter;
};

export enum CourseModes {
  Session = 'SESSION',
  OnDemand = 'ONDEMAND',
}

export const getFormattedStartDate = (
  course: CoursesV1,
  sessionsV2EnrollmentEnabled: boolean,
  privateSessionStart?: number
): ReactElement | null => {
  if (!course) {
    return null;
  }
  const { courseStatus, plannedLaunchDate, upcomingSessionStartDate, courseMode } = course;
  let startDate: string | undefined;
  let startDateMessage: string;

  if (privateSessionStart) {
    startDate = formatDateTimeDisplay(privateSessionStart, 'MMM D');
    startDateMessage = _t('Starts {startDate}');
  } else if (courseStatus === CourseStatus.preEnroll) {
    // Parse in english and convert it to the relevant locale
    startDate = formatDateTimeDisplay(
      moment(plannedLaunchDate, ['MM-DD-YYYY', 'MMM DD YYYY', 'DD MMM YYYY', 'MMM YYYY', 'MMM Do YYYY'], 'en').locale(
        _t.getLocale()
      ),
      LONG_DATE_ONLY_DISPLAY
    );

    startDateMessage = _t('Starting {startDate}');
  } else if (courseStatus === CourseStatus.launched) {
    if (courseMode === CourseModes.Session && upcomingSessionStartDate) {
      const upcoming = moment(upcomingSessionStartDate).locale(_t.getLocale());
      const now = moment().locale(_t.getLocale());
      if (sessionsV2EnrollmentEnabled) {
        startDate = formatDateTimeDisplay(now, SHORT_MONTH_DAY_DISPLAY);
        startDateMessage = _t('Starts {startDate}');
      } else {
        startDate = formatDateTimeDisplay(upcoming, SHORT_MONTH_DAY_DISPLAY);
        startDateMessage = now.isAfter(upcoming, 'minutes') ? _t('Started {startDate}') : _t('Starts {startDate}');
      }
    } else if (courseMode === CourseModes.OnDemand) {
      startDateMessage = _t('Self Paced');
    } else {
      startDateMessage = _t('No Upcoming Session Available');
    }
  } else {
    startDateMessage = _t('Coming Soon');
  }
  return <FormattedMessage message={startDateMessage} startDate={startDate} />;
};

class StartDateString extends React.Component<Props> {
  // TODO (rkhandelwal) FIXME session start date should not be ongoing session
  // TODO ensure other courseStatuses besides unlaunched can use this copy
  render() {
    const { className, course, sessionsV2EnrollmentEnabled, customStyle } = this.props;
    const combinedClassName = classNames('rc-StartDateString font-xs', className);
    return (
      <div className={classNames('startdate', combinedClassName)} style={customStyle} data-testid="start-date-string">
        {getFormattedStartDate(course, sessionsV2EnrollmentEnabled)}
      </div>
    );
  }
}

export default compose<Props, InputProps>(
  connectToRouter((router) => ({ router })),
  waitForGraphQL<{ courseId: string }, CoursesV1GetQuery, CoursesV1GetQueryVariables, {}>(
    gql`
      query CoursesV1Query($id: String!) {
        CoursesV1 @naptime {
          get(id: $id, showHidden: true) {
            elements {
              id
              courseStatus
              upcomingSessionStartDate
              plannedLaunchDate
              courseMode
            }
          }
        }
      }
    `,
    {
      options: (props: { courseId: string }) => ({
        variables: {
          id: props.courseId,
        },
      }),
      props: ({ data }) => {
        return {
          course: data?.CoursesV1?.get?.elements?.[0],
        };
      },
    }
  ),
  withSessionsV2EnrollmentEnabled(
    ({ courseId }) => courseId,
    () => (
      <div className="startdate rc-StartDateString font-xs start-date-string" style={{ textIndent: '-9999px' }}>
        <span>{_t('Retrieving date')}</span>
      </div>
    )
  )
)(StartDateString);

export const BaseComponent = StartDateString;
