import * as React from 'react';
import { graphql } from 'react-apollo';

import type { ThirdPartyOrganization } from 'bundles/admin-dashboard/types';
import { adminDashboardPageBaseQuery } from 'bundles/admin-dashboard/utils/AdminDashboardPageQuery';
import type {
  AdminDashboardPageBaseQuery,
  AdminDashboardPageBaseQueryVariables,
} from 'bundles/admin-dashboard/utils/__generated__/AdminDashboardPageBaseQuery';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';

// ThirdPartyOrganization will always be defined due to the branch function in AdminDashboard.
export const ThirdPartyOrganizationContext = React.createContext<ThirdPartyOrganization>({} as $TSFixMe);

type PropsFromCaller = {
  thirdPartySlug: string;
  children: React.ReactNode;
};

type PropsFromGraphql = {
  thirdPartyOrganization: ThirdPartyOrganization;
};

export const ThirdPartyOrganizationProviderBase: React.LegacyFunctionComponentWithChildren<PropsFromGraphql> = ({
  thirdPartyOrganization,
  children,
}) => {
  return (
    <ThirdPartyOrganizationContext.Provider value={thirdPartyOrganization}>
      {children}
    </ThirdPartyOrganizationContext.Provider>
  );
};

export const useThirdPartyOrganization = () => React.useContext(ThirdPartyOrganizationContext);

export default graphql<
  PropsFromCaller,
  AdminDashboardPageBaseQuery,
  AdminDashboardPageBaseQueryVariables,
  PropsFromGraphql
>(adminDashboardPageBaseQuery, {
  options: ({ thirdPartySlug }) => ({
    variables: {
      thirdPartySlug,
    },
  }),
  props: ({ data }) => {
    return {
      thirdPartyOrganization: filterExistsOrDefault(data?.ThirdPartyOrganizationsV1Resource?.bySlug?.elements, [])[0],
    };
  },
})(ThirdPartyOrganizationProviderBase);
