import * as React from 'react';
import { useCallback } from 'react';

import { Transforms } from 'slate';
import { useSlate, useSlateStatic } from 'slate-react';

import { UserIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import { usePersonalizationTagContext } from 'bundles/cml/editor/context/personalizationTagContext';
import { isTypeDisabled } from 'bundles/cml/editor/utils/elementUtils';
import { hasAncestorOfType } from 'bundles/cml/editor/utils/slateUtils';
import { BLOCK_TYPES } from 'bundles/cml/shared/constants';
import type { PersonalizationTagElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

const PersonalizationTagButton: React.FC = () => {
  const editor = useSlate();
  const staticEditor = useSlateStatic();

  const disabled = isTypeDisabled(editor, BLOCK_TYPES.PERSONALIZATION_TAG);
  const active = hasAncestorOfType(editor, BLOCK_TYPES.PERSONALIZATION_TAG);
  const { onPersonalizationTagClick } = usePersonalizationTagContext();

  const addTagToEditor = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    (tagValue: string) => {
      const personalizationTag: PersonalizationTagElement = {
        type: BLOCK_TYPES.PERSONALIZATION_TAG,
        isInline: true,
        isVoid: true,
        tagValue,
        children: [{ text: '' }],
      };

      Transforms.insertNodes(staticEditor, personalizationTag);
    },
    [staticEditor]
  );

  const handleChange = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    onPersonalizationTagClick?.(addTagToEditor);
  }, [onPersonalizationTagClick, addTagToEditor]);

  return (
    <Button
      className="rc-PersonalizationTagButton"
      onClick={handleChange}
      disabled={disabled}
      active={active}
      title={_t('Insert Personalization Tag')}
      buttonProps={{
        icon: <UserIcon color="interactive" size="small" />,
        iconPosition: 'before',
        variant: 'secondary',
      }}
      useDefaultCdsColor={true}
      type={TOOLBAR_BUTTON_TYPES.insertion}
    >
      {_t('Tag')}
    </Button>
  );
};

export { PersonalizationTagButton };
