import React from 'react';

import { useRadioGroup } from '@material-ui/core/RadioGroup';

import clsx from 'clsx';

import type { IconProps } from '@coursera/cds-common';
import { IconContext, useId } from '@coursera/cds-common';

import type { ValidationStatus } from '@core/forms/FormControl';
import getRadioAndCheckboxCss, {
  classes,
} from '@core/forms/getRadioAndCheckboxCss';
import { RadioInput } from '@core/forms/RadioInput';
import Typography from '@core/Typography2';
import { ariaLabelledByForMultipleLabels } from '@core/utils/a11y';

export type Props = {
  /**
   * The element's unique identifier.
   * @ignore
   */
  id?: string;
  /**
   * CSS classname applied to the root element.
   */
  className?: string;
  /**
   * Label displayed next to the radio
   */
  label: string;
  /**
   * The value of the component.
   */
  value?: string;
  /**
   * Name attribute of the input element.
   */
  name?: string;
  /**
   * Defines support text for the field.
   */
  supportText?: React.ReactNode;
  /**
   * Attributes applied to the input element.
   */
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  /**
   * Render an icon next to the radio selection input.
   */
  icon?: React.ReactElement<IconProps>;
  /**
   * Ref that points to the `input` element node
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * Prop that dictates wether the radio appears checked on unchecked.
   */
  checked?: boolean;
  /**
   * Prop that makes the radio disabled when true.
   */
  disabled?: boolean;
  /**
   * Defines field success/error styling.
   */
  validationStatus?: ValidationStatus;
  /**
   * Callback function that executes when the control is clicked.
   * @param {object} event. The even source of the callback.
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Non-interactive content space.
   */
  children?: React.ReactNode;
};

/**
 * Radio allow the user to select one option from a set
 *
 * See [Props](__storybookUrl__/components-inputs-radio--default#props)
 */
const Radio = React.forwardRef<HTMLDivElement, Props>(function Radio(
  props,
  ref
) {
  const {
    id: idFromProps,
    icon,
    className,
    label,
    supportText,
    disabled,
    onChange,
    value,
    name,
    inputRef,
    inputProps,
    validationStatus,
    children,
    ...rest
  } = props;

  const radioGroup = useRadioGroup();

  let checked = props.checked;

  // Use props if component is not in the RadioGroupContext provided by MUI
  // @link https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Radio/Radio.js#L75
  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = radioGroup.value === props.value;
    }
  }

  const id = useId(idFromProps);
  const labelId = `${id}-label`;
  const supportTextId = supportText ? `${id}-support` : undefined;

  return (
    <div
      ref={ref}
      className={clsx(className, {
        [classes.checked]: checked,
        [classes.disabled]: disabled,
        [classes.success]: validationStatus === 'success',
        [classes.error]: validationStatus === 'error',
      })}
      css={getRadioAndCheckboxCss}
      {...rest}
    >
      <label className={classes.label}>
        <RadioInput
          checked={checked}
          className={classes.input}
          disabled={disabled}
          focusVisibleClassName={classes.focusVisible}
          id={id}
          inputProps={{
            'aria-labelledby': ariaLabelledByForMultipleLabels(
              labelId,
              supportTextId
            ),
            ...inputProps,
          }}
          inputRef={inputRef}
          name={name}
          value={value}
          onChange={onChange}
        />

        {icon && (
          <div className={classes.icon}>
            <IconContext.Provider value={{ size: 'medium' }}>
              {icon}
            </IconContext.Provider>
          </div>
        )}

        <Typography
          className={classes.labelText}
          color="inherit"
          component="span"
          id={labelId}
          variant="bodyPrimary"
        >
          {label}
        </Typography>
      </label>

      {supportText && (
        <Typography
          className={clsx(classes.supportText, !!icon && classes.hasIcon)}
          component="div"
          id={supportTextId}
          variant="bodySecondary"
        >
          {supportText}
        </Typography>
      )}

      {children && (
        <div className={classes.nonInteractiveSpace}>{children}</div>
      )}
    </div>
  );
});

export default Radio;
