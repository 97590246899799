import * as React from 'react';

import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { keyBy } from 'lodash';

import { useOnboardingData } from 'bundles/program-personalized-tab/contexts/OnboardingContext';
import SelectedSkillRecsContext from 'bundles/program-personalized-tab/contexts/SelectedSkillRecsContext';
import EnterpriseProductRecsForSkills from 'bundles/program-personalized-tab/queries/EnterpriseProductRecsForSkills.graphql';
import type {
  EnterpriseProductRecsForSkillsQuery,
  EnterpriseProductRecsForSkillsQueryVariables,
} from 'bundles/program-personalized-tab/queries/__generated__/EnterpriseProductRecsForSkills';
import type {
  DiscoveryCollectionsProductTypename,
  MixedCollectionsBySkill,
  ProductEntry,
  Skill,
} from 'bundles/program-personalized-tab/types/sharedTypes';
import { getRequestSkillsParams } from 'bundles/program-personalized-tab/utils/skillRecsUtils';
import {
  useEnterpriseProductFeatures,
  useEnterpriseProductFeaturesLoading,
} from 'bundles/unified-common/contexts/EnterpriseProductFeaturesContext';

type Props = {
  programId: string;
  children: React.ReactNode;
};

const useRecsForSkillsQuery = (programId: string, skills?: Skill[]) => {
  const productFeatures = useEnterpriseProductFeatures();
  const areProductFeaturesLoading = useEnterpriseProductFeaturesLoading();
  const skillParams = getRequestSkillsParams(skills);

  const { loading, error, data } = useQuery<
    EnterpriseProductRecsForSkillsQuery,
    EnterpriseProductRecsForSkillsQueryVariables
  >(EnterpriseProductRecsForSkills, {
    variables: {
      programId,
      skillParams,
      contextId: productFeatures?.enableV2SkillsTaxonomy
        ? 'personalized-tab-skill-based-recs-t2'
        : 'personalized-tab-skill-based-recs-t1',
    },
    skip: areProductFeaturesLoading || skillParams.length === 0,
    notifyOnNetworkStatusChange: true,
    // If we don't set this, the request returns the data for the scored skill recs too (uses the same query). I don't know why.
    fetchPolicy: 'no-cache',
    context: { clientName: 'gatewayGql' },
  });

  const collectionsBySkill: MixedCollectionsBySkill = {};
  const skillByName = keyBy(skills, 'name');

  for (const collection of data?.DiscoveryCollections?.queryEnterpriseCollectionsByProgram?.productCollections ?? []) {
    if (collection?.label && skillByName[collection.label]) {
      const skillId = skillByName[collection.label].id;
      const productEntryEntities: ProductEntry[] = [];

      collection.entities?.forEach((entity) => {
        if (entity && entity.__typename && entity.__typename !== 'DiscoveryCollections_Clip') {
          productEntryEntities.push({
            type: 'productCard',
            ...entity,
            __typename: entity.__typename as DiscoveryCollectionsProductTypename,
          });
        }
      });

      collectionsBySkill[skillId] = { id: collection.id, label: collection.label, entities: productEntryEntities };
    }
  }

  return { loading, error, recs: collectionsBySkill };
};

const SelectedSkillRecsDataProvider = ({ programId, children }: Props) => {
  const { skillsLearnerInterestedIn } = useOnboardingData();
  const { loading, error, recs } = useRecsForSkillsQuery(programId, skillsLearnerInterestedIn);

  if (error) {
    Sentry.captureException(error);
  }

  return (
    <SelectedSkillRecsContext.Provider value={{ loading, error, recs }}>{children}</SelectedSkillRecsContext.Provider>
  );
};

export default SelectedSkillRecsDataProvider;
