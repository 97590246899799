/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Typography, useTheme } from '@coursera/cds-core';
import { CloseIcon } from '@coursera/cds-icons';

import _t from 'i18n!nls/common';

const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      background: var(--cds-color-white-0);
      box-shadow: 2px 2px 4px 2px rgb(0 0 0 / 25%);
      border-radius: 8px;
      padding: var(--cds-spacing-300);
      min-height: 617px;
      max-height: 800px;
      width: 422px;
      display: flex;
      flex-direction: column;
      row-gap: var(--cds-spacing-300);
      overflow-y: auto;
    `,
    header: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--cds-color-grey-975);
    `,
    cursorPointer: css`
      cursor: pointer;
    `,
  };
};

type ContainerProps = {
  title: string;
  onClose: () => void;
};

const HelpBoxContainer: React.LegacyFunctionComponentWithChildren<ContainerProps> = ({ title, onClose, children }) => {
  const styles = useStyles();

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <Typography variant="h1semibold">{title}</Typography>
        <CloseIcon
          role="button"
          aria-label={_t('Close')}
          tabIndex={0}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Escape' || e.key === 'Enter') {
              onClose();
            }
          }}
          onClick={onClose}
          css={styles.cursorPointer}
        />
      </div>
      {children}
    </div>
  );
};

export default HelpBoxContainer;
