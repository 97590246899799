import * as React from 'react';
import { useEffect, useMemo } from 'react';

import { ReactEditor, useSlateStatic } from 'slate-react';

type Props = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export const TABABBLE_ELEMENTS =
  'a, area, button:not(.vjs-default-button):not(.vjs-close-button):not(.vjs-done-button), frame, iframe, input, object, textarea, [tabindex]:not([disabled]):not([tabindex="-1"]';

const getPreviousElement = (elements: HTMLElement[], anchorEl: HTMLElement) => {
  const splitIndex = elements.indexOf(anchorEl);
  const previousElements = elements?.slice(0, splitIndex).reverse();
  const previousElement = previousElements?.[0];

  return previousElement || anchorEl;
};

const getNextElement = (elements: HTMLElement[], anchorEl: HTMLElement) => {
  const splitIndex = elements.indexOf(anchorEl);
  const nextElements = elements.slice(splitIndex + 1);
  const nextElement = nextElements?.[0];

  return nextElement || anchorEl;
};

const RestoreFocus: React.LegacyFunctionComponentWithChildren<Props> = ({ anchorEl, onClose, children }) => {
  const staticEditor = useSlateStatic();
  const tabbableElements = useMemo(() => Array.from(document.querySelectorAll(TABABBLE_ELEMENTS)) as HTMLElement[], []);

  useEffect(() => {
    if (!anchorEl) {
      return () => undefined;
    }

    const handleClose = (e: KeyboardEvent) => {
      if (e.key !== 'Escape') {
        return;
      }

      ReactEditor.focus(staticEditor);
      onClose();

      anchorEl.focus();
    };

    document.addEventListener('keydown', handleClose);

    return () => document.removeEventListener('keydown', handleClose);
  }, [staticEditor, anchorEl, onClose]);

  useEffect(() => {
    if (!anchorEl) {
      return undefined;
    }

    const handleFocus = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') {
        return;
      }

      ReactEditor.focus(staticEditor);
      onClose();

      const element = e.shiftKey
        ? getPreviousElement(tabbableElements, anchorEl)
        : getNextElement(tabbableElements, anchorEl);
      element.focus();
      e.preventDefault();
    };

    document.addEventListener('keydown', handleFocus);
    return () => document.removeEventListener('keydown', handleFocus);
  }, [staticEditor, tabbableElements, anchorEl, onClose]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default RestoreFocus;
