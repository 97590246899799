/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import type { InputRendererHandler } from 'react-select';
import ReactSelect from 'react-select';

import _ from 'lodash';
import PropTypes from 'prop-types';
import type { LegacyContextType } from 'types/legacy-context-types';

import Retracked from 'js/app/retracked';

// CDS
import type { ButtonProps } from '@coursera/cds-core';
import { Button } from '@coursera/cds-core';
import { ChevronDownIcon, ChevronUpIcon } from '@coursera/cds-icons';

import withSingleTracked from 'bundles/common/components/withSingleTracked';
import SearchFilterModal from 'bundles/enterprise-collections/components/filters/SearchFilterModal';
import SearchFilterOption from 'bundles/enterprise-collections/components/filters/SearchFilterOption';
import { NUMBER_OF_ITEMS_TO_FIT_IN_FILTER_DROPDOWN } from 'bundles/search-common/constants/filter';
import { getFilterItemsOrder } from 'bundles/search-common/utils/SearchUtils';
import getFilterItemsTranslations from 'bundles/search-common/utils/getFilterItemsTranslations';

import _t from 'i18n!nls/enterprise-collections';

const TrackedButton = withSingleTracked({ type: 'BUTTON' })<ButtonProps>(Button);

const styles = {
  searchFilterContainer: () => css`
    padding: 0;
    margin-right: 14px;
    margin-bottom: 8px;

    &:has([role='button'][aria-expanded='false']):focus,
    &:has([role='button'][aria-expanded='false']):focus-within {
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px rgb(102 175 233 / 60%);
      outline: var(--cds-color-grey-975) solid 1px;
    }

    .Select.is-disabled {
      display: none;
    }

    .Select-placeholder {
      font-family: 'Source Sans Pro', Arial, sans-serif;
      font-weight: 600;
      color: var(--cds-color-grey-975);
    }

    .Select-menu-outer {
      width: 330px;
      z-index: 100;
      border: none;
    }

    .Select-menu {
      padding: 10px 10px;
      max-height: none;
      background: var(--cds-color-white-0);
      border: 1px solid var(--cds-color-grey-300);
    }

    .Select-control {
      width: 160px;
      border-radius: 5px;
      border: 1px solid var(--cds-color-grey-300);
      font-size: 14px;
    }

    .Select-input {
      width: 100% !important;
    }

    .Select-option {
      padding: 0;
    }

    .is-focused {
      background: #e5f2ff;

      .rc-SearchFilterOption {
        .filter-button,
        .checkboxText {
          background: transparent;
        }
      }

      .filters-menu-buttons {
        background: transparent;
      }
    }

    .filters-menu-buttons {
      background-color: var(--cds-color-white-0);
    }

    .update-refinements {
      background: none;
      border: none;
      color: var(--cds-color-blue-700);
      font-weight: bold;
      font-size: 14px;
      width: fit-content;
      vertical-align: middle;
    }
  `,
};

type option = { count: number; isRefined: boolean; label: string; value: string };
type optionWithType = option & { type: string };

export type Props = {
  updateFilterAvailability: (attribute: string, filtersIsAvailable: boolean) => void;
  filterName: string;
  attribute: string;
  limit: number;
  filterLabel: string;
  addFilters?: (x0: string) => void;
  removeFilters?: (filters: string[]) => void;
  currentRefinement?: string | string[];
  items: option[];
  searching: boolean;
};

export type State = {
  isFiltersModalOpened: boolean;
  lastFocusedOption?: option;
};
export class SearchFilter extends React.Component<Props, State> {
  declare ref: HTMLElement | null | undefined;

  declare selectRef: HTMLElement | null | undefined;

  /**
   * this is a workaround for ACCESS-1678: pressing "tab" selects the checkbox.
   * react-select onChange implementation prevents us from accessing original event
   * so this hack store the last event key to be used on onChange
   */
  declare lastEventKey: string | null | undefined;

  declare lastEventKeyTimeout: ReturnType<typeof setTimeout> | null | undefined;

  static contextTypes = {
    _eventData: PropTypes.object.isRequired,
  };

  declare context: LegacyContextType<typeof SearchFilter.contextTypes>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isFiltersModalOpened: false,
      lastFocusedOption: undefined,
    };
  }

  componentDidMount = () => {
    const { items, updateFilterAvailability, attribute } = this.props;
    updateFilterAvailability(attribute, items.length > 0);
  };

  componentDidUpdate = (prevProps: Props) => {
    const { items, updateFilterAvailability, attribute } = this.props;
    const { items: oldItems } = prevProps;
    if (items.length !== oldItems.length) updateFilterAvailability(attribute, items.length > 0);
  };

  componentWillUnmount = () => {
    if (this.lastEventKeyTimeout) {
      clearTimeout(this.lastEventKeyTimeout);
    }
  };

  setRef = (node: HTMLElement | null) => {
    this.ref = node;
  };

  setSelectRef = (node: HTMLElement | null) => {
    this.selectRef = node;
  };

  clearFilters = () => {
    const { removeFilters, items, attribute } = this.props;
    removeFilters?.(items?.map(({ value }) => `${attribute}:${value}`));
  };

  onChange = (clickedOption: optionWithType) => {
    const { _eventData } = this.context;
    const { value, isRefined } = clickedOption;
    const { attribute, addFilters, removeFilters } = this.props;
    const isTabEventKey = this.lastEventKey === 'Tab';

    if (isTabEventKey) {
      return;
    } else if (value === 'SHOW_ALL') {
      this.showAllFilters();
      return;
    } else if (value === 'CLEAR') {
      this.clearFilters();
      return;
    }

    if (isRefined) {
      removeFilters?.([`${attribute}:${value}`]);
    } else {
      addFilters?.(`${attribute}:${value}`);
    }
    Retracked.trackComponent(_eventData, { clickedOption }, 'search_filter_option', 'click');
    this.setFocusedOption({ ...clickedOption, isRefined: !clickedOption.isRefined });
  };

  onSearchFilterClose = () => {
    const { filterName, currentRefinement } = this.props;
    const { _eventData } = this.context;

    const trackingName = `search_filter_${filterName}_close`;
    const adjustedEventData = _eventData;

    if (currentRefinement && currentRefinement.length) adjustedEventData.refinementList = currentRefinement;

    Retracked.trackComponent(adjustedEventData, {}, trackingName, 'click');
  };

  onSearchFilterOpen = () => {
    const { filterName, currentRefinement } = this.props;
    const { _eventData } = this.context;

    const trackingName = `search_filter_${filterName}_open`;
    const adjustedEventData = _eventData;

    if (currentRefinement && currentRefinement.length) adjustedEventData.refinementList = currentRefinement;
    Retracked.trackComponent(adjustedEventData, {}, trackingName, 'click');

    this.setFocusedOption();
  };

  closeModal = () => {
    this.setState({ isFiltersModalOpened: false });
  };

  arrowRenderer = ({ isOpen }: { isOpen: boolean }) => {
    return isOpen ? <ChevronUpIcon size="small" /> : <ChevronDownIcon size="small" />;
  };

  showAllFilters = () => {
    this.setState(() => ({
      isFiltersModalOpened: true,
    }));
  };

  getFocusedOption = () => {
    // @ts-expect-error
    return this.selectRef._focusedOption;
  };

  setFocusedOption = (option?: option) => {
    const currentFocusedOption = option || this.getFocusedOption();

    this.setState(() => ({ lastFocusedOption: currentFocusedOption }));
  };

  isMenuOpen = () => {
    // @ts-expect-error
    return this.selectRef.state.isOpen;
  };

  /* This function will be called after each user interaction (click, keydown, mousemove).
     If menu is opened and focused value has been changed we will call onFocusedOptionChanged
     function passed to this component using props. We do it asynchronously because onKeyDown
     event is fired before the focused option has been changed.
     modifiedFrom: https://stackoverflow.com/questions/51660675/get-value-of-highlighted-option-in-react-select/54275802
  */
  onUserInteracted = (event: React.KeyboardEvent<HTMLElement>) => {
    const { lastFocusedOption } = this.state;
    this.lastEventKey = event.key; // see definition for more details
    const isTabEventKey = this.lastEventKey === 'Tab';
    this.lastEventKeyTimeout = setTimeout(() => {
      this.lastEventKey = null;
      this.lastEventKeyTimeout = null;
    }, 200);
    Promise.resolve().then(() => {
      const currentFocusedOption = this.getFocusedOption();

      if (this.isMenuOpen() && lastFocusedOption !== currentFocusedOption) {
        if (isTabEventKey) {
          // @ts-expect-error
          this.selectRef.setState({ isOpen: false });
        }
        this.setFocusedOption(currentFocusedOption);
      }
    });
  };

  renderAutocompleteInput: InputRendererHandler = (autoCompleteInputProps) => {
    const { filterName } = this.props;
    // react-select v1's assignment of aria props is causing VoiceOver to incorrectly announce the combobox; this manually fixes it
    // TODO: remove once web is migrated to React 16.x and react-select v2
    return (
      <div
        role="button"
        aria-controls={autoCompleteInputProps['aria-owns']}
        aria-haspopup="listbox"
        aria-owns={autoCompleteInputProps['aria-owns']}
        aria-expanded={autoCompleteInputProps['aria-expanded']}
        className="Select-input"
        aria-label={`${filterName} Filter`}
        tabIndex={0}
      />
    );
  };

  renderOption = (option: optionWithType) => {
    const { filterName, attribute } = this.props;
    const filterNameSnakeCased = _.snakeCase(filterName);

    if (option.type === 'REFINEMENT') {
      return (
        <SearchFilterOption filterName={attribute} option={option} onHover={() => this.setFocusedOption(option)} />
      );
    } else if (option.type === 'SHOW_ALL') {
      return (
        <div key={option.label} className="filters-menu-buttons">
          <TrackedButton
            className="update-refinements"
            trackingName={`${filterNameSnakeCased}_filter_show_all_button`}
            variant="ghost"
            onClick={this.showAllFilters}
          >
            {_t('Show All')}
          </TrackedButton>
        </div>
      );
    } else if (option.type === 'CLEAR') {
      return (
        <div key={option.label} className="filters-menu-buttons">
          <TrackedButton
            trackingName={`${filterNameSnakeCased}_filter_clear_button`}
            className="update-refinements"
            variant="ghost"
            onClick={this.clearFilters}
          >
            {_t('Clear')}
          </TrackedButton>
        </div>
      );
    } else return null;
  };

  render() {
    const { items, filterName, attribute, filterLabel, addFilters, removeFilters, currentRefinement } = this.props;
    const { isFiltersModalOpened, lastFocusedOption } = this.state;

    const sortedItems = getFilterItemsOrder(items, attribute);
    const translatedItems: option[] = getFilterItemsTranslations(sortedItems, attribute);

    const trimmedItems = translatedItems.slice(0, NUMBER_OF_ITEMS_TO_FIT_IN_FILTER_DROPDOWN);
    const options: optionWithType[] = trimmedItems.map((item) => ({ ...item, type: 'REFINEMENT' }));

    // Passing an additional option so it can be rendered as menu with clear and show all buttons in the select menu
    if (items.length > NUMBER_OF_ITEMS_TO_FIT_IN_FILTER_DROPDOWN) {
      options.push({
        label: `Show all ${filterName} filters`,
        type: 'SHOW_ALL',
        value: 'SHOW_ALL' as string,
      } as optionWithType);
    }
    if ((currentRefinement?.length || 0) > 0) {
      options.push({
        label: `Clear all ${filterName} filters`,
        type: 'CLEAR',
        value: 'CLEAR' as string,
      } as optionWithType);
    }

    return (
      <div className="rc-SearchFilter" css={styles.searchFilterContainer} ref={this.setRef} data-testid="search-filter">
        <div data-e2e={attribute} onKeyDown={this.onUserInteracted} data-testid="enroll-in-credential-button">
          {/* @ts-expect-error custom option object should not throw error */}
          <ReactSelect
            ref={this.setSelectRef}
            arrowRenderer={this.arrowRenderer}
            closeOnSelect={false}
            searchable={false}
            disabled={trimmedItems.length === 0}
            placeholder={filterName}
            inputRenderer={this.renderAutocompleteInput}
            optionRenderer={this.renderOption}
            options={options}
            onChange={this.onChange}
            onOpen={this.onSearchFilterOpen}
            onClose={this.onSearchFilterClose}
          />
          <div className="sr-only" aria-live="assertive">
            {lastFocusedOption &&
              `${lastFocusedOption.label} - ${lastFocusedOption.count}, ${
                lastFocusedOption.isRefined ? 'checked' : 'unchecked'
              }, checkbox`}
          </div>
          <SearchFilterModal
            items={translatedItems}
            closeModal={this.closeModal}
            filterName={attribute}
            filterLabel={filterLabel}
            open={isFiltersModalOpened}
            addFilters={addFilters}
            removeFilters={removeFilters}
            clearFilters={this.clearFilters}
          />
        </div>
      </div>
    );
  }
}

export default SearchFilter;
