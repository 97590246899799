import { useEffect, useMemo, useRef } from 'react';

import { createEditor } from 'slate';
import type { Element } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { useEventingContext } from 'bundles/cml/editor/context/eventingContext';
import { insertData } from 'bundles/cml/editor/html/deserialize';
import { normalize } from 'bundles/cml/editor/normalize/normalize';
import type { NotificationMessage } from 'bundles/cml/editor/types/notification';
import type { CodeBlockOptions } from 'bundles/cml/legacy/types/Content';
import type { ToolsKeys } from 'bundles/cml/shared/utils/customTools';

export const useSlateEditor = (
  setNotification: (message: NotificationMessage) => void,
  tools: ToolsKeys[],
  codeBlockOptions?: CodeBlockOptions
) => {
  const { eventingData } = useEventingContext();

  const { editor, insertTextData, normalizeNode } = useMemo(() => {
    const slateEditor = withHistory(withReact(createEditor()));
    const { isVoid, isInline, normalizeNode: normalizeFallback, insertData: insertDataFallback } = slateEditor;

    slateEditor.isVoid = (el: Element) => el.isVoid === true || isVoid(el);
    slateEditor.isInline = (el: Element) => el.isInline === true || isInline(el);

    return { editor: slateEditor, insertTextData: insertDataFallback, normalizeNode: normalizeFallback };
  }, []);

  const staticEditor = useRef(editor).current;

  useEffect(() => {
    staticEditor.insertData = insertData(staticEditor, insertTextData, setNotification, eventingData);
  }, [staticEditor, insertTextData, setNotification, eventingData]);

  useEffect(() => {
    staticEditor.normalizeNode = normalize(staticEditor, { tools: new Set(tools), codeBlockOptions }, normalizeNode);
  }, [staticEditor, tools, normalizeNode, codeBlockOptions]);

  return editor;
};
