import { ThemeProvider } from '@emotion/react';

import * as React from 'react';
import { graphql } from 'react-apollo';

import { compose, withProps } from 'recompose';

import Retracked from 'js/app/retracked';
import user from 'js/lib/user';

import type { UserEmail } from '@coursera/grpc-types-useremails/coursera/proto/useremails/v1/email_address';

import type { SearchScope } from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchBarV2';
import { EnterpriseSearchBarImpl } from 'bundles/enterprise-learner-search/components/search/EnterpriseSearchBarV2';
import filterExistsOrDefault from 'bundles/enterprise-legacy-learner-home/utils/filterExistsOrDefault';
import PageFooter from 'bundles/page-footer/components/PageFooter';
import PageHeader from 'bundles/page-header/components/PageHeader';
import useSimplifiedNav from 'bundles/page-header/hooks/useSimplifiedNav';
import { IdTypes } from 'bundles/product-features';
import EnterpriseHomeAccessErrorPage from 'bundles/program-home/components/enterprise-home/EnterpriseHomeAccessErrorPage';
import { EnterpriseHomePageQuery } from 'bundles/program-home/components/enterprise-home/EnterpriseHomePageQuery';
import type {
  EnterpriseHomePageQuery_EnterpriseLearnerSummariesV1Resource_byUserIdAndThirdPartyOrgSlug_elements_domains as Domain,
  EnterpriseHomePageQuery as EnterpriseHomePageQueryType,
  EnterpriseHomePageQueryVariables,
  EnterpriseHomePageQuery_EnterpriseLearnerSummariesV1Resource_byUserIdAndThirdPartyOrgSlug_elements_enterprisePrograms_elements as EnterpriseProgram,
  EnterpriseHomePageQuery_EnterpriseLearnerSummariesV1Resource_byUserIdAndThirdPartyOrgSlug_elements_invitations_elements as ProgramInvitation,
  EnterpriseHomePageQuery_SubdomainsV1Resource_getAll_elements as Subdomain,
  EnterpriseHomePageQuery_EnterpriseLearnerSummariesV1Resource_byUserIdAndThirdPartyOrgSlug_elements_thirdPartyOrganization as ThirdPartyOrganization,
} from 'bundles/program-home/components/enterprise-home/__generated__/EnterpriseHomePageQuery';
import { useSsoAuthProviderParams } from 'bundles/program-home/components/enterprise-home/useSsoAuthProviderParams';
import { withProductFeatures } from 'bundles/program-home/productFeatureFlags';
import FindUserEmailsByUserIdQuery from 'bundles/program-home/queries/FindUserEmailsByUserIdQuery';
import type {
  FindUserEmailsByUserIdQueryResult,
  FindUserEmailsByUserIdQueryVariables,
} from 'bundles/program-home/queries/FindUserEmailsByUserIdQuery';
import { searchTheme } from 'bundles/search-common/searchTheme';

import _t from 'i18n!nls/program-home';

import 'css!./__styles__/EnterpriseHomePage';

type PropsFromCaller = {
  children: JSX.Element;
  // Used via recompose.
  params: { orgSlug: string };
};

type PropsFromWithProps = {
  userId?: number;
  thirdPartyOrgSlug: string;
};

type PropsFromGraphql = {
  loading: boolean;
  thirdPartyOrganization: ThirdPartyOrganization | null | undefined;
  enterprisePrograms: EnterpriseProgram[];
  programInvitations: ProgramInvitation[];
  domains: Domain[];
  subdomains: Subdomain[];
};

type PropsFromFindUserEmailsByUserIdQuery = {
  userEmails: UserEmail[];
};

type PropsFromProductFeatures = {
  isBrowseOnlyProgram: boolean;
  shouldShowSponsoredByMessage: boolean;
};

type PropsFromProductFeatures2 = {
  allowOrgForCurriculumBuilder?: boolean;
  allowOrgForSpecializationConfiguration?: boolean;
  allowShortFormContentBasedOnAudience: boolean;
  enableSkillsDashboard: boolean;
  isFeatureToggleLoading: boolean;
  showSkillSetRoleFilter: boolean;
};

export type Props = PropsFromWithProps &
  PropsFromGraphql &
  PropsFromFindUserEmailsByUserIdQuery &
  PropsFromProductFeatures &
  PropsFromProductFeatures2 & { children: JSX.Element };

export type PropsFromEnterpriseHomeApp = {
  searchBar?: JSX.Element;
  programIds: Array<string>;
} & PropsFromWithProps &
  PropsFromGraphql &
  PropsFromFindUserEmailsByUserIdQuery &
  PropsFromProductFeatures &
  PropsFromProductFeatures2;

type WrapperProps = {
  thirdPartyOrganizationId?: string;
  searchBar?: JSX.Element;
};
const EnterpriseHomeWrapper: React.LegacyFunctionComponentWithChildren<WrapperProps> = ({
  children,
  thirdPartyOrganizationId,
  searchBar,
}) => {
  const useUnifiedSearchBar = useSimplifiedNav();

  return (
    <div className="rc-EnterpriseHomeApp">
      <PageHeader
        hideSearch={!useUnifiedSearchBar}
        showGDPRBanner
        isEnterprise
        thirdPartyOrganizationId={thirdPartyOrganizationId}
        hideEnterprise
        logoWrapper="div"
        injectedSearchBar={searchBar}
        isOrgHome
      />
      {children}
      <PageFooter />
    </div>
  );
};

export function getSearchBar(
  enterprisePrograms: EnterpriseProgram[],
  thirdPartyOrgSlug: string,
  allowShortFormContentBasedOnAudience = false,
  shouldHideSearchScope = false
) {
  const programIds: Array<string> = [];
  const scopes: Array<SearchScope> = enterprisePrograms
    .map((tmp) => {
      const programId = tmp.id;
      programIds.push(programId);
      const { name: programName, slug: programSlug0 } = tmp.metadata;
      return {
        trackingData: { programSlug: programSlug0 },
        pathname: `/programs/${programSlug0}`,
        placeholder: _t("Search in '#{programName}'...", { programName }),
        title: programName,
        programIds: [programId],
      };
    })
    .sort((a, b) => a.placeholder.localeCompare(b.placeholder));
  scopes.unshift({
    trackingData: { thirdPartyOrgSlug },
    pathname: `/programs/all/${thirdPartyOrgSlug}`,
    placeholder: _t('Search in all programs you joined...'),
    title: _t('All Learning Programs'),
    programIds,
  });
  // Explicitly pass empty array for program curriculum here since we are not running experiment here but we marked it as a required [] to track changes more easily
  return (
    <EnterpriseSearchBarImpl
      initialScopeIndex={0}
      scopes={scopes}
      shouldShowShortFormContent={allowShortFormContentBasedOnAudience}
      shouldHideSearchScope={shouldHideSearchScope}
    />
  );
}

export const EnterpriseHomeApp: React.LegacyFunctionComponentWithChildren<Props> = ({
  userId,
  thirdPartyOrgSlug,
  loading,
  thirdPartyOrganization,
  enterprisePrograms,
  programInvitations,
  userEmails,
  domains,
  subdomains,
  isBrowseOnlyProgram,
  shouldShowSponsoredByMessage,
  allowOrgForCurriculumBuilder,
  allowOrgForSpecializationConfiguration,
  allowShortFormContentBasedOnAudience,
  enableSkillsDashboard,
  isFeatureToggleLoading,
  showSkillSetRoleFilter,
  children,
}: Props) => {
  useSsoAuthProviderParams({ userId, thirdPartyOrgSlug });

  if (loading || isFeatureToggleLoading) {
    return null;
  }

  let searchBar: JSX.Element | undefined;

  if (userId && thirdPartyOrganization) {
    searchBar = getSearchBar(enterprisePrograms, thirdPartyOrgSlug);
  }
  const propsForChildren: PropsFromEnterpriseHomeApp = {
    userId,
    thirdPartyOrgSlug,
    loading,
    thirdPartyOrganization,
    enterprisePrograms,
    programInvitations,
    userEmails,
    domains,
    subdomains,
    isBrowseOnlyProgram,
    shouldShowSponsoredByMessage,
    allowOrgForCurriculumBuilder,
    allowOrgForSpecializationConfiguration,
    searchBar,
    // TODO: need to investigate why enterprisePrograms could be undefined
    // https://coursera.sentry.io/issues/4027751718/?project=200711&query=is%3Aunresolved&referrer=issue-stream&stream_index=3
    programIds: enterprisePrograms ? enterprisePrograms.map((program) => program.id) : [],
    allowShortFormContentBasedOnAudience,
    enableSkillsDashboard,
    isFeatureToggleLoading,
    showSkillSetRoleFilter,
  };

  const shouldRenderAccessErrorPage =
    !loading &&
    (!userId || !thirdPartyOrganization || (programInvitations.length < 1 && enterprisePrograms.length < 1));

  return (
    <React.Fragment>
      {/* Additional theme provider required for search components */}
      <ThemeProvider theme={searchTheme}>
        <EnterpriseHomeWrapper thirdPartyOrganizationId={thirdPartyOrganization?.id} searchBar={searchBar}>
          {shouldRenderAccessErrorPage ? (
            <EnterpriseHomeAccessErrorPage
              isLoggedIn={!!userId}
              thirdPartyOrganizationId={thirdPartyOrganization?.id}
            />
          ) : (
            <>{React.cloneElement(children, propsForChildren)}</>
          )}
        </EnterpriseHomeWrapper>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default compose<Props, {}>(
  withProps<PropsFromWithProps, PropsFromCaller>((props) => ({
    userId: user.get().id,
    thirdPartyOrgSlug: props.params.orgSlug,
  })),
  graphql<
    {},
    FindUserEmailsByUserIdQueryResult,
    FindUserEmailsByUserIdQueryVariables,
    PropsFromFindUserEmailsByUserIdQuery
  >(FindUserEmailsByUserIdQuery, {
    props: ({ data }) => {
      return { userEmails: filterExistsOrDefault(data?.FindUserEmailsByUserIdResponse?.userEmails) };
    },
    options: () => ({
      variables: {
        userId: `${user.get().id}`,
      },
      errorPolicy: 'all',
    }),
  }),
  graphql<PropsFromWithProps, EnterpriseHomePageQueryType, EnterpriseHomePageQueryVariables, PropsFromGraphql>(
    EnterpriseHomePageQuery,
    {
      skip: ({ userId }) => !userId,
      options: ({ userId, thirdPartyOrgSlug }) => ({
        variables: {
          // this call running with userId as undefined is not possible due to the skip
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          userId: userId!.toString(),
          thirdPartyOrgSlug,
        },
      }),
      props: ({ data }) => {
        const tmp = data?.EnterpriseLearnerSummariesV1Resource?.byUserIdAndThirdPartyOrgSlug?.elements?.[0];
        return {
          loading: data?.loading ?? true,
          thirdPartyOrganization: tmp?.thirdPartyOrganization,
          enterprisePrograms: filterExistsOrDefault(tmp?.enterprisePrograms?.elements),
          programInvitations: filterExistsOrDefault(tmp?.invitations?.elements),
          domains: filterExistsOrDefault(tmp?.domains),
          subdomains: filterExistsOrDefault(data?.SubdomainsV1Resource?.getAll?.elements),
        };
      },
    }
  ),
  withProps<PropsFromProductFeatures, {}>(() => ({
    // TODO(ppaskaris): We need to decide how to fetch these values. Do we fetch them for each program and vary the UI
    // depending on what program they're interacting with? Do we fetch them at the org level and ignore any
    // program-specific overrides? Do we just use the default values and not bother until we have requirements?
    isBrowseOnlyProgram: false,
    shouldShowSponsoredByMessage: true,
  })),
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_home_v3',
      page: 'enterprise_home_page',
    },
  })),
  withProductFeatures<PropsFromProductFeatures2, { thirdPartyOrganization: ThirdPartyOrganization | null | undefined }>(
    ({ thirdPartyOrganization }) => ({ idType: IdTypes.Organization, id: thirdPartyOrganization?.id ?? '' }),
    ({ features, loading }) => ({
      allowOrgForCurriculumBuilder: features.get('enterprise', 'allowOrgForCurriculumBuilder'),
      allowOrgForSpecializationConfiguration: features.get('enterprise', 'allowOrgForSpecializationConfiguration'),
      allowShortFormContentBasedOnAudience: features.get('enterprise', 'allowShortFormContentBasedOnAudience'),
      enableSkillsDashboard: features.get('enterprise', 'enableSkillsDashboard'),
      isFeatureToggleLoading: loading,
      showSkillSetRoleFilter: features.get('enterprise', 'enableSkillsetRoleFilter'),
    })
  )
)(EnterpriseHomeApp);

export { EnterpriseHomeWrapper };
