/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback } from 'react';

import { ExternalLinkIcon } from '@coursera/cds-icons';

import Button, { TOOLBAR_BUTTON_TYPES } from 'bundles/cml/editor/components/buttons/Button';
import type { LinkElement } from 'bundles/cml/shared/types/elementTypes';

import _t from 'i18n!nls/cml';

type Props = {
  link: LinkElement;
};

const styles = {
  linkButton: css`
    flex: 1;
  `,
  link: css`
    max-width: 161px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--cds-color-interactive-primary);
    font-weight: bold;
  `,
};

const OpenLinkButton: React.FC<Props> = ({ link }) => {
  const handleOpenLink = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    window.open(link.href);
  }, [link]);

  return (
    <Button
      title={link.href}
      role="menuitem"
      onClick={handleOpenLink}
      buttonProps={{
        icon: <ExternalLinkIcon size="small" title={_t('Opens in a new tab')} />,
        iconPosition: 'after',
      }}
      css={styles.linkButton}
      type={TOOLBAR_BUTTON_TYPES.menu}
    >
      <span css={styles.link}>{link.href}</span>
    </Button>
  );
};

export default OpenLinkButton;
