import { ThirdPartyOrganizationsV1_Org_Coursera_Authentication_Thirdpartyauth_OrganizationType as ThirdPartyOrganizationTypes } from '__generated__/graphql-types';

import { useThirdPartyOrganization } from 'bundles/admin-dashboard/components/ThirdPartyOrganizationProvider';

/*
    This is for an effort to change “for-credit” to “required” in all C4B/G programs, 
    The word “Required“ is more aligned to internal corporate training / badging use cases vs an academic context. 
    The C4C copy stays the same.
*/
export const useCampusWordingForAdmin = (): boolean => {
  const org = useThirdPartyOrganization();
  if (!org?.organizationType) return false;
  return org.organizationType === ThirdPartyOrganizationTypes.Campus;
};
