import _t from 'i18n!nls/stackability-common';

const getHomepagesNavXDPCopy = () => ({
  buildTowardADegree: _t('Build toward a degree'),
  earnDegreeCredit: _t('Earn degree credit'),
  degreeCreditEligible: _t('Degree credit eligible'),
  variantAceCreditRecommended: (variantTitle: string) =>
    _t(
      'This #{variantTitle} has ACE® recommendation. It is eligible for college credit at participating U.S. colleges and universities. Note: The decision to accept specific credit recommendations is up to each institution.',
      { variantTitle }
    ),
  prepareForADegree: _t('Prepare for a degree'),
  getAHeadStartOnYourDegree: _t('Get a head start on your degree'),
  eligibleDegreesFcndDescription: (productName: string, degreePartnerName: string) =>
    _t(
      'This #{productName} is part of the following online degree programs offered by #{degreePartnerName}. When you enroll as a non-degree student with the university and complete the #{productName} online, you will earn transferable college credit, which will count toward your degree learning if you are accepted into the full degree program.',
      {
        productName,
        degreePartnerName,
      }
    ),
  eligibleDegreesPartOfDescription: (productName: string, degreePartnerName: string) =>
    _t(
      'This #{productName} is part of the following degree program(s) offered by #{degreePartnerName}. If you are admitted and enroll, your completed coursework may count toward your degree learning and your progress can transfer with you.¹',
      {
        productName,
        degreePartnerName,
      }
    ),
  eligibleDegreesOffersCreditDescription: (productName: string) =>
    _t(
      'When you complete this #{productName}, you may be able to have your learning recognized for credit if you are admitted and enroll in one of the following online degree programs.¹',
      {
        productName,
      }
    ),
  eligibleDegreesOffersCreditDisclaimer: _t(
    '¹Each university determines the number of pre-approved prior learning credits that may count towards the degree requirements according to institutional policies.'
  ),
  eligibleDegreesRelatedToDescription: (productName: string, productPagePartnerName: string) =>
    _t(
      'Taking this #{productName} by #{productPagePartnerName} may provide you with a preview of the topics, materials and instructors in a related degree program which can help you decide if the topic or university is right for you.',
      {
        productName,
        productPagePartnerName,
      }
    ),
  eligibleDegreesFallbackDescription: (productName: string) =>
    _t(
      'When you complete this #{productName}, you can earn college credit if you apply and are accepted into one of the following online degree programs.',
      {
        productName,
      }
    ),
  eligibleDegreesDisclaimerSuccessfulAppRequired: _t(
    '¹Successful application and enrollment are required. Eligibility requirements apply. Each institution determines the number of credits recognized by completing this content that may count towards degree requirements, considering any existing credits you may have. Click on a specific course for more information.'
  ),
});

export default getHomepagesNavXDPCopy;
