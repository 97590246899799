/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import type { Theme } from '@coursera/cds-core';

import {
  PAGELESS_BUTTON_SPACING,
  TOOLBAR_BUTTON_SPACING,
  TOOLBAR_PADDING,
} from 'bundles/cml/editor/components/toolbars/constants';
import ManagedFocus from 'bundles/cml/editor/components/utils/ManagedFocus';

import _t from 'i18n!nls/cml';

const styles = {
  root: (theme: Theme) => css`
    padding: ${theme.spacing(8, TOOLBAR_PADDING)};
    overflow: hidden;
    outline: none;
  `,
  toolbar: (theme: Theme) => css`
    display: flex;
    align-items: center;
    column-gap: ${theme.spacing(TOOLBAR_BUTTON_SPACING)};
  `,
  pageless: (theme: Theme) => css`
    column-gap: ${theme.spacing(PAGELESS_BUTTON_SPACING)};
  `,
};

type BaseProps = React.HTMLAttributes<HTMLElement> & {
  label?: string;
  pageless?: boolean;
  autoFocus?: boolean;
};

type Props = Omit<BaseProps, 'children'> & {
  children: JSX.Element | JSX.Element[] | ((updateFocus: () => void) => JSX.Element | JSX.Element[]);
};

type PropsWithManagedFocus = BaseProps & {
  children: JSX.Element | JSX.Element[];
};

const Toolbar = React.forwardRef<HTMLDivElement, PropsWithManagedFocus>((props, ref) => {
  const { pageless, onKeyDown, children, label = _t('Text Formatting'), ...attributes } = props;

  return (
    <div
      tabIndex={-1}
      role="toolbar"
      aria-label={label}
      ref={ref}
      css={styles.root}
      {...attributes}
      onMouseDown={(e) => e.preventDefault()}
      onClick={(e) => e.preventDefault()}
      onKeyDown={onKeyDown}
    >
      <div css={[styles.toolbar, pageless && styles.pageless]}>{children}</div>
    </div>
  );
});

const ToolbarWithManagedFocus = React.forwardRef<HTMLDivElement, Props>(({ autoFocus, children, ...props }, ref) => {
  const callback = typeof children === 'function' ? children : () => <React.Fragment>{children}</React.Fragment>;

  return (
    <ManagedFocus autoFocus={autoFocus}>
      {(updateFocus) => (
        <Toolbar ref={ref} {...props}>
          {callback(updateFocus)}
        </Toolbar>
      )}
    </ManagedFocus>
  );
});

export default ToolbarWithManagedFocus;
