import type React from 'react';

import { classes } from './getContextualHelpCss';

export type ContextualHelpTooltipProps = {
  /**
   * Help content for the tooltip
   */
  children?: React.ReactNode;

  /**
   * Optional CTA that renders next to the Close button. For example, a "Learn More" link.
   */
  cta?: React.ReactNode;

  /**
   * Additional properties on the Close button
   * @deprecated the close button was removed from the default tooltip renderer
   */
  closeButtonProps?: {
    'aria-label': string;
  };
};

const ContextualHelpTooltip = (
  props: ContextualHelpTooltipProps & { onClose: () => void }
) => {
  return (
    <div className={classes.tooltipContainer}>
      <div className={classes.tooltipContent}>{props.children}</div>
      {props.cta && (
        <div className={classes.tooltipActions}>
          <div className={classes.tooltipCta}>{props.cta}</div>
        </div>
      )}
    </div>
  );
};

export default ContextualHelpTooltip;
